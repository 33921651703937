import { makeStyles } from "@mui/styles";

const MbAccordStyles = makeStyles({
  mobOuterContainer: {
    backgroundColor: "white",
    paddingTop: "25px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
    height: "100%",
  },
  container: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    marginTop: "20px",
    marginBottom: "20px",
  },
  accordion: {
    width: "100%",
    borderRadius: "7px !important",
    border: "1px solid #E3E3E3",
    boxShadow: "none !important",
  },
  accordionSummary: {
    fontSize: "12px",
  },
  accordianHeader: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
  },
  iconContainer: {
    width: 25,
    height: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#007E63",
  },
  userIcon: {
    fontSize: "12px !important",
    width: "12px",
    height: "12px",
    color: "white",
  },
  expandIcon: {
    color: "#007E63",
    fontSize: 8,
    transform: "rotate(-90deg) !important",
  },
  headingText: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px !important",
    fontWeight: 400,
    lineHeight: 14.06,
    textAlign: "left",
  },

  customAccord:{
    width: "100%",
    height: "35px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #E3E3E3",
    borderRadius: "7px !important",
  },
  customAccordHeader: {
    width: "100%",
    height: "33px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    backgroundColor: "white",
  },
  accordHeaderText: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px !important",
    fontWeight: 400,
    lineHeight: "14.06px",
    textAlign: "left",
  },
  accordHeaderIconCont: {
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#007E63",
  },
  accordHeaderIcon: {
    width: "14px",
    height: "14px",
  },
  headerExpandIcon: {
    fontSize: "16px !important",
    color: "#007E63",
    transform: "rotate(-90deg)",
  },
  accordianHeaderLeft: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    alignItems: "center",
    width: "100%",
  },


  customAccordExp:{
    width: "100%",
    height: "340px",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #E3E3E3",
    borderRadius: "7px !important",
  },
  customAccordHeaderExp: {
    width: "100%",
    height: "27px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0.7rem 1rem",
    backgroundColor: "#007E63",
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
  },
  accordHeaderTextExp: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px !important",
    fontWeight: 400,
    lineHeight: "14.06px",
    textAlign: "left",
    color: "white !important",
  },
  accordHeaderIconContExp: {
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#007E63",
  },
  accordHeaderIconExp: {
    width: "15px !important",
    height: "15px !important",
  },
  headerExpandIconExp: {
    fontSize: "16px !important",
    color: "white",
    transform: "rotate(-180deg)",
  },
  accordianHeaderLeftExp: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    alignItems: "center",
    width: "100%",
  },

  expandedAccordContent: {
    width: "100%",
    height: "223px",
    padding: "1rem",
    backgroundColor: "white",
    borderBottomLeftRadius: "7px",
    borderBottomRightRadius: "7px",
  }
});

export default MbAccordStyles;
