import {
  // feed_image_dashboard,
  // feed_image_dashboard_active,
  // feed_image_followups,
  // feed_image_followups_active,
  feed_image_campaign,
  feed_image_campaign_active,
  // feed_image_reports,
  // feed_image_reports_active,
  // feed_image_settings,
  // feed_image_settings_active,
  // noun_campaigner,
  // noun_campaigner_active
} from "../icons";

export const FeedbackRoutes = [
  {
    path: "/feedback/dashboard/new",
    label: "Dashboard",
    image: feed_image_campaign,
    active_image: feed_image_campaign_active,
  },
  {
    path: "/feedback/new",
    label: "Inbox",
    image: feed_image_campaign,
    active_image: feed_image_campaign_active,
  },
  {
    path: "/feedback/business/location",
    label: "Business Locations",
    image: feed_image_campaign,
    active_image: feed_image_campaign_active,
  },
  {
    path: "/",
    label: "Automation",
    image: feed_image_campaign,
    active_image: feed_image_campaign_active,
    childData: [
      {
        path: "/feedback/autotag",
        label: "Auto Tag",
        image: feed_image_campaign,
        active_image: feed_image_campaign_active,
      },
      {
        path: "/feedback/workflow/automation",
        label: "Workflow automation",
        image: feed_image_campaign,
        active_image: feed_image_campaign_active,
      },
      {
        path: "/feedback/settings",
        label: "Settings",
        image: feed_image_campaign,
        active_image: feed_image_campaign_active,
      },
    ],
  },
  {
    path: "/",
    label: "UI Management",
    image: feed_image_campaign,
    active_image: feed_image_campaign_active,
    childData: [
      {
        path: "/feedback/campaign/management",
        label: "Feedback Page UI",
        image: feed_image_campaign,
        active_image: feed_image_campaign_active,
      },
      {
        path: "/feedback/settings/scoreboard",
        label: "Scoreboard UI",
        image: feed_image_campaign,
        active_image: feed_image_campaign_active,
      },
    ],
  },
  // {
  //     path: "/",
  //     label: "Old ui",
  //     image: feed_image_campaign,
  //     active_image: feed_image_campaign_active,
  //     childData: [
  //         {
  //             path: "/feedback/dashboard",
  //             label: "Dashboard",
  //             image: feed_image_dashboard,
  //             active_image: feed_image_dashboard_active
  //         },
  //         {
  //             path: "/feedback/followups",
  //             label: "Followups",
  //             image: feed_image_followups,
  //             active_image: feed_image_followups_active
  //         },
  //         {
  //             path: "/feedback/campaign",
  //             label: "Campaign",
  //             image: noun_campaigner,
  //             active_image: noun_campaigner_active
  //         },
  //         {
  //             path: "/feedback/location",
  //             label: "Locations",
  //             image: feed_image_campaign,
  //             active_image: feed_image_campaign_active
  //         },
  //         {
  //             path: "/feedback/reports",
  //             label: "Reports",
  //             image: feed_image_reports,
  //             active_image: feed_image_reports_active
  //         },
  //         {
  //             path: "/feedback/settings",
  //             label: "Settings",
  //             image: feed_image_settings,
  //             active_image: feed_image_settings_active
  //         },
  //         {
  //             path: "/feedback/qr",
  //             label: "SendFeedback",
  //             image: feed_image_settings,
  //             active_image: feed_image_settings_active
  //         }
  //     ]
  // },
];
