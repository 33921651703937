import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const styles = (theme) => ({
  menu: {
    minWidth: "250px",
    borderRadius: "5px",
    backgroundColor: "#fafafa",
  },
  MenuItem: {
    border: "1px solid #f2f2f2",
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: "#FFF0F0",
      "& $mYicon": {
        color: "#D72C0D",
      },
      "& $Typography": {
        fontWeight: "500",
        color: "#D72C0D",
      },
    },
  },
  MenuItemHR: {
    border: "1px solid #f2f2f2",
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: "rgba(0, 126, 99, 0.25)",
      "& $mYicon": {
        color: "rgba(0, 126, 99, 1)",
      },
      "& $Typography": {
        fontWeight: "500",
        color: "rgba(0, 126, 99, 1)",
      },
    },
  },
  mYicon: {
    color: "#7C7C7C",
    height: 24,
    width: 24,
  },
  ListItemIcon: {
    minWidth: "45px",
  },
  Typography: {
    color: "#202223",
    fontSize: "15px",
    lineHeight: "2rem",
  },
});

class MoreMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortAnchorEl: null,
    };
  }

  // handleOpen = (event) => {
  //   this.props.moreMenuClick();
  //   this.setState({ sortAnchorEl: event.currentTarget });
  // };
  handleOpen = (event) => {
    event.stopPropagation(); // Stop the event from propagating
    this.props.moreMenuClick();
    this.setState({ sortAnchorEl: event.currentTarget });
  };
  // handleClose = (value) => {
  //   if (value) {
  //     value.onClick(value.sortName);
  //   }
  //   this.setState({ sortAnchorEl: null });
  // };
  handleClose = (value, event) => {
    event.stopPropagation();
    if (value) {
      value.onClick(value.sortName);
    }
    this.setState({ sortAnchorEl: null });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Tooltip arrow title="More">
          <IconButton
            aria-label="More"
            aria-haspopup="true"
            onClick={this.handleOpen}
            style={{
              color: "rgb(19, 80, 152)",
              width: "30px",
              height: "30px",
            }}          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>

        <Menu
          id="customized-menu"
          MenuListProps={{ disablePadding: true }}
          anchorEl={this.state.sortAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          open={Boolean(this.state.sortAnchorEl)}
          onClose={(event) => this.handleClose("", event)}
          PopoverClasses={{
            paper: classes.menu,
          }}
        >
          {this.props.moreData.map((value, index) => {
            return (
              <MenuItem
                key={index}
                disabled={
                  value.permission === undefined
                    ? false
                    : value.permission
                    ? false
                    : true
                }
                onClick={(event) => this.handleClose(value, event)}
                className={this.props.product === "HR" ? classes.MenuItemHR : classes.MenuItem}
              >
                <ListItemIcon className={classes.ListItemIcon}>
                  <value.icon className={classes.mYicon} />
                </ListItemIcon>

                <Typography
                  className={classes.Typography}
                  color="textPrimary"
                  variant="inherit"
                >
                  {value.title}
                </Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }
}

export default withStyles(styles)(MoreMenu);
