import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  TablePagination,
  Grid,
} from "@material-ui/core";
import { rolesStyles } from "./Styles";
import Skeleton from "react-loading-skeleton";
import { rolesServices } from "../modules/services";
import moment from "moment";
import { alert, utcToLocal } from "../../../../utilities";
import {
  headCells,
  menuListData,
  permissionsObjectForAddRole,
} from "./constants";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { OutlinedButton } from "../../../../utilities/v5/common";
import { ovHrV5Color } from "../../../../config";
import { MdOutlineAddCircle } from "react-icons/md";
import {
  DeleteModal,
  MoreMenu,
  MyLeft50Modal,
  MyPlain25Modal,
} from "../../../../components";
import { MdRemoveRedEye } from "react-icons/md";
import { Link } from "react-router-dom";
import { loginAsUser } from "../../UsersList/modules/actions";
import RoleUsers from "../RoleUsers";
import RoleFormContent from "../AddOrEditRoles/AddOrEditRoles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    height: 48,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Roles = ({ permissionsData }) => {
  const classes = rolesStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [viewAssignUsersLoading, setViewAssignUsersLoading] = useState(false);
  const [viewAssignedUsers, setViewAssignedUsers] = useState([]);
  const [roleDeleteId, setRoleDeleteId] = useState("");
  const [deleteRoleModalLoading, setDeleteRoleModalLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [singleRoleData, setSingleRoleData] = useState({});
  const [totalRolesCount, setTotalRolesCount] = useState(0);
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    if (permissionsData.hr_view_roles) {
      fetchRoles();
    }
  }, [page, rowsPerPage, orderBy, orderDirection]);

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const data = {
        pageNumber: page + 1,
        name: searchTerm,
        sortBy: orderBy,
        sortDirection: orderDirection,
      };
      const response = await rolesServices.fetchData(data);
      setRoles(response.data.result || []);
      setTotalRolesCount(response.data.totalCount || 0);
    } catch (error) {
      console.error("Error fetching roles:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRolesSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setPage(0);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTimeout = setTimeout(() => {
      if (value.trim() === "") {
        fetchRoles();
      } else {
        fetchRoles();
      }
    }, 1500);

    setTypingTimeout(newTimeout);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleViewLogsClicked = () => {};
  const handleAddRoleClick = () => {
    setIsEdit(false);
    setSingleRoleData(permissionsObjectForAddRole);
    setShowAddEditModal(true);
  };

  const modifyMenuItemData = (action, id, roleData) => {
    switch (action) {
      case "assign":
        handleViewAssignUsers(id);
        break;
      case "delete":
        handleRoleDeleteModal(id);
        setRoleDeleteId(id);
        break;
      case "clone":
        handleCloneRole(id);
        break;
      case "edit":
        setIsEdit(true);
        setSingleRoleData(roleData);
        setShowAddEditModal(true);
        break;
      default:
        break;
    }
  };

  const handleCloneRole = async (id) => {
    try {
      const response = await rolesServices.cloneRoles({ id });
      if (response.data.success !== 2) {
        alert.success(response.data.message);
        fetchRoles();
      }
    } catch (error) {
      console.error("Error cloning role:", error);
    }
  };

  const handleViewAssignUsers = async (id) => {
    setViewAssignUsersLoading(true);
    try {
      const response = await rolesServices.fetchUsersByRole(id);
      setViewAssignedUsers(response.data.assignUsers || []);
      setShowAssignModal(true);
    } catch (error) {
      console.error("Error fetching assigned users:", error);
    } finally {
      setViewAssignUsersLoading(false);
    }
  };

  const handleRoleDeleteModal = (id) => {
    setRoleDeleteId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteRole = async () => {
    setDeleteRoleModalLoading(true);
    try {
      const res = await rolesServices.deleteData(roleDeleteId);
      if (res.data.success !== 2) {
        alert.success(res.data.message);
        setShowDeleteModal(false);
        fetchRoles();
      }
    } catch (error) {
      console.error("Error deleting role:", error);
    } finally {
      setDeleteRoleModalLoading(false);
    }
  };

  const handleModalClose = () => {
    setShowAssignModal(false);
    setShowDeleteModal(false);
  };

  const handleAddOrEditModalSubmission = async () => {
    try {
      if (isEdit) {
        const response = await rolesServices.editData(singleRoleData);
        if (response.data.success !== 2) {
          setShowAddEditModal(false);
          alert.success(response.data.message);
          fetchRoles();
          setRoles([]);
        }
      } else {
        const response = await rolesServices.addData(singleRoleData);
        if (response.data.success !== 2) {
          alert.success(response.data.message);
          setShowAddEditModal(false);
          fetchRoles();
          setRoles([]);
        }
      }
    } catch (error) {
      console.error("Error submitting role data:", error);
    }
  };

  return (
    <div className={classes.hrRolesContainer}>
      <Typography className={classes.hrRoleHeading}>Roles</Typography>
      <div className={classes.hrFieldRow}>
        <TextField
          fullWidth
          className={classes.hrTextFieldRole}
          variant="outlined"
          placeholder="Search Role Name"
          onChange={handleRolesSearch}
          value={searchTerm}
          inputProps={{
            style: {
              height: "40px",
              padding: "0 14px",
              border: "none",
            },
          }}
        />

        <Link to="/hr/roles/logs">
          <OutlinedButton
            text={"View Logs"}
            onClick={handleViewLogsClicked}
            borderColor={ovHrV5Color.main}
            textColor={ovHrV5Color.main}
            icon={<MdRemoveRedEye className={classes.hrViewLogIcon} />}
          />
        </Link>
      </div>

      <Paper>
        <TableContainer>
          <div className={classes.hrButtonRow}>
            <Typography className={classes.hrTableHeadHeadingt}>
              {Math.min((page + 1) * rowsPerPage, totalRolesCount)}/
              {totalRolesCount} Roles
            </Typography>

            <OutlinedButton
              disabled={!permissionsData.hr_add_role}
              text={"Add New"}
              onClick={handleAddRoleClick}
              borderColor={ovHrV5Color.main}
              textColor={ovHrV5Color.main}
              icon={<MdOutlineAddCircle className={classes.hrViewLogIcon} />}
            />
          </div>
          <Table className={classes.hrTableContainer} size="medium">
            <TableHead className={classes.hrTableHead}>
              <TableRow>
                {headCells.map((headCell) => (
                  <StyledTableCell
                    align={headCell.align}
                    key={headCell.id}
                    sortDirection={
                      orderBy === headCell.id ? orderDirection : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={
                        orderBy === headCell.id ? orderDirection : "asc"
                      }
                      onClick={() => handleSort(headCell.id)}
                    >
                      <Typography className={classes.hrTableHeadText}>
                        {headCell.label}
                      </Typography>
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? Array.from({ length: rowsPerPage }).map((_, index) => (
                    <TableRow key={index}>
                      {headCells.map((cell) => (
                        <TableCell key={cell.id}>
                          <Skeleton height={12} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : roles.map((role, index) => (
                    <TableRow key={index}>
                      <TableCell>{role.role_name}</TableCell>
                      <TableCell>{role.role_description}</TableCell>
                      <TableCell>{role.user_role_count}</TableCell>
                      <TableCell>
                        {moment(
                          utcToLocal(role.createdAt).locale("en-US")
                        ).format("DD MMM YYYY,hh:mm A")}
                      </TableCell>
                      <TableCell>
                        {role.updatedAt
                          ? moment(
                              utcToLocal(role.updatedAt).locale("en-US")
                            ).format("DD MMM YYYY, hh:mm A")
                          : ""}
                      </TableCell>
                      <TableCell>
                        <MoreMenu
                          product={"HR"}
                          moreMenuClick={() => {}}
                          moreData={menuListData(permissionsData, role._id)
                            .filter((item) => item.permission)
                            .map((item) => ({
                              ...item,
                              onClick: () =>
                                modifyMenuItemData(
                                  item.sortName,
                                  role._id,
                                  role
                                ),
                            }))}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && (
          <TablePagination
            component="div"
            count={-1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>

      <MyLeft50Modal
        bgColor={ovHrV5Color.main}
        title={isEdit ? "Edit Roles" : "Add Roles"}
        open={showAddEditModal}
        onClose={() => setShowAddEditModal(false)}
        handleSubmit={handleAddOrEditModalSubmission}
      >
        <RoleFormContent
          roleData={singleRoleData}
          isEdit={isEdit}
          onRoleDataChange={(updatedData) => setSingleRoleData(updatedData)}
        />
      </MyLeft50Modal>

      <MyPlain25Modal
        bgColor={ovHrV5Color.main}
        title="View Assigned User"
        open={showAssignModal}
        onClose={handleModalClose}
      >
        <Grid container spacing={0}>
          <RoleUsers
            isAssignUsersLoading={viewAssignUsersLoading}
            // toggleAssignUser={props.toggleAssignUser}
            assignUsers={viewAssignedUsers}
            loginAsUser={loginAsUser}
          />
        </Grid>
      </MyPlain25Modal>

      <DeleteModal
        product={"HR"}
        bgColor={ovHrV5Color.main}
        desc="Are you sure you want to delete?"
        open={showDeleteModal}
        onClose={handleModalClose}
        handleDelete={handleDeleteRole}
      />
    </div>
  );
};

export default Roles;
