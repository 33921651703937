import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  fetchTodoList,
  addList,
} from "../pages/Todo/Dashboard/modules/actions";
import { userPermissions } from "../pages/common/Roles/modules/actions";
import { getLessonDetailsById } from "../pages/eLearning/MyCourses/modules/actions";
import { onChangePage, onChangeSidebar } from "../components/modules/actions";
import {
  userLogout,
  userExitAccess,
} from "../pages/common/UsersList/modules/actions";
import classnames from "classnames";
import Header from "./components/Header";
// import ElearningHeaderMobile from "./components/ElearningHeaderMobile";
import Sidebar from "./components/Sidebar";
import LogoutMenus from "./components/LogoutMenus";
import {
  MyCenterModal,
  MyTextfield,
  LoadingData,
  LoadingDataHR,
} from "../components";
import { Grid } from "@material-ui/core";
import Cookies from "universal-cookie";
import { history } from "../history";
// import { COOKIE_MAX_AGE } from "../config";
import {
  ElearningBottomNav,
  ElearningTopNav,
} from "../mobileView/eLearning/components";
import { withWindowSize } from "../mobileView/hoc/withWindowSize";
import { HrBottomNav } from "../mobileView/HR/components";
import HrMobileTopNav from "../mobileView/HR/components/TopNav";

const cookies = new Cookies();

const styles = (theme) => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflow: "hidden",
    backgroundColor: "#F5F5F5",
    height: "100%",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    marginTop: "56px",
    width: `calc(100vw - 200px)`,
    minHeight: `calc(100vh - 56px)`,
  },
  contentMobile: {
    flexGrow: 1,
    padding: theme.spacing(0),
    marginTop: "56px",
    width: `100vw`,
    minHeight: `100vh`,
    maxHeight: "100%",
    backgroundColor: "#ffffff",
    //  overflowY: "auto",
  },
  contentMobileHR: {
    flexGrow: 1,
    padding: theme.spacing(0),
    // marginTop: "56px",
    width: `100vw`,
    minHeight: `100vh`,
    maxHeight: "100%",
    overflowY: "auto",
  },
  contentShift: {
    width: `calc(100vw - ${200 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "whitesmoke",
  },
});

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listTitle: "",
      activeProduct: "",
      errors: {},
      helperText: {},
    };
  }

  componentDidMount = () => {
    var isPermissionReload =
      Object.keys(this.props.permissionsData).length > 0 ? false : true;
    this.props.userPermissions(isPermissionReload);
    window.scrollTo(0, 0);
    this.props.onChangePage();
    this.getActiveProduct();
  };

  getActiveProduct = () => {
    var currentLocation = window.location.pathname;
    var productName = "";
    if (currentLocation.includes("/feedback/")) {
      productName = "Feedback";
    } else if (currentLocation.includes("/elearning/")) {
      productName = "eLearning";
    } else if (currentLocation.includes("/todo/")) {
      productName = "Todo";
    } else if (currentLocation.includes("/scheduler/")) {
      productName = "Scheduler";
    } else if (currentLocation.includes("/newsandupdate/")) {
      productName = "News";
    } else if (currentLocation.includes("/chat/")) {
      productName = "Chat";
    } else if (currentLocation.includes("/forms/")) {
      productName = "Forms";
    } else if (currentLocation.includes("/ovRating/")) {
      productName = "360 Rating";
    } else if (currentLocation.includes("/settings/")) {
      productName = "Settings";
    } else if (currentLocation.includes("/contacts/")) {
      productName = "Contacts";
    } else if (currentLocation.includes("/cashtrack/")) {
      productName = "CashTrack";
    } else if (currentLocation.includes("/inventory/")) {
      productName = "Inventory";
    } else if (currentLocation.includes("/checklist/")) {
      productName = "CheckList";
    } else if (currentLocation.includes("/hr/")) {
      productName = "ovHR";
    } else {
      productName = "";
    }
    this.setState({ activeProduct: productName });
    if (productName === "Todo") {
      this.props.fetchTodoList();
    }
  };

  handleChange = (e) => {
    let state = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        [state]: value,
      },
    }));
  };

  handleFocus = (e) => {
    let state = e.target.name;
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [state]: false,
      },
      errorsHelper: {
        ...prevState.errorsHelper,
        [state]: "",
      },
    }));
  };

  handleSidebarToggle = () => {
    this.props.onChangeSidebar(!this.props.isSidebarOpen);
  };

  addList = () => {
    var data = {
      title: this.state.listTitle,
    };
    this.props.addList(data);
    this.addListClose();
  };

  addListOpen = () => {
    this.setState({ listTitle: "", openList: true });
  };

  addListClose = () => {
    this.setState({ openList: false });
  };

  handleChange = (e) => {
    let state = e.target.name;
    this.setState({ [state]: e.target.value });
  };

  selectMyCourseLesson = (lesson) => {
    var data = {
      course_id: this.props.active_course_details._id,
      lesson_id: lesson._id,
    };
    this.props.getLessonDetailsById(data, lesson);
  };

  handleLogoutOpen = (event) => {
    this.setState({
      logoutAnchor: event.currentTarget,
    });
  };

  handleLogoutClose = (value) => {
    if (value === "exit_access") {
      this.props.userExitAccess();
    }
    if (value === "logout" || value === "exit_access") {
      this.props.userLogout();
    }
    if (value === "profile") {
      history.push("/settings/profile");
    }
    this.setState({
      logoutAnchor: null,
    });
  };

  render() {
    const {
      classes,
      children,
      history,
      categoryData,
      myLessons,
      listData,
      locationData,
      displaySection,
      pageNo,
      rowsPerPage,
      locationTimezone,
      permissionsLoading,
      permissionsData,
    } = this.props;

    const { activeProduct } = this.state;
    var ovUserName = cookies.get("ovUserName");
    var ovUserImage = cookies.get("ovUserImage");

    const { width } = this.props.windowSize;
    const isMobile = width < 768;

    if (activeProduct === "ovHR") {
      if (permissionsLoading) return <LoadingDataHR />;
    } else {
      if (permissionsLoading) return <LoadingData />;
    }
    return (
      <div className={classes.root}>
        {!isMobile ? (
          <Header
            drawerWidth={200}
            userName={ovUserName}
            userImage={ovUserImage}
            locationData={locationData}
            activeProduct={activeProduct}
            handleLogoutOpen={this.handleLogoutOpen}
            handleSidebarToggle={this.handleSidebarToggle}
            isSidebarOpen={this.props.isSidebarOpen}
            history={history}
          />
        ) : this.state.activeProduct === "eLearning" ? (
          // <ElearningHeaderMobile
          //   drawerWidth={200}
          //   userName={ovUserName}
          //   userImage={ovUserImage}
          //   locationData={locationData}
          //   activeProduct={activeProduct}
          //   handleLogoutOpen={this.handleLogoutOpen}
          //   handleSidebarToggle={this.handleSidebarToggle}
          //   isSidebarOpen={this.props.isSidebarOpen}
          //   history={history}
          // />
          <ElearningTopNav userName={ovUserName} />
        ) // : this.state.activeProduct === "ovHR" ? (
         // <HrMobileTopNav permissionsData={permissionsData} />
        // ) 
        : (
          <Header
            drawerWidth={200}
            userName={ovUserName}
            userImage={ovUserImage}
            locationData={locationData}
            permissionsData={permissionsData}
            activeProduct={activeProduct}
            handleLogoutOpen={this.handleLogoutOpen}
            handleSidebarToggle={this.handleSidebarToggle}
            isSidebarOpen={this.props.isSidebarOpen}
            history={history}
          />
        )}

        {isMobile && this.state.activeProduct === "eLearning" ? (
          <ElearningBottomNav />
        ) // : isMobile && this.state.activeProduct === "ovHR" ? (
         // <HrBottomNav permissionsData={permissionsData} />
       // ) 
        : (
          // <Navigation />
          <Sidebar
            permissionsData={permissionsData}
            locationTimezone={locationTimezone}
            active_course_details={this.props.active_course_details}
            active_lesson_details={this.props.active_lesson_details}
            selectMyCourseLesson={this.selectMyCourseLesson}
            addListOpen={this.addListOpen}
            displaySection={displaySection}
            myLessons={myLessons}
            categoryData={categoryData}
            listData={listData}
            activeProduct={activeProduct}
            handleSidebarToggle={this.handleSidebarToggle}
            isSidebarOpen={this.props.isSidebarOpen}
          />
        )}
        <div
          className={
            isMobile
              ? classes.contentMobile
              : classnames(classes.content, {
                  [classes.contentShift]: this.props.isSidebarOpen,
                })
          }
        >
          {React.cloneElement(children, {
            permissionsData,
            onChangePage: this.props.onChangePage,
            pageNo,
            rowsPerPage,
          })}
        </div>

        <LogoutMenus
          handleLogoutClose={this.handleLogoutClose}
          logoutAnchor={this.state.logoutAnchor}
        />

        <MyCenterModal
          title="Create List"
          open={this.state.openList}
          onClose={this.addListClose}
          handleSubmit={this.addList}
          isActive={this.state.listTitle.length > 3 ? true : false}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <MyTextfield
                name="listTitle"
                label="Title"
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                value={this.state.listTitle}
                type="text"
                error={this.state.errors.listTitle}
                helperText={
                  this.state.errors.listTitle
                    ? this.state.errorsHelper.listTitle
                    : ""
                }
                placeholder="Enter list title"
              />
            </Grid>
          </Grid>
        </MyCenterModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  displaySection: state.eLearningMyCourses.displaySection,
  active_lesson_details: state.eLearningMyCourses.active_lesson_details,
  active_course_details: state.eLearningMyCourses.active_course_details,
  myLessons: state.eLearningMyCourses.courseLessonsData,
  listData: state.todoDashboard.listData,
  categoryData: state.eLearningCategory.categoryData,
  locationData: state.settingsLocation.locationData,
  isSidebarOpen: state.localData.isSidebarOpen,
  pageNo: state.localData.pageNo,
  rowsPerPage: state.localData.rowsPerPage,
  permissionsLoading: state.settingsRoles.permissionsLoading,
  permissionsData: state.settingsRoles.permissionsData,
  locationTimezone: state.settingsRoles.locationTimezone,
});

const ConnectWith = connect(mapStateToProps, {
  getLessonDetailsById,
  userLogout,
  userExitAccess,
  userPermissions,
  onChangePage,
  fetchTodoList,
  addList,
  onChangeSidebar,
})(MainLayout);

const ConnectedWithWindowSize = withWindowSize(ConnectWith);

export default withStyles(styles)(ConnectedWithWindowSize);
