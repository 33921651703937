// components
import Modal from "@mui/material/Modal";

// icons
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { mobileTheme } from "../../constant";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { MobileAccordion } from "../../components";
import { useState } from "react";
import { hexToRgb } from "../../../../utilities/v5/common";
import { InputAdornment } from "@mui/material";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    outline: "none",
  },
  content: {
    position: "absolute",
    bottom: "0",
    top: "0px",
    width: "100%",
    height: "100%",
    paddingBottom: "100px",
    background: "white",
    outline: "none",
    border: "none",
  },
  container: {
    width: "100%",
    display: "flex",
    position: "fixed",
    padding: "10px",
    height: "56px",
    marginBottom: "70px",
    alignItems: "center",
    background: "white",
    boxShadow: "0px 1px 4px 0px #0000001F",
    justifyContent: "center",
    zIndex: 99,
  },
  backButton: {
    position: "absolute",
    left: "20px",
    color: mobileTheme,
  },
  title: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#000000",
  },
  accordianContainer: {
    marginTop: "60px",
    padding: "10px 20px",
    overflowY: "auto",
    display: "flex",
    height: "100%",
    paddingBottom: "120px",
    flexDirection: "column",
    gap: "10px",
    border: "none",
  },
  checkbox: {
    color: mobileTheme,
    "&.Mui-checked": {
      color: mobileTheme,
    },
  },
  label: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#00000080",
  },
  btnContainer: {
    position: "fixed",
    bottom: "0px",
    height: "115px",
    background: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    gap: "10px",
    paddingTop: "10px",
    width: "100%",
  },
  saveBtn: {
    width: "150px",
    height: "40px",
    borderRadius: "7px",
    background: mobileTheme,
    color: "white",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cancelBtn: {
    width: "150px",
    height: "40px",
    borderRadius: "7px",
    background: `rgba(${hexToRgb(mobileTheme)}, 0.25)`,
    color: mobileTheme,
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  searchInput: {
    width: "100%",
  },

  inputField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "6px",
        borderColor: "#E3E3E3",
        borderWidth: "1px",
      },
      "&:hover fieldset": {
        borderColor: "#007E63",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007E63",
      },
    },
  },
}));

export const RolePermissionModal = ({
  open,
  handleClose,
  selectedTab,
  onChangeCheck,
  myItem,
  handleChangeSelectValue,
}) => {
  const classes = useStyles();

  const [currentIndex, setCurrentIndex] = useState(null);
  const [common_assigned_roles_dropdown, setcommon_assigned_roles_dropdown] =
    useState([
      {
        label: "All roles",
        value: 1,
      },
      {
        label: "Assigned Roles",
        value: 2,
      },
      {
        label: "Assigned roles with same levels and subordinates",
        value: 3,
      },
      {
        label: "SubOrdinates",
        value: 4,
      },
    ]);

  const handleAccordionChange = (index) => {
    setCurrentIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const generateCheckbox = (label) => (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(event) => onChangeCheck(event, label.name)}
            checked={myItem[label.name]}
            name={label.name}
            classes={{ root: classes.checkbox }}
            size="small"
          />
        }
        // label={label.label}
        label={
          label.name === "common_roles_view_assigned" ? (
            <Typography
              variant="h6"
              component="div"
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: 400,
              }}
            >
              <span style={{ color: "#00000080", fontSize: "12px" }}>View</span>
              <TextField
                variant="outlined"
                select
                size="small"
                disabled={
                  myItem.common_roles_view_assigned === 1 ? false : true
                }
                value={myItem.common_roles_view ? myItem.common_roles_view : 1}
                style={{
                  width: 90,
                  marginTop: -19,
                  marginLeft: 10,
                  height: 23,
                  marginRight: 10,
                }}
                InputProps={{
                  style: { borderColor: mobileTheme }, // Change to your desired border color
                  classes: {
                    focused: "focused",
                  },
                }}
                InputLabelProps={{
                  style: { color: mobileTheme }, // Change this to your desired label color
                }}
                SelectProps={{
                  classes: {
                    select: "select",
                  },
                }}
                name="common_roles_view"
                onChange={handleChangeSelectValue}
              >
                {common_assigned_roles_dropdown.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <span style={{ color: "#00000080", fontSize: "12px" }}>
                roles
              </span>
            </Typography>
          ) : label.name === "common_users_is_view" ? (
            <Typography
              variant="h6"
              component="div"
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: 400,
              }}
            >
              <span
                style={{
                  whiteSpace: "nowrap",
                  color: "#00000080",
                  fontSize: "12px",
                }}
              >
                View users of
              </span>
              <TextField
                variant="outlined"
                select
                size="small"
                disabled={myItem.common_users_is_view === 1 ? false : true}
                value={myItem.common_users_view ? myItem.common_users_view : 1}
                style={{
                  width: 90,
                  marginTop: -19,
                  marginLeft: 10,
                  height: 23,
                  marginRight: 10,
                }}
                InputProps={{
                  style: { borderColor: mobileTheme }, // Change to your desired border color
                  classes: {
                    focused: "focused",
                  },
                }}
                InputLabelProps={{
                  style: { color: mobileTheme }, // Change this to your desired label color
                }}
                SelectProps={{
                  classes: {
                    select: "select",
                  },
                }}
                name="common_users_view"
                onChange={handleChangeSelectValue}
              >
                {common_assigned_roles_dropdown.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <span style={{ color: "#00000080", fontSize: "12px" }}>
                roles
              </span>
            </Typography>
          ) : (
            label.label
          )
        }
        classes={{ label: classes.label }}
      />
    </FormGroup>
  );

  const generatePermissionDetails = (details) => (
    <div>{details.map((label, index) => generateCheckbox(label))}</div>
  );

  const eLearningPermissionDetails = [
    // {
    //   id: 1,
    //   name: "Dashboard",
    //   details: generatePermissionDetails([
    //     { name: "elearning_dashboard", label: "Dashboard" },
    //   ]),
    // },
    // {
    //   id: 2,
    //   name: "My Course",
    //   details: generatePermissionDetails([
    //     { name: "elearning_mycourse", label: "My Course" },
    //   ]),
    // },
    // {
    //   id: 3,
    //   name: "My Library",
    //   details: generatePermissionDetails([
    //     { name: "elearning_my_library", label: "My Library" },
    //   ]),
    // },
    // {
    //   id: 4,
    //   name: "Grading",
    //   details: generatePermissionDetails([
    //     { name: "elearning_grading_my_grading", label: "My Grading" },
    //     { name: "elearning_grading_team_grading", label: "Team Grading" },
    //     { name: "elearning_grading_settings", label: "Settings" },
    //   ]),
    // },
    // {
    //   id: 5,
    //   name: "Progress",
    //   details: generatePermissionDetails([
    //     { name: "elearning_progress", label: "Progress" },
    //   ]),
    // },
    {
      id: 6,
      name: "Library",
      details: generatePermissionDetails([
        { name: "elearning_grading_my_grading", label: "Add Category" },
        { name: "elearning_grading_my_grading", label: "Edit Category" },
        { name: "elearning_grading_my_grading", label: "Delete Category" },
        { name: "elearning_grading_my_grading", label: "Add Lesson" },
        { name: "elearning_grading_my_grading", label: "Edit Lesson" },
        { name: "elearning_grading_my_grading", label: "Delete Lesson" },
        { name: "elearning_grading_my_grading", label: "Copy Link" },
      ]),
    },
    // {
    //   id: 7,
    //   name: "Feedback",
    //   details: generatePermissionDetails([
    //     { name: "elearning_view_feedback", label: "View Feedback" },
    //     { name: "elearning_submit_feedback", label: "Submit Feedback" },
    //     {
    //       name: "elearning_feedback_update_status",
    //       label: "Edit/Update Feedback",
    //     },
    //   ]),
    // },
    {
      id: 8,
      name: "Assign Library",
      details: (
        <TextField
          key="unique-key"
          placeholder="Search Library"
          variant="outlined"
          size="small"
          className={classes.inputField}
          fullWidth
          style={{
            width: "100%",
            height: "40px",
            borderRadius: "6px",
          }}
          inputProps={{
            style: {
              fontSize: "14px",
              lineHeight: "16.41px",
              fontFamily: "Roboto",
              fontWeight: "400",
              color: "#00000080",
            },
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: "#007E63", fontSize: "16px" }} />
              </InputAdornment>
            ),
          }}
        />
      ),
    },
    // {
    //   id: 9,
    //   name: "Earning",
    //   details: generatePermissionDetails([
    //     { name: "elearning_my_earnings", label: "My Earnings" },
    //     { name: "elearning_team_earnings", label: "Team Earnings" },
    //     { name: "elearning_earning_settings", label: "Earning Settings" },
    //   ]),
    // },
  ];

  const schedularPermissionDetails = [
    // {
    //   id: 1,
    //   name: "Scheduler Dashboard",
    //   details: generatePermissionDetails([
    //     { name: "scheduler_dashboard", label: "Scheduler Dashboard" },
    //   ]),
    // },
    {
      id: 2,
      name: "Scheduler",
      details: generatePermissionDetails([
        { name: "scheduler_schedule_add_shifts", label: "Add Shifts" },
        {
          name: "scheduler_schedule_view_others_schedule",
          label: "View Team Schedule",
        },
        { name: "scheduler_schedule_edit_shifts", label: "Edit Shifts" },
        { name: "scheduler_schedule_delete_shifts", label: "Delete Shifts" },
        { name: "scheduler_schedule_drag", label: "Drag Drop Shifts" },
        { name: "scheduler_schedule_view_events", label: "View Events" },
        { name: "scheduler_schedule_add_events", label: "Add Events" },
        { name: "scheduler_schedule_edit_events", label: "Edit Events" },
        { name: "scheduler_schedule_delete_events", label: "Delete Events" },
        { name: "scheduler_schedule_print", label: "Print Schedule" },
        { name: "scheduler_schedule_copy", label: "Copy Schedule" },
        { name: "scheduler_schedule_publish", label: "Publish Schedule" },
      ]),
    },
    {
      id: 3,
      name: "My Availability",
      details: generatePermissionDetails([
        { name: "scheduler_myavailability_add", label: "Add Availability" },
        { name: "scheduler_myavailability_edit", label: "Edit Availability" },
        {
          name: "scheduler_myavailability_delete",
          label: "Delete Availability",
        },
      ]),
    },
    {
      id: 4,
      name: "Team Availability",
      details: generatePermissionDetails([
        { name: "scheduler_teamavailability_add", label: "Add Availability" },
        { name: "scheduler_teamavailability_edit", label: "Edit Availability" },
        {
          name: "scheduler_teamavailability_delete",
          label: "Delete Availability",
        },
        {
          name: "scheduler_teamavailability_approve",
          label: "Approve & Decline",
        },
      ]),
    },
    {
      id: 5,
      name: "Time-off Requests",
      details: generatePermissionDetails([
        { name: "scheduler_timeoffrequests_add", label: "Add Time Off" },
        { name: "scheduler_timeoffrequests_edit", label: "Edit Time Off" },
        { name: "scheduler_timeoffrequests_delete", label: "Delete Time Off" },
        {
          name: "scheduler_timeoffrequests_approve",
          label: "Approve Time Off",
        },
      ]),
    },
    {
      id: 6,
      name: "Trade Shift",
      details: generatePermissionDetails([
        { name: "scheduler_tradeshifts_add", label: "Add Trade" },
        { name: "scheduler_tradeshifts_edit", label: "Edit Trade" },
        { name: "scheduler_tradeshifts_delete", label: "Delete Trade" },
        { name: "scheduler_tradeshifts_approve", label: "Approve & Decline" },
      ]),
    },
    {
      id: 7,
      name: "Cover Shifts",
      details: generatePermissionDetails([
        { name: "scheduler_covershifts_add", label: "Add Cover" },
        { name: "scheduler_covershifts_edit", label: "Edit Cover" },
        { name: "scheduler_covershifts_delete", label: "Delete Cover" },
        { name: "scheduler_covershifts_approve", label: "Approve & Decline" },
      ]),
    },
    {
      id: 8,
      name: "TimeSheets",
      details: generatePermissionDetails([
        { name: "scheduler_timesheet_add", label: "Add Timecard" },
        { name: "scheduler_timesheet_edit", label: "Edit Timecard" },
        { name: "scheduler_timesheet_view", label: "View Timecard" },
        {
          name: "scheduler_timesheet_view_timecard_notes",
          label: "Add/View Timecard notes",
        },
        { name: "scheduler_timesheet_fix_issue", label: "Fix Timecard issues" },
        {
          name: "scheduler_timesheet_view_edit_history",
          label: "View Edit History",
        },
        { name: "scheduler_timesheet_lock", label: "Lock Unlock Timecard" },
        { name: "scheduler_timesheet_filter", label: "Filter Timesheet" },
        { name: "scheduler_timesheet_download", label: "Download Timesheet" },
        {
          name: "scheduler_timesheet_view_others_timesheet",
          label: "View Team Timesheet",
        },
      ]),
    },
    {
      id: 9,
      name: "Payroll Template",
      details: generatePermissionDetails([
        { name: "scheduler_payroll_add", label: "Add Payroll" },
        { name: "scheduler_payroll_edit", label: "Edit Payroll" },
        { name: "scheduler_payroll_delete", label: "Delete Payroll" },
        { name: "scheduler_payroll_assign", label: "Assign Payroll" },
        { name: "scheduler_payroll_code_edit", label: "Edit Timecode" },
        { name: "scheduler_payroll_view_assign", label: "View Assign Payroll" },
        { name: "scheduler_payroll_code_delete", label: "Delete Timecode" },
        { name: "scheduler_payroll_code_add", label: "Add Timecode" },
      ]),
    },
    {
      id: 10,
      name: "Payroll Run",
      details: generatePermissionDetails([
        { name: "level_1_approval", label: "Level 1 Approval" },
        { name: "level_2_approval", label: "Level 2 Approval" },
        { name: "level_3_approval", label: "Level 3 Approval" },
        { name: "payrun_reset", label: "Reset Button" },
      ]),
    },
    {
      id: 11,
      name: "Correction Request",
      details: generatePermissionDetails([
        { name: "correction_Level_1_Approval", label: "Level 1 Approval" },
        { name: "correction_Level_2_Approval", label: "Level 2 Approval" },
        { name: "correction_Level_3_Approval", label: "Level 3 Approval" },
        {
          name: "delete_correction_request",
          label: "Delete Correction Request",
        },
        { name: "correction_reset", label: "Reset Correction Request" },
      ]),
    },
    {
      id: 12,
      name: "Payroll Summary",
      details: generatePermissionDetails([
        { name: "scheduler_payroll_id", label: "Payroll id" },
        {
          name: "scheduler_payroll_reset_data_and_approvals",
          label: "Reset Data & Approvals",
        },
      ]),
    },
    // {
    //   id: 13,
    //   name: "Scheduler Roles",
    //   details: generatePermissionDetails([
    //     { name: "scheduler_roles_add", label: "Add Role" },
    //     { name: "scheduler_roles_edit", label: "Edit Role" },
    //     { name: "scheduler_roles_delete", label: "Delete Role" },
    //   ]),
    // },
    // {
    //   id: 14,
    //   name: "Scheduler Settings",
    //   details: generatePermissionDetails([
    //     { name: "scheduler_settings", label: "Scheduler Settings" },
    //   ]),
    // },
    // {
    //   id: 15,
    //   name: "Receive Manager Alerts",
    //   details: generatePermissionDetails([
    //     { name: "receive_manager_alert", label: "Receive Manager Alerts" },
    //   ]),
    // },
  ];

  const commonPermissionDetails = [
    {
      id: 1,
      name: "Company",
      details: generatePermissionDetails([
        { name: "common_company_edit", label: "Edit Company" },
        { name: "common_company_delete", label: "Delete Company" },
      ]),
    },
    {
      id: 2,
      name: "Locations",
      details: generatePermissionDetails([
        { name: "common_location_add", label: "Add Location" },
        { name: "common_location_edit", label: "Edit Location" },
        { name: "common_location_delete", label: "Delete Location" },
      ]),
    },
    {
      id: 3,
      name: "Users",
      details: generatePermissionDetails([
        { name: "common_users_add", label: "Add User" },
        { name: "common_users_edit", label: "Edit User" },
        { name: "common_users_delete", label: "Delete User" },
        { name: "common_users_loginas", label: "Login as User" },
        { name: "common_users_active", label: "Deactivate User" },
        { name: "common_users_is_view", label: "View Assigned Users" },
      ]),
    },
    {
      id: 4,
      name: "Roles",
      details: generatePermissionDetails([
        { name: "common_roles_add", label: "Add Roles" },
        { name: "common_roles_edit", label: "Edit Roles" },
        { name: "common_roles_delete", label: "Delete Roles" },
        { name: "common_roles_view_assigned", label: "View Assigned Roles" },
      ]),
    },
    {
      id: 5,
      name: "Payroll Management",
      details: generatePermissionDetails([
        { name: "common_payroll_add", label: "Add Payroll" },
        { name: "common_payroll_edit", label: "Edit Payroll" },
        { name: "common_payroll_delete", label: "Delete Payroll" },
        { name: "common_payroll_viewHistory", label: "View History" },
      ]),
    },
    {
      id: 6,
      name: "Ticket",
      details: generatePermissionDetails([
        { name: "common_tickets_add", label: "Add Ticket" },
        { name: "common_tickets_edit", label: "Edit Ticket" },
        { name: "common_tickets_delete", label: "Delete Ticket" },
      ]),
    },
    {
      id: 7,
      name: "Ideas",
      details: generatePermissionDetails([
        { name: "common_ideas_add", label: "Add Ideas" },
        { name: "common_ideas_edit", label: "Edit Ideas" },
        { name: "common_ideas_delete", label: "Delete Ideas" },
      ]),
    },
    {
      id: 8,
      name: "Billings",
      details: generatePermissionDetails([
        { name: "common_billings", label: "Billings" },
      ]),
    },
    {
      id: 9,
      name: "Coupons",
      details: generatePermissionDetails([
        { name: "common_coupons", label: "Coupons" },
      ]),
    },
    {
      id: 10,
      name: "Products",
      details: generatePermissionDetails([
        { name: "common_products", label: "Products" },
      ]),
    },
    {
      id: 11,
      name: "Hierarchy",
      details: generatePermissionDetails([
        { name: "common_hierarchy", label: "Hierarchy" },
      ]),
    },
  ];

  const newsPermissionDetails = [
    {
      id: 1,
      name: "My News",
      details: generatePermissionDetails([
        { name: "view_all_my_news", label: "View All News" },
        { name: "view_my_news_details", label: "View News Details" },
      ]),
    },
    {
      id: 2,
      name: "News Builder",
      details: generatePermissionDetails([
        { name: "view_all_news_builder", label: "View All News" },
        { name: "news_builder_details", label: "View News Details" },
        { name: "create_news_builder", label: "Create News" },
        { name: "edit_news_builder", label: "Edit News" },
        { name: "delete_news_builder", label: "Delete News" },
      ]),
    },
    {
      id: 3,
      name: "News Report",
      details: generatePermissionDetails([
        { name: "view_news_report", label: "News Report" },
      ]),
    },
  ];

  const RatingPermissionDetails = [
    {
      id: 1,
      name: "Rating Form",
      details: generatePermissionDetails([
        { name: "rating_form_add", label: "Add Form" },
        { name: "rating_form_delete", label: "Delete Form" },
      ]),
    },
  ];

  const feedBackPermissionDetails = [
    // {
    //   id: 1,
    //   name: "Dashboard",
    //   details: generatePermissionDetails([
    //     { name: "feedback_dashboard", label: "Dashboard" },
    //   ]),
    // },
    {
      id: 2,
      name: "Followups",
      details: generatePermissionDetails([
        { name: "feedback_followups_approve", label: "Approve Follow ups" },
        {
          name: "feedback_followups_view_detail",
          label: "View Details Followups",
        },
        { name: "feedback_followups_delete", label: "Delete Followups" },
      ]),
    },
    {
      id: 3,
      name: "Campaign",
      details: generatePermissionDetails([
        { name: "feedback_campaign_add", label: "Add Campaign" },
        { name: "feedback_campaign_edit", label: "Edit Campaign" },
        { name: "feedback_campaign_delete", label: "Delete Campaign" },
        {
          name: "feedback_campaign_assign_location",
          label: "Assign Locations",
        },
      ]),
    },
    {
      id: 4,
      name: "Locations",
      details: generatePermissionDetails([
        { name: "feedback_qr_locations_devices", label: "Device Management" },
        { name: "feedback_qr_locations_view_history", label: "View History" },
        { name: "feedback_qr_locations_edit", label: "Edit Location" },
      ]),
    },
    {
      id: 5,
      name: "Reports",
      details: generatePermissionDetails([
        { name: "feedback_reports", label: "Reports" },
      ]),
    },
    {
      id: 6,
      name: "Settings",
      details: generatePermissionDetails([
        { name: "feedback_settings_sms", label: "SMS" },
        { name: "feedback_settings_percentage", label: "Percentage" },
        { name: "feedback_settings_timezone", label: "Timezone" },
        {
          name: "feedback_settings_notification_dashboard",
          label: "Dashboard Notification",
        },
        {
          name: "feedback_settings_notification_email",
          label: "Email Notification",
        },
        {
          name: "feedback_settings_notification_push",
          label: "Push Notification",
        },
        {
          name: "feedback_settings_notification_mobile_push",
          label: "Mobile Push notification",
        },
      ]),
    },
    {
      id: 7,
      name: "Team",
      details: generatePermissionDetails([
        { name: "feedback_team", label: "Team" },
      ]),
    },
  ];

  const renderPermission = (data) => {
    return data.map((item, index) => (
      <MobileAccordion
        key={item.id}
        index={index}
        currentIndex={currentIndex}
        onChange={handleAccordionChange}
        heading={item.name}
        details={item.details}
        themeColor={mobileTheme}
      />
    ));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modal}
    >
      <div className={classes.content}>
        <div className={classes.container}>
          <div className={classes.backButton} onClick={handleClose}>
            <MdOutlineArrowBackIosNew className={classes.backIcon} />
          </div>
          <div className={classes.title}>{selectedTab} Permission</div>
        </div>
        <div className={classes.accordianContainer}>
          {selectedTab === "eLearning"
            ? renderPermission(eLearningPermissionDetails)
            : selectedTab === "Scheduler"
            ? renderPermission(schedularPermissionDetails)
            : selectedTab === "Common"
            ? renderPermission(commonPermissionDetails)
            : selectedTab === "News"
            ? renderPermission(newsPermissionDetails)
            : selectedTab === "Feedback"
            ? renderPermission(feedBackPermissionDetails)
            : selectedTab === "360 Rating" &&
              renderPermission(RatingPermissionDetails)}
        </div>

        <div className={classes.btnContainer}>
          <button className={classes.saveBtn}>Save</button>
          <button className={classes.cancelBtn}>Cancel</button>
        </div>
      </div>
    </Modal>
  );
};
