import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { MyTimePicker, MySelectField, MyTextfield, MyDateTimePicker, MyDatePicker, BasicTimePicker } from '../../../components'
import DeleteIcon from '@material-ui/icons/DeleteForever';
import BackIcon from '@material-ui/icons/ArrowBack';
import { utcDateToLocal } from '../../../utilities';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import DatePicker from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import addDays from 'date-fns/addDays';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DateFnsUtils from '@date-io/date-fns';
import 'bootstrap/dist/css/bootstrap.min.css';
import AlarmIcon from '@mui/icons-material/Alarm';
import SnoozeIcon from '@mui/icons-material/Snooze';
import ClockIcon from '@mui/icons-material/AccessTime';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import { utcToTimeZone } from "../../../utilities/utcToTimeZone";
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import dayjs from 'dayjs';
import './viewtimecard.css'
import InfoIcon from '@mui/icons-material/Info';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Divider,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ButtonGroup,
  Button,
  Avatar,
  IconButton,
  TextField,
  Card,
  Tooltip
}
  from '@material-ui/core';
import { FILE_URL, schedulerColor, GOOGLE_API_KEY } from "../../../config";
import { localTimeToUtc, utcToLocal, alert } from '../../../utilities'
import {
  StaticGoogleMap,
  Marker,
} from 'react-static-google-map';

import { Check as CheckIcon } from '@material-ui/icons';
import { color } from "@mui/system";

const styles = (theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
        display: "none"
    },
  },
  root1: {
    padding: theme.spacing(2),
  },
  mainDiv: {
    padding: theme.spacing(0),
    maxWidth: 1500
  },
  breakhelptext: {
    color: "red"
  },
  geoButton: {
    margin: "auto",
    height: "2rem",
    textTransform: "capitalize",
    fontWeight: "600",
    fontSize: "12px",
    border: `1px solid "#AA076B"`,
    color: "#AA076B",
    backgroundColor: '#ffffff',
    padding: '0px 8px !important'
  },
  approveButton: {
    color: '#ffffff',
    height: '2rem',
    margin: '15px 0px',
    padding: '0px 35px !important',
    fontSize: '12px',
    fontWeight: '600',
    borderRadius: '5px',
    marginLeft: '10px',
    textTransform: 'capitalize',
  },
  otherButton: {
    fontSize: '12px!important',
    textTransform: 'capitalize',
  },
  saveButton: {
    backgroundImage: schedulerColor.main,
    color: '#ffffff',
    padding: '10px 22px'
  },
  shadow: {
    padding: "15px",
    backgroundColor: 'white',
    border: '1px solid #cccccc9c',
    margin: 'unset',
  },
  geoimage: {
    border: '1px solid gray',
    padding: '10px',
    cursor: 'pointer',
    display: 'block',
    '&:hover': {
      backfaceVisibility: 'visible',
    }
  },
  avatarText: {
    float: 'left',
    textTransform: 'uppercase',
    height: '45px',
    width: '45px',
    fontWeight: 800,
    fontSize: '15px',
    backgroundImage: schedulerColor.main,
    color: '#ffffff'
  },
  myTitle: {
    color: "#AA076B",
    fontSize: '18px',
    marginLeft: '10px',
    paddingTop: '5px',
    lineHeight: '20px',
    float: "left",
    fontWeight: 500
  },
  myTitle1: {
    color: "#AA076B",
    fontSize: '14px',
    marginLeft: '64px',
    paddingTop: '5px',
    lineHeight: '20px',
    float: "left",
    fontWeight: 500
  },
  myTitle2: {
    color: "#AA076B",
    fontSize: '14px',
    marginLeft: '10px',
    paddingTop: '5px',
    lineHeight: '20px',
    float: "left",
    fontWeight: 500
  },
  tagLine: {
    fontSize: '13px',
    fontWeight: '300'
  },
  tableCell: {
    color: '#000',
    fontSize: 14
  },
  tsleft: {
    backgroundColor: '#F7F8FC',
  },
  tstittle: {
    backgroundColor: "#AA076B",
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  tslogtittle: {
    backgroundColor: "#AA076B",
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    padding: '8px',
  },
  tstitleh4: {
    color: 'white',
    lineHeight: 'unset',
    fontSize: 14,
    marginBottom: 'unset',

  },
  emptySpace: {
    height: '30px',
  },
  tsheader: {
    paddingTop: '30px!important',
  },
  tsinfo: {
    padding: '0px!important',
  },
});


class AddUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '2'
    }
  }

  handleChangeTab = (event, newValue) => {
    this.setState({
      value: newValue
    })
  };

  alertnOX = () => {
    const TodaySystemDate = new Date();
    const currentDate = this.props.currentDate;
    if(new Date(currentDate) > TodaySystemDate ){
      alert.error("You can not approve upcoming shift")
    }else{
      this.props.approveRevisedHours("Revised")
    } 

  }

  alertWholeTimecard = () => {
    const TodaySystemDate = new Date();
    const currentDate = this.props.currentDate;
    if(new Date(currentDate) > TodaySystemDate ){
      alert.error("You can not approve upcoming shift")
    }else{
      this.props.approveWholetimecard(this.props.myItem.is_break_approved === 1 && this.props.myItem.is_revised_approved === 1 ? "Unapprove" :   this.props.myItem.is_revised_approved === 1 && this.props.myItem.allBreaks.length ===0 ? "Unapprove" :"Approve")
      // this.props.approveRevisedHours("Revised")
    } 

  }


  render() {
    const {
      classes,
      myItem,
      activeTab,
      display_timecard,
      isGeo,
      permissionsData,
      errors,
      open,
      errorsHelper
    } = this.props;





    var startTime = moment(myItem.revised_clock_in ? myItem.revised_clock_in : myItem.clock_in).format("YYYY-MM-DD HH:mm ")
    var endTime = moment(myItem.revised_clock_out ? myItem.revised_clock_out : myItem.clock_out).format("YYYY-MM-DD HH:mm ")
    var duration = moment.duration(moment(endTime).diff(moment(startTime)))
    
    var minitus = duration.asMinutes()
    
    var hours = Math.floor(minitus / 60);
    var tempMinites111 = minitus % 60;
    var hm = hours > 0 ? hours + "hr " : ""
    var final_actual_diff = minitus ? hm + Math.round(tempMinites111) + "min" : "0 Min"



    //  var actual_diff = myItem.actual_diff
    // var temp_diff2 = Math.abs(actual_diff)
    // var totalMinutes111 = parseFloat(temp_diff2) * 0.60 * 100
    // var tempHours111 = Math.floor(totalMinutes111 / 60);
    // var tempMinites111 = totalMinutes111 % 60;
    // var hm = tempHours111 > 0 ? tempHours111 + "hr " : ""
    // var final_actual_diff = totalMinutes111 ? hm +  Math.round(tempMinites111) + "min" : "0 Min"


    var break_diff = myItem.break_diff
    var temp_diff_break = Math.abs(break_diff)
    var totalMinuteBreak = parseFloat(temp_diff_break) * 0.60 * 100
    var tempHoursBreak = Math.floor(totalMinuteBreak / 60);
    var tempMiniteBreak = totalMinuteBreak % 60;
    var hm = tempHoursBreak > 0 ? tempHoursBreak + "hr " : ""
    var final_break_diff = totalMinuteBreak ? hm + Math.round(tempMiniteBreak) + "min" : "0 Min"
    var date = moment(utcToLocal(new Date())).format("YYYY-MM-DD")
    const currentDate = moment(utcToLocal(myItem.currentDate));

// Get the current date and time
const currentDateTime = moment();    


    var actuallDifference1 = Math.floor(parseInt(myItem.total_actual_hrs) / parseFloat(myItem.break_break_hours))
    var actualDiff = parseFloat(myItem.break_rule_minites) * actuallDifference1
    var BreakActualdiff = parseFloat(totalMinuteBreak) - parseFloat(actualDiff)
    var breakDifference = parseInt(BreakActualdiff)
    var timecodeValue = []
    this.props.timecodeData.map((item) => {
      if (item._id === myItem.timecode) {
        timecodeValue.push(item.timecode_name)
      }
    })


    if (!myItem.breakRule && open) {
      alert.error("Please add Break Rule")
    }
    // if (!myItem.timecode && open) {
    //   alert.error("Please add timecode")
    // }

    // var actuallDifference1Revised = Math.floor(parseInt(myItem.total_actual_hrs) / parseFloat(myItem.break_break_hours))
    // var actualDiffRevised = parseFloat(myItem.break_rule_minites) * actuallDifference1Revised
    // var BreakActualdiffRevised = parseFloat(totalMinuteBreak) - parseFloat(actualDiffRevised)
    // var breakDifferenceRevised = parseInt(BreakActualdiffRevised)

    return (
      display_timecard ?
        <Grid
          className={classes.mainDiv}
          container
          spacing={2}
        >
          <Grid className={classes.tsheader}
            item sm={7} xs={12}>
            {this.props.userImage ?
              <Avatar
                src={this.props.userImage}
                className={classes.avatarText}
              />
              :
              <Avatar className={classes.avatarText} >
                {this.props.userName.charAt(0)}
              </Avatar>
            }

            <Typography
              className={classes.myTitle}
              variant="h4"
            >
              {this.props.userName}&nbsp;
              <br />
              <b className={classes.tagLine}>
                {/* {moment(this.props.currentDate).format('mm/dd/yyyy')} */}
                Role: {this.props.roleName}
              </b>

              {/* <b className={classes.tagLine}>{moment(this.props.currentDate).format("dddd DD MMM, YYYY")}</b> */}

              <br />

              <b className={classes.tagLine}>Type: {myItem.isScheduled ?  "Scheduled" : "Manual"}</b>

              <br />


              <b className={classes.tagLine}>

                Payroll Rule:&nbsp;&nbsp;{myItem.payroll_name} - {myItem.payroll_rule}
              </b>
              <br />
              <b className={classes.tagLine}>
                {/* {new Date(this.props.currentDate).toDateString()} */}
                {new Date( utcToTimeZone(this.props.currentDate, this.props.timezone[0].location_timezone)).toDateString()}
              </b>
              <br />
              { myItem.isDeleted ===  1 ?
              <b className={classes.tagLine} style={{color:myItem.is_break_approved && myItem.is_revised_approved ? "rgb(60, 197, 44)":  myItem.is_revised_approved === 1 && myItem.allBreaks.length ===0 ? "rgb(60, 197, 44)" : "#B71C1C",fontWeight:700}}>
              Deleted
            </b>
               :
              <b className={classes.tagLine} 
              style={{color:myItem.is_approved ? 
              "rgb(60, 197, 44)" : 
              !myItem.clock_in &&
                                                      !myItem.clock_out &&
                                                      moment(
                                                        utcToLocal(
                                                          myItem.currentDate
                                                        )
                                                      ).format("YYYY-MM-DD") >
                                                      date
                                                      ? "orange" :
              "#B71C1C",fontWeight:700}}>
                {myItem.is_approved ? 
                 "Approved" :
                 !myItem.clock_in && !myItem.clock_out &&
                 moment(
                   utcToLocal(
                     myItem.currentDate
                   )
                 ).format("YYYY-MM-DD") > date
                 ?
                 "Upcoming" :
                  "Pending" }
              </b>}


            </Typography>



          </Grid>







          <Grid
            item
            style={{ display: "flex", justifyContent: "flex-end" }}
            sm={5} xs={12}>
            {/* <Button
                  className={classes.geoButton}
                  onClick={this.props.toggleViewTimecard}>
                  GEO & Photos
                </Button> */}
            {permissionsData.scheduler_timesheet_edit ?
            myItem.isDeleted ===  1 ? "" 
            :
              <Grid item sm={4} style={{ padding: "15px 0px", display: "flex", justifyContent: "flex-end" }} >
                {this.props.isTimecardEdit ?
                  <Button
                    style={{ height: "2rem", fontSize: '12px', textTransform: 'capitalize' }}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    onClick={this.props.handleSubmitEditTimecard}
                  >
                    save
                  </Button> : ""}
                &nbsp; &nbsp;
                {new Date(myItem.currentDate) > date ?
                  <Button
                    style={{ height: "2rem", fontSize: '12px', textTransform: 'capitalize' }}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    disabled={true}
                    onClick={this.props.setTimecardEdit}
                  >
                    {this.props.isTimecardEdit ? "cancel" : "edit"}
                  </Button>
                  :
                  <Button
                    style={{ height: "2rem", fontSize: '12px', textTransform: 'capitalize' }}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    //disabled={myItem.breakRule && myItem.timecode   ? false :  true }
                    onClick={this.props.setTimecardEdit}
                  >
                    {this.props.isTimecardEdit ? "cancel" : "edit"}
                  </Button>}
              </Grid> : ""}
              {myItem.isDeleted === 1 ?
                <Button
                style={{
                  border: "1px solid #DA3E3E",
                  backgroundColor:  "#B71C1C"
                }}
                // onClick={this.alertWholeTimecard}
                className={classes.approveButton}
                //onClick={() => this.props.approveWholetimecard(myItem.is_break_approved === 1 && myItem.is_revised_approved === 1 ? "Unapprove" : "Approve")}
              //onClick={this.props.toggleViewTimecard}
              >
               
              Deleted
              </Button>
               :
              <Button
               disabled={permissionsData.scheduler_timesheet_lock ? false : true}
              style={{
                border: myItem.is_break_approved && myItem.is_revised_approved ? "1px solid #DA3E3E"  : myItem.is_revised_approved === 1 && myItem.allBreaks.length ===0 ? "1px solid #DA3E3E" :  "1px solid rgb(60, 197, 44)",
                backgroundColor: myItem.is_break_approved && myItem.is_revised_approved ? "#B71C1C" : myItem.is_revised_approved === 1 && myItem.allBreaks.length ===0 ? "#B71C1C" :  "rgb(60, 197, 44)"
              }}
              onClick={this.alertWholeTimecard}
              className={classes.approveButton}
              //onClick={() => this.props.approveWholetimecard(myItem.is_break_approved === 1 && myItem.is_revised_approved === 1 ? "Unapprove" : "Approve")}
            //onClick={this.props.toggleViewTimecard}
            >
             
              {myItem.is_break_approved === 1 && myItem.is_revised_approved === 1 ? "Unapprove" :  myItem.is_revised_approved === 1 && myItem.allBreaks.length ===0 ? "Unapprove"  : "Approve"}
            </Button>}
            {/* <Grid item sm={4} style={{ padding: "15px 0px", display: "flex", justifyContent: "flex-end" ,marginLeft:"-72px"}} >
            <Button
                    style={{ height: "2rem", fontSize: '12px', textTransform: 'capitalize' ,}}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    startIcon={<DeleteIcon/>}
                    //disabled={myItem.breakRule && myItem.timecode   ? false :  true }
                    onClick={() => this.props.deleteTimecard(myItem)} 
                  >
                    Delete
                  </Button>
                  </Grid> */}


          </Grid>












          <Grid className={classes.tsleft} style={{ padding: "15px" }} item sm={8} xs={12}>
            <Grid
              container
              spacing={2}
            >




              {/* <Divider
                style={{ color: "gray", width: "100%" }}
              /> */}
              <div className={classes.emptySpace}></div>
              {/* <Grid item sm={12} xs={12}>
                {this.props.isTimecardEdit ?
                  <MySelectField
                    label="Timecode"
                    name="timecode"
                    disabled={true}
                    onChange={this.props.handleChange}
                    onFocus={this.props.handleFocus}
                    value={myItem.timecode}
                    error={errors.timecode}
                    helperText={errors.timecode ? errorsHelper.timecode : ""}
                    placeholder="Please Select Timecode"
                  >
                    <option value={0}>
                      Select Timecode
                    </option>
                    {this.props.timecodeData.map((item) => {
                      return (
                        <option key={item._id} value={item._id} >
                          {item.timecode_name}
                        </option>
                      )
                    })}
                  </MySelectField>
                  :
                  <MyTextfield
                    name="name"
                    label="Timecode"
                    value={timecodeValue[0]}
                    type="text"
                    disabled={true}
                  />}
              </Grid> */}

              <Grid className={classes.tstittle}
                item sm={12} xs={12}>
                <Typography className={classes.tstitleh4}
                  variant="h4"
                >
                  Schedule
                </Typography>
              </Grid>

              {/* shadow div start */}
              <Grid
                container
                spacing={0}
                className={classes.shadow}
              >
                <Grid item sm={9} xs={12}>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid item sm={3} xs={12}>
                      <Typography className={classes.tableCell}>
                        Schedule
                      </Typography>
                    </Grid>

                    <Grid item sm={3} xs={12}>
                      <Typography
                        className={classes.tableCell}
                      >

                       {myItem.isScheduled === 1 ? moment(myItem.startTime).format("hh:mm A") :""}
                        {/* {moment(myItem.startTime).fo()} */}
                      </Typography>
                    </Grid>

                    <Grid item sm={3} xs={12}>
                      <Typography
                        className={classes.tableCell}
                      >
                        {myItem.isScheduled === 1 ? moment(myItem.endTime).format("hh:mm A"):""}
                        {/* {new Date(myItem.endTime).toDateString()} */}
                      </Typography>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      {myItem.isScheduled === 1 ?
                      <Typography
                        className={classes.tableCell}
                      >
                        
                        {myItem.total_scheduled_hrs}&nbsp; Hrs
                      </Typography> : ""}
                    </Grid>



                    <Grid item sm={3} xs={12}>
                      <Typography
                        className={classes.tableCell}
                      >
                        Actual
                      </Typography>
                    </Grid>

                    <Grid item sm={3} xs={12}>
                      <span>
                        <Typography
                          variant="body1"
                          style={{ fontSize: '11px' }}
                        >


                        </Typography>
                        <Typography
                          variant="body1"
                        >

                          {myItem.clock_in ?
                            moment(myItem.clock_in).format("hh:mm A")
                            // new Date(myItem.clock_in).toDateString()
                            : ""}
                          {myItem.clock_in ? 
                          <Tooltip arrow title={myItem.clock_in ? moment(myItem.clock_in).format("YYYY/MM/DD") : ""}>
                            <InfoIcon style={{ fontSize: "16px", marginLeft: "8%", color: "#AA076B", marginBottom: "5px" }} />
                          </Tooltip>:""}
                        </Typography>

                      </span>
                      {/* <Typography
                        className={classes.tableCell}
                      >
                        {myItem.clock_in ?
                          moment(myItem.clock_in).format("hh:mm A")
                          : ""}
                      </Typography> */}
                    </Grid>

                    <Grid item sm={3} xs={12}>
                      <span>
                        <Typography
                          variant="body1"
                          style={{ fontSize: '11px' }}
                        >
                          {/* {myItem.clock_out ? new Date(utcDateToLocal(myItem.clock_out)).toDateString() : ""} */}

                        </Typography>
                        <Typography
                          variant="body1"
                        >
                          {myItem.clock_out ?
                            moment(myItem.clock_out).format("hh:mm A")
                            : ""}

                            {myItem.clock_out ? 
                          <Tooltip arrow title={myItem.clock_out ? moment(myItem.clock_out).format("YYYY/MM/DD") : ""}>
                            <InfoIcon style={{ fontSize: "16px", marginLeft: "8%", color: "#AA076B", marginBottom: "5px" }} />
                          </Tooltip> : ""}
                        </Typography>
                      </span>
                      {/* <Typography
                        className={classes.tableCell}
                      >
                        {myItem.clock_out ?
                          moment(myItem.clock_out).format("hh:mm A")
                          : ""}
                      </Typography> */}
                    </Grid>
                    <Grid item sm={2} xs={12}>
                      <Typography
                        className={classes.tableCell}
                      >

                      </Typography>
                    </Grid>

                    <Grid item sm={3} xs={12}>
                      <Typography
                        className={classes.tableCell}
                      >
                        Approved
                      </Typography>
                    </Grid>



                    <Grid item sm={3} xs={12} className="form-group">
                      {this.props.isTimecardEdit ?

                        // <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        //   <KeyboardDateTimePicker
                        //     key={`dateTime-${this.props.timezone[0].location_timezone}`}
                        //     showTodayButton
                        //     fixWidth={true}
                        //     label=""
                        //     style={{ width: 170 }}
                        //     todayText="now"
                        //     openTo="hours"
                        //     name="revised_clock_in"
                        //     value={myItem.revised_clock_in ? myItem.revised_clock_in : myItem.clock_in}
                        //     onChange={(date) => { this.props.handleChangeDateTime(date, "revised_clock_in") }}
                        //     // minDate={myItem.currentDate}
                        //     onFocus={this.props.handleFocus}
                        //     error={errors.revised_clock_in}
                        //     helperText={errors.revised_clock_in ? errorsHelper.revised_clock_in : ""}
                        //     components={{
                        //       LeftArrowIcon: AlarmIcon,
                        //       RightArrowIcon: SnoozeIcon,
                        //       OpenPickerIcon: ClockIcon,
                        //     }}
                        //     leftArrowButtonText="Open previous month"
                        //     rightArrowButtonText="Open next month"
                        //   // minTime={new Date(0, 0, 0, 9)}
                        //   // maxTime={new Date(0, 0, 0, 20)}
                        //   // renderInput={(params) => (
                        //   //   <TextField {...params} helperText={errors.revised_clock_in ? errorsHelper.revised_clock_in : ""} />
                        //   // )}
                        //   ></KeyboardDateTimePicker>
                        // </MuiPickersUtilsProvider>
                        <BasicTimePicker
                          label=""
                          key={`dateTime-${this.props.timezone[0].location_timezone}`}
                          name="revised_clock_in"
                          value={dayjs(myItem.revised_clock_in)}
                          onChange={(date) => { this.props.handleChangeDateTime(date, "revised_clock_in") }}

                          onFocus={this.props.handleFocus}
                          error={errors.revised_clock_in}
                          helperText={errors.revised_clock_in ? errorsHelper.revised_clock_in : ""}
                          renderInput={(params) => (
                            <TextField {...params} helperText={errors.revised_clock_in ? errorsHelper.revised_clock_in : ""} />
                          )}
                        />
                        :
                        <span>
                          <Typography
                            variant="body1"
                            style={{ fontSize: '11px' }}
                          >

                          </Typography>
                          <Typography
                            variant="body1"
                          >
                            {myItem.revised_clock_in ?
                              moment(myItem.revised_clock_in).format("hh:mm A")
                              : ""}
                              {myItem.revised_clock_in  ? 
                            <Tooltip arrow title={myItem.revised_clock_in ? moment(myItem.revised_clock_in).format("YYYY/MM/DD") : ""}>
                              <InfoIcon style={{ fontSize: "16px", marginLeft: "8%", color: "#AA076B", marginBottom: "5px" }} />
                            </Tooltip>:""}
                          </Typography>
                        </span>
                      }
                    </Grid>



                    <Grid item sm={3} xs={12} className="form-group" style={{ width: 170 }}>
                      {this.props.isTimecardEdit ?

                        // <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        //   <KeyboardDateTimePicker
                        //     key={`dateTime-${this.props.timezone[0].location_timezone}`}
                        //     fixWidth={true}
                        //     label=""
                        //     // disableFuture
                        //     hideTabs
                        //     showTodayButton
                        //     name="revised_clock_out"
                        //     todayText="now"
                        //     openTo="hours"
                        //     value={myItem.revised_clock_out ? myItem.revised_clock_out : myItem.clock_out}
                        //     onChange={(date) => { this.props.handleChangeDateTime(date, "revised_clock_out") }}
                        //     minDate={myItem.currentDate}
                        //     onFocus={this.props.handleFocus}
                        //     error={errors.revised_clock_out}
                        //     helperText={errors.revised_clock_out ? errorsHelper.revised_clock_out : ""}
                        //     components={{
                        //       LeftArrowIcon: AlarmIcon,
                        //       RightArrowIcon: SnoozeIcon,
                        //       OpenPickerIcon: ClockIcon,
                        //     }}
                        //     leftArrowButtonText="Open previous month"
                        //     rightArrowButtonText="Open next month"
                        //   //  minTime={new Date(0, 0, 0, 9)}
                        //   //  maxTime={new Date(0, 0, 0, 20)}
                        //   //  renderInput={(params) => (
                        //   //    <TextField {...params} helperText={errors.revised_clock_out ? errorsHelper.revised_clock_out : ""} />
                        //   //  )}
                        //   ></KeyboardDateTimePicker>
                        // </MuiPickersUtilsProvider>
                        <>
                            <BasicTimePicker
                          label=""
                          key={`dateTime-${this.props.timezone[0].location_timezone}`}
                          name="revised_clock_out"
                          value={dayjs(myItem.revised_clock_out)}
                          onChange={(date) => { this.props.handleChangeDateTime(date, "revised_clock_out") }}

                          onFocus={this.props.handleFocus}
                          error={errors.revised_clock_out}
                          helperText={errors.revised_clock_out ? errorsHelper.revised_clock_out : ""}
                          renderInput={(params) => (
                            <TextField {...params} helperText={errors.revised_clock_out ? errorsHelper.revised_clock_out : ""} />
                          )}
                        />
                        {this.props.nextDay &&
                          <Typography style={{color:"red"}}>Next Day</Typography>}

                        </>
                    

                        :
                        <span>
                          <Typography
                            variant="body1"
                            style={{ fontSize: '11px' }}
                          >

                          </Typography>
                          <Typography
                            variant="body1"
                          >
                            {myItem.revised_clock_out ?
                              moment(myItem.revised_clock_out).format("hh:mm A")
                              : ""}
                              {myItem.revised_clock_out ?
                            <Tooltip arrow title={myItem.revised_clock_out ? moment(myItem.revised_clock_out).format("YYYY/MM/DD") : ""}>
                              <InfoIcon style={{ fontSize: "16px", marginLeft: "8%", color: "#AA076B", marginBottom: "5px" }} />
                            </Tooltip> :""}
                          </Typography>
                          {this.props.nextDay &&
                          <Typography style={{color:"red"}}>Next Day</Typography>}
                        </span>
                      }
                    </Grid>



                    <Grid item sm={3} xs={12}>
                      {myItem.revised_clock_out && myItem.revised_clock_in ?
                      <Typography
                        className={classes.tableCell}
                      >
                        {myItem.total_manual_hrs}&nbsp; Hrs
                      </Typography>:""}
                    </Grid>







                  </Grid>
                </Grid>

                {myItem.revised_clock_out || myItem.clock_in ?
                  <Grid
                    style={{
                      textAlign: "center",
                      height: "110px",
                      borderRadius: "5px",
                      border: permissionsData.scheduler_timesheet_lock ? myItem.is_revised_approved ? "2px solid rgb(170, 7, 107)" : "2px solid #b71c1c" : " 2px solid grey"
                    }}
                    item sm={3} xs={12}>
                    <Grid container style={{ padding: "10px 5px" }}>
                      <Grid item sm={12}>
                        <Typography
                          style={{ color: "#AA076B" }}
                          variant="body1"
                        >
                          {final_actual_diff}&nbsp;&nbsp; {parseFloat(hours) > 0 ? parseFloat(hours) > 0 ? "Over" : "Less" : "Less"}
                        </Typography>
                      </Grid>
                      {myItem.isDeleted === 1 ?
                       <Grid item sm={12}>
                       <IconButton
                         style={{
                           margin: "2px",
                           padding: '6px',
                           backgroundColor: permissionsData.scheduler_timesheet_lock ? myItem.is_revised_approved ? "#3cc52c" : "#ffc107" : "grey"
                         }}>
                         <CheckIcon
                           style={{ backgroundColor: "transparent",color:"black" }}
                         />
                       </IconButton>
                     </Grid> :
                      <Grid item sm={12}>
                        <IconButton
                          onClick={this.alertnOX}

                          disabled={permissionsData.scheduler_timesheet_lock   ? false  : true}
                          style={{
                            margin: "2px",
                            padding: '6px',
                            backgroundColor: permissionsData.scheduler_timesheet_lock ? myItem.is_revised_approved ? "#3cc52c" : "#ffc107" : "grey"
                          }}>
                          <CheckIcon
                            style={{ backgroundColor: "transparent",color:"black" }}
                          />
                        </IconButton>
                      </Grid>}

                      <Grid item sm={12} style={{
                        textAlign: '-webkit-center',
                        paddingTop: '8px',
                      }}>
                        <Typography
                          style={{
                            color: permissionsData.scheduler_timesheet_lock ? myItem.is_revised_approved ? "#3cc52c" : "#ffc107" : "grey",
                            backgroundColor: permissionsData.scheduler_timesheet_lock ? myItem.is_revised_approved ? "#9ddf9566" : "#fde08a4a" : "grey",
                            width: '80px',
                            borderRadius: '20px',
                          }}
                          variant="body1"
                        >
                          {myItem.is_revised_approved ? "Approved" : "Pending"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid> : ""}
              </Grid>
              {/* shadow div end */}


              <Grid
                style={{ marginTop: "1rem" }} className={classes.tstittle}
                item sm={12} xs={12}>
                <Typography className={classes.tstitleh4} variant="h4"
                  gutterBottom
                >
                  Breaks
                </Typography>
              </Grid>

              {/* shadow div start */}
              <Grid
                container
                spacing={1}
                className={classes.shadow}
              >
                <Grid item sm={9} xs={12}>
                  <Typography
                    className={classes.tableCell}
                    gutterBottom
                  >
                    Break Rule : {myItem.breakRule}
                  </Typography>

                  {myItem.allBreaks?.map((breakItem, breakIndex) => {
                    return (
                      <Grid
                        className={classes.root2}
                        container
                        spacing={2}
                      >
                        <Grid item sm={3} xs={12}>
                          <Typography
                            style={{ lineHeight: "2rem" }}
                            className={classes.tableCell}
                          >
                            Break {breakIndex + 1}
                          </Typography>
                        </Grid>

                        <Grid item sm={4} xs={12}>
                          {this.props.isTimecardEdit ?
                            // <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            //   <KeyboardDateTimePicker
                            //     fixWidth={true}
                            //     key={`dateTime-${this.props.timezone[0].location_timezone}`}
                            //     label=""
                            //     // disableFuture
                            //     hideTabs
                            //     showTodayButton
                            //     name="break_start"
                            //     todayText="now"
                            //     openTo="hours"
                            //     value={breakItem.break_start ? breakItem.break_start : myItem.currentDate}
                            //     onChange={(date) => { this.props.handleChangeBreakTime(date, breakIndex, "break_start") }}
                            //     //minDate={myItem.currentDate}
                            //     onFocus={(e) => this.props.handleFocusBreak(e, breakIndex)}
                            //     error={breakItem.break_start_error}
                            //     helperText={breakItem.break_start_error ? breakItem.break_start_error_helper : ""}
                            //   //  components={{
                            //   //    LeftArrowIcon: AlarmIcon,
                            //   //    RightArrowIcon: SnoozeIcon,
                            //   //    OpenPickerIcon: ClockIcon,
                            //   //  }}
                            //   //  leftArrowButtonText="Open previous month"
                            //   //  rightArrowButtonText="Open next month"
                            //   //  minTime={new Date(0, 0, 0, 9)}
                            //   //  maxTime={new Date(0, 0, 0, 20)}
                            //   //  renderInput={(params) => (
                            //   //    <TextField {...params}  helperText={breakItem.break_start_error ? breakItem.break_start_error_helper : ""} className={classes.breakhelptext}/>
                            //   //  )}
                            //   ></KeyboardDateTimePicker>
                            // </MuiPickersUtilsProvider>
                            <>
                              <BasicTimePicker
                                label=""
                                key={`dateTime-${this.props.timezone[0].location_timezone}`}
                                name="break_start"
                                value={dayjs(breakItem.break_start ? breakItem.break_start : myItem.currentDate)}
                                onChange={(date) => { this.props.handleChangeBreakTime(date, breakIndex, "break_start") }}
                                onFocus={this.props.handleFocus}
                                error={breakItem.break_start_error}
                                helperText={breakItem.break_start_error ? breakItem.break_start_error_helper : ""}
                                renderInput={(params) => (
                                  <TextField {...params} helperText={errors.break_start_error ? errorsHelper.break_start_error : ""} />
                                )}

                              />
                              {breakItem.break_start_error === true &&
                                <Typography style={{ color: "red" }}>{breakItem.break_start_error ? breakItem.break_start_error_helper : ""}</Typography>}
                            </>

                            // <MyTimePicker
                            //   fixWidth={true}
                            //   label=""
                            //   name="break_start"
                            //   onFocus={(e) => this.props.handleFocusBreak(e, breakIndex)}
                            //   onChange={(date) => { this.props.handleChangeBreakTime(date, breakIndex, "break_start") }}
                            //   error={breakItem.break_start_error}
                            //   helperText={breakItem.break_start_error ? breakItem.break_start_error_helper : ""}
                            //   value={breakItem.break_start}
                            //   placeholder="Please enter start"
                            // />
                            :
                            <span>
                              <Typography
                                variant="body1"
                                style={{ fontSize: '11px' }}
                              >
                                {breakItem.break_start  != null ? moment(breakItem.break_start).format("YYYY/MM/DD") : ""}
                                {/* {breakItem.break_start ? new Date(utcDateToLocal(breakItem.break_start)).toDateString() : ""} */}
                              </Typography>
                              <Typography
                                style={{ lineHeight: "2rem" }}
                                className={classes.tableCell}
                              >
                                {/* {breakItem.break_start ?
                                new Date(breakItem.break_start).toDateString()
                                : ''} */}
                                {breakItem.break_start != null  ?
                                  moment(breakItem.break_start).format("hh:mm A")
                                  : ""}
                              </Typography>
                            </span>

                          }
                        </Grid>

                        <Grid item sm={4} xs={12}>
                          {this.props.isTimecardEdit ?
                            // <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            //   <KeyboardDateTimePicker
                            //     fixWidth={true}
                            //     key={`dateTime-${this.props.timezone[0].location_timezone}`}
                            //     label=""
                            //     // disableFuture
                            //     hideTabs
                            //     showTodayButton
                            //     name="break_end"
                            //     todayText="now"
                            //     openTo="hours"
                            //     value={breakItem.break_end ? breakItem.break_end : myItem.currentDate}
                            //     onChange={(date) => { this.props.handleChangeBreakTime(date, breakIndex, "break_end") }}
                            //     minDate={myItem.currentDate}
                            //     onFocus={(e) => this.props.handleFocusBreak(e, breakIndex)}
                            //     error={breakItem.break_end_error}
                            //     helperText={breakItem.break_end_error ? breakItem.break_end_error_helper : ""}
                            //     components={{
                            //       LeftArrowIcon: AlarmIcon,
                            //       RightArrowIcon: SnoozeIcon,
                            //       OpenPickerIcon: ClockIcon,
                            //     }}
                            //     leftArrowButtonText="Open previous month"
                            //     rightArrowButtonText="Open next month"
                            //   // minTime={new Date(0, 0, 0, 9)}
                            //   // maxTime={new Date(0, 0, 0, 20)}
                            //   // renderInput={(params) => (
                            //   //   <TextField {...params}  helperText={breakItem.break_end_error ? breakItem.break_end_error_helper : ""} style={{color:"red"}}/>
                            //   // )}
                            //   ></KeyboardDateTimePicker>
                            // </MuiPickersUtilsProvider>
                            <>
                              <BasicTimePicker
                                label=""
                                key={`dateTime-${this.props.timezone[0].location_timezone}`}
                                name="break_end"
                                value={dayjs(breakItem.break_end ? breakItem.break_end : myItem.currentDate)}
                                onChange={(date) => { this.props.handleChangeBreakTime(date, breakIndex, "break_end") }}

                                onFocus={this.props.handleFocus}
                                error={breakItem.break_end_error}
                                helperText={breakItem.break_end_error ? breakItem.break_end_error_helper : ""}
                                renderInput={(params) => (
                                  <TextField {...params} helperText={errors.break_end_error ? errorsHelper.break_end_error : ""} />
                                )}
                              />
                              {breakItem.break_end_error === true &&
                                <Typography style={{ color: "red" }}>{breakItem.break_end_error ? breakItem.break_end_error_helper : ""}</Typography>}
                            </>

                            // <MyTimePicker
                            //   fixWidth={true}
                            //   label=""
                            //   name="break_end"
                            //   onFocus={(e) => this.props.handleFocusBreak(e, breakIndex)}
                            //   onChange={(date) => { this.props.handleChangeBreakTime(date, breakIndex, "break_end") }}
                            //   error={breakItem.break_end_error}
                            //   helperText={breakItem.break_end_error ? breakItem.break_end_error_helper : ""}
                            //   value={breakItem.break_end}
                            //   placeholder="Please enter end"
                            // />
                            :
                            <span>
                              <Typography
                                variant="body1"
                                style={{ fontSize: '11px' }}
                              >
                                {/* {breakItem.break_end ? new Date(utcDateToLocal(breakItem.break_end)).toDateString() : ""} */}
                                {breakItem.break_end != null ? moment(breakItem.break_end).format("YYYY/MM/DD") : ""}
                              </Typography>
                              <Typography
                                style={{ lineHeight: "2rem" }}
                                className={classes.tableCell}
                              >
                                {/* {breakItem.break_end ?
                                new Date(breakItem.break_end).toDateString()
                                : ""} */}
                                {breakItem.break_end !=null ?
                                  moment(breakItem.break_end).format("hh:mm A")
                                  : ""}
                              </Typography>
                            </span>
                          }
                        </Grid>

                        <Grid item sm={1} xs={12}>
                          {this.props.isTimecardEdit ?
                            <IconButton
                              style={{ padding: '5px' }}
                              onClick={() => { this.props.removeBreakRow(breakIndex) }}
                            >
                              <DeleteIcon />
                            </IconButton> : ""}
                        </Grid>

                      </Grid>
                    )
                  })}
                </Grid>

                {this.props.isTimecardEdit ? "" :
                myItem.allBreaks.length > 0 ?
                  <Grid
                    style={{
                      margin: "auto",
                      textAlign: "center",
                      height: "auto",
                      borderRadius: "5px",
                      // backgroundColor:"rgb(183, 28, 28)",
                      border: permissionsData.scheduler_timesheet_lock ? myItem.is_break_approved ? "2px solid #AA076B" : "2px solid rgb(183, 28, 28)" : "2px solid grey",
                    }}
                    item sm={3} xs={12}>
                    <Grid container style={{ padding: "10px 5px" }}>
                      <Grid item sm={12}>
                        <Typography
                          style={{ color: "#AA076B" }}
                          variant="body1"
                        >
                          {final_break_diff}&nbsp;&nbsp; {parseInt(BreakActualdiff) > parseFloat(myItem.break_rule_minites) ? "Over" : "less"}
                        </Typography>
                      </Grid>
                      {myItem.isDeleted === 1 ?
                       <Grid item sm={12}>
                       <IconButton
                         disabled={permissionsData.scheduler_timesheet_lock ? false : true}
                         style={{
                           margin: "2px", padding: '6px',
                           backgroundColor: permissionsData.scheduler_timesheet_lock ? myItem.is_break_approved ? "#3cc52c" : "#ffc107" : "grey"

                         }}>
                         <CheckIcon
                           style={{ backgroundColor: "transparent" }}
                         />
                       </IconButton>
                     </Grid>:
                      <Grid item sm={12}>
                        <IconButton
                          disabled={permissionsData.scheduler_timesheet_lock ? false : true}
                          onClick={() => this.props.approveRevisedHours("Breaks")}
                          style={{
                            margin: "2px", padding: '6px',
                            backgroundColor: permissionsData.scheduler_timesheet_lock ? myItem.is_break_approved ? "#3cc52c" : "#ffc107" : "grey"

                          }}>
                          <CheckIcon
                            style={{ backgroundColor: "transparent" }}
                          />
                        </IconButton>
                      </Grid>}

                      <Grid item sm={12} style={{
                        textAlign: '-webkit-center',
                        paddingTop: '8px',
                      }}>
                        <Typography
                          style={{
                            color: permissionsData.scheduler_timesheet_lock ? myItem.is_break_approved ? "#3cc52c" : "#ffc107" : "grey",
                            backgroundColor: permissionsData.scheduler_timesheet_lock ? myItem.is_break_approved ? "#9ddf9566" : "#fde08a4a" : "grey",
                            width: '80px',
                            borderRadius: '20px',
                          }}
                          variant="body1"
                        >
                          {myItem.is_break_approved ? "Approved" : "Pending"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid> : ""}
              </Grid>
              {/* shadow div end */}

              {this.props.isTimecardEdit ?
                <Grid item sm={12} xs={12}
                  style={{ display: 'flex', justifyContent: 'right', textAlign: 'right' }}>
                  <Button
                    style={{ backgroundImage: schedulerColor.main, marginTop: '5px' }}
                    onClick={this.props.addBreakRow}
                  >
                    <span style={{ color: 'white' }}>Add break</span>
                  </Button>
                </Grid> : ""}
            </Grid>
          </Grid>


          <Grid style={{
            // height: "auto",
            // minHeight: "500px",
            // borderLeft: "1px solid lightgray",
            // padding: "15px",
            // boxShadow: ' 0px 2px 10px rgb(0 0 0 / 20%)',
            // margin: '15px 0px',
            // borderRadius: '5px',
          }} item sm={4} xs={12}>

            <Grid
              container
              spacing={2}
            >
              <Grid item sm={12} xs={12} style={{ textAlign: "center", height: "250px" }}>
                {/* <Grid item md={12}>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "600px",
                      fontSize: "14px",
                      margin: '1px',
                      borderRadius: '10px',
                      border: "none",
                      color: "#ffffff",
                      width: "135px",
                      backgroundColor: schedulerColor.main
                    }}
                    //onClick={() => { this.props.handleActiveTab(0) }}
                    className={classes.button2}>Request & Issue</Button>
                  <hr />
                </Grid>

                <Typography
                  variant="body1"
                  style={{ textAlign: "center", fontWeight: "600" }}
                >
                  Issues
                </Typography>
                <List className={classes.listRoot}>
                  {myItem.allIssues?.map((items) => {
                    return (
                      <ListItem
                        alignItems="flex-start"
                        style={{ padding: "0px 0px" }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              gutterBottom
                              display="inline"
                              style={{ cursor: "pointer", fontWeight: "600" }}
                            >
                              {items.issue}

                              {permissionsData.scheduler_timesheet_fix_issue ?
                                <Typography
                                  onClick={() => { this.props.toggleTimesheetIssue(items) }}
                                  variant="body1"
                                  style={{
                                    float: 'right',
                                    color: items.status ?
                                      schedulerColor.main : "red"
                                  }}
                                  display="inline"
                                >
                                  {items.status ? "Ignored" : "Ignore"}
                                </Typography> :
                                <Typography
                                  variant="body1"
                                  style={{
                                    float: 'right',
                                    color: items.status ?
                                      schedulerColor.main : "grey"
                                  }}
                                  display="inline"
                                >
                                  {items.status ? "Ignored" : "Ignore"}
                                </Typography>}

                            </Typography>
                          }
                        />
                      </ListItem>
                    )
                  })}
                </List> */}
                <Card>
                  <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={this.state.value}>
                      <Grid className={classes.tslogtittle}
                        item sm={12} xs={12}>
                        <Typography className={classes.tstitleh4}
                          variant="h4"
                        >
                          Triggers / Logs
                        </Typography>
                      </Grid>
                      <Box sx={{}}>
                        {/* <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>

                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>Correction Requests</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                              malesuada lacus ex, sit amet blandit leo lobortis eget.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                          >
                            <Typography>Issues</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                              malesuada lacus ex, sit amet blandit leo lobortis eget.
                            </Typography>
                          </AccordionDetails>
                        </Accordion> */}

                        <TabList style={{ display: 'inline-flex', paddingTop: '20px', }} orientation="horizontal" onChange={this.handleChangeTab} aria-label="lab API tabs example">
                          <Tab label="Issue"
                            style={{
                              backgroundColor: '#EFF1F2',
                              color: '#212121',
                              width: '130px',
                              fontSize: '11px',
                              fontSize: '11px',
                              borderTopLeftRadius: '100px',
                              borderBottomLeftRadius: '100px',
                              padding: '15px 9px',
                              display: 'inline-block',
                              float: 'left',
                            }}
                            value="1" /><br />
                          <Tab
                            style={{
                              backgroundColor: '#EFF1F2',
                              color: '#212121',
                              width: '130px',
                              display: 'inline-block',
                              float: 'right',
                              borderTopRightRadius: '100px',
                              borderBottomRightRadius: '100px',
                              padding: '15px 9px'
                            }}
                            label="Activity" value="2" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        <Typography
                          variant="body1"
                          style={{ textAlign: "center", fontWeight: "600" }}
                        >
                          Issues
                        </Typography>
                        <List className={classes.listRoot}>
                          {myItem.allIssues?.map((items) => {
                            return (
                              <ListItem
                                alignItems="flex-start"
                                style={{ padding: "0px 0px" }}
                              >
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="body1"
                                      gutterBottom
                                      display="inline"
                                      style={{ cursor: "pointer", fontWeight: "600" }}
                                    >
                                      {items.issue}

                                      {permissionsData.scheduler_timesheet_fix_issue ?
                                        <Typography
                                          onClick={() => { this.props.toggleTimesheetIssue(items) }}
                                          variant="body1"
                                          style={{
                                            float: 'right',
                                            backgroundImage: items.status ?
                                              schedulerColor.main : "red"
                                          }}
                                          display="inline"
                                        >
                                          {items.status ? "Ignored" : "Ignore"}
                                        </Typography> :
                                        <Typography
                                          variant="body1"
                                          style={{
                                            float: 'right',
                                            backgroundImage: items.status ?
                                              schedulerColor.main : "grey"
                                          }}
                                          display="inline"
                                        >
                                          {items.status ? "Ignored" : "Ignore"}
                                        </Typography>}

                                    </Typography>
                                  }
                                />
                              </ListItem>
                            )
                          })}
                        </List>
                      </TabPanel>
                      <TabPanel value="2">
                        {permissionsData.scheduler_timesheet_view_edit_history ?
                          myItem.allActivity?.map((item) => {
                            var startTimeold = new Date(item.time)
                            var startTimeee = startTimeold.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                            var startTime = moment(startTimeee).format("hh:mm A")

                            // var endTimeold = new Date(item.endTime)
                            // var endTimeee = endTimeold.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                            // var endTime = moment(endTimeee).format("hh:mm A")

                            return (
                              <>
                                <Grid item xs={12} className="ActiveLogMain" style={{ paddingTop: 0, textAlign: 'right' }}>
                                  <Grid item xs={3} md={3} lg={3} sm={3} >

                                    <Button className="newActivity-btn" style={{ lineHeight: "7.75px" }}>
                                      {/* {item.time && moment(utcToLocal(item.time)).format("hh:mm A")} */}

                                    </Button>
                                    <div className="ActivityverticalLine" />
                                    {/* <Button className="Account-btn">{moment(item.payroll_approve_date).locale('en-US').format("DD-MMM-YYYY")}</Button> */}
                                  </Grid>
                                  <Grid item xs={8} md={8} lg={8} sm={8} >

                                    <div className="ActiveTitlename" style={{ marginLeft: "13%" }}>{item.label}</div>
                                    {item.device ?
                                      <div className="ActiveTitle" style={{ marginLeft: "13%" }}>{"With" + " " + item.device}</div> : ""}
                                    <div className="ActiveTitle" style={{ marginLeft: "13%" }}>
                                      {item.time ? moment(startTimeee).format("YYYY/MM/DD") : ""}
                                      {item.editeby ? "Editor" + " " + item.editeby : ""}&nbsp;{"@" + " " + item.editeby ? item.label === "Clocked In" ? startTime : item.label === "Started Break" ? startTime : item.label === "Clocked Out" ? startTime : item.label === "Ended Break" ? startTime : "" : ""}<br />
                                      {item.editeby && item.msg ? item.msg : ""}
                                    </div>
                                  </Grid>
                                </Grid>

                              </>
                            )
                          })
                          : ""}
                      </TabPanel>
                    </TabContext>
                  </Box>
                </Card>
              </Grid>

              {/* {permissionsData.scheduler_timesheet_view_edit_history ?
                <Grid item sm={12} xs={12} style={{ textAlign: "center" }}>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "600px",
                      fontSize: "14px",
                      margin: '1px',
                      borderRadius: '10px',
                      border: "none",
                      color: "#ffffff",
                      width: "135px",
                      backgroundColor: schedulerColor.main
                    }}
                    //onClick={() => { this.props.handleActiveTab(1) }}
                    className={classes.button1}>Activity</Button>
                  <hr />
                  <Grid item sm={12} xs={12}>
                    <List className={classes.listRoot} style={{ overflow: "scroll", height: "250px" }}>
                      {myItem.allActivity?.map((items) => {
                        var startTimeold = new Date(items.time)
                        var startTimeee = startTimeold.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });

                        var startTime = moment(startTimeee).format("hh:mm A") */}

              {/* // var endTimeold = new Date(item.endTime)
                        // var endTimeee = endTimeold.toLocaleString('en-US', { timeZone: this.props.timezone[0].location_timezone });
                        // var endTime = moment(endTimeee).format("hh:mm A") */}

              {/*  return (
                           <ListItem
                             alignItems="flex-start"
                             style={{ padding: "0px 0px" }}
                           >
                             <ListItemText
                               primary={
                                 <Typography
                                   variant="body1"
                                   gutterBottom
                                   display="inline"
                                   style={{ fontWeight: "600" }}
                                 > */}

              {/* vruti */}

              {/*                                   
                                  {items.label ? items.label : "Edit By" + " " + items.editeby}&nbsp;{items.editeby ? startTime : startTime}
                                   */}

              {/* {items.label ? items.label : "Edit By" + " " + items.editeby}&nbsp;{items.editeby ? new Date(utcToLocal(items.time)).toLocaleString() : items.time && moment(utcToLocal(items.time)).format("hh:mm A")} */}
              {/* {items.device &&
                                    <Typography
                                      variant="body1"
                                      style={{
                                        float: 'right',
                                        color: "#7C7C7C"
                                      }}
                                      display="inline"
                                    >
                                      {items.device}
                                    </Typography>}
                                </Typography>
                              }
                            />
                          </ListItem>
                        )
                      })}
                    </List>
                  </Grid>
                </Grid> : ""} */}
            </Grid>

          </Grid >
        </Grid >
        :
        <Grid
          style={{ overflowX: "hidden" }}
          className={classes.root2}
          container
          spacing={2}
        >
          <Grid style={{ padding: "15px" }} item sm={8} xs={12}>
            <Button startIcon={<BackIcon />} onClick={this.props.toggleViewTimecard}>
              back
            </Button>
          </Grid>

          <Grid item sm={12} xs={12} style={{ textAlign: "center" }}>
            <ButtonGroup
              size="small"
              style={{
                border: `1px solid "#AA076B"`,
                backgroundColor: '#ffffff',
                borderRadius: '100px',
                padding: '2px'
              }}
            >
              <Button
                style={{
                  padding: "5px 25px",
                  textTransform: "capitalize",
                  fontWeight: "600px",
                  fontSize: "14px",
                  border: isGeo ? "0px solid blue" : "1px solid blue",
                  borderRadius: '100px',
                  color: isGeo ? "#AA076B" : "#ffffff",
                  backgroundImage: isGeo ? '#ffffff' : "#AA076B"
                }}
                onClick={() => { this.props.handleActiveGeo(0) }}
                className={classes.button2}>Geo Location</Button>

              <Button
                style={{
                  padding: "5px 25px",
                  textTransform: "capitalize",
                  fontWeight: "600px",
                  fontSize: "14px",
                  border: isGeo ? `1px solid #AA076B` : "0px solid blue",
                  borderRadius: '100px',
                  color: isGeo ? "#ffffff" : "#AA076B",
                  backgroundImage: isGeo ? schedulerColor.main : '#ffffff'
                }}
                onClick={() => { this.props.handleActiveGeo(1) }}
                className={classes.button1}>Photos</Button>
            </ButtonGroup>
          </Grid>

          {!this.props.isGeo ?
            <Grid
              container
              spacing={2}
              justifyContent="center"
            >
              {myItem.geoLocations.length > 0 ? myItem.geoLocations.map((item) => {
                if (item.latitude && item.longitude)
                  return (
                    <Grid item sm={3}>
                      <StaticGoogleMap
                        onClick={() => { this.props.redictToMap(item) }}
                        zoom="18"
                        size="200x200"
                        className={classes.geoimage}
                        apiKey={GOOGLE_API_KEY}
                      >
                        <Marker
                          location={`${item.latitude},${item.longitude}`}
                          color="red"
                        />
                      </StaticGoogleMap>
                      <Typography
                        className={classes.geotext}
                        variant="body1"
                      >
                        {
                          item.status === 1 ? "Clock In" :
                            item.status === 2 ? "Break Start" :
                              item.status === 3 ? "Break End" :
                                "Clock Out"
                        }
                      </Typography>
                    </Grid>
                  )
              }) :
                <Grid item sm={12} style={{ marginTop: "2rem" }}>
                  <Typography
                    align="center"
                    variant="h4"
                  >
                    No records found...
                  </Typography>
                </Grid>
              }
            </Grid>
            :
            <Grid
              container
              spacing={2}
              justifyContent="center"
            >
              {myItem.allSelfie.length > 0 ? myItem.allSelfie.map((item) => {
                if (item.image)
                  return (
                    <Grid item sm={4}>
                      <Avatar
                        style={{ margin: "1rem 0px", width: "100%", height: "300px" }}
                        variant="square"
                        src={item.image}
                        className={classes.avatarText}
                      />
                      <Typography
                        className={classes.geotext}
                        variant="body1"
                      >
                        {
                          item.status === 1 ? "Clock In" :
                            item.status === 2 ? "Break Start" :
                              item.status === 3 ? "Break End" :
                                "Clock Out"
                        }
                      </Typography>
                    </Grid>
                  )
              }) :
                <Grid item sm={12} style={{ marginTop: "2rem" }}>
                  <Typography
                    align="center"
                    variant="h4"
                  >
                    No records found...
                  </Typography>
                </Grid>
              }
            </Grid>
          }

        </Grid>
    )
  }
}

export default withStyles(styles)(AddUser);
