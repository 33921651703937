import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { addOrEditRolesStyles } from "../Styles";
import { news_my_news_Data, news_news_builder_Data } from "../constants";

const NewsContent = ({ onPermissionsUpdate, checkboxesData }) => {
  const styles = addOrEditRolesStyles();

  const initializeAccessRights = () => ({
    view_news_report: checkboxesData.view_news_report === 1,
    news_dashboard_refresh_settings:
      checkboxesData.news_dashboard_refresh_settings === 1,
    news_my_news_Data: news_my_news_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    news_news_builder_Data: news_news_builder_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
  });

  const [accessRights, setAccessRights] = useState(initializeAccessRights());

  useEffect(() => {
    setAccessRights(initializeAccessRights());
  }, [checkboxesData]);

  const handleCheckboxChange = (group, index = null) => {
    setAccessRights((prevState) => {
      if (Array.isArray(prevState[group])) {
        let updatedGroup;
        if (index !== null) {
          updatedGroup = [...prevState[group]];
          updatedGroup[index].checked = !updatedGroup[index].checked;
        } else {
          const allChecked = prevState[group].every((item) => item.checked);
          updatedGroup = prevState[group].map((item) => ({
            ...item,
            checked: !allChecked,
          }));
        }

        const newState = { ...prevState, [group]: updatedGroup };
        onPermissionsUpdate(getPermissionsValues(newState));
        return newState;
      } else {
        const newState = { ...prevState, [group]: !prevState[group] };
        onPermissionsUpdate(getPermissionsValues(newState));
        return newState;
      }
    });
  };

  const getPermissionsValues = (state) => {
    const permissions = {};
    Object.entries(state).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          permissions[item.Name] = item.checked ? 1 : 0;
        });
      } else {
        permissions[key] = value ? 1 : 0;
      }
    });
    return permissions;
  };

  const renderAccessRightPanel = (title, groupKey, data = []) => (
    <ExpansionPanel className={styles.hrExpansionPanel}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        className={styles.hrExpansionPanelSummaryThird}
      >
        <Checkbox
          color="primary"
          checked={data.every((item) => item.checked)}
          indeterminate={
            data.some((item) => item.checked) &&
            !data.every((item) => item.checked)
          }
          onChange={() => handleCheckboxChange(groupKey)}
          className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
        />
        <Typography className={styles.hrPageTitle} variant="h6" color="primary">
          {title}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={styles.hrExpansionPanelDetailsThird}>
        <Grid container spacing={2}>
          {data.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={item.Name}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={item.checked}
                    className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
                    onChange={() => handleCheckboxChange(groupKey, index)}
                  />
                }
                label={item.Label}
              />
            </Grid>
          ))}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );

  return (
    <Grid item md={12} xs={12}>
      <ExpansionPanel className={styles.hrExpansionPanelMain}>
        <ExpansionPanelSummary
          className={styles.hrExpansionPanelSummary}
          expandIcon={<ExpandMoreIcon className={styles.hrHeadingIcon} />}
          aria-controls="news-content"
          id="news-header"
        >
          <Typography className={styles.hrHeading}>News</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={styles.hrExpansionPanelDetailsSecond}>
          <Grid>
            {renderAccessRightPanel(
              "My News",
              "news_my_news_Data",
              accessRights.news_my_news_Data
            )}
          </Grid>
          <Grid>
            {renderAccessRightPanel(
              "News Builder",
              "news_news_builder_Data",
              accessRights.news_news_builder_Data
            )}
          </Grid>
          <Grid item md={12} xs={12}>
            <ExpansionPanel className={styles.hrExpansionPanel}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className={styles.hrExpansionPanelSummaryThird}
              >
                <Checkbox
                  color="primary"
                  checked={accessRights.view_news_report}
                  onChange={() => handleCheckboxChange("view_news_report")}
                  className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
                />
                <Typography
                  className={styles.hrPageTitle}
                  variant="h6"
                  color="primary"
                >
                  News Report
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                className={styles.hrExpansionPanelDetailsThird}
              ></ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item md={12} xs={12}>
            <ExpansionPanel className={styles.hrExpansionPanel}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className={styles.hrExpansionPanelSummaryThird}
              >
                <Checkbox
                  color="primary"
                  checked={accessRights.news_dashboard_refresh_settings}
                  onChange={() =>
                    handleCheckboxChange("news_dashboard_refresh_settings")
                  }
                  className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
                />
                <Typography
                  className={styles.hrPageTitle}
                  variant="h6"
                  color="primary"
                >
                  News Settings
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                className={styles.hrExpansionPanelDetailsThird}
              ></ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  );
};

export default NewsContent;
