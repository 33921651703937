import React from "react";
import { FormControl } from "@mui/material";
import ReactSelect from "react-select";

const CustomSearchDropdown = ({
  options,
  selectedValue,
  handleChange,
  placeholder,
  width,
  height,
  iconHeight,
  iconWidth,
  borderColor,
  borderWidth,
  borderRadius,
  textSize,
  textColor,
  bgColor,
  boxShadowFlag,
  dropdownTextSize,
  dropdownTextColor,
  dropdownTextAlign,
}) => {
  return (
    <FormControl
      sx={{
        width: width,
        height: height || "",
        borderRadius: borderRadius,
        backgroundColor: bgColor || "white",
        padding: "0px !important",
        margin: "0px",
      }}
      size="small"
    >
      <ReactSelect
        className="basic-single"
        classNamePrefix="select"
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
        menuPortalTarget={document.body}
        isSearchable={true}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            // border: `${borderWidth || "1px"} solid ${borderColor}`,
            border: state.isFocused || state.isSelected
            ? `2px solid ${borderColor}`  // Change border to red when focused or selected
            : `1px solid #00000033`,
            borderRadius: borderRadius,
            width: width,
            minHeight: height || "",
            height: height || "",
            background: bgColor || "#ffffff",
            boxShadow: boxShadowFlag
              ? "0px 2px 2px rgba(0, 0, 0, 0.1)"
              : "0 0 0 transparent",
            fontSize: textSize,
            color: textColor,
            margin: "0px",
          }),
          dropdownIndicator: (base) => ({
            ...base,
            color: textColor,
            height: iconHeight || "",
            width: iconWidth || "",
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontSize: dropdownTextSize,
            // backgroundColor:state.isSelected ? borderColor: "white",
            color: state.isSelected ? "white" : dropdownTextColor,
            textAlign: dropdownTextAlign,
          }),
          singleValue: (baseStyles, state) => ({
            ...baseStyles,
            color: textColor,
            fontSize: textSize,
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            fontSize: dropdownTextSize,
            color: dropdownTextColor,
            textAlign: dropdownTextAlign,
          }),
          menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: 9999,
          }),
        }}
      />
    </FormControl>
  );
};

export default CustomSearchDropdown;
