// import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import { LoadingData, Confirmation } from "../../../components";
// import Grid from "@material-ui/core/Grid";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
// import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import Avatar from "@material-ui/core/Avatar";
// import { FILE_URL, settingColor } from "../../../config";
// import { Typography, Tooltip, Button } from "@material-ui/core";
// import { data_not_found } from "../../../icons";
// import { ExitToApp as LoginAsIcon } from "@material-ui/icons";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//   },
//   ListItem: {
//     // paddingLeft: 5,
//     marginBottom: "3px",
//   },
//   avatarText: {
//     textTransform: "uppercase",
//     height: "40px",
//     width: "40px",
//     fontWeight: 800,
//     fontSize: "15px",
//     backgroundColor: "#DFF2F7",
//     color: theme.palette.primary.main,
//   },
// }));

// export default function CheckboxListSecondary(props) {
//   const classes = useStyles();
//   var [confirmModal, setConfirmModal] = useState(false);
//   var [login_as_data, setLogin_as_data] = useState({});

//   const confirmModalOpen = (row) => {
//     var login_as_data = {
//       user_name: row.user_name,
//       user_email: row.user_email,
//       user_password: row.user_password,
//     };
//     setConfirmModal(true);
//     setLogin_as_data(login_as_data);
//   };

//   const confirmModalClose = () => {
//     setConfirmModal(false);
//   };

//   const loginAsUser = () => {
//     props.loginAsUser(login_as_data);
//   };
//   return (
//     <>
//       {props.assignUsersLoading ? (
//         <Grid item sm={12}>
//           <LoadingData />
//         </Grid>
//       ) : props.assignUsers.length > 0 ? (
//         <List
//           dense
//           disablePadding
//           className={classes.root}
//           style={{ display: "flex-end" }}
//         >
//           {props.assignUsers.map((value) => {
//             return (
//               <ListItem className={classes.ListItem} key={value} button>
//                 <ListItemAvatar>
//                   {value.user_image ? (
//                     <Avatar
//                       src={value.user_image}
//                       className={classes.avatarText}
//                     />
//                   ) : (
//                     <Avatar className={classes.avatarText}>
//                       {value.user_name.charAt(0)}
//                     </Avatar>
//                   )}
//                 </ListItemAvatar>
//                 <ListItemText
//                   primary={
//                     <Typography variant="h5">{value.user_name}</Typography>
//                   }
//                   secondary={
//                     <Typography variant="body2">{value.user_email}</Typography>
//                   }
//                   // style={{ display: 'flex-end' }}
//                 />
//                 <Tooltip
//                   onClick={() => {
//                     confirmModalOpen(value);
//                   }}
//                   title="Login as"
//                   arrow
//                 >
//                   <Button
//                     style={{
//                       background: "green",
//                       color: "white",
//                       fontSize: "10px",
//                       // padding: '7px 15px'  ,
//                       // marginLeft:'10px'
//                     }}
//                   >
//                     LOGIN AS
//                   </Button>
//                 </Tooltip>
//                 {/* <ListItemSecondaryAction>

//                         </ListItemSecondaryAction> */}
//               </ListItem>
//             );
//           })}
//         </List>
//       ) : (
//         <Grid
//           item
//           sm={12}
//           style={{
//             height: "75vh",
//             flexDirection: "column",
//             alignItems: "center",
//             display: "flex",
//             justifyContent: "center",
//             verticalAlign: "center",
//           }}
//         >
//           <img
//             alt="dsa"
//             style={{
//               height: "200px",
//             }}
//             src={data_not_found}
//           />
//         </Grid>
//       )}
//       <Confirmation
//         bgColor={settingColor.main}
//         desc={
//           `Are you sure you want to login as "` +
//           login_as_data?.user_name +
//           `" ?`
//         }
//         open={confirmModal}
//         onClose={confirmModalClose}
//         handleSubmit={loginAsUser}
//       />
//     </>
//   );
// }


import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LoadingData, Confirmation } from "../../../components";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { Typography, Tooltip, Button } from "@material-ui/core";
import { data_not_found } from "../../../icons";
import { ExitToApp as LoginAsIcon } from "@material-ui/icons";
import { settingColor } from "../../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  ListItem: {
    marginBottom: "3px",
  },
  avatarText: {
    textTransform: "uppercase",
    height: "40px",
    width: "40px",
    fontWeight: 800,
    fontSize: "15px",
    backgroundColor: "#DFF2F7",
    color: theme.palette.primary.main,
  },
}));

export default function CheckboxListSecondary({
  assignUsersLoading = false,
  toggleAssignUser,
  assignUsers = [], // Default to empty array if undefined
  loginAsUser,
}) {
  const classes = useStyles();
  const [confirmModal, setConfirmModal] = useState(false);
  const [login_as_data, setLogin_as_data] = useState({});

  const confirmModalOpen = (row) => {
    setLogin_as_data({
      user_name: row.user_name,
      user_email: row.user_email,
      user_password: row.user_password,
    });
    setConfirmModal(true);
  };

  const confirmModalClose = () => setConfirmModal(false);

  const handleLoginAsUser = () => {
    loginAsUser(login_as_data);
    setConfirmModal(false);
  };

  return (
    <>
      {assignUsersLoading ? (
        <Grid item sm={12}>
          <LoadingData />
        </Grid>
      ) : assignUsers && assignUsers.length > 0 ? (
        <List dense disablePadding className={classes.root}>
          {assignUsers.map((value) => (
            <ListItem className={classes.ListItem} key={value.user_name} button>
              <ListItemAvatar>
                {value.user_image ? (
                  <Avatar src={value.user_image} className={classes.avatarText} />
                ) : (
                  <Avatar className={classes.avatarText}>
                    {value.user_name.charAt(0)}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="h5">{value.user_name}</Typography>}
                secondary={<Typography variant="body2">{value.user_email}</Typography>}
              />
              <Tooltip title="Login as" arrow>
                <Button
                  style={{ background: "green", color: "white", fontSize: "10px" }}
                  onClick={() => confirmModalOpen(value)}
                >
                  LOGIN AS
                </Button>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      ) : (
        <Grid
          item
          sm={12}
          style={{
            height: "75vh",
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img alt="data not found" src={data_not_found} style={{ height: "200px" }} />
        </Grid>
      )}
      <Confirmation
        bgColor={settingColor.main}
        desc={`Are you sure you want to login as "${login_as_data.user_name}"?`}
        open={confirmModal}
        onClose={confirmModalClose}
        handleSubmit={handleLoginAsUser}
      />
    </>
  );
}
