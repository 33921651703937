export const SET_LESSON_LOADING = "SET_LESSON_LOADING";
export const SET_LESSON = "SET_LESSON";
export const SET_ALL_LESSON = "SET_ALL_LESSON";
export const ADD_LESSON = "ADD_LESSON";
export const EDIT_LESSON = "EDIT_LESSON";
export const DELETE_LESSON = "DELETE_LESSON";
export const SET_FETCH_LESSON_LOADING = "SET_FETCH_LESSON_LOADING";
export const FETCH_LESSON_BY_ID = "FETCH_LESSON_BY_ID";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_CATEGORIES_LOADING = "GET_ALL_CATEGORIES_LOADING";
export const GET_ALL_CATEGORIES_WITH_LESSONS =
  "GET_ALL_CATEGORIES_WITH_LESSONS";
export const GET_ALL_CATEGORIES_WITH_LESSONS_LOADING =
  "GET_ALL_CATEGORIES_WITH_LESSONS_LOADING";
