import {
  Box,
  CircularProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
// import { earningTableBody, earningTableHead } from "./constant";

import "./EarningPaymentMobile.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  fetchAllEarningLessonsData,
  fetchUserEarningData,
} from "../../../../../pages/eLearning/Earning/modules/actions";
import { Stack } from "rsuite";
import moment from "moment";
import {
  Dropdown,
  EarningDateRangePicker,
  EarningMultiSelectDropdown,
  SearchWithCounter,
} from "../../../../../pages/eLearning/Earning/Components/Dropdown";
import CustomSearchDropdown from "../../../../../components/ELearning/Dropdown/CustomSearchDropdown";
import { LoadMoreMobile } from "../../../components/LoadMoreMobile";

export const EarningPaymentMobile = ({
  selectedRows,
  setSelectedRows,
  userId,
  onSelectedRows,
  payUserEarningData,
}) => {
  const dispatch = useDispatch();
  const {
    userEarningData,
    userEarningLoading,
    allEarningLeassonsData,
    allEarningLessonsLoading,
  } = useSelector((state) => ({
    userEarningData: state.eLearningEarning.userEarningData,
    userEarningLoading: state.eLearningEarning.userEarningLoading,
    allEarningLeassonsData: state.eLearningEarning.allEarningLeassonsData,
    allEarningLessonsLoading: state.eLearningEarning.allEarningLessonsLoading,
  }));

  const [selectedEarningPoints, setSelectedEarningPoints] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [lessonIds, setLessonIds] = useState([]);
  const [accumulatedData, setAccumulatedData] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedPage, setSelectedPage] = useState(1);

  const statusOptions = [
    { value: null, label: "All" },
    { value: 1, label: "Paid" },
    { value: 0, label: "Unpaid" },
  ];

  const handleStatus = (event) => {
    const status = event.value;

    setSelectedStatus(status);
  };

  useEffect(() => {
    dispatch(fetchAllEarningLessonsData());
  }, [dispatch]);

  const handleFetchUserEarningData = async (dataObj) => {
    let data = {
      uid: userId,
      lessonIds: [],
      page: 1,
      limit: 10,
    };

    if (dataObj) {
      data = { ...data, ...dataObj };
    }

    if (selectedEarningPoints !== undefined && selectedEarningPoints !== null) {
      data.points = selectedEarningPoints;
    }

    if (selectedStatus !== undefined && selectedStatus !== null) {
      data.status = selectedStatus;
    }

    if (lessonIds) {
      data.lessonIds = [...data.lessonIds, ...lessonIds];
    }

    const { page = 1, limit = 10, ...rest } = data;

    dispatch(fetchUserEarningData(rest, page, limit));
  };

  useEffect(() => {
    if (!userEarningLoading && userEarningData.result) {
      setAccumulatedData([...accumulatedData, ...userEarningData.result]);
    }
  }, [userEarningData, userEarningLoading]);

  useEffect(() => {
    setAccumulatedData([]);
    handleFetchUserEarningData();
  }, [selectedStatus, selectedEarningPoints, lessonIds, dispatch]);

  const handleDateChange = (startDate, endDate) => {
    setAccumulatedData([]);

    setStartDate(startDate);
    setEndDate(endDate);

    let data = {};

    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);

      // Join all dates into a single string with comma-separated values
      data.date = start.format("YYYY-MM-DD") + "," + end.format("YYYY-MM-DD");
    }

    handleFetchUserEarningData(data);
  };

  useEffect(() => {
    setSelectedPage(userEarningData?.currentPage || 1);
  }, [userEarningData]);

  useEffect(() => {
    if (payUserEarningData) {
      if (payUserEarningData.success === 1) {
        handleFetchUserEarningData();
      }
    }
  }, [payUserEarningData]);

  // const handlePageChange = (event, value) => {
  //   let data = {
  //     page: value,
  //     limit: 10,
  //   };

  //   handleFetchUserEarningData(data);
  // };

  const loadMoreData = () => {
    if (selectedPage < userEarningData.totalPages) {
      let data = {
        page: selectedPage + 1,
        limit: 10,
      };
      handleFetchUserEarningData(data);
    }
  };

  const handleCheckboxChange = (event, rowData) => {
    const isChecked = event.target.checked;
    setSelectedRows((prevSelectedRows) => {
      let newSelectedRows;
      if (isChecked) {
        // If the checkbox is checked, add the row to the selectedRows state
        newSelectedRows = [...prevSelectedRows, rowData];
      } else {
        // If the checkbox is unchecked, remove the row from the selectedRows state
        newSelectedRows = prevSelectedRows.filter(
          (row) => row._id !== rowData._id
        );
      }
      // Update the parent component's state with the new selected rows
      onSelectedRows(newSelectedRows);
      return newSelectedRows;
    });
  };

  const renderHeadRows = (data) =>
    data.map((item, index) => (
      <TableCell key={item.id} className={"headCell"}>
        {item.dropdown ? (
          <div
            style={{
              display: "flex",
              justifyContent: index === 0 ? "start" : "center",
              alignItems: "start",
              paddingLeft: index === 0 ? "20px" : "",
            }}
          >
            {item.dropdown}
          </div>
        ) : (
          item.heading
        )}
      </TableCell>
    ));

  const renderMyDataRows = (data) => {
    return data?.map(
      (
        {
          _id,
          lesson_name,
          earningPoints,
          earningAmounts,
          lessonExpiryDays,
          lessonExpiryHours,
          lessonExpiryMinutes,
          createdAt,
          paymentStatus,
        },
        index
      ) => {
        let formattedDate = "";
        if (createdAt) {
          const date = new Date(createdAt);
          const year = date.getFullYear();
          const month = date.getMonth() + 1; // Months are zero-based, so we add 1
          const day = date.getDate();
          formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
            day < 10 ? "0" : ""
          }${day}`;
        }

        return (
          <TableRow key={_id}>
            <TableCell className={"bodyCell"} component="th" scope="row">
              {paymentStatus === 0 ? (
                <div className="earning-table-body-first-child-pending-status">
                  <input
                    type="checkbox"
                    checked={selectedRows.some((row) => row._id === _id)}
                    onChange={(event) =>
                      handleCheckboxChange(event, {
                        _id,
                        lesson_name,
                        earningPoints,
                        earningAmounts,
                        lessonExpiryDays,
                        lessonExpiryHours,
                        lessonExpiryMinutes,
                        createdAt,
                        paymentStatus,
                      })
                    }
                  />
                  <div className="earning-table-body-row-cell-first-child-text-position">
                    {lesson_name.slice(0, 20)}
                  </div>
                </div>
              ) : (
                <div className="earning-table-body-row-cell-first-child-text-position">
                  {lesson_name.slice(0, 20)}
                </div>
              )}
            </TableCell>

            <TableCell className={"bodyCell"} component="th" scope="row">
              {earningPoints}
            </TableCell>

            <TableCell className={"bodyCell"} component="th" scope="row">
              {earningAmounts}
            </TableCell>
            <TableCell className={"bodyCell"} component="th" scope="row">
              {`${lessonExpiryDays} Days ${lessonExpiryHours} Hours ${lessonExpiryMinutes} Minutes`}
            </TableCell>
            <TableCell className={"bodyCell"} component="th" scope="row">
              {formattedDate}
            </TableCell>
            <TableCell
              className={"bodyCell"}
              style={{
                color:
                  paymentStatus === 0
                    ? "#F6B000"
                    : paymentStatus === 1
                    ? "#287502"
                    : "",
              }}
              component="th"
              scope="row"
            >
              {paymentStatus === 0 ? "Pending" : paymentStatus === 1 && "Paid"}
            </TableCell>
          </TableRow>
        );
      }
    );
  };

  const earningTableHead = [
    {
      id: 1,
      heading: "Lesson name",
      dropdown: (
        <EarningMultiSelectDropdown
          size={{ width: "100px" }}
          bgColor="white"
          arrowColor={"#1B3F8F"}
          textColor={"#1B3F8F"}
          borderColor={"#1B3F8F33"}
          buttonText={"Employee"}
          rounded={"10px"}
          options={allEarningLeassonsData?.result?.map((option) => ({
            value: option._id,
            label: option.lesson_name,
          }))}
          selectedOptions={lessonIds}
          onOptionSelect={(value) =>
            setLessonIds((prev) => {
              const isSelected = prev.includes(value);
              if (isSelected) {
                return prev.filter((id) => id !== value);
              } else {
                return [...prev, value];
              }
            })
          }
          earningEmployeesData={allEarningLeassonsData?.result}
          userIds={lessonIds}
          setUserIds={setLessonIds}
          labelProperty="lesson_name"
        />
      ),
    },
    {
      id: 2,
      heading: "Point",
      dropdown: (
        <SearchWithCounter
          size={{ width: "100px" }}
          bgColor="white"
          textColor={"#1B3F8F"}
          borderColor={"#1B3F8F33"}
          rounded={"10px"}
          buttonText={"Point"}
          textSize={"12px"}
          value={selectedEarningPoints}
          onChange={(value) => setSelectedEarningPoints(Number(value))}
        />
      ),
    },
    {
      id: 3,
      heading: "Amount",
      dropdown: "",
    },
    {
      id: 4,
      heading: "Duration",
      dropdown: "",
    },
    {
      id: 5,
      heading: "Date",
      dropdown: (
        <EarningDateRangePicker
          size={{ width: "150px" }}
          bgColor="white"
          textColor={"#1B3F8F"}
          borderColor={"#1B3F8F33"}
          rounded={"10px"}
          startDate={startDate}
          endDate={endDate}
          handleClose={() => handleDateChange("", "")}
          handleDateChange={handleDateChange}
        />
      ),
    },
    {
      id: 6,
      heading: "Status",
      dropdown: (
        <CustomSearchDropdown
          options={statusOptions}
          selectedValue={selectedStatus}
          handleChange={handleStatus}
          placeholder={
            selectedStatus === 1
              ? "Paid"
              : selectedStatus === 0
              ? "Pending"
              : "Status"
          }
          width={100}
          borderColor="transparent"
          borderRadius="10px"
          textSize="12px"
          textColor="#1B3F8F"
          dropdownTextSize="12px"
          dropdownTextColor="#333"
          dropdownTextAlign="left"
        />
      ),
    },
  ];

  return (
    <>
      <div className="earningPaymentMobileRootContainer">
        <TableContainer className={`scroll tableContainer`}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>{renderHeadRows(earningTableHead)}</TableRow>
            </TableHead>
            <TableBody className={"tableBodyContainer"}>
              {renderMyDataRows(accumulatedData)}
            </TableBody>
          </Table>
        </TableContainer>
        {userEarningLoading && (
          <div className="loaderContainer">
            <CircularProgress style={{ color: "#1B3F8F" }} />
          </div>
        )}

        {!userEarningLoading && userEarningData?.result?.length === 0 && (
          <Box textAlign="center">
            <Typography
              style={{
                color: "#848484",
                fontWeight: "400",
                fontSize: "16px",
              }}
              spacing={2}
              m={"1rem"}
            >
              No Record Found
            </Typography>
          </Box>
        )}
        {/* <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Stack spacing={2} m={"1rem"}>
            <Pagination
              color="primary"
              count={userEarningData?.totalPages}
              variant="outlined"
              page={selectedPage}
              onChange={handlePageChange}
            />
          </Stack>
        </div> */}
        <div style={{ marginTop: "10px" }}>
          {selectedPage < userEarningData?.totalPages && (
            <LoadMoreMobile handlePageChange={loadMoreData} />
          )}
        </div>
      </div>
    </>
  );
};
