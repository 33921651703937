import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Slide,
  Grid,
  Button,
  Typography,
  Avatar,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { ovHrV5Color } from "../config";

const styles = (theme) => ({
  avatar: {
    margin: "0px auto",
    backgroundColor: "#ffffff",
    height: "60px",
    width: "60px",
  },
  avatarIcon: {
    fontSize: "4rem",
  },
  avatarIconHR: {
    fontSize: "4rem",
    color:ovHrV5Color.main
  },
  title: {
    textAlign: "center",
  },
  dialog: {
    borderRadius: 0,
  },
  dialogcontent: {
    backgroundColor: "#fcfcfc",
    padding: "2rem",
    borderRadius: 0,
    overflow: "hidden",
    "&:last-child": {
      borderRadius: 0,
      padding: "2rem",
    },
  },
  saveButton: {
    boxShadow: "none",
    fontWeight: 600,
    color: "#ffffff",
    borderRadius: 0,
    border: "1px solid green",
    padding: "10px 22px",
  },
  closeButton: {
    boxShadow: "none",
    fontWeight: 600,
    color: "#ffffff",
    borderRadius: 0,
    border: "1px solid gray",
    padding: "10px 22px",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DeleteModal extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        className={classes.dialog}
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <DialogContent className={classes.dialogcontent} dividers>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
              <Avatar className={classes.avatar}>
                <DeleteIcon color="primary" className={this.props.product === "HR" ? classes.avatarIconHR : classes.avatarIcon} />
              </Avatar>
            </Grid>

            <Grid item md={12} xs={12}>
              <Typography className={classes.title} variant="h3" gutterBottom>
                {this.props.desc}
              </Typography>

              <Typography
                className={classes.title}
                variant="body1"
                gutterBottom
              >
                Do you really want to delete these records? This process cannot
                be undone.
              </Typography>
            </Grid>

            <Grid item md={12} xs={12}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={6}>
                  <Button
                    onClick={this.props.onClose}
                    size="large"
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: "gray" }}
                    className={classes.closeButton}
                  >
                    No,cancel!
                  </Button>
                </Grid>
                <Grid item md={6} xs={6}>
                  <Button
                    style={{
                      backgroundImage: this.props.bgColor
                        ? this.props.bgColor
                        : "gray",
                        backgroundColor: this.props.bgColor
                        ? this.props.bgColor
                        : "gray"
                    }}
                    onClick={this.props.handleDelete}
                    size="large"
                    fullWidth
                    variant="contained"
                    className={classes.saveButton}
                  >
                    Yes,delete it!
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DeleteModal);
