import {
    sc_image_dashboard,
    sc_image_dashboard_active,
    sc_image_scheduler,
    sc_image_scheduler_active,
    sc_image_myavailability,
    sc_image_myavailability_active,
    // sc_image_teamavailability,
    // sc_image_teamavailability_active,
    sc_image_timeoffrequest,
    sc_image_timeoffrequest_active,
    sc_image_tradeshift,
    sc_image_tradeshift_active,
    tickets,
    Wish,
    coupons,
    sc_image_req,
    sc_image_req_active,
    device_management,
    ideas,
    billings,
    Coloricon,
    company,
    users,
    locations,
    sc_image_covershift,
    sc_image_covershift_active,
    sc_image_timesheets,
    sc_image_timesheets_active,
    sc_image_pay_summary,
    sc_image_pay_summary_active,
    // sc_image_roles,
    // sc_image_roles_active,
    // sc_image_team,
    // sc_image_team_active,
    // sc_image_back,
    // sc_image_settings,
    // sc_image_settings_active,
    sc_image_settings_scheduling,
    sc_image_settings_scheduling_active,
    // sc_image_settings_timetracking,
    // sc_image_settings_timetracking_active,
    // sc_image_settings_timeofpto,
    // sc_image_settings_timeofpto_active,
    // ele_image_dashboard,
    // ele_image_dashboard_active,
    // ele_image_mycourses,
    // ele_image_mycourses_active,
    // ele_image_library,
    // ele_image_library_active,
    // ele_image_courses,
    // ele_image_courses_active,
    // ele_image_helpdesk,
    // ele_image_helpdesk_active,
    // ele_image_reports,
    // ele_image_reports_active,
    // ele_image_team,
    // ele_image_team_active,
    // sc_image_timecard,
    // sc_image_timecard_active,
    // sc_image_settings_location,
    // sc_image_settings_location_active,
    // news_update,
    products,
    profile,
    // ovChat,
    // ovRoutine,
    // device_management,
  } from "../icons";
  // import TimelineIcon from "@mui/icons-material/Timeline";
  
  export const AdminRoutes = [
    {
        label: "Products",
        path: "/settings/products",
        image: products,
        active_image: products,
      },
      {
        label: "Profile",
        path: "/settings/profile",
        image: profile,
        active_image: profile,
      },
    {
      label: "Company",
      path: "/settings/company",
      image: company,
      active_image: company,
    },
    // {
    //     label: "Live Tracking",
    //     path: "/scheduler/live_tracking",
    //     image: sc_image_dashboard,
    //     active_image: sc_image_dashboard_active
    // },
    {
      type: "divider",
    },
    {
      label: "Users",
      path: "/settings/users",
      image: users,
      active_image: users,
    },
  
    // {
    //   type: "divider",
    // },
    // {
    //   // asad hide
    //   path: "/",
    //   label: "Request",
    //   image: sc_image_req,
    //   active_image: sc_image_req_active,
    //   childData: [
    //     {
    //       label: "Trade shift",
    //       path: "/scheduler/tradeshift/my",
    //       image: sc_image_tradeshift,
    //       active_image: sc_image_tradeshift_active,
    //       count: "scheduler_trade_shifts",
    //     },
    //     {
    //       label: "Cover shift",
    //       path: "/scheduler/covershift/my",
    //       image: sc_image_covershift,
    //       active_image: sc_image_covershift_active,
    //     },
    //     {
    //       label: "Time off",
    //       path: "/scheduler/timeoffrequest/my",
    //       image: sc_image_timeoffrequest,
    //       active_image: sc_image_timeoffrequest_active,
    //       count: "scheduler_timeoff_requests",
    //     },
    //     {
    //       label: "Correction",
    //       path: "/scheduler/correction_request/my",
    //       image: sc_image_pay_summary,
    //       active_image: sc_image_pay_summary_active,
    //     },
    //     // {
    //     //   label: "My Request",
    //     //   path: "/scheduler/mycorrection_request",
    //     //   image: sc_image_pay_summary,
    //     //   active_image: sc_image_pay_summary_active,
    //     // },
    //   ],
    // },
    // {
    //   type: "divider",
    // },
    {
      label: "Device Management",
      path: "/settings/device",
      image: device_management,
      active_image: device_management,
    },
    {
      label: "Tickets",
      path: "/settings/tickets",
      image: tickets,
      active_image: tickets,
    },
    {
        label: "Ideas",
        path: "/settings/ideas",
        image: ideas,
        active_image: ideas,
      },
      {
        label: "Billing Summary",
        path: "/settings/billings",
        image: billings,
        active_image: billings,
      },
      {
        label: "Coupons",
        path: "/settings/coupons",
        image: coupons,
        active_image: coupons,
      },
      {
        label: "Product Color",
        path: "/settings/color",
        image: Coloricon,
        active_image: Coloricon,
      },
      // {
      //   label: "Product List",
      //   path: "/settings/productList",
      //   image: Wish,
      //   active_image: Wish,
      // },
    {
      type: "divider",
    },
    {
      label: "Customers",
      path: "/settings/customers",
      image: billings,
      active_image: billings,
    },
    {
      path: "/",
      label: "Billing",
      childData: [
        {
          label: "Sign ups",
          path: "/settings/billing/signups",
          image: sc_image_pay_summary,
          active_image: sc_image_pay_summary_active,
        },
        {
          label: "Reports",
          path: "/",
          image: sc_image_settings_scheduling,
          active_image: sc_image_settings_scheduling_active,
        },
        // {
        //   label: "Time off & PTO",
        //   path: "/scheduler/settings/timeoff_pto",
        //   image: sc_image_settings_timeofpto,
        //   active_image: sc_image_settings_timeofpto_active,
        // },
      ],
    },
    {
      path: "/",
      label: "Website Management",
      childData: [
        // {
        //   label: "Features",
        //   path: "/",
        //   image: sc_image_pay_summary,
        //   active_image: sc_image_pay_summary_active,
        // },
        {
          label: "Timeline",
          path: "/settings/websitemanagement/timeline",
          image: sc_image_settings_scheduling,
          active_image: sc_image_settings_scheduling_active,
        },
        // {
        //   label: "Page Content",
        //   path: "/",
        //   image: sc_image_pay_summary,
        //   active_image: sc_image_pay_summary_active,
        // },
        {
            label: "Product",
            path: "/settings/websitemanagement/products",
            image: sc_image_pay_summary,
            active_image: sc_image_pay_summary_active,
          },
          {
            label: "Pricing",
            path: "/settings/websitemanagement/pricing",
            image: sc_image_pay_summary,
            active_image: sc_image_pay_summary_active,
          },
        // {
        //   label: "Time off & PTO",
        //   path: "/scheduler/settings/timeoff_pto",
        //   image: sc_image_settings_timeofpto,
        //   active_image: sc_image_settings_timeofpto_active,
        // },
      ],
    },
  ];
  