import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  OutlinedInput,
} from "@material-ui/core";
import { addOrEditRolesStyles, selectCustomStyles } from "./Styles";
import { usersServices } from "../../UsersList/modules/services";
import NewsContent from "./components/News";
import SchedulerContent from "./components/Scheduler";
import HRContent from "./components/HR";
import ELearningContent from "./components/ELearning";

const RoleFormContent = ({ roleData, isEdit, onRoleDataChange }) => {
  const styles = addOrEditRolesStyles();
  const [rolesList, setRolesList] = useState([]);
  const [formData, setFormData] = useState(roleData);
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await usersServices.fetchRoleData();
        if (response.data.success !== 2) {
          const formattedRoles = response.data.result.map((item) => ({
            value: item._id,
            label: item.role_name,
          }));
          setRolesList(formattedRoles);
          if (!isEdit && formattedRoles.length > 0) {
            const defaultRole = formattedRoles[0].value;
            setFormData((prevData) => ({
              ...prevData,
              role_parentId: defaultRole,
            }));
            onRoleDataChange({ ...formData, role_parentId: defaultRole });
          }
        }
      } catch (error) {
        console.error("Error fetching roles for dropdown:", error);
      }
    };
    fetchRoles();
  }, [isEdit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...formData, [name]: value };
    setFormData(updatedData);
    onRoleDataChange(updatedData);
  };

  const handlePermissionsUpdate = (updatedPermissions) => {
    const updatedData = { ...formData, ...updatedPermissions };
    setFormData(updatedData);
    onRoleDataChange(updatedData);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          name="role_name"
          label="Role Name"
          value={formData.role_name}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          placeholder={isEdit ? "Edit Role Name" : "Add Role Name"}
          className={styles.hrTextField}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="role_description"
          label="Role Description"
          value={formData.role_description}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          placeholder={
            isEdit ? "Edit Role Description" : "Add Role Description"
          }
          className={styles.hrTextField}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Reports To</Typography>
        <Select
          name="role_parentId"
          value={formData.role_parentId || ""}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          input={<OutlinedInput />}
        >
          {rolesList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <ELearningContent
        checkboxesData={formData}
        onPermissionsUpdate={handlePermissionsUpdate}
      />
      <NewsContent
        checkboxesData={formData}
        onPermissionsUpdate={handlePermissionsUpdate}
      />
      <SchedulerContent
        checkboxesData={formData}
        onPermissionsUpdate={handlePermissionsUpdate}
      />
      <HRContent
        checkboxesData={formData}
        onPermissionsUpdate={handlePermissionsUpdate}
      />
    </Grid>
  );
};

export default RoleFormContent;
