import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TableMyPagination, TableRecordNotFound, LoadingData, DeleteModal, MyLeftModal, MyTextfield, MoreMenu, LoadingDataHR } from '../../../components'
import { stableSort, getComparator } from '../../../utilities/TableFunctions'
import { fetchData, addData, editData, deleteData, defaultTimecode } from './modules/actions';
import { connect } from "react-redux";
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@material-ui/icons';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { MY_TOKEN } from "../../../config";
import { timecodeServices } from "./modules/services";
import axios from "axios";
import {
    FormControlLabel,
    Radio,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    Grid,
    Tooltip
}
    from '@material-ui/core';
import { ovHrV5Color } from "../../../config";
import { utcToLocal, validationIsEmpty, alert } from '../../../utilities';
import HolidayLinked from "./HolidayLinked";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { LoadMore } from "../../../components/ELearning/Button/LoadMore";


const styles = (theme) => ({
    root: {
        padding: theme.spacing(0)
    },
    headCell: {
        padding: '0.5rem 0rem',
        color: '#ffffff',
        fontWeight: 500,
        textTransform: 'capitalize',
        fontSize: '16px'
    },
    rootToolbar: {
        minHeight: '58px',
        padding: theme.spacing(0, 2)
    },
    title: {
        flex: '1 1 100%',
        fontSize: '16px',
        fontWeight: '400',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    TableCell: {
        fontSize: '14px',
        fontWeight: '400'
    }
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: " 70%",
    width: "74%",
    overflow: "scroll",
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


class Timecode extends Component {
    constructor(props) {
        super(props)
        this.state = {
            myItem: {},
            order: 'asc',
            orderBy: "_id",
            headCells: [
                { id: 'name', label: "Name", numeric: false },
                { id: 'desc', label: "Holiday", numeric: false },
                { id: 'code', label: "Code", numeric: false },

            ],
            errors: {},
            errorsHelper: {},
            assignLocationData: [],
            locationLoader: false,
            accumulatedData: [],
            moreData: [
                {
                    icon: EditIcon,
                    title: "Edit",
                    sortName: "edit",
                    onClick: this.modifyData,
                    permission: props.permissionsData.scheduler_payroll_code_edit
                },
                {
                    icon: DeleteIcon,
                    title: "Delete",
                    sortName: "delete",
                    onClick: this.modifyData,
                    permission: props.permissionsData.scheduler_payroll_code_delete
                }
            ],
            moreData1: [
                // {
                //     icon: EditIcon,
                //     title: "Edit",
                //     sortName: "edit",
                //     onClick: this.modifyData,
                //     permission: props.permissionsData.scheduler_payroll_code_edit
                // },
                // {
                //     icon: DeleteIcon,
                //     title: "Delete",
                //     sortName: "delete",
                //     onClick: this.modifyData,
                //     permission: props.permissionsData.scheduler_payroll_code_delete
                // }
            ],
            timecodeData: [],
            loading: false,
            loadingArray: [1,2,3,4,5,6,7,8,9,10],
            page: 1,
            loadingAddEditDelete: false
        }
    }

    componentDidMount = () => {
        var isReload = this.props.timecodeData.length > 0 ? false : true
        this.fetchData()

        this.setState({
            timecodeData: this.props.timecodeData,
            accumulatedData: []
        })
    }

    async fetchData() {
        let data = { page: this.state.page, limit: 10 };
        this.setState({
            loading: true,
        });

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/payroll/timecode/paginated`,
                {
                    headers: { Authorization: MY_TOKEN },
                    params: data,
                }
            );

            if (response.data.success) {
                this.setState({
                    accumulatedData: [
                        ...this.state.accumulatedData,
                        ...response.data.TimecodeData,
                    ],
                    page: response.data.pageNumber,
                    totalPage: response.data.total_page,
                    loading: false,
                });
            } else {
                this.setState({
                    loading: false,
                });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            this.setState({
                loading: false,
            });
        }
    }

    handleChangePage = (event, value) => {
        this.setState(
            {
                page: value,
            },
            () => {
                this.fetchData()
            }
        );
    };


    createSortHandler = (property) => (event) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        })
    };

    handleSubmit = async () => {
        var userItem = this.state.myItem;
        var valid_name = await validationIsEmpty(userItem.timecode_name);
        var valid_code = await validationIsEmpty(userItem.code);

        if (valid_name) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    name: true
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    name: "Please enter name"
                }
            }))
        }

        if (valid_code) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    code: true
                },
                errorsHelper: {
                    ...prevState.errorsHelper,
                    code: "Please enter code"
                }
            }))
        }

        if (valid_name || valid_code) {
            return;
        }

        this.setState({ addEditModal: false })
        if (this.state.isEdit) {
            // this.props.editData(userItem)
            this.setState({
                loadingAddEditDelete: true,
            })
            timecodeServices.editData(userItem).then((response) => {
                if (response.data.success === 2) {
                    // invalidToken(response.data.message)
                } else {
                    alert.success(response.data.message)
                    this.setState({
                        loadingAddEditDelete: false,
                        accumulatedData: this.state.accumulatedData.map((item) => item._id === userItem._id ? response.data.post : item),
                    })

                }
            });
        } else {
            this.setState({
                loadingAddEditDelete: true,
            })
            timecodeServices.addData(userItem).then((response) => {
                if (response.data.success === 2) {
                    // invalidToken(response.data.message)
                } else {
                    alert.success(response.data.message)
                    this.setState({
                        loadingAddEditDelete: false,
                        accumulatedData: [...this.state.accumulatedData, response.data.post]
                    })

                }
            });
            // this.props.addData(userItem)
        }
    };

    deleteData = () => {
        this.setState({ deleteModal: false })
        // this.props.deleteData(this.state.myItem._id)
        timecodeServices.deleteData(this.state.myItem._id).then((response) => {
            if (response.data.success === 2) {
                // invalidToken(response.data.message)
            } else {
                alert.success(response.data.message)
                this.setState({
                    loadingAddEditDelete: false,
                    accumulatedData: this.state.accumulatedData.filter((item) => item._id !== this.state.myItem._id)
                })

            }
        });
    };

    deleteModalClose = () => {
        this.setState({
            deleteModal: false
        })
    };

    addEditModalClose = () => {
        this.setState({
            addEditModal: false
        })
    };

    modifyData = (value) => {
        this.setState({
            isEdit: value === "edit" ? 1 : 0,
            addEditModal: value === "add" || value === "edit" ? true : false,
            deleteModal: value === "delete" ? true : false
        })
    }


    handleChange = (e) => {
        let state = e.target.name;
        let value = e.target.value;
        this.setState(prevState => ({
            myItem: {
                ...prevState.myItem,
                [state]: value
            }
        }));
    }

    handleFocus = (e) => {
        let state = e.target.name;
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                [state]: false
            },
            errorsHelper: {
                ...prevState.errorsHelper,
                [state]: ""
            }
        }))
    }

    moreMenuClick = (item, value) => {
        var myItem = {
            timecode_name: "",
            code: ""
        }
        if (item) {
            myItem = item
        }
        this.setState({
            isEdit: 0,
            myItem,
            addEditModal: value === "add" ? true : false
        })
    }

    loadMoreData = () => {
        const nextPage = parseInt(this.state.page) + 1;
        if (nextPage <= this.state.totalPage) {
            this.setState({ page: nextPage, loading: true }, () => {
                this.fetchData();
            });
        }
    };


    defaultTimecode = (e, item) => {
        var data = {
            id: item._id
        }
        this.props.defaultTimecode(data)
    }

    openRoleLocation = (row, value) => {
        this.setState({
            activepage: value,
            locationRoleModal: true,
            locationLoader: true
        })
        if (value === "holiday") {
            this.setState({
                assignLocationData: row.public_holiday_count_list,
                locationLoader: false
            })
        }

    }

    closelocationroleModal = () => {
        this.setState({
            locationRoleModal: false
        })
    }

    render() {
        const { classes, isLoading, pageNo, rowsPerPage, permissionsData } = this.props;
        const { timecodeData } = this.state;
        if (this.state.loadingAddEditDelete) return <LoadingDataHR />
        return (
            permissionsData.scheduler_payroll_template ?
                <Grid
                    container
                    spacing={4}
                    className={classes.root}
                >
                    <Modal
                        open={this.state.locationRoleModal}
                        onClose={this.closelocationroleModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            {this.state.activepage === "holiday" &&
                                <HolidayLinked
                                    assignLocationData={this.state.assignLocationData}
                                    locationLoader={this.state.locationLoader}
                                    pageNo={pageNo}
                                    rowsPerPage={rowsPerPage}
                                />
                            }
                        </Box>
                    </Modal>
                    <Grid
                        item
                        xs={12}
                    >
                        <Paper>
                            <Toolbar variant="regular" className={classes.rootToolbar}>
                                {/* <Tooltip arrow title="Back">
                                <IconButton
                                    className={classes.closeIcon}
                                    size="small"
                                    onClick={this.props.addEditModalCloseTimeCode}
                                >
                                    <BackIcon />
                                </IconButton>
                            </Tooltip> */}
                                <Typography
                                    display="inline"
                                    className={classes.title}
                                    variant="h6" >
                                    Timecode
                                </Typography>
                                {this.props.permissionsData.scheduler_payroll_code_add ?
                                    <Tooltip arrow title="Add">
                                        <IconButton
                                            disabled={permissionsData.scheduler_payroll_code_add ? false : true}
                                            style={{ float: 'right' }}
                                            size="small"
                                            onClick={() => { this.moreMenuClick(null, "add") }}
                                        >
                                            <AddIcon
                                                style={{ border: '3px', borderRadius: '50%', backgroundColor: ovHrV5Color.main, color: 'white', fontWeight: '500' }} />

                                        </IconButton>
                                    </Tooltip> : ""}
                            </Toolbar>
                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    size='medium'
                                >
                                    <TableHead style={{ backgroundColor: ovHrV5Color.main }}>
                                        <TableRow>
                                            {this.state.headCells.map((headCell) => (
                                                <TableCell
                                                    style={{ padding: '10px' }}
                                                    key={headCell.id}
                                                    sortDirection={this.state.orderBy === headCell.id ? this.state.order : false}
                                                >
                                                    <TableSortLabel
                                                        className={classes.headCell}
                                                        active={this.state.orderBy === headCell.id}
                                                        direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                                                        onClick={this.createSortHandler(headCell.id)}
                                                    >
                                                        {headCell.label}
                                                        {this.state.orderBy === headCell.id ? (
                                                            <span className={classes.visuallyHidden}>
                                                                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </span>
                                                        ) : null}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}
                                            <TableCell
                                                style={{ paddingRight: '20px' }}
                                                className={classes.headCell}
                                                align="right"
                                                colSpan={2}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {stableSort(this.state.accumulatedData, getComparator(this.state.order, this.state.orderBy)).map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row._id}
                                                >
                                                    <TableCell className={classes.TableCell}>
                                                        <FormControlLabel
                                                            onChange={(e) => { this.defaultTimecode(e, row) }}
                                                            style={{
                                                                margin: "0px"
                                                            }}
                                                            // value={row.is_default}
                                                            control={<Radio
                                                                style={{ color: ovHrV5Color.main }}
                                                                checked={row.is_default}
                                                            />}
                                                            label=""
                                                        />

                                                        {row.timecode_name}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => this.openRoleLocation(row, "holiday")}
                                                        className={classes.TableCell}>
                                                        {row.public_holiday_count}
                                                    </TableCell>
                                                    <TableCell
                                                        className={classes.TableCell}>
                                                        {row.code}
                                                    </TableCell>
                                                    {row.isDeleteEnable === 1 ?
                                                        <TableCell
                                                            align="right"
                                                            className={classes.TableCell}>
                                                            <MoreMenu
                                                                product={'HR'}
                                                                moreMenuClick={() => { this.moreMenuClick(row) }}
                                                                moreData={this.state.moreData}
                                                            />
                                                        </TableCell>
                                                        :
                                                        <TableCell
                                                            style={{ pointerEvents: "none" }}
                                                            align="right"
                                                            className={classes.TableCell}>
                                                            <MoreMenu
                                                                product={'HR'}
                                                                moreMenuClick={() => { this.moreMenuClick(row) }}
                                                                moreData={this.state.moreData1}
                                                            />

                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            );
                                        })}

                                        {this.state.loading && (
                                            this.state.loadingArray?.map(i => {
                                                return (
                                                    <TableRow hover>
                                                        <TableCell align="left" className={classes.TableCell}>
                                                            <Skeleton />
                                                        </TableCell>
                                                        <TableCell align="left" className={classes.TableCell}>
                                                            <Typography className={classes.categoryName}>
                                                                <Skeleton />
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left" className={classes.TableCell}>
                                                            <Skeleton />
                                                        </TableCell>
                                                        <TableCell align="left" className={classes.TableCell}>
                                                            <Skeleton />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        )}

                                        {!this.state.loading &&
                                            this.state.accumulatedData &&
                                            this.state.accumulatedData.length < 1 ? (
                                            <TableRecordNotFound
                                                colSpan={8}
                                                label="No Data Found.."
                                            />
                                        ) : (
                                            ""
                                        )}

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {/* <Stack spacing={2}>
                                <Pagination
                                color="primary"
                                count={this.props.totalPage}
                                variant="outlined"
                                page={parseInt(this.state.page)}
                                onChange={this.handleChangePage}
                                className={classes.pagination}
                                />
                            </Stack> */}
                        </Paper>


                        {this.state.page < this.state.totalPage && (
                            <LoadMore
                                product="hr"
                                handlePageChange={this.loadMoreData}
                            />
                        )}

                        <MyLeftModal
                            bgColor={ovHrV5Color.main}
                            title={this.state.isEdit ? "Edit Timecode" : "Add Timecode"}
                            open={this.state.addEditModal}
                            onClose={this.addEditModalClose}
                            handleSubmit={this.handleSubmit}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <MyTextfield
                                        product={'HR'}
                                        name="timecode_name"
                                        label="Name"
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        value={this.state.myItem.timecode_name}
                                        type="text"
                                        error={this.state.errors.name}
                                        helperText={this.state.errors.name ? this.state.errorsHelper.name : ""}
                                        placeholder="Enter name"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <MyTextfield
                                        product={'HR'}
                                        name="code"
                                        label="Code"
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        value={this.state.myItem.code}
                                        type="text"
                                        error={this.state.errors.code}
                                        helperText={this.state.errors.code ? this.state.errorsHelper.code : ""}
                                        placeholder="Enter code"
                                    />
                                </Grid>
                            </Grid>
                        </MyLeftModal>

                        <DeleteModal
                            bgColor={ovHrV5Color.main}
                            product={"HR"}
                            desc="Are you sure you want to delete?"
                            open={this.state.deleteModal}
                            onClose={this.deleteModalClose}
                            handleDelete={this.deleteData}
                        />
                    </Grid>
                </Grid> : ""
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.schedulerTimecode.isLoading,
    timecodeData: state.schedulerTimecode.timecodeData,
    totalPage: state.schedulerTimecode.totalPage
});

const ConnectWith = connect(mapStateToProps, { fetchData, addData, editData, deleteData, defaultTimecode })(Timecode);
export default withStyles(styles)(ConnectWith);