import {
  SET_GRADING_DATA,
  SET_GRADING_LOADING,
  SET_TEAM_GRADING_DATA,
  SET_TEAM_GRADING_LOADING,
  SET_GRADING_DATA_PAYLOAD,
  SET_GRADING_DATA_PAYLOAD1,
  SET_LESSON_GRADING_LOADING,
  SET_LESSON_DATA,
  SET_STATUS_DATA,
  SET_MANAGE_GRADING_LOADING,
  SET_VIDEO_DATA,
  SET_VIDEO_GRADING_LOADING,
  SET_VIDEO_URL_LOADING,
  GET_VIDEO_URL,
  SET_GRADING_LOADING_LESSON,
  SET_ROLE,
  GET_LOCATIONS,
  SET_LOCATION_LOADING,
  GET_ROLES,
  SET_ROLES_LOADING,
  GET_LESSON_NAMES,
  GET_LESSON_NAMES_LOADING,
  GET_USERS,
  GET_USERS_LOADING,
  GET_QUIZ_COUNT_LOADING,
  GET_QUIZ_COUNT_DATA,
  GET_COMPRESSED_VIDEO_URL,
  SET_COMPRESSED_VIDEO_URL_LOADING,
} from "./constants";

import {
  // alert,
  invalidToken,
} from "../../../../utilities";
import { gradingServices } from "./services";

export function getUsers() {
  return function (dispatch) {
    dispatch({
      type: GET_USERS_LOADING,
      payload: true,
    });

    gradingServices.getUsers().then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_USERS,
          payload: response.data,
        });
      }
    });
  };
}

export function fetchTeamGradingData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_TEAM_GRADING_LOADING,
      payload: true,
    });

    gradingServices.fetchTeamGradingData(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: SET_TEAM_GRADING_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function getQuizCount(data) {
  return function (dispatch) {
    dispatch({
      type: GET_QUIZ_COUNT_LOADING,
      payload: true,
    });

    gradingServices.getQuizCount(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_QUIZ_COUNT_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function fetchData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_GRADING_LOADING,
      payload: true,
    });

    gradingServices.fetchData(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: SET_GRADING_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function getLessonData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_GRADING_LOADING_LESSON,
      payload: true,
    });

    gradingServices.getLessonData(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: SET_LESSON_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function fetchAssignLesson(uid, data) {
  return function (dispatch) {
    dispatch({
      type: SET_LESSON_GRADING_LOADING,
      payload: true,
    });

    gradingServices.fetchAssignLesson(uid, data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: SET_GRADING_DATA_PAYLOAD1,
          payload: response.data,
        });
      }
    });
  };
}

export function setManagerGrading(data) {
  return function (dispatch) {
    dispatch({
      type: SET_MANAGE_GRADING_LOADING,
      payload: true,
    });

    gradingServices.setManagerGrading(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: SET_STATUS_DATA,
          payload: response.data,
        });
      }
    });
  };
}

export function setLessonVideo(data) {
  return function (dispatch) {
    dispatch({
      type: SET_VIDEO_GRADING_LOADING,
      payload: true,
    });

    gradingServices.setLessonVideo(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: SET_VIDEO_DATA,
          payload: response.data,
        });
        // console.log(response.data.message);
      }
    });
  };
}

export function getVideoURL(data) {
  return function (dispatch) {
    dispatch({
      type: SET_VIDEO_URL_LOADING,
      payload: true,
    });

    gradingServices.getVideoURL(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_VIDEO_URL,
          payload: response.data,
        });
      }
    });
  };
}

export function getCompressedVideoURL(data) {
  return function (dispatch) {
    dispatch({
      type: SET_COMPRESSED_VIDEO_URL_LOADING,
      payload: true,
    });

    gradingServices.getCompressedVideoURL(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_COMPRESSED_VIDEO_URL,
          payload: response.data,
        });
      }
    });
  };
}

export function getGradingPayload(data) {
  return function (dispatch) {
    dispatch({
      type: SET_GRADING_DATA_PAYLOAD,
      payload: data,
    });
  };
}

export function handleGradingRole(data) {
  return function (dispatch) {
    dispatch({
      type: SET_ROLE,
      payload: data,
    });
  };
}

export function fetchlocationData(isReload) {
  return function (dispatch) {
    if (isReload) {
      dispatch({
        type: SET_LOCATION_LOADING,
        payload: true,
      });
    }
    gradingServices.fetchlocationData().then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_LOCATIONS,
          payload: response.data.LocationData,
        });
      }
    });
  };
}

export function fetchRoleData() {
  return function (dispatch) {
    dispatch({
      type: SET_ROLES_LOADING,
      payload: true,
    });
    gradingServices.fetchRoleData().then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_ROLES,
          payload: response.data.RoleData,
        });
      }
    });
  };
}

export function getLessonNames() {
  return function (dispatch) {
    dispatch({
      type: GET_LESSON_NAMES_LOADING,
      payload: true,
    });
    gradingServices.getLessonNames().then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: GET_LESSON_NAMES,
          payload: response.data.lesson_list,
        });
      }
    });
  };
}
