import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import { mobileTheme } from "../../constant";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import MbAccordStyles from "./MbAccordStyles";
import PropTypes from "prop-types";
import { ExpandMoreOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  accordion: {
    border: "1px solid #E3E3E3 !important",
    boxShadow: "none !important",
    borderRadius: "8px !important",
  },
  expandIcon: {
    fontSize: "23px",
    color: (props) => (props.expanded ? "#ffffff" : mobileTheme),
  },
  accordionSummary: {
    // border: "1px solid #E3E3E3 !important",
    borderRadius: (props) =>
      props.expanded ? "6px 6px 0 0 !important" : "8px !important",
    height: "40px !important",
    background: (props) =>
      props.expanded ? `${props.themeColor} !important` : `#ffffff !important`,
  },
  expandedSummary: {
    // backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  headingText: {
    width: "70%",
    flexShrink: 0,
    fontSize: "14px",
  },
}));

export default function MobileAccordion({
  index,
  currentIndex,
  onChange,
  heading,
  details,
  themeColor,
}) {
  const expanded = index === currentIndex;
  const classes = useStyles({ expanded, themeColor });

  return (
    <Accordion
      expanded={expanded}
      onChange={() => onChange(index)}
      className={classes.accordion}
      sx={{ "& .MuiAccordionSummary-root": { minHeight: "40px" } }}
    >
      <AccordionSummary
        expandIcon={
          <div>
            <ExpandMoreIcon className={classes.expandIcon} />
          </div>
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className={`${classes.accordionSummary}`}
      >
        <Typography
          className={classes.headingText}
          style={{ color: expanded ? "#FFFFFF" : "#00000080" }}
        >
          {heading}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: "10px" }}>{details}</AccordionDetails>
    </Accordion>
  );
}

export const AccordionItem = ({ icon: Icon, title, children }) => {
  const classes = MbAccordStyles();

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
        aria-controls={`${title}-content`}
        id={`${title}-header`}
        className={classes.accordionSummary}
      >
        <div className={classes.accordianHeader}>
          <div className={classes.iconContainer}>
            <Icon className={classes.userIcon} />
          </div>
          <Typography className={classes.headingText}>{title}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

AccordionItem.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export const CustomAccordian = ({ icon: Icon, title, children, expanded, handleAccordClick, handleExpandIconClick }) => {
  const classes = MbAccordStyles();
  return (
  <>
    <div className={expanded ? classes.customAccordExp : classes.customAccord} onClick={handleAccordClick}>
        <div className={expanded ? classes.customAccordHeaderExp : classes.customAccordHeader}>
          <div className={expanded ? classes.accordianHeaderLeftExp : classes.accordianHeaderLeft}>
            <div className={expanded ? classes.accordHeaderIconContExp : classes.accordHeaderIconCont}>
              <Icon className={expanded ? classes.accordHeaderIconExp : classes.accordHeaderIcon} />
            </div>
            <Typography className={expanded ? classes.accordHeaderTextExp : classes.accordHeaderText}>
              {title}
            </Typography>
          </div>
          <ExpandMoreOutlined className={expanded ? classes.headerExpandIconExp : classes.headerExpandIcon} onClick={handleExpandIconClick} />
        </div>
        {expanded && (
            <div className={classes.expandedAccordContent}>
              {children}
            </div>
        )}
      </div>
  </>
  );
}

CustomAccordian.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  expanded: PropTypes.bool,
  handleAccordClick: PropTypes.func,
  handleExpandIconClick: PropTypes.func,
};