import { Dropdown } from "../../../../../utilities/v5/common";
import { ovHrV5Color } from "../../../../../config";

export let options = ["View Logs", "Edit User", "Delete User"];

export let formDataArray = [
  {
    id: 1,
    Name: "Evaluation form",
    Category: 2,
    QuestionCount: 12,
    ObservationCount: 2,
    AssignedTo: 2,
    Date: "23 mar 2023",
  },
  {
    id: 2,
    Name: "Evaluation form",
    Category: 2,
    QuestionCount: 12,
    ObservationCount: 2,
    AssignedTo: 2,
    Date: "23 mar 2023",
  },
  {
    id: 3,
    Name: "Evaluation form",
    Category: 2,
    QuestionCount: 12,
    ObservationCount: 2,
    AssignedTo: 2,
    Date: "23 mar 2023",
  },
  {
    id: 4,
    Name: "Evaluation form",
    Category: 2,
    QuestionCount: 12,
    ObservationCount: 2,
    AssignedTo: 2,
    Date: "23 mar 2023",
  },
  {
    id: 5,
    Name: "Evaluation form",
    Category: 2,
    QuestionCount: 12,
    ObservationCount: 2,
    AssignedTo: 2,
    Date: "23 mar 2023",
  },
];

// export const logs = [
//   { id: 1, heading: "User Name", content: "Mishal Irfan" },
//   { id: 2, heading: "Last Login", content: "23 March 2023, 1:45 PM" },
//   { id: 3, heading: "App Version", content: "2.3.8(ios)" },
// ];

export const themeColor = ovHrV5Color.main;

export let headRowCandidateItems = [
  {
    id: 1,
    heading: "Name",
    dropdown: "",
  },
  {
    id: 2,
    heading: "Email",
    dropdown: "",
  },
  {
    id: 3,
    heading: "Phone code",
    dropdown: "",
  },
  {
    id: 4,
    heading: "Phone Number",
    dropdown: "",
  },
]

export let headRowItems = [
  {
    id: 1,
    heading: "Name",
    dropdown: (
      <Dropdown
        size={{ width: "110px", height: "40px" }}
        bgColor="white"
        arrowColor={themeColor}
        textColor={"white"}
        borderColor={""}
        buttonText={""}
      />
    ),
  },
  // {
  //   id: 2,
  //   heading: "Category",
  //   dropdown: (
  //     <Dropdown
  //       size={{ width: "110px", height: "40px" }}
  //       bgColor="white"
  //       arrowColor={themeColor}
  //       textColor={"white"}
  //       borderColor={""}
  //       buttonText={""}
  //     />
  //   ),
  // },
  {
    id: 3,
    heading: "Question",
    dropdown: "",
  },
  {
    id: 4,
    heading: "Observation",
    dropdown: "",
  },
  {
    id: 5,
    heading: "Assigned to",
    dropdown: "",
  },
  {
    id: 6,
    heading: "Date",
    dropdown: (
      <Dropdown
        size={{ width: "110px", height: "40px" }}
        bgColor="white"
        arrowColor={themeColor}
        textColor={"white"}
        borderColor={""}
        buttonText={""}
      />
    ),
  },
  // { id: 7, heading: "Action", dropdown: "" },
];

// styles
export const tableHeadColumn = {
  color: "#FFFFFF",
  fontSize: "18px",
  fontWeight: "400",
};

export const tableBodyColumn = {
  ...tableHeadColumn,
  color: "rgba(0, 0, 0, 0.7)",
  fontWeight: "500",
  fontSize: "16px",
};
