// CustomTextField.js
import React from "react";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  textField: {
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      color: "black",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      lineHeight: "15.75px",

      "& fieldset": {
        borderColor: "#E3E3E3",
        borderRadius: "6px",
        borderWidth: "1px",
      },
      "&:hover fieldset": {
        borderColor: "#007E63",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007E63",
      },
    },

    "& .MuiInputLabel-root": {
      fontSize: "12px",
      color: "#00000050",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      lineHeight: "13.75px",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#007E63",
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: "14px",
      color: "#00000050",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      lineHeight: "15.75px",
    }
  },
});

const CustomTextField = ({ label, name, value, onChange, ...props }) => {
  const classes = useStyles();

  return (
    <TextField
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      variant="outlined"
      inputProps={{
        style: {
          color: "#00000050",
          fontSize: "14px",
          lineHeight: "16.41px",
          fontFamily: "Roboto",
          fontWeight: "400",
        },
      }}
      className={classes.textField}
      {...props}
    />
  );
};

export default CustomTextField;
