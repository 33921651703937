import {
  SET_DASHBOARD_LOADING,
  SET_DASHBOARD_LOCATION_BASED,
  SET_DASHBOARD_ROLE_BASED,
  SET_DASHBOARD_LESSON_BASED,
  SET_DASHBOARD_USER_BASED,
  GET_DASHBOARD_USERS_LOADING,
  GET_DASHBOARD_USERS,
  GET_DASHBOARD_LESSONS_LOADING,
  GET_DASHBOARD_LESSONS,
  GET_DASHBOARD_ROLES_LOADING,
  GET_DASHBOARD_ROLES,
  GET_DASHBOARD_LOCATIONS_LOADING,
  GET_DASHBOARD_LOCATIONS,
} from "./constants";

const initialState = {
  isLoadingDashboard: true,
  dashboardLocationData: [],
  dashboardLessonData: [],
  dashboardRoleData: [],
  dashboardUserData: [],
  dashboardLocationData1: [],
  dashboardLessonData1: [],
  dashboardRoleData1: [],
  dashboardUserData1: [],
  locationLength: "",
  dashboardUsersLoading: true,
  dashboardLessonsLoading: true,
  dashboardRolesLoading: true,
  dashboardLocationsLoading: true,

  dashboardUsersData: {},
  dashboardLessonsData: {},
  dashboardRolesData: {},
  dashboardLocationsData: {},
};

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DASHBOARD_USERS_LOADING:
      return {
        ...state,
        dashboardUsersLoading: payload,
      };

    case GET_DASHBOARD_USERS:
      return {
        ...state,
        dashboardUsersLoading: false,
        dashboardUsersData: payload,
      };
    case GET_DASHBOARD_LESSONS_LOADING:
      return {
        ...state,
        dashboardLessonsLoading: payload,
      };

    case GET_DASHBOARD_LESSONS:
      return {
        ...state,
        dashboardLessonsLoading: false,
        dashboardLessonsData: payload,
      };
    case GET_DASHBOARD_ROLES_LOADING:
      return {
        ...state,
        dashboardRolesLoading: payload,
      };

    case GET_DASHBOARD_ROLES:
      return {
        ...state,
        dashboardRolesLoading: false,
        dashboardRolesData: payload,
      };
    case GET_DASHBOARD_LOCATIONS_LOADING:
      return {
        ...state,
        dashboardLocationsLoading: payload,
      };

    case GET_DASHBOARD_LOCATIONS:
      return {
        ...state,
        dashboardLocationsLoading: false,
        dashboardLocationsData: payload,
      };

    case SET_DASHBOARD_LOADING:
      return {
        ...state,
        isLoadingDashboard: payload,
      };

    case SET_DASHBOARD_LOCATION_BASED:
      return {
        ...state,
        isLoadingDashboard: false,
        dashboardLocationData: payload.result,
        dashboardLocationData1: payload,
        locationLength: payload.totalPages,
      };

    case SET_DASHBOARD_ROLE_BASED:
      return {
        ...state,
        isLoadingDashboard: false,
        dashboardRoleData: payload.result,
        dashboardRoleData1: payload,
        roleLength: payload.totalPages,
      };

    case SET_DASHBOARD_LESSON_BASED:
      return {
        ...state,
        isLoadingDashboard: false,
        dashboardLessonData: payload.result,
        dashboardLessonData1: payload,
        lessonLength: payload.totalPages,
      };

    case SET_DASHBOARD_USER_BASED:
      return {
        ...state,
        isLoadingDashboard: false,
        dashboardUserData: payload.result,
        dashboardUserData1: payload,
        userLength: payload.totalPages,
      };

    default:
      return state;
  }
};

export default Reducer;
