import { makeStyles } from "@mui/styles";

export const docStyles = makeStyles({
  docContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    margin: "auto",
    paddingTop: "41px",
    padding: "1rem 1.5rem",
    position: "relative",
  },

  buttonContainer: {
    position: "absolute",
    bottom: "10rem", 
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    alignItems: "center",
    padding: "0 1.5rem",
    width: "100%",
  },

  saveButton: {
    width: "143px !important",
    height: "40px !important",
    backgroundColor: "#007E63 !important",
    borderRadius: "5px !important",
    color: "#ffffff !important",
    fontSize: "18px !important",
    fontWeight: "400 !important",
    fontFamily: "Roboto, sans-serif !important",
    lineHeight: "21.09px !important",
    textTransform: "none !important",
  },

  cancelButton: {
    width: "143px !important",
    height: "40px !important",
    backgroundColor: "#007E6340 !important",
    borderRadius: "5px !important",
    color: "#007E63 !important",
    fontSize: "18px !important",
    fontWeight: "400 !important",
    fontFamily: "Roboto, sans-serif !important",
    lineHeight: "21.09px !important",
    textTransform: "none !important",
  }
});
