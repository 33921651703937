export const SET_GRADING_LOADING = "SET_GRADING_LOADING";
export const SET_GRADING_LOADING_LESSON = "SET_GRADING_LOADING_LESSON";
export const SET_GRADING_DATA = "SET_GRADING_DATA";
export const SET_GRADING_DATA_PAYLOAD = "SET_GRADING_DATA_PAYLOAD";
export const SET_GRADING_DATA_PAYLOAD1 = "SET_GRADING_DATA_PAYLOAD1";
export const SET_LESSON_GRADING_LOADING = "SET_LESSON_GRADING_LOADING";
export const SET_LESSON_DATA = "SET_LESSON_DATA";
export const SET_STATUS_DATA = "SET_STATUS_DATA";
export const SET_MANAGE_GRADING_LOADING = "SET_MANAGE_GRADING_LOADING";
export const SET_ROLE = "SET_ROLE";
export const SET_VIDEO_DATA = "SET_VIDEO_DATA";
export const SET_VIDEO_GRADING_LOADING = "SET_VIDEO_GRADING_LOADING";
export const SET_VIDEO_URL_LOADING = "SET_VIDEO_URL_LOADING";
export const GET_VIDEO_URL = "GET_VIDEO_URL";
export const SET_COMPRESSED_VIDEO_URL_LOADING =
  "SET_COMPRESSED_VIDEO_URL_LOADING";
export const GET_COMPRESSED_VIDEO_URL = "GET_COMPRESSED_VIDEO_URL";
export const SET_LOCATION_LOADING = "SET_LOCATION_LOADING";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const SET_ROLES_LOADING = "SET_ROLES_LOADING";
export const GET_ROLES = "GET_ROLES";
export const SET_TEAM_GRADING_DATA = "SET_TEAM_GRADING_DATA";
export const SET_TEAM_GRADING_LOADING = "SET_TEAM_GRADING_LOADING";
export const GET_LESSON_NAMES = "GET_LESSON_NAMES";
export const GET_LESSON_NAMES_LOADING = "GET_LESSON_NAMES_LOADING";
// export const DELETE_ELE_CATEGORY = 'DELETE_ELE_CATEGORY'
// export const SET_ELE_CATEGORY1 = 'SET_ELE_CATEGORY1'
// export const SET_LESSON_CATEGORY = 'SET_LESSON_CATEGORY'
// export const SET_LESSON_CATEGORYDATA = 'SET_LESSON_CATEGORYDATA'
export const GET_USERS = "GET_USERS";
export const GET_USERS_LOADING = "GET_USERS_LOADING";
export const GET_QUIZ_COUNT_LOADING = "GET_QUIZ_COUNT_LOADING";
export const GET_QUIZ_COUNT_DATA = "GET_QUIZ_COUNT_DATA";
