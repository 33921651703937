import Cookies from "universal-cookie";
import { store } from "../store";
const cookies = new Cookies();
const myStore = store.getState();

export const COOKIE_MAX_AGE = 300 * 60; //minite * seconds
export const WEB_URL = "https://orderingbackend-huzaifa.vercel.app";
// export const WEB_URL = 'https://testing.onevision.io/api';
export const SLIDER_URL =
  process.env.REACT_APP_FRONTEND_BASE_URL + "/elearning/mycourses/slider";
// export const API_URL = `https://orderingbackend-huzaifa.vercel.app`;
export const API_URL = `http://localhost:8008`;
// export const API_URL = `https://testing.onevision.io/api`;
export const MoNGO_DB_API_URL = `https://testing.onevision.io/api/onevision`;
// export const MoNGO_DB_API_URL = `http://localhost:8808/onevision`;
export const MONGO_URL = `https://testing.onevision.io/api/onevision/routine`;
// export const MONGO_URL = `http://localhost:8808/onevision/routine`;
export const GOOGLE_API_KEY = "AIzaSyDjaOhHjUWJGXFbgFvIRkkairVvCFoYlIk";
export const FILE_URL = "https://onevision-space.nyc3.digitaloceanspaces.com";
export const PHP_FILE_URL = process.env.REACT_APP_FRONTEND_BASE_URL + "/php/uploadFile.php";
export const PERSIST_KEY = "onevision";
export const CRYPTOJS_SECRATE_KEY = "onevision";
export const MY_TOKEN = cookies.get("ovToken");

export const MoNGO_DB_API_URL_V5 = `https://testing.onevision.io/api/onevision/v5`;
//localhost:8808/onevision/v5
export const local_host_url = `http://localhost:8808/onevision/v5`;

// https://testing.onevision.io/api/onevision/web/elearning/grading

export const HEADER_TOKEN = {
  headers: {
    Authorization: MY_TOKEN,
  },
};
// productsColorsData
const data_local = localStorage.getItem("productsColorsData");

var productsColorsData = data_local
  ? data_local == "undefined" || data_local == undefined
    ? []
    : JSON.parse(data_local)
  : [];

const myColor = (myProduct) => {
  if (productsColorsData.length) {
    const data = productsColorsData?.find(
      (item) => item?.product_name?.toLowerCase() === myProduct?.toLowerCase()
    );
    return data?.color_code;
  }
  return null;
};

export const feedbackColor = {
  main: myColor("feedback") || "red",
};
export const eLearningColor = {
  main: "linear-gradient(45deg, #1B3F8F, #635BFF)",
};

export const todoColor = {
  main: myColor("todo") || "#735D78",
};
export const schedulerColor = {
  main: "linear-gradient(45deg, #AA076B, #9F0D9C)",

  // main: myColor("scheduler") || "#005F73"
};
export const analyticsColor = {
  main: "#0A0A0A",
  secondary: "#222222"
}

export const schedulerColorHrV5 = {
  main: "#007E63",

  // main: myColor("scheduler") || "#005F73"
};

export const settingColor = {
  main: "rgba(170, 7, 107, 0.125)",
};

export const adminColor = {
  main: "rgba(8, 117, 225, 1)",

  // main: myColor("scheduler") || "#005F73"
};

export const superadminColor = {
  main:"linear-gradient(45deg, #AA076B 5%, #9f0d9c 50% ,#0875e1 100%)"
}
export const newsandupdate = {
  main: myColor("newsandupdate") || "#8E0C0C",
};

export const newsandupdatemobile = { main: "#B80000" };

export const ratingColor = {
  main: myColor("rating") || "darkseagreen",
};
export const ovChatColor = {
  main: myColor("ovChat") || "#28a745",
};

export const ovFormsColor = {
  main: myColor("ovForms") || "#20c997",
};

export const RoutineColor = {
  main: myColor("Routine") || "#295CDC",
};

export const ovHrColor = {
  main: "rgb(0, 126, 99)",
};

export const ovHrV5Color = {
  main: myColor("ovHrV5") || "#007E63",
};

export const ovHrMobileColor = {
  main: "#007E63",
};

export const ovElearningMobileColor = {
  main: "linear-gradient(to bottom, #1B3F8F, #635BFF)",
};

export const ovSupportColor = {
  main: myColor("ovSupport") || "#635BFF",
};

export const getRandomColors = (length) => {
  let randomColors = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#ffebee",
    "#fce4ec",
    "#f3e5f5",
    "#ef5350",
    "#880e4f",
    "#4a148c",
    "#ff5252",
    "#ff4081",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
  ];
  const colorsData = [];
  const randomIndex = Math.floor(Math.random() * randomColors.length);
  for (let i = 0; i < length; i++) {
    colorsData.push(randomColors[randomIndex]);
  }
  return colorsData;
};
