import React, { useEffect } from "react";
import {
  FormControlLabel,
  Grid,
  Switch,
  Checkbox,
  Typography,
} from "@mui/material";
import locationStyling from "./LocationStyling";
import { styled } from "@mui/material/styles";
import CustomSelect from "../../../components/CustomSelectField/SelectField";
import Divider from "@mui/material/Divider";

// Define an array of location data objects
const locationDataArray = [
  {
    locationName: "Location 1",
    payrollOptions: [
      { value: "Manager", label: "Manager" },
      { value: "Employee", label: "Employee" },
      { value: "Admin", label: "Admin" },
    ],
    showLocationSchedule: true,
    eligibleForOpenShifts: true,
  },
  {
    locationName: "Location 2",
    payrollOptions: [
      { value: "Intern", label: "Intern" },
      { value: "Contractor", label: "Contractor" },
      { value: "Full-time", label: "Full-time" },
    ],
    showLocationSchedule: false,
    eligibleForOpenShifts: true,
  },
  {
    locationName: "Location 3",
    payrollOptions: [
      { value: "Temporary", label: "Temporary" },
      { value: "Part-time", label: "Part-time" },
      { value: "Consultant", label: "Consultant" },
    ],
    showLocationSchedule: true,
    eligibleForOpenShifts: false,
  },
  {
    locationName: "Location 3",
    payrollOptions: [
      { value: "Temporary", label: "Temporary" },
      { value: "Part-time", label: "Part-time" },
      { value: "Consultant", label: "Consultant" },
    ],
    showLocationSchedule: true,
    eligibleForOpenShifts: false,
  },
  {
    locationName: "Location 3",
    payrollOptions: [
      { value: "Temporary", label: "Temporary" },
      { value: "Part-time", label: "Part-time" },
      { value: "Consultant", label: "Consultant" },
    ],
    showLocationSchedule: true,
    eligibleForOpenShifts: false,
  },
];

const unCheckedLocationArray = [
  {
    locationName: "Location 1",
  },
  {
    locationName: "Location 2",
  },
  {
    locationName: "Location 3",
  },
  {
    locationName: "Location 4",
  },
];

// Custom Switch Component
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#007E63",
      "& + .MuiSwitch-track": {
        backgroundColor: "#3d5d5a",
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    backgroundColor: "#E9E9EA",
    opacity: 1,
  },
}));

const UserLocation = () => {
  const classes = locationStyling();

  useEffect(() => {
    console.log("UserLocation data loaded");
  }, []);

  return (
    <div className={classes.locationOuterContainer}>
      <Grid container className={classes.locContainer}>
        {locationDataArray.map((location, index) => (
          <>
            <Grid item key={index} className={classes.locCardContainer}>
              <Grid container className={classes.locCardFirstRow}>
                <Typography className={classes.locCardLocationText}>
                  {location.locationName}
                </Typography>
                <IOSSwitch sx={{ m: 1 }} defaultChecked />
              </Grid>

              <Grid container className={classes.locCardSecRow}>
                <Typography className={classes.locCardPayrollLabel}>
                  Payroll:
                </Typography>
                <CustomSelect
                  name="payroll"
                  placeholder="Select user"
                  className={classes.selectField}
                  options={location.payrollOptions}
                />
              </Grid>

              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={location.showLocationSchedule}
                    className={classes.checkbox}
                  />
                }
                label={
                  <Typography className={classes.locCardLocationText}>
                    Show location schedule
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={location.eligibleForOpenShifts}
                    className={classes.checkbox}
                  />
                }
                label={
                  <Typography className={classes.locCardLocationText}>
                    Eligible for open shifts
                  </Typography>
                }
              />
            </Grid>
            {index >= 0 && <Divider className={classes.divider} />}
          </>
        ))}

        {unCheckedLocationArray.map((location, index) => (
          <>
            <Grid
              item
              className={classes.locCardUnCheckedContainer}
              key={index}
            >
              <Typography className={classes.locCardLocationText}>
                {location.locationName}
              </Typography>
              <IOSSwitch sx={{ m: 1 }} />
            </Grid>
            {index >= 0 && <Divider className={classes.divider} />}
          </>
        ))}
      </Grid>
    </div>
  );
};

export default UserLocation;
