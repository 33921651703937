import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { newsandupdate } from "../../../../config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MenuItem, Grid, TextField } from "@material-ui/core";
import Select from 'react-select';

import TimePicker from "rc-time-picker";
import moment from "moment";
import "rc-time-picker/assets/index.css";
import { mobileThemeNews } from "../../constant";
// import "./news.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-elevation24": {
    height: "400px",
    overflow: "auto",
    width: "-webkit-fill-available",
  },
  "& .react-datepicker__input-container": {
    width: "100%",
    paddingLeft: "5px",
  },
  "& .rc-time-picker-panel": {
    zIndex: "9999999",
  },
  "& .rc-time-picker-input": {
    border: "none",
    borderRadius: "4px",
    height: "35px",
    boxShadow: "0px 0px 4px 0px #0000001F",
    outline: "none",
  },
  "& .rc-time-picker-clear": {
    top: 7,
  },
  "& .rc-time-picker-panel-inner": {
    width: 190,
    minWidth: "190px",
  },
  "& .react-datepicker-wrapper": { boxShadow: "0px 0px 4px 0px #0000001F" },
  "& .react-datepicker-wrapper, .react-datepicker__input-container, .react-datepicker__input-container input":
  {
    width: "100%",
    border: "none",
    borderRadius: "4px",
    height: "35px",
    outline: "none",
    color: "black",
    fontSize: "12px",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            // position: "absolute",
            // right: 8,
            // top: 4,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const ScheduleNewsDateMobileModal = (props) => {
  const date = moment(props.startDate).format("YYYY-MM-DD");
  const time = moment(props.startTime).format("THH:mm:ssZ");
  const total = date + time;

  const format = "h:mm a";

  const objMain = {
    schedule_Time: total,
    isscheduled: 1,
    timezone: props.timezone,
  };

  const getTimezoneNames = () => {
    let names = moment.tz.names();
    let final_array = [];

    names.forEach((name) => {
      final_array = final_array.concat({
        label: name,
        value: name,
      });
    });

    return final_array;
  };

  const [timeZonedropDown, setTimeZonedropDown] = useState(getTimezoneNames);

  return (
    <div>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        style={{
          borderRadius: "5px",
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handleClose}
          style={{
            backgroundColor: mobileThemeNews,
            color: "white",
            fontSize: "14px",
            height: "35px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Schedule Later
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "100%" }}>
          <p style={{ marginBottom: 8, fontSize: "12px", color: "#848484" }}>
            Select Date:
          </p>
          <div style={{ marginBottom: "6px" }}>
            <DatePicker
              style={{ width: "100%" }}
              selected={props.startDate}
              onChange={(date) => props.onChangeStartDate(date)}
              minDate={moment().toDate()}
              placeholderText="Select Date"
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              width: "100%",
            }}
          >
            <div>
              <p
                style={{ marginBottom: 11, fontSize: "12px", color: "#848484" }}
              >
                {" "}
                Select Time:
              </p>
              <div>
                <TimePicker
                  showSecond={false}
                  value={props.startTime}
                  className="xxx"
                  style={{
                    width: "100%",
                  }}
                  onChange={props.onChangeStartTime}
                  format={format}
                  use12Hours
                  allowEmpty
                  placeholder="Select Time (hh:mm)"
                />
              </div>
            </div>

            <Grid
              item
              md={5}
              style={{ margin: "auto", marginTop: 4, width: "100%" }}
            >
              <p
                style={{ marginBottom: 8, fontSize: "12px", color: "#848484" }}
              >
                Select TimeZone:
              </p>
              <Select
                name="location_timezone"
                options={timeZonedropDown}
                isSearchable={true}
                onChange={(e) => props.handleChangeTimeZone(e)}
                value={
                  {
                    label: props.timezone,
                    value: props.timezone
                  }
                }
              />
              {/* <Typography>
                <TextField
                  variant="outlined"
                  select
                  size="small"
                  label="Select"
                  value={props.timezone}
                  name="location_timezone"
                  onChange={(e) => props.handleChangeTimeZone(e)}
                  style={{
                    width: "100%",
                    border: "none !important",
                    borderRadius: "4px",
                    boxShadow: "0px 0px 4px 0px #0000001F",
                  }}
                >
                  {timeZonedropDown &&
                    timeZonedropDown.map((option, index) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Typography> */}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={() => props.handleSubmit(objMain, 0)}
            style={{
              backgroundColor: mobileThemeNews,
              color: "white",
              textTransform: "capitalize",
              borderRadius: "5px",
              border: `1px solid  ${mobileThemeNews}`,
              width: "140px",
            }}
          >
            Schedule
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
