import React, { useState } from "react";
import {
  TextField,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { MdFileUpload } from "react-icons/md"; // Default upload icon if no custom icon is provided
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  fileUploaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: "8px",
    marginBottom: "1rem",
  },
  fileLabel: {
    color: "#007E63",
    fontWeight: "500 !important",
    fontSize: "14px",
    fontFamily: "Roboto, sans-serif",
  },

  labelIcon: {
    color: "#007E63",
    width: "16px",
    height: "14px",
  },

  inputField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "6px",
        borderColor: "#E3E3E3",
        borderWidth: "1px",
      },
      "&:hover fieldset": {
        borderColor: "#007E63",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007E63",
      },
    },
  },
  uploadIcon: {
    color: "#007E63",
    fontSize: "20px",
  },
}));

const CustomFileUploader = ({
  label,                
  icon: Icon,
  placeholder,         
  fileType,             
  onFileChange,         
  uploadIcon: UploadIcon = MdFileUpload,
}) => {
  const classes = useStyles();
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      if (onFileChange) {
        onFileChange(file);
      }
    }
  };

  return (
    <div className={classes.fileUploaderContainer}>
      <Typography className={classes.fileLabel}>
        {label} {Icon && <Icon className={classes.labelIcon} style={{ marginLeft: "2px" }} />}
      </Typography>
      <TextField
        variant="outlined"
        className={classes.inputField}
        size="small"
        value={fileName || placeholder}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton component="label">
                <UploadIcon className={classes.uploadIcon} />
                <input
                  type="file"
                  hidden
                  onChange={handleFileChange}
                  accept={fileType || "*"}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default CustomFileUploader;
