import * as React from "react";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { themeColor } from "../../../../pages/v5/HR/Evaluation/Form/constant";
import { hexToRgb } from "../hexToRgb";

export function DaySelector({handleChange,value}) {

  const children = [
    {id:"monday",name:"Mon"},
    {id:"tuesday",name:"Tue"},
    {id:"wednesday",name:"Wed"},
    {id:"thursday",name:"Thur"},
    {id:"friday",name:"Fri"},
    {id:"saturday",name:"Sat"},
    {id:"sunday",name:"Sun"}
  ];

  const control = {
    value: value,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <Stack spacing={2} alignItems="center">
      <ToggleButtonGroup size="large" {...control} aria-label="Large sizes">
        {children.map((day, index) => (
          <ToggleButton
            key={day?.id}
            value={day?.id}
            style={{
              backgroundColor:
                value === day?.id
                  ? `rgba(${hexToRgb(themeColor)}, 0.3)`
                  : "transparent",
              borderRight:
                index < children.length - 1 ? "none" : "1px solid #ccc",
              color: value === day?.id ? themeColor : "rgba(0, 0, 0, 0.7)",
            }}
          >
            {day.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
}
