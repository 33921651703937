import { makeStyles } from "@mui/styles";

export const createGroupStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    marginTop: "41px",
    padding: "0 1.5rem",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    position: "relative",
  },

  fileUploaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: "8px",
    marginBottom: "1rem",
  },
  fileLabel: {
    color: "#007E63",
    fontWeight: "500 !important",
    fontSize: "14px",
    fontFamily: "Roboto, sans-serif",
  },

  labelIcon: {
    color: "#007E63",
    width: "16px",
    height: "14px",
  },

  inputField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "6px",
        borderColor: "#E3E3E3",
        borderWidth: "1px",
      },
      "&:hover fieldset": {
        borderColor: "#007E63",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007E63",
      },
    },
  },
  uploadIcon: {
    color: "#007E63",
    fontSize: "20px",
  },

  checkbox: {
    color: "#007E63 !important",
    "&.Mui-checked": {
      color: "#007E63 !important",
    },
  },

  checkboxLabel: {
    fontSize: "12px !important",
    fontWeight: "400 !important",
    fontFamily: "Roboto, sans-serif !important",
    color: "#00000080 !important",
    lineHeight: "14.06px !important",
  },

  menuItem: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
  },

  menuItemRole: {
    fontFamily: "Roboto, sans-serif !important",
    fontSize: "8px !important",
    fontWeight: "400 !important",
    lineHeight: "9.38px",
    textAlign: "left",
    color: "#00000066"
  },

  menuItemIcon: {
    color: "#007E63 !important",
    fontSize: "12px !important",
  },

  menuItemAvatar: {
    width: "19px !important",
    height: "19px !important",
    marginLeft: "3rem",
    marginRight: "5px",
    backgroundColor: "#007E6333 !important",
  },

  selectFieldLabel:{
    color: "#007E63 !important",
  },

  selectField: {
    "& .MuiSelect-select": {
      fontSize: "12px !important",
      fontWeight: 400,
      lineHeight: "15.75px",
      fontFamily: "Roboto, sans-serif",
      color: "black",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E3E3E3 !important", // Default border color
      borderRadius: "8px !important", // Border radius
      borderWidth: "1px", // Border width
    },
    // Hover effect - change border color to #007E63
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#007E63 !important",
    },
    // Focus effect - change border color to #007E63
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#007E63 !important",
    },
    "& .MuiInputLabel-root": {
      fontSize: "12px",
      color: "#E3E3E3",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      lineHeight: "13.75px",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#007E63",
    },
  },

  searchFieldContainer: {
    width: "100%",
    padding: "10px !important",
  },

  searchIcon: {
    color: "#007E63",
    fontSize: "20px",
  },

  chip: {
    padding: "5px 10px !important",
    backgroundColor: "#007E6333 !important",
    borderRadius: "5px !important",
  },

  chipText: {
    fontSize: "10px !important",
    fontWeight: "400 !important",
    fontFamily: "Roboto, sans-serif !important",
    color: "#007E63 !important",
    lineHeight: "11.72px !important",
  },

  createGroupBottomBtns: {
    position: "absolute",
    bottom: "12rem",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: "1rem",
    padding: "0 1.5rem",
    left: "50%",
    transform: "translateX(-50%)",
  },

  createBtn: {
    width: "100% !important",
    height: "40px !important",
    borderRadius: "7px !important",
    backgroundColor: "#007E63 !important",
    color: "white !important",
    "&:hover": {
      backgroundColor: "#007E63 !important",
    },
    fontSize: "16px !important",
    fontWeight: "500 !important",
    fontFamily: "Roboto, sans-serif !important",
    lineHeight: "18.75px !important",
  },

  cancelBtn: {
    width: "100% !important",
    height: "40px !important",
    borderRadius: "7px !important",
    backgroundColor: "#007E6340 !important",
    color: "#007E63 !important",
    "&:hover": {
      backgroundColor: "#007E63 !important",
    },
    fontSize: "16px !important",
    fontWeight: "500 !important",
    fontFamily: "Roboto, sans-serif !important",
    lineHeight: "18.75px !important",
  }
});
