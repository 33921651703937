import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    background: (props) =>
      props.bgColor || "rgba(116, 85, 246, 0.15) !important",
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "space-between !important",
    width: (props) => props.size.width || "178px !important",
    height: (props) => props.size.height || "35px !important",
    borderRadius: "30px !important",
    boxShadow: "0px -5px 15px 5px rgba(0,0,0,0.08) !important",
    border: (props) => props.border || "",
    padding: "0 2px 0 2px !important",
  },
  tab: {
    width: (props) => props.tabSize.width || "93px",
    height: (props) => props.tabSize.height || "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "30px !important",
    fontSize: "12px",
    fontWeight: "400",
    textTransform: "capitalize",
    cursor: "pointer",
  },
}));

const Toggel = ({
  selectedBgColor,
  bgColor,
  options,
  selected,
  selectedTextColor,
  textColor,
  size,
  tabSize,
  handleTabChange,
  border,
}) => {
  const classes = useStyles({
    selectedBgColor,
    bgColor,
    size,
    tabSize,
    border,
  });

  return (
    <div className={classes.container}>
      {options.map((option, index) => (
        <div
          key={option.id}
          className={classes.tab}
          onClick={() => handleTabChange(option.value, option.path)}
          style={{
            background:
              selected === option.value ? selectedBgColor : "transparent",
            color:
              selected === option.value
                ? selectedTextColor || "#ffffff"
                : textColor || "#212121",
          }}
        >
          {option.heading}
        </div>
      ))}
    </div>
  );
};

export default Toggel;
