import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export function MobileDatePicker({ placeholder, onChange, onFocus, value }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <DemoContainer components={["DatePicker"]}> */}
      <DatePicker
        label={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        slotProps={{
          textField: { size: 'small' },
          inputProps: {
            style: {
              color: "#00000080",
              fontSize: "14px !important",
              fontWeight: "400 !important",
              lineHeight: "16.94px !important",
              fontFamily: "Roboto, sans-serif !important",
            },
          },
        }}
        sx={{
          width: "100%",
          //   borderRadius: "8px",
          color: "#00000080",

          background: "transparent",
          // "& .MuiTextField-root": {
          //   maxWidth: "320px",
          // },
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px !important",
            height: "40px !important",
            // border: "0.5px solid #E3E3E3",
          },
          label: {
            color: "#00000080",
            fontSize: "14px !important",
            fontWeight: "400 !important",
            lineHeight: "16.94px !important",
            fontFamily: "Roboto, sans-serif !important",
          },
        }}
      />
      {/* </DemoContainer> */}
    </LocalizationProvider>
  );
}
