import { MoNGO_DB_API_URL, HEADER_TOKEN, MY_TOKEN } from "../../../../config";
import axios from "axios";

export const helpdeskServices = {
  fetchData,
  fetchViewLessons,
  addData,
  // checkDuplicateCode,
  editData,
  deleteData,
  addDevice,
  deleteDevice,
  toggleHelpDeskAssignUser,
  binData,
  deleteHelpdesk,
  toggleHelpDeskAssignDevice,
  // fetchDataCategory,
  editDataCategory,
  addDataCategory,
  addSlide,
  deleteSlide,
  fetchAllDevices,
  fetchLocationCountClickData,
  fetchRoleCountClickData,
  fetchDeviceCountClickData,
  fetchUsersCountClickData,
  toggleHelpDeskAssignRole,
  fetchAssignRolesUsers,
  fetchthePdfUrl,
  // deleteDataCategory,
  fetchAssignLocationUsers,
};

function fetchData() {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/elearning/helpdesk/course/v2/getDesks`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchViewLessons(id) {
  return axios
    .get(`${MoNGO_DB_API_URL}/elearning/lesson/${id}`, HEADER_TOKEN)
    .then((response) => response);
}

function fetchAssignRolesUsers() {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/roles`;
  return axios.get(url, HEADER_TOKEN).then((response) => response);
}

function fetchAssignLocationUsers() {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/locations`;
  return axios.get(url, HEADER_TOKEN).then((response) => response);
}

function fetchAllDevices(data) {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/elearning/helpdesk/course/v3/assigndevice/${data}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function addData(myData) {
  const data = new FormData();
  var totalLessons = myData.totalLessons;
  data.append("desk_name", myData.desk_name);
  data.append("desk_code", myData.desk_code);
  data.append("desk_description", myData.desk_description);
  data.append("myCourse_settings", myData.myCourse_settings);
  data.append("LockedLesson_setting", myData.LockedLesson_setting);
  data.append("myHelpdesk_setting", myData.myHelpdesk_setting);
  data.append("priority", myData.priority);
  data.append("totalLessons", JSON.stringify(totalLessons));
  data.append("file", myData.desk_image, myData.desk_image.name);
  return axios
    .post(`${MoNGO_DB_API_URL}/elearning/helpdesk/course`, data, HEADER_TOKEN)
    .then((response) => response);
}

// function checkDuplicateCode(data) {
//     return axios.post(`${MoNGO_DB_API_URL}/elearning/helpdesk/course/duplicate`,  {data} , HEADER_TOKEN).then(response => response)
// };

function addDevice(data) {
  return axios
    .post(`${MoNGO_DB_API_URL}/elearning/helpdesk/devices`, data, HEADER_TOKEN)
    .then((response) => response);
}

function editData(myData, id) {
  const data = new FormData();
  var totalLessons = myData.totalLessons;
  data.append("_id", myData.desk_id);
  data.append("desk_name", myData.desk_name);
  data.append("desk_code", myData.desk_code);
  data.append("desk_description", myData.desk_description);
  data.append("myCourse_settings", myData.myCourse_settings);
  data.append("LockedLesson_setting", myData.LockedLesson_setting);
  data.append("myHelpdesk_setting", myData.myHelpdesk_setting);
  data.append("priority", myData.priority);
  data.append("totalLessons", JSON.stringify(totalLessons));
  if (myData.changeFile) {
    data.append("file", myData.desk_image, myData.desk_image.name);
  }
  return axios
    .put(
      `${MoNGO_DB_API_URL}/elearning/helpdesk/course/${id}`,
      data,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function toggleHelpDeskAssignUser(data) {
  return axios
    .post(
      `${MoNGO_DB_API_URL}/elearning/helpdesk/course/v2/assign/toggle`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function toggleHelpDeskAssignRole(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/roles/assign`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function toggleHelpDeskAssignDevice(data) {
  return axios
    .post(
      `${MoNGO_DB_API_URL}/elearning/helpdesk/course/assigndevice`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function deleteData(_id) {
  return axios
    .delete(
      `${MoNGO_DB_API_URL}/elearning/helpdesk/course/v2/helpdesk/${_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function addSlide(data) {
//   return axios
//     .post(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/course/lessons/slide`,
//       data,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function addSlide(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson/slide`,
      data,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function deleteSlide(slide_id) {
//   return axios
//     .delete(
//       `${MoNGO_DB_API_URL}/elearning/helpdesk/course/lessons/slide/${slide_id}`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function deleteSlide(slide_id, lesson_id = null, slide_type = null) {
  let queryParam = "";

  if (lesson_id && slide_type == 5) {
    queryParam = `?lesson_id=${lesson_id}&lesson_type=${slide_type}`;
  }

  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/lesson/slide/${slide_id}${queryParam && (queryParam)}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function deleteDevice(id) {
  return axios
    .delete(
      `${MoNGO_DB_API_URL}/elearning/helpdesk/devices/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function binData() {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/elearning/helpdesk/course/deletedDesk`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function deleteHelpdesk(_id) {
  return axios
    .delete(
      `${MoNGO_DB_API_URL}/elearning/helpdesk/course/deleteHelpdesk/${_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function fetchDataCategory() {
//     return axios.get(`${MoNGO_DB_API_URL}/elearning/category`, HEADER_TOKEN).then(response => response)
// };

// function addDataCategory(myData) {
//   const data = new FormData();
//   data.append("category_name", myData.category_name);
//   data.append("file", myData.image, myData.image.name);
//   var config = {
//     method: "post",
//     url: `${MoNGO_DB_API_URL}/elearning/category`,
//     headers: {
//       Authorization: MY_TOKEN,
//     },
//     data: data,
//   };
//   return axios(config).then((response) => response);
// }

function addDataCategory(myData) {
  const data = new FormData();
  data.append("category_name", myData.category_name);
  data.append("file", myData.image, myData.image.name);
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/category`,
    headers: {
      Authorization: MY_TOKEN,
    },
    data: data,
  };
  return axios(config).then((response) => response);
}

// function editDataCategory(myData) {
//   const data = new FormData();
//   data.append("_id", myData._id);
//   data.append("category_name", myData.category_name);
//   data.append("category_lesson", myData.category_lesson);
//   if (myData.changeFile) {
//     data.append("file", myData.image, myData.image.name);
//   }
//   var config = {
//     method: "post",
//     url: `${MoNGO_DB_API_URL}/elearning/category/edit`,
//     headers: {
//       Authorization: MY_TOKEN,
//     },
//     data: data,
//   };
//   return axios(config).then((response) => response);
// }

function editDataCategory(myData) {
  const data = new FormData();
  // data.append("_id", myData._id);
  data.append("category_name", myData.category_name);
  data.append("category_lesson", myData.category_lesson);
  if (myData.changeFile) {
    data.append("file", myData.image, myData.image.name);
  }
  var config = {
    method: "put",
    url: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/${process.env.REACT_APP_PLATFORM}/elearning/category/${myData._id}`,
    headers: {
      Authorization: MY_TOKEN,
    },
    data: data,
  };
  return axios(config).then((response) => response);
}

// function deleteDataCategory(_id) {
//     return axios.delete(`${MoNGO_DB_API_URL}/elearning/category/${_id}`, HEADER_TOKEN).then(response => response)
// };

function fetchLocationCountClickData(id) {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/elearning/helpdesk/course/v2/helpdesk/locations/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchRoleCountClickData(id) {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/elearning/helpdesk/course/v2/helpdesk/role/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchDeviceCountClickData(id) {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/elearning/helpdesk/course/v2/helpdesk/device/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchUsersCountClickData(id) {
  return axios
    .get(
      `${MoNGO_DB_API_URL}/elearning/helpdesk/course/v2/helpdesk/user/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchthePdfUrl(data) {
  return axios
    .post(
      `${MoNGO_DB_API_URL}/v5/upload/ppt`,data,
      HEADER_TOKEN
    )
    .then((response) => response);
}

