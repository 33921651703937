import React, { useState } from "react";
import {
  ContactIcon,
  UserIcon,
} from "../../../../assets/images/mobileView/icons/svgs";
import { CustomAccordian } from "../../components/Accordian/MobileAccordian";
import { userDetailExpandStyles } from "./UserDetailStyles";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
} from "@mui/material";
import CustomTextField from "../../components/CustomTextField/TextField";
import CustomSelect from "../../components/CustomSelectField/SelectField";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { Typography } from "@material-ui/core";

const UserDetailExpand = () => {
  const classes = userDetailExpandStyles();
  const [userDetailExpand, setUserDetailExpand] = useState(false);
  const [userContactExpand, setContactExpand] = useState(false);

  const accordianData = [
    {
      icon: UserIcon,
      title: "User Detail",
      children: <UserDetails />,
      expanded: userDetailExpand,
      handleAccordClick: () => {},
      handleExpandIconClick: () => setUserDetailExpand(!userDetailExpand),
    },
    {
      icon: ContactIcon,
      title: "Contact",
      children: <Contact />,
      expanded: userContactExpand,
      handleAccordClick: () => {},
      handleExpandIconClick: () => setContactExpand(!userContactExpand),
    },
  ];

  return (
    <div className={classes.mobOuterContainer}>
      <div className={classes.container}>
        {accordianData.map((item, index) => (
          <div className={classes.accordianContainer}>
            <CustomAccordian
              key={index}
              icon={item.icon}
              title={item.title}
              children={item.children}
              expanded={item.expanded}
              handleExpandIconClick={item.handleExpandIconClick}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserDetailExpand;

const UserDetails = () => {
  const classes = userDetailExpandStyles();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    status: "",
    role: "",
    dateOfBirth: "",
    hireDate: "",
  });

  // Handle change in form fields
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className={classes.formColumn}>
      <div className={classes.formRow}>
        {/* First Name */}
        <CustomTextField
          label="First Name"
          name="firstName"
          placeholder="Enter First Name"
          value={formData.firstName}
          onChange={handleInputChange}
          size="small"
        />

        {/* Last Name */}
        <CustomTextField
          label="Last Name"
          name="lastName"
          placeholder="Enter Last Name"
          value={formData.lastName}
          onChange={handleInputChange}
          size="small"
        />
      </div>
      <div className={classes.formRow}>
        {/* Email */}
        <CustomTextField
          fullWidth
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          size="small"
          placeholder="Enter email"
        />
      </div>

      <div className={classes.formRow}>
        {/* Status */}
        <CustomSelect
          label="Status"
          name="status"
          placeholder="Select Status"
          value={formData.status}
          onChange={handleInputChange}
          options={[
            { value: "Active", label: "Active" },
            { value: "Inactive", label: "Inactive" },
          ]}
        />

        {/* Role */}
        <CustomSelect
          label="Role"
          name="role"
          placeholder="Select Role"
          value={formData.role}
          onChange={handleInputChange}
          options={[
            { value: "Manager", label: "Manager" },
            { value: "Employee", label: "Employee" },
            { value: "Admin", label: "Admin" },
          ]}
        />
      </div>

      <div className={classes.formRow}>
        {/* Date of Birth */}
        <CustomTextField
          fullWidth
          label="Date of Birth"
          name="dateOfBirth"
          type="date"
          value={formData.dateOfBirth}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: true }}
          size="small"
        />
      </div>

      <div className={classes.formRow}>
        {/* Hire Date */}
        <CustomTextField
          fullWidth
          label="Hire Date"
          name="hireDate"
          type="date"
          value={formData.hireDate}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: true }}
          size="small"
        />
      </div>
    </div>
  );
};

const Contact = () => {
  const classes = userDetailExpandStyles();
  const [showClearIcon, setShowClearIcon] = useState("none");
  const [formData, setFormData] = useState({
    address: "",
    city: "",
    province: "",
    country: "",
    mobile: "",
    dateOfBirth: "",
    name: "",
    number: "",
  });

  const handleChange = (event) => {
    setShowClearIcon(event.target.value === "" ? "none" : "flex");
  };

  const handleClick = () => {
    console.log("clicked the clear icon...");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className={classes.formColumn}>
      <FormControl>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          placeholder="Enter Address"
          label="Address"
          onChange={handleChange}
          className={classes.textField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ display: showClearIcon }}
                onClick={handleClick}
              >
                <ClearIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>

      <div className={classes.formRow}>
        <CustomSelect
          label="City"
          name="city"
          placeholder="Select City"
          value={formData.city}
          onChange={handleInputChange}
          options={[
            { value: "Active", label: "Active" },
            { value: "Inactive", label: "Inactive" },
          ]}
        />

        <CustomSelect
          label="Province"
          name="province"
          placeholder="Select province"
          value={formData.province}
          onChange={handleInputChange}
          options={[
            { value: "Manager", label: "Manager" },
            { value: "Employee", label: "Employee" },
            { value: "Admin", label: "Admin" },
          ]}
        />
      </div>
      <div className={classes.formRow}>
        <CustomSelect
          label="Country"
          name="country"
          placeholder="Select country"
          value={formData.country}
          onChange={handleInputChange}
          options={[
            { value: "Manager", label: "Manager" },
            { value: "Employee", label: "Employee" },
            { value: "Admin", label: "Admin" },
          ]}
        />
      </div>
      <div className={classes.formRow}>
        <CustomTextField
          label="Mobile"
          name="mobile"
          type="number"
          placeholder="Enter mobile number"
          value={formData.mobile}
          onChange={handleInputChange}
          size="small"
        />
      </div>
      <Typography className={classes.contactHeading}>
        Emergency Contact
      </Typography>
      <div className={classes.formRow}>
        <CustomTextField
          label="Name"
          name="name"
          type="text"
          placeholder="Enter name"
          value={formData.mobile}
          onChange={handleInputChange}
          size="small"
        />

        <CustomTextField
          label="Number"
          name="number"
          type="number"
          placeholder="Enter mobile number"
          value={formData.mobile}
          onChange={handleInputChange}
          size="small"
        />
      </div>
    </div>
  );
};
