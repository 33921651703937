import React, { Component } from "react";
import { BsChevronDown } from 'react-icons/bs';
import { withStyles } from "@material-ui/core/styles";
import { schedulerColor } from '../../../config';
import {
  Grid,
  Button,
  ButtonGroup,
  IconButton,
}
  from '@material-ui/core';
import moment from "moment";

import {
  Settings as SettingsIcon,
  Print as PrintIcon,
} from '@material-ui/icons';
import { FiFilter, FiEye, FiPrinter, FiDownload } from 'react-icons/fi';

const styles = (theme) => ({
  root: {
    padding: '10px 10px',
    background: '#fff',
  },
  btnChild: {
    fontWeight: 500,
    padding: '8px 25px',

    backgroundColor: schedulerColor.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: schedulerColor.main,
    }
  },
  btnIcon: {
    padding: '0',
    color: schedulerColor.main
  },
  dflex: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '4px 10px', border: '1px solid #f1f1f1' },
  topBar: {
    display: 'flex', alignItems: 'center',
    justifyContent: 'space-between', padding: '14px 10px',
    border: '1px solid #f1f1f1', background: '#fff',
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  btnIconButton: {
    marginLeft: '5px',
    borderRadius: '4px',
    padding: '3px 5px',
    boxShadow: "0px 2px 2px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    backgroundColor: "rgba(30, 33, 52, 0.08)",
    border: '2px solid #ccc'
  },
  btnPrimary: {
    // backgroundColor: "white",
    backgroundImage: "linear-gradient(45deg, #AA076B, #9F0D9C)",
    marginLeft: '5px',
    padding: '4px 20px',
    color: '#fff',
    '&:hover': {
      border: '1px solid #AA076B',
      color: "black"
    }
  },
  btnSecondary: {
    marginRight: '5px',
    border: '1px solid #ccc',
    padding: '4px 10px',
    color: "#AA076B",
    '&:hover': {
      border: '1px solid #AA076B',
      color: "#AA076B"
    }
  },
  clrWhite: {
    color: '#fff',
    marginLeft: 10,
    '&:hover': {
      color: schedulerColor.main,
    }
  },
  iccolor: {
    color: '#AA076B',
  },
  icdiv: {
    cursor: "pointer",
    border: "1px solid #DEDEDE",
    height: "34px",
    paddingTop: "3px",
    width: "37px",
    borderRadius: "5px",
    textAlign: "center",
    marginRight: "8px",
  }



});


class AddEditShifts extends Component {
  render() {
    const { classes, permissionsData } = this.props;
    
    return (
      <Grid container className={classes.topBar}>
        <Grid item
          xl={5}
          lg={5}
          sm={5}
          xs={12}
        >
          <Grid container justifyContent="flex-start">
            <span style={{
              color: "#AA076B",
              fontWeight: "500",
              fontSize: "17px",
              marginLeft: "15px",
              marginRight:'20px',
            }}>Timesheet</span>
            {this.props.urlDate ? 
            <Button className={classes.btnSecondary}
            onClick={this.props.handlePopoverOpen}
          >
            {
                moment(this.props.urlDate).locale('en-US').format('ddd DD MMM YYYY') + " - " +  moment(this.props.urlDate).locale('en-US').format('ddd DD MMM YYYY')
                 
            }
            <BsChevronDown
              style={{
                padding: '0',
                color: schedulerColor.main,
                marginLeft: '5'
              }}
            />
          </Button>
            :
         
            <Button className={classes.btnSecondary}
              onClick={this.props.handlePopoverOpen}
            >
              {this.props.payPeriod === "current" ?
                moment(this.props.current_startDate).locale('en-US').format('ddd DD MMM YYYY')+ " - " + moment(this.props.current_endDate).locale('en-US').format('ddd DD MMM YYYY')
                :
                this.props.payPeriod === "previous" ?
                  moment(this.props.previous_startDate).locale('en-US').format('ddd DD MMM YYYY')+ " - " + moment(this.props.previous_endDate).locale('en-US').format('ddd DD MMM YYYY')
                  : this.props.payPeriod === "custom" ?
                  moment(this.props.custom_startDate).locale('en-US').format('ddd DD MMM YYYY')+ " - " + moment(this.props.custom_endDate).locale('en-US').format('ddd DD MMM YYYY')
                    // (this.props.custom_startDate)+ " - " + (this.props.custom_endDate)
                   :
                    moment(this.props.todayDate).locale('en-US').format('ddd DD MMM YYYY')
              }
              <BsChevronDown
                style={{
                  padding: '0',
                  color: schedulerColor.main,
                  marginLeft: '5'
                }}
              />
            </Button>}

            {/* <ButtonGroup
              size="small"
              style={{
                border: `1px solid linear-gradient(45deg, #AA076B, #9F0D9C)`,
                backgroundColor: '#ffffff',
                borderRadius: '5px',
                padding: '2px'
              }}
            >
              <Button
                style={{
                  textTransform: "capitalize",
                  fontWeight: "600px",
                  fontSize: "14px",
                  margin: '1px',
                  borderRadius: '5px',
                  padding: '2px 20px',
                  border: "none",
                  color: this.props.is_expanded ? "white" : "black",
                  backgroundImage: this.props.is_expanded ? "linear-gradient(45deg, #AA076B, #9F0D9C)" : "linear-gradient(45deg, #ffffff, #ffffff)"
                }}
                onClick={() => { this.props.handleToggleDetails(1) }}
                className={classes.button2}>
                Total
              </Button>

              <Button
                style={{
                  textTransform: "capitalize",
                  fontWeight: "600px",
                  fontSize: "14px",
                  margin: '1px',
                  borderRadius: '5px',
                  padding: '2px 20px',
                  border: "none",
                  color: this.props.is_expanded ? "black" : "white",
                  backgroundImage: this.props.is_expanded ? "linear-gradient(45deg, #ffffff, #ffffff)" : "linear-gradient(45deg, #AA076B, #9F0D9C)"
                }}
                onClick={() => { this.props.handleToggleDetails(0) }}
                className={classes.button1}
              >
                Details
              </Button>
            </ButtonGroup> */}

          </Grid>

        </Grid>

        <Grid item
          xl={7}
          lg={7}
          sm={7}
          xs={12}
        >
          <Grid container justifyContent="flex-end">
            <div className={classes.icdiv} onClick={this.props.handleShowHideOpen}>
              <FiEye className={classes.iccolor} />

            </div>

            <div className={classes.icdiv}style={{display:permissionsData.scheduler_timesheet_filter ? "visible" :"none"}} onClick={this.props.handleFilterPopoverOpen}
              >
              <FiFilter className={classes.iccolor} />

            </div>

            <div className={classes.icdiv} onClick={this.props.printModalOpen}>
              <FiPrinter
                className={classes.iccolor}
              />
            </div>
      
            <div className={classes.icdiv}  style={{display:permissionsData.scheduler_timesheet_download ? "visible" :"none"}} onClick={this.props.openDownloadModal}>
              <FiDownload
                className={classes.iccolor}
              />
            </div>
  
            <Button className={classes.btnPrimary}
              style={{ backgroundColor: permissionsData.scheduler_timesheet_add ? schedulerColor.main : "grey", color: permissionsData.scheduler_timesheet_add ? "white" : "white" }}
              disabled={permissionsData.scheduler_timesheet_add ? false : true}
              onClick={this.props.openTimecardModal}>
              Add Time Card
            </Button>
          </Grid>
        </Grid>

      </Grid>
    )
  }
}

export default withStyles(styles)(AddEditShifts);