import React, { Component } from 'react'
import AdminSidebarLink from './AdminSidebarLink'
import { AdminRoutes } from '../../routes/AdminRoutes'
import Cookies from "universal-cookie";

const cookies = new Cookies();
const ovCid = cookies.get('ovCid')
export default class AdminSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newRoutes: AdminRoutes,
        };
    }

    handleToggleRoutes = (index, isOpen) => {
        var newRoutes = this.state.newRoutes.map((item, myIndex) => {
            var cloneItem = item
            if (myIndex === index) {
                cloneItem["isOpen"] = isOpen ? false : true
            }
            return cloneItem
        })

        this.setState({
            newRoutes: newRoutes
        })
    }



    render() {
        const { countData } = this.props;
        return (
            <div>
            {this.state.newRoutes.map((page, key) => {
              console.log("Page:", page.label, "ovCid:", ovCid.toString());
              if (
                (
                  page.label === "Website Management" ||
                  page.label === "Billing" ||
                  page.label === "Device Management" ||
                  page.label === "Users" ||
                  page.label === "Company" ||
                  page.label === "Profile" ||
                  page.label === "Tickets" ||
                  page.label === "Ideas" ||
                  page.label === "Coupons" ||
                  page.label === "Customers" ||
                  page.label === "Location"
                ) && ovCid.toString() === "624e80fff8ee75131034af66"
              ) {
                console.log("Rendering with ovCid check:", page.label);
                return (
                  <AdminSidebarLink
                    handleToggleRoutes={() => {
                      this.handleToggleRoutes(key, page.isOpen);
                    }}
                    clickLink={this.props.clickLink}
                    key={key}
                    location={this.props.location}
                    isSidebarOpen={this.props.isSidebarOpen}
                    {...page}
                  />
                );
              } else if (
                page.label === "Device Management" ||
                page.label === "Billing Summary" ||
                page.label === "Users" ||
                page.label === "Company" ||
                page.label === "Profile" ||
                page.label === "Tickets" ||
                page.label === "Ideas" ||
                page.label === "Coupons" ||
                page.label === "Location"
              ) {
                console.log("Rendering without ovCid check:", page.label);
                return (
                  <AdminSidebarLink
                    handleToggleRoutes={() => {
                      this.handleToggleRoutes(key, page.isOpen);
                    }}
                    clickLink={this.props.clickLink}
                    key={key}
                    location={this.props.location}
                    isSidebarOpen={this.props.isSidebarOpen}
                    {...page}
                  />
                );
              } else {
                return null; // Optional else condition to handle other cases
              }
            })}
          </div>
          
          
          
          
        )
    }
}
