import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
        Grid,Button
} from '@material-ui/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom'
const settings = {
        dots: false,
        infinite: false,
        width: "200px",
        height: "200px",
        speed: 300,
        arrows:false,
        slidesToShow: 6,
        slidesToScroll: 1,
};

const styles = (theme) => ({
        mainRoot: {
                margin:'1rem'
            },
            tabletitle:{
                borderBottom: '1px solid #cccccc5c',
                backgroundColor: 'white',
                paddingTop: '10px',
                paddingBottom: '10px',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
            },
            topBar:{
                display: 'flex', alignItems: 'center',
                justifyContent: 'space-between', padding: '14px 10px',
                border: '1px solid #f1f1f1', background: '#fff',
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
            },
            icdiv: {
                cursor: "pointer",
                border: "1px solid #DEDEDE",
                height: "34px",
                paddingTop: "3px",
                width: "37px",
                borderRadius: "5px",
                textAlign: "center",
                marginRight: "8px",
              },
        root: {
                //backgroundColor: '#171a29',
                display: 'block',
                padding: "10px 30px 10px 20px",
                //paddingLeft: 130, paddingRight: 130,
                // [theme.breakpoints.down('md')]: {
                //         paddingLeft: 40,
                //         paddingRight: 40
                // }
        },
        image: {
                marginLeft: '1.5rem',
                cursor: 'pointer',
                display: 'block',
                width: '250px',
                height: '250px',
                '&:hover': {
                        transform: 'scale(1.09)',
                        backfaceVisibility: 'visible',
                },
                [theme.breakpoints.down('md')]: {
                        width: '180px',
                        height: '180px',
                        cursor: 'pointer',

                },
                [theme.breakpoints.down('sm')]: {
                        width: '120px',
                        height: '120px',
                        cursor: 'pointer',

                }
        }
})

class SliderDemo extends Component {
        constructor(props) {
                super(props)
                this.state = {
                }
        }

  

              forward = ()=>{
                this.props.showNewCard("Next")
            }
            backward = ()=>{ 
                this.props.showNewCard("Prev")
            }

        render() {
                const { classes, sliderData ,is_select_card,getData} = this.props;
                return (
                    
                        <Grid container style={{marginLeft:'auto',marginRight:'auto',marginBottom:'1rem',position:"relative"}} spacing={2} className={classes.root}>
                          
    


                            <Button style={{padding:'0',minWidth:'50px',position: "absolute", zIndex: 999,left: "2px",top: "50%",transform: "translate(0%, 0%)"}}
                            onClick={()=> this.backward()}
                            >
        
                                <ArrowLeftIcon style={{ fontSize: "50px", cursor: 'pointer',color: '#ccccccbf' }}  />
                            </Button>
                            <Slider {...settings}>
                        {sliderData?.map((row) => {
                            return (
                                <Grid item>
                                <div style={{ padding: "20px 20px 20px 20px", backgroundColor: "white" }}
                                    onClick={() => this.props.clickCard(row,this.props.payload_ids)}
                                    >
                                    <div
                                        style={{
                                            width: 150,
                                            height: 86,
                                            cursor: "pointer",
                                            border: is_select_card === row.Date ? '2px solid #aa076b' : "2px solid #aa076b20",
                                            borderRadius:'5px',
                                            textAlign: "center",
                                            lineHeight: "28px",
                                            boxSizing: "border-box",
                                            visibility: "block",
                                            background: is_select_card === row.Date ? 'white' : "white"
                                        }}
                                    >
                                        <div 
                                        // onClick={() => getData(row)}
                                        >
                                            <div style={{ fontWeight: 700, color:'#aa076b' }} 
                                            // onClick={() => 
                                            // getData(row)}
                                            >
                                                {row.Month}</div>
                                            <div >{row.Date}</div>
                                            {row.Status === 0 ?
                                                <div style={{ 
                                                    color: is_select_card === row.Date ? 'white' : "#aa076b",
                                                    background: is_select_card === row.Date ? '#aa076b' : "#aa076b20"
                                                    }}>
                                                    Pending</div>
                                                :
                                                <div style={{ color: 'green', background: '#E3E0E0' }}><CheckCircleIcon
                                                    style={{ marginRight: 8 }}
                                                />Completed</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                </Grid>
                            )
                        })}

                                </Slider>
                                <Button
                                
                               style={{padding:'0',minWidth:'50px',position: "absolute", zIndex: 999,right: "11px",top: "50%",transform: "translate(0%, 0%)"}}
                                onClick={() => this.forward()}
                                >
        
                                <ArrowRightIcon style={{ fontSize: "50px", cursor: 'pointer' ,color: '#ccccccbf'}} />
                                </Button>
                        </Grid>
                    
                  
             
                );
        }
}
export default withStyles(styles)(SliderDemo);
