//ExportCsv
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { CSVLink } from "react-csv";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { payrollSummaryServices } from "./modules/services";
import { alert } from '../../../utilities';
import { DownloadModal } from "../../../components"
import { schedulerColor } from "../../../config";
const styles = (theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  Download: {
    marginLeft: '3px',
    padding: '7px 30px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  }
});

class ExportCsv extends Component {
  csvLink = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      valueSeclect: "",
    }
  }


  // handleChange = (event) => {
  //   this.setState({
  //     valueSeclect: event.target.value,
  //     DownloadFileName: event.target.value.name
  //   })
  //   var data = {
  //     date: this.props.row,
  //     lid: this.props.lid,
  //     pi_id: event.target.value._id
  //   }

  //   if(this.props.permissionsData.level_1_approval && this.props.permissionsData.level_2_approval && this.props.permissionsData.level_3_approval 
  //     && this.props.payrollData?.Correction_resolve === 0 && this.props.payrollData?.Correction_Request === 0){
  //       payrollSummaryServices.posttDetailsPayrollCash(data).then(response => {
  //         if (response.data.success === 1) {
  //           var allData = []

  //           var username = [];
  //           var is_hour_rate = 0;
  //           var is_username = 0;
  //           var is_timecode = 0;
  //           var is_total_hour = 0;
  //           var is_payroll_rule = 0;
  //           var is_sche_hours = 0;
  //           var is_est_wages = 0;
  //           var is_unpaid_break = 0;
  //           var is_authorized = 0;
  //           var is_emp_id = 0;
  //           var is_actual_hours = 0;
  //           var WorkDate = 0;






  //           // var is_username = 0;

  //           response.data.HeaderData.map((item) => {
  //             if (!item.isdeleted) {
  //               username.push(item.custom_name ? item.custom_name : item.internal_name)
  //             }
  //             switch (item.internal_name) {
  //               case 'Rate':
  //                 is_hour_rate = !item.isdeleted;
  //                 break;
  //               case 'Username':
  //                 is_username = !item.isdeleted;
  //                 break;
  //               case 'Timecode':
  //                 is_timecode = !item.isdeleted;
  //                 break;
  //               case 'Paid Hours':
  //                 is_total_hour = !item.isdeleted;
  //                 break;
  //               case 'Payrole Rule':
  //                 is_payroll_rule = !item.isdeleted;
  //                 break;
  //               case 'Scheduled':
  //                 is_sche_hours = !item.isdeleted;
  //                 break;
  //               case 'Estimated Wages':
  //                 is_est_wages = !item.isdeleted;
  //                 break;
  //               case 'Unpaid Breaks':
  //                 is_unpaid_break = !item.isdeleted;
  //                 break;
  //               case 'Authorized':
  //                 is_authorized = !item.isdeleted;
  //                 break;
  //               case 'Employee ID':
  //                 is_emp_id = !item.isdeleted;
  //                 break;
  //               case 'WorkDate':
  //                 WorkDate = !item.isdeleted;
  //                 break;
  //               case 'Actual Hours':
  //                 is_actual_hours = !item.isdeleted;
  //                 break;
  //               default:
  //                 break;
  //             }
  //           })
  //           username.push("workDate")
  //           var headData = username
  //           //headData = ["username" , "payroll_rule" , "EmployeeID" , "TimeCode" , "TotalHours" , "HourRate" , "Total" , "WorkDate" , "auth"]
  //           // var payrollPeriod = ["Cash", this.props.printDate]
  //           // allData.push(payrollPeriod
  //           allData.push(headData)
  //           response.data.payrollPEData && response.data.payrollPEData?.map(item => {
  //             var rowData = []
  //             is_hour_rate ? rowData.push(item.HourRate ? item.HourRate : null) : console.log("Hour rate disabled")
  //             is_authorized ? rowData.push(item.Authorized ? item.Authorized : null) : console.log("Authorized disabled")
  //             is_emp_id ? rowData.push(item.EmployeeID ? item.EmployeeID : null) : console.log("employee id disabled")
  //             is_unpaid_break ? rowData.push(item.unpaidBreak ? item.unpaidBreak : null): console.log("is_unpaid_break disabled")
  //             is_sche_hours ?  rowData.push(item.SchedHours ? item.SchedHours : null) : console.log("Total scheduled hours disabled")
  //             is_est_wages ? rowData.push( item.wage ? item.wage : null) : console.log("est wages disabled")
  //             is_username ?  rowData.push(item.username ? item.username : null) : console.log("username disabled")
  //             is_actual_hours ? rowData.push( item.totalActualHours ? item.totalActualHours : null) : console.log("actual hours disabled")
  //             is_total_hour ? rowData.push( item.TotalHours ? item.TotalHours : null): console.log("total hours disabled")
  //             is_payroll_rule ? rowData.push(item.payroll_rule ? item.payroll_rule : null) : console.log("payroll rule disabled")
  //             is_timecode ?  rowData.push( item.TimeCode ? item.TimeCode : null) : console.log("Timecode disabled")
  //             WorkDate?  rowData.push( item.WorkDate ?  moment(item.WorkDate ).locale('en-US').format("DD/MM/YYYY") : null) : console.log("work date disabled")

  //             // rowData.push(null)

  //             //rowData.push(item.WorkDate ? item.WorkDate : null): console.log("username disabled")
  //             allData.push(rowData)
  //           });
  //           this.setState({
  //             payrollPEData: allData
  //           }, () => {
  //             this.csvLink.current.link.click()
  //           })
  //         }
  //       });
  //     }else{
  //       alert.error("Please manage all correction request or approve all timesheets")
  //     }
  // };


  handleChange = (event) => {
    this.setState({
      valueSeclect: event.target.value,
      DownloadFileName: event.target.value.name,
      id: event.target.value._id
    })
    var data = {
      date: this.props.date,
      lid: this.props.lid,
      pi_id: event.target.value._id,
      is_work_date: 0,
      is_period_start: 1,
      is_period_end: 1
    }
    alert.success("File is downloading! Please Wait")
    payrollSummaryServices.getDownloadFilterData(data).then((response) => {
      if (response.data.payrollPEData.length > 0) {
        var employees_Without_id = response.data.payrollPEData.filter(d => d.EmployeeID === "" || d.EmployeeID === null)
        if (employees_Without_id.length) {
          this.setState({
            openModal: true
          })
        } else {
          var allData = []
          //var headData = ["EmployeeID","TimeCode","TotalHours", "HourRate", "Total", "WorkDate", "WorkDateEnd", "TimeIn", "TimeOut","Authorized"]
          //allData.push(headData)
          var valueDtaa = response.data.HeaderData.map(dd => dd.custom_name ? dd.custom_name : dd.internal_name);
          allData.push(valueDtaa);
          response.data.payrollPEData?.forEach(item => {
            var valueDaa = response.data.HeaderData.map(ff => {
              switch (ff.internal_name) {
                case "Username":
                  return item.Username_prefix + item.username;
                case "Employee ID":
                  return '=""' + item.EmployeeID + '""';
                case "Timecode":
                  return item.TimeCode;
                case "Approved Hours":
                  return '=""' + parseFloat(item.TotalHours).toFixed(2) + '""';
                case "Hourly Rate":
                  return '=""' + parseFloat(item.HourRate).toFixed(2) + '""';
                case "Total Amount":
                  return '=""' + parseFloat(item.Total).toFixed(2) + '""';
                case "Work Date":
                  return '=""' + item.WorkDate + '""';
                case "Authorized":
                  return item.Authorized;
                case "Period Start":
                  return '=""' + item.period_start + '""';
                case "Period End":
                  return '=""' + item.period_end + ff.custom_name_value + '""';
                case "Email":
                  return item.Email;
                case "Location":
                  return item.Location;
                case "Payrole Rule":
                  return item.payroll_rule;
                case "Role":
                  return item.Role;
                case "Contact":
                  return item.Contact;
                case "Due Date":
                  return '=""' + item.due_date + '""';
                case "Account":
                  return item.Account_value;
                default:
                  return ff.isCustom === 1 ? ff.custom_name_value : "-";
              }
            });
            allData.push(valueDaa);
          });
          this.setState({
            payrollpedata: allData,
            valueSeclect: ""
            //headerData:response.data.HeaderData
          }, () => {
            this.csvLink.current.link.click()
          })

        }

      }
      else {
        this.setState({
          valueSeclect: ""
        })
        alert.error("No records")
      }
    })
  }

  closeModal = () => {
    this.setState({
      openModal: false,
      valueSeclect: "",
    })
  }


  handleChangeDropdowm = (event) => {
    var data = {
      date: this.props.date,
      lid: this.props.lid,
      pi_id: this.state.id,
      is_work_date: 0,
      is_period_start: 1,
      is_period_end: 1
    }
    alert.success("File is downloading! Please Wait")
    payrollSummaryServices.getDownloadFilterData(data).then((response) => {
      var allData = []
      //var headData = ["EmployeeID","TimeCode","TotalHours", "HourRate", "Total", "WorkDate", "WorkDateEnd", "TimeIn", "TimeOut","Authorized"]
      //allData.push(headData)
      var valueDtaa = response.data.HeaderData.map(dd => dd.custom_name ? dd.custom_name : dd.internal_name);
      allData.push(valueDtaa);
      response.data.payrollPEData?.forEach(item => {
        var valueDaa = response.data.HeaderData.map(ff => {
          switch (ff.internal_name) {
            case "Username":
              return item.Username_prefix + item.username;
            case "Employee ID":
              return '=""' + item.EmployeeID + '""';
            case "Timecode":
              return item.TimeCode;
            case "Approved Hours":
              return '=""' + parseFloat(item.TotalHours).toFixed(2) + '""';
            case "Hourly Rate":
              return '=""' + parseFloat(item.HourRate).toFixed(2) + '""';
            case "Total Amount":
              return '=""' + parseFloat(item.Total).toFixed(2) + '""';
            case "Work Date":
              return '=""' + item.WorkDate + '""';
            case "Authorized":
              return item.Authorized;
            case "Period Start":
              return '=""' + item.period_start + '""';
            case "Period End":
              return '=""' + item.period_end + ff.custom_name_value + '""';
            case "Email":
              return item.Email;
            case "Location":
              return item.Location;
            case "Payrole Rule":
              return item.payroll_rule;
            case "Role":
              return item.Role;
            case "Contact":
              return item.Contact;
            case "Due Date":
              return '=""' + item.due_date + '""';
            case "Account":
              return item.Account_value;
            default:
              return ff.isCustom === 1 ? ff.custom_name_value : "-";
          }
        });
        allData.push(valueDaa);
      });
      this.setState({
        payrollpedata: allData,
        valueSeclect: "",
      }, () => {
        this.csvLink.current.link.click()
        this.closeModal()
      })

    })
  }


  render() {
    const { classes, rowData } = this.props;
    return (
      <>
        <div>
          <FormControl sx={{ m: 1, minWidth: 120, marginTop: "auto",marginBottom:'0px' }} size="small" error>
            <InputLabel id="demo-controlled-open-select-label">Download</InputLabel>
            <Select sx={{ border:'1px solid #aa076b!important',borderRadius:'4px!important' }}
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              value={this.state.valueSeclect}
              label="Download"
              onChange={this.handleChange}
            >
              {this.props.rowData && this.props.rowData.map((row) => {
                return (
                  <MenuItem
                    value={row}
                    style={{ textDecoration: "none", color: "black" }}>
                    {row.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <CSVLink
            filename={this.state.DownloadFileName ? this.state.DownloadFileName : ""}
            data={this.state.payrollpedata ? this.state.payrollpedata : ""}
            className="hidden"
            ref={this.csvLink}
            target="_blank"
          />
        </div>


        <DownloadModal
          bgColor={schedulerColor.main}
          //desc="Are you sure you want to delete?"
          open={this.state.openModal}
          onClose={this.closeModal}
          id={this.state.valueSeclect}
          handleDelete={this.handleChangeDropdowm}
        />
      </>
    );
  }
}

export default withStyles(styles)(ExportCsv);
