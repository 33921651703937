import React, { useState } from "react";
import { createGroupStyles } from "./CreateGroupStyles";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Avatar,
  InputAdornment,
  ListSubheader,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomTextField from "../../../components/CustomTextField/TextField";
import { MdFileUpload } from "react-icons/md";
import { Search } from "@material-ui/icons";
import { FaUserAlt } from "react-icons/fa";

const people = [
  { name: "Asad", role: "Manager" },
  { name: "Mishal", role: "Manager" },
  { name: "Zunaira", role: "Manager" },
  { name: "Ali", role: "Designer" },
  { name: "Sara", role: "Designer" },
  { name: "John", role: "Developer" },
  { name: "Jane", role: "Developer" },
];

const roles = ["Manager", "Designer", "Developer"];

const CustomSelect = styled(Select)({
  width: "100%",
  marginTop: "8px",
  marginBottom: "8px",
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
  },
});

const CustomButton = styled(Button)({
  minWidth: "100px",
  margin: "8px",
});

const CreateGroup = () => {
  const classes = createGroupStyles();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    personOrRole: "",
  });
  const [fileName, setFileName] = useState("");
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedRoles, setExpandedRoles] = useState({});

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedPeople(typeof value === "string" ? value.split(",") : value);
  };

  const handleInputChange = () => {};

  const onFileChange = (file) => {
    console.log("Uploaded File:", file);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      if (onFileChange) {
        onFileChange(file);
      }
    }
  };

  const handleRoleToggle = (role) => {
    setExpandedRoles((prev) => ({
      ...prev,
      [role]: !prev[role],
    }));
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filterPeopleByRole = (role) =>
    people.filter(
      (person) =>
        person.role === role && person.name.toLowerCase().includes(searchTerm)
    );

  const handlePersonToggle = (personName) => {
    setSelectedPeople((prevSelected) =>
      prevSelected.includes(personName)
        ? prevSelected.filter((name) => name !== personName)
        : [...prevSelected, personName]
    );
  };

  return (
    <div className={classes.container}>
      <CustomTextField
        name="name"
        type="text"
        placeholder="Group Name"
        value={formData.name}
        onChange={handleInputChange}
        size="small"
        fullWidth
      />

      <CustomTextField
        name="description"
        type="text"
        placeholder="Description..."
        value={formData.description}
        onChange={handleInputChange}
        size="small"
        variant="outlined"
        multiline
        rows={3}
        fullWidth
      />

      <TextField
        variant="outlined"
        className={classes.inputField}
        size="small"
        placeholder="Upload Logo"
        value={"Upload Logo"}
        InputProps={{
          readOnly: true,
          style: {
            color: "#00000050",
            fontSize: "14px",
            lineHeight: "16.41px",
            fontFamily: "Roboto",
            fontWeight: "400",
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton component="label">
                <MdFileUpload className={classes.uploadIcon} />
                <input
                  type="file"
                  hidden
                  onChange={handleFileChange}
                  accept={"image/*" || "*"}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {/* Multi-Select with Search and Roles */}
      <FormControl
        variant="outlined"
        fullWidth
        size="small"
        className={classes.selectField}
      >
        <InputLabel
          sx={{
            fontSize: "14px !important",
            lineHeight: "16.41px",
            fontFamily: "Roboto",
            fontWeight: "400",
            color: "#00000050 !important",
          }}
        >
          Add Person or Role
        </InputLabel>
        <Select
          multiple
          label="Select Person or Role"
          value={selectedPeople}
          onChange={handleSelectChange}
          input={<OutlinedInput label="Add Person or Role" />}
          placeholder="Add Person or Role"
          className={classes.selectField}
          inputProps={{
            style: {
              fontSize: "16px",
              lineHeight: "16.41px",
              fontFamily: "Roboto",
              fontWeight: "400",
              color: "#00000080",
            },
          }}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) =>
                value ? (
                  <Chip
                    className={classes.chip}
                    key={value}
                    label={<Typography className={classes.chipText}>{value}</Typography>}
                    avatar={<Avatar className={classes.menuItemAvatar}><FaUserAlt className={classes.menuItemIcon} /></Avatar>}
                  />
                ) : null
              )}
            </Box>
          )}
        >
          {/* Search Field */}
          <MenuItem disableGutters className={classes.searchFieldContainer}>
            <TextField
              fullWidth
              placeholder="Search for person or role"
              className={classes.inputField}
              InputProps={{
                style: {
                  fontSize: "14px",
                  lineHeight: "16.41px",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  color: "#00000080",
                },
                size: "small",
                startAdornment: (
                  <InputAdornment position="start">
                    <Search className={classes.searchIcon} />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearchChange}
            />
          </MenuItem>

          {/* Role-Based People List */}
          {roles.map((role) => (
            <React.Fragment key={role}>
              <ListSubheader>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      checked={expandedRoles[role] || false}
                      onChange={() => handleRoleToggle(role)}
                      size="small"
                    />
                  }
                  label={
                    <Typography className={classes.checkboxLabel}>
                      {role}
                    </Typography>
                  }
                />
              </ListSubheader>
              {expandedRoles[role] &&
                filterPeopleByRole(role).map((person) => (
                  <MenuItem
                    key={person.name}
                    value={person.name}
                    className={classes.menuItem}
                  >
                    <Avatar className={classes.menuItemAvatar}>
                      <FaUserAlt className={classes.menuItemIcon} />
                    </Avatar>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography className={classes.checkboxLabel}>
                        {person.name}
                      </Typography>
                      <Typography className={classes.menuItemRole}>
                        {person.role}
                      </Typography>
                    </Box>
                    <Checkbox
                      className={classes.checkbox}
                      checked={selectedPeople.includes(person.name)}
                      onChange={() => handlePersonToggle(person.name)}
                      size="small"
                    />
                  </MenuItem>
                ))}
            </React.Fragment>
          ))}
        </Select>
      </FormControl>

      <div className={classes.createGroupBottomBtns}>
        <Button className={classes.createBtn}>Create</Button>
        <Button className={classes.cancelBtn}>Cancel</Button>
      </div>
    </div>
  );
};

export default CreateGroup;
