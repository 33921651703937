import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Button, Grid, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1, 0),
  },
  questionContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    gap: "10px",
    alignItems: "start",
    justifyContent: "start",
    borderRadius: "5px",
    padding: "8px 25px",
    background: "transparent",
    width: "95%",
    height: "92px",
    boxShadow: "0px 0px 4px 0px #00000040",
  },
  question: {
    fontSize: "16px",
    fontWeight: "700",
    color: "#6A6A6A",
  },
  questionContent: { fontSize: "12px", fontWeight: "400", color: "#6A6A6A" },
  correctIcon: {
    position: "absolute",
    top: "10px",
    right: "20px",
    color: "#48742C",
    overflow: "auto"
  },
  incorrectIcon: {
    position: "absolute",
    top: "10px",
    right: "20px",
    color: "#BB271A"
  },
  message: {
    fontSize: "10px",
    fontWeight: "400",
    lineHeight: "11.5px",
    color: "#6A6A6A",
  },
  button: {
    width: "200px",
    height: "40px",
    borderRadius: "4px",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "20.7px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
    background: "#1B3F8F",
    transition: "background 0.3s",
    "&:hover": {
      background: "#23538F"
    },
  },
});

const summaryQuestionsSkeletonLoader = () => {
  return (
    <>
      <SkeletonTheme
        baseColor="#ebebeb"
        // highlightColor="#f5f5f5"
        duration={4}
      >
        <div style={{ fontSize: "20px", color: "#6A6A6A" }}>
          <Skeleton
            style={{
              width: "120px",
              borderRadius: "10px"
            }}
          />
        </div>

        <article
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <div
            style={{
              fontSize: "12px",
              color: "#6A6A6A",
              display: "flex",
              width: "100%",
              gap: "2px",
              fontWeight: "400",
              lineHeight: "13.8px",
              margin: "8px 0 8px 0",
              position: "relative",
              justifyContent: "end"
            }}
          >
            <Skeleton
              style={{
                width: "40px",
                borderRadius: "10px",
              }}
            />
            <Skeleton
              style={{
                width: "40px",
                borderRadius: "10px",
              }}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            {[1, 2, 3].map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  gap: "10px",
                  alignItems: "start",
                  justifyContent: "start",
                  borderRadius: "5px",
                  padding: "8px 20px",
                  background: "transparent",
                  width: "90%",
                  minWidth: "250px",
                  height: "92px",
                  boxShadow: "0px 0px 4px 0px #00000040",
                }}
              >
                <Typography
                  variant="h6"
                  style={{ fontSize: "16px", color: "#6A6A6A" }}
                >
                  <Skeleton
                    style={{
                      width: "90px",
                      borderRadius: "10px",
                    }}
                  />
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontSize: "12px", color: "#6A6A6A" }}
                >
                  <Skeleton
                    count={2}
                    style={{
                      width: "160px",
                      borderRadius: "10px",
                    }}
                  />
                </Typography>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "5px",
              margin: "8px 0 8px 0",
            }}
          >
            <div
              style={{
                width: "20px",
                height: "15px",
                borderRadius: "50%",
                color: "white",
                fontSize: "9px",
                fontWeight: "700",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton
                style={{
                  width: "20px",
                  height: "15px",
                  borderRadius: "50%",
                }}
              />
            </div>
            <Typography variant="body2">
              <Skeleton
                style={{
                  width: "150px",
                  height: "8px",
                  borderRadius: "10px",
                }}
              />
            </Typography>
          </div>

          <Button
            style={{ width: "120px", height: "40px", borderRadius: "4px" }}
          >
            <Skeleton
              style={{
                width: "120px",
                height: "40px",
                borderRadius: "4px",
              }}
            />
          </Button>
        </article>
      </SkeletonTheme>
    </>
  );
};

class QuizSummaryModalMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGrid: true,
    };
  }

  render() {
    const { classes } = this.props;
    
    return (
      <Grid
        container
        spacing={2}
        style={{
          padding: 2,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          gap: "7px",
        }}
      >
        {this.props.loading ? (
          summaryQuestionsSkeletonLoader()
        ) : (
          <>
            {" "}
            <div
              style={{ fontSize: "20px", fontWeight: "700", color: "#6A6A6A" }}
            >
              Quiz Summary
            </div>
            <article
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <div
                style={{
                  fontSize: "12px",
                  color: "#6A6A6A",
                  display: "flex",
                  width: "100%",
                  fontWeight: "400",
                  lineHeight: "13.8px",
                  margin: "8px 0 8px 0",
                  position: "relative",
                  justifyContent: "end"
                }}
              >
                Quiz Score: {this.props.quizScore} /{" "}
                {this.props.questions?.length}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                {this.props.questions?.map((question, index) => (
                  <div key={question._id} className={classes.questionContainer}>
                    <Typography variant="h6" className={classes.question}>
                      Question {index + 1}
                    </Typography>
                    {question.result?.is_correct ? (
                      <CheckIcon
                        style={{ fontSize: 32, fontWeight: "400" }}
                        className={classes.correctIcon}
                      />
                    ) : (
                      <ClearIcon
                        style={{ fontSize: 30, fontWeight: "400" }}
                        className={classes.incorrectIcon}
                      />
                    )}
                    <Typography
                      variant="body2"
                      className={classes.questionContent}
                    >
                      {question.question}
                    </Typography>
                  </div>
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "5px",
                  margin: "8px 0 8px 0",
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "15px",
                    backgroundColor: "#23538F",
                    borderRadius: "50%",
                    color: "white",
                    fontSize: "9px",
                    fontWeight: "700",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  i
                </div>
                <Typography variant="body2" className={classes.message}>
                  Until you give all quiz answers correct your lesson will not
                  be completed. Now your lesson will be restarted.
                </Typography>
              </div>

              {this.props.summary_status === "Failed" ? (
                <Button
                  className={`${classes.button} ${classes.startAgain}`}
                  onClick={() => {
                    this.props.startQuiz(this.props.slide_id);
                    this.props.closeSummaryModal();
                  }}
                >
                  Start Again
                </Button>
              ) : (
                <Button
                  className={classes.button}
                  onClick={() => {
                    this.props.updateQuiz(
                      this.props.slide_id,
                      this.props.SlideIndex
                    );
                    this.props.closeSummaryModal();
                  }}
                >
                  Proceed Next
                </Button>
              )}
            </article>
          </>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({});

const ConnectedQuizSummaryModal = connect(
  mapStateToProps,
  null
)(QuizSummaryModalMobile);
export default withStyles(styles)(ConnectedQuizSummaryModal);
