// components
import Modal from "@mui/material/Modal";
import React from "react";
// icons
import { Button, Grid, Typography, TextField } from "@mui/material";
import { BiArrowBack } from "react-icons/bi";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { RoleCard, UserCard } from "../../components";
import { Box, Pagination, Stack } from "@mui/material";
import { rolesServices } from "../../../../pages/common/Roles/modules/services";
import { history } from "../../../../history";
import { usersServices } from "../../../../pages/common/UsersList/modules/services";
import { FaGalacticSenate } from "react-icons/fa6";
import {LoadingDataHR} from "../../../../components";
import { LoadMoreMobile } from "../../components/LoadMoreMobile";
const styles = (theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      display: "none",
    },
  },
  root: {
    padding: theme.spacing(1, 0),
  },
  mainContainer: { outline: "none !important", zIndex: "40 !important" },
  contentSearch: {
    position: "absolute",
    //   top: "50%",
    //   left: "50%",
    bottom: "0",
    // top: "30px",
    //   transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    background: "white",
    outline: "none",
    border: "none",
    padding: "20px",
    overflow: "scroll",
    // borderRadius: "28px 28px 0 0 ",
  },
  paginationContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  mainContentBox: {
    marginBottom: "30px",
  },
  contentAlignmentSearch: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    height: "100%",
    justifyContent: "start",
  },
  backArrowWrapperSearch: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root": {
      borderRadius: "20px", // Adjust the radius value as per your preference
    },
    "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
      background: "rgba(0, 126, 99, 0.25)",
      borderRadius: "20px",
      color: "rgba(0, 126, 99, 1)",
      fontWeight: 400,
    },
  },
  FormControlLabel: {
    position: "relative",
    margin: 0,
    backgroundColor: "#fdfdfd",
    padding: "0px 9px 0px 0px",
    borderRadius: "25px",
    border: "1px solid #CCCCCC",
    width: "300px",
    height: "auto",
    fontWeight: "700",
    color: "#6A6A6A",
    // '&::after': {
    //     border: "1px solid #B8B8B8",
    // }
  },
  cardContainerRole: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "start",
    gap: "14px",
  },
  questionContainer: {
    display: "grid",
    justifyContent: "start",
    textAlign: "start",
    gap: "2px",
  },
  question: {
    marginBottom: "20px",
    // textAlign: "center",
    color: "#6A6A6A",
    fontSize: "12px",
    fontWeight: "400",
  },
  questionHeading: {
    color: "#6A6A6A",
    fontSize: "16px",
    fontWeight: "700",
    margin: "0px",
  },
  optionsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "start",
    maxHeight: "300px",
    overflowY: "auto",
    gap: "20px",
    width: "100%",
  },
  optionWrapper: {
    width: "270px",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    gap: "3px",
    padding: "4px 7px 4px 7px",
    border: "1px solid #CCCCCC",
    borderRadius: "21px",
    cursor: "pointer",
  },
  alphabetChar: {
    fontSize: "16px",
    color: "#135098",
    fontWeight: "400",
  },
  optionText: {
    fontSize: "16px",
    color: "#6A6A6A",
    fontWeight: "400",
    overflowWrap: "break-word",
    wordBreak: "break-word",
  },
  topRow: {
    marginTop: "30px",
    marginBottom: "20px",
  },
  btn: {
    width: "225px !important",
    height: "52px !important",
    display: "flex !important",
    backgroundColor: "#245193 !important",
    border: "1px solid #245193 !important",
    margin: "auto !important",
    borderRadius: "4px !important",
    color: "white !important",
    fontWeight: "bold !important",
    fontSize: "16px !important",
    boxShadow: "2px 4px 7px rgba(0, 0, 0, 0.25) !important",
  },
  text: {
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
  },
});

// const useStyles = makeStyles((theme) => styles(theme));

const SlideQuiz = ({ open, handleClose, classes }) => {
  const location = useLocation();
  const [accumulatedDataRole, setAcuumulatedDataRole] = useState([]);
  const [accumulatedDataUser, setAcuumulatedDataUser] = useState([]);
  const [rolesData, setRolesData] = React.useState([]);
  const [usersData, setUsersData] = React.useState([]);
  const [isLoading, setisLoading] = React.useState(false);
  const [searchUsersData, setSearchUsersData] = React.useState("");
  const [totalRolePage, setTotalRolePage] = React.useState("");
  const [totalUserPage, setTotalUserPage] = React.useState("");
  const [timer, setTimer] = React.useState(null);
  const [pageRole, setPageRole] = React.useState(1);
  const [pageUser, setPageUser] = React.useState(1);
  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");

  useEffect(() => {
    // Check if rolesData is not empty before merging
    if (rolesData.length > 0) {
      setAcuumulatedDataRole(prevaccumulatedDataRole => [...prevaccumulatedDataRole, ...rolesData]);
    }
  }, [rolesData]);

  useEffect(() => {
    // Check if usersData is not empty before merging
    if (usersData.length > 0) {
      setAcuumulatedDataUser(prevaccumulatedDataUser => [...prevaccumulatedDataUser, ...usersData]);
    }
  }, [usersData]);


  const fetchRoleData = (data) => {
    setisLoading(true)
    rolesServices.fetchData(data).then((response) => {
      if (response.data.success === 2) {
        // invalidToken(response.data.message)
      } else {
        setRolesData(response.data.result);
        setPageRole(response.data.currentPage)
        setisLoading(false)
        setTotalRolePage(response.data.totalPages);
      }
    });
  };

  const fetchUsersData = (data) => {
    setisLoading(true)
    usersServices.fetchData(data).then((response) => {
      if (response.data.success === 2) {
        // invalidToken(response.data.message)
      } else {
        setUsersData(response.data.result);
        setPageUser(response.data.currentPage)
        setisLoading(false)
        setTotalUserPage(response.data.totalPages);
      }
    });
  };

  React.useEffect(() => {
    var isReload = usersData.length > 0 ? false : true;
    let data = {
      pageNumber: pageParam ? pageParam : pageUser,
      nPerPage: 10,
    };
    fetchUsersData(data);
  }, [location.pathname === "/hr/users"]);

  React.useEffect(() => {
    let data = {
      pageNumber: pageParam ? pageParam : pageRole,
      nPerPage: 10,
    };
    fetchRoleData(data);
  }, [location.pathname === "/hr/roles"]);

  const handleRoleSearch = (value) => {
    setSearchUsersData(value);
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      let data = {
        pageNumber: 1,
        nPerPage: 10,
      };
      if (value) {
        data.name = value;
      }
      fetchRoleData(data);
      setAcuumulatedDataRole([])
    }, 1500);

    setTimer(newTimer);
    // setTimer(newTimer);
  };


  const handleUserSearch = (value) => {
    setSearchUsersData(value);

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      let data = {
        pageNumber:  1 ,
        nPerPage: 10,
      };
      if (value) {
        data.name = value;
      }
      fetchUsersData(data);
      setAcuumulatedDataUser([])
    }, 1500);

    setTimer(newTimer);
    // setTimer(newTimer);
  };

  // const handleChangePageRole = (event, value) => {
  //   // var url = `/hr/roles/?page=` + value;
  //   setPageRole(value);
  //   let data = {
  //     pageNumber: value,
  //     nPerPage: 10,
  //   };
  //   fetchRoleData(data);
  //   // history.push(url);
  // };

  const loadMoreDataRole = () => {
    const nextPage = pageRole+ 1;
    if (nextPage <= totalRolePage) {
      setPageRole(nextPage)
      let data = {
        pageNumber: nextPage,
        nPerPage: 10,
      };
      if (searchUsersData) {
        data.name = searchUsersData;
      }
      fetchRoleData(data);
    }
  };

  const loadMoreDataUser = () => {
    const nextPage = pageUser+ 1;
    if (nextPage <= totalUserPage) {
      setPageUser(nextPage)
      let data = {
        pageNumber: nextPage,
        nPerPage: 10,
      };
        if (searchUsersData) {
          data.name = searchUsersData;
        }
        fetchUsersData(data);
    }
  };

  // const handleChangePageUsers = (event, value) => {
  //   // var url = `/hr/users/?page=` + value;
  //   setPageUser(value);
  //   let data = {
  //     pageNumber: value,
  //     nPerPage: 10,
  //   };
  //   fetchUsersData(data);
  //   // history.push(url);
  // };

  // if(isLoading) return <LoadingData/>

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.mainContainer}
      >
        <div className={classes.contentSearch}>
          <div className={classes.contentAlignmentSearch}>
            <div className={classes.backArrowWrapperSearch}>
              <ArrowBackIosIcon
                size={20}
                style={{ color: "rgba(0, 126, 99, 1)" }}
                className={classes.mainBackIcon}
                onClick={handleClose}
              />
              {location.pathname === "/hr/roles" || pageParam ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  className={classes.textField}
                  size="small"
                  placeholder="Search Role Name"
                  // onChange={(e) => {
                  //   // this.setState({ searchUsers: e.target.value });
                  //   // this.debouncedSearchUsers(e.target.value);
                  //   this.handleUserSearch(e.target.value);
                  // }}
                  onChange={(e) => handleRoleSearch(e.target.value)}
                  value={searchUsersData}
                />
              ) : (
                <TextField
                  fullWidth
                  variant="outlined"
                  className={classes.textField}
                  size="small"
                  placeholder="Search user Name"
                  // onChange={(e) => {
                  //   // this.setState({ searchUsers: e.target.value });
                  //   // this.debouncedSearchUsers(e.target.value);
                  //   this.handleUserSearch(e.target.value);
                  // }}
                  onChange={(e) => handleUserSearch(e.target.value)}
                  value={searchUsersData}
                />
              )}
            </div>

            <Box className={classes.cardContainerRole}>
              {isLoading ? 
              <LoadingDataHR/> :
              location.pathname === "/hr/users" ? (
                accumulatedDataUser.length > 0 ? (
                  accumulatedDataUser.map((item) => (
                    <UserCard
                    search={true}
                      key={item._id}
                      designation={item.role_name}
                      email={item.user_email}
                      //location={item.assignLocations_count}
                      //message={item.message}
                      name={item.user_name}
                    />
                  ))
                ) : (
                  <Typography className={"noRecordText"}>No Record</Typography>
                )
              ) : location.pathname === "/hr/roles" || pageParam ? (
                accumulatedDataRole.length > 0 ? (
                  accumulatedDataRole.map((item) => (
                    <RoleCard
                      search={true}
                      key={item._id}
                      count={item.user_role_count}
                      date={item.createdAt}
                      description={item?.role_description}
                      name={item?.role_name}
                    />
                  ))
                ) : (
                  <Typography className={"noRecordText"}>No Record</Typography>
                )
              ) : null}
              <div style={{ marginBottom: "55px" }}>
                {  location.pathname === "/hr/roles" ?
                    pageRole < totalRolePage && (
                      <LoadMoreMobile handlePageChange={loadMoreDataRole} />
                    ) :                
                    pageUser < totalUserPage && (
                      <LoadMoreMobile handlePageChange={loadMoreDataUser} />
                    )
                }
                {/* <Stack spacing={2} m={"1rem"}>
                  <Pagination
                    size="small"
                    color="primary"
                    count={
                      location.pathname === "/hr/roles" || pageParam
                        ? totalRolePage
                        : totalUserPage
                    }
                    variant="outlined"
                    page={
                      location.pathname === "/hr/roles" || pageParam
                        ? pageRole
                        : pageUser
                    }
                    onChange={
                      location.pathname === "/hr/roles" || pageParam
                        ? handleChangePageRole
                        : handleChangePageUsers
                    }
                  />
                </Stack> */}
              </div>
            </Box>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default withStyles(styles)(SlideQuiz);
