export const SET_DASHBOARD_LOADING = "SET_DASHBOARD_LOADING";
export const SET_DASHBOARD_LOCATION_BASED = "SET_DASHBOARD_LOCATION_BASED";
export const SET_DASHBOARD_ROLE_BASED = "SET_DASHBOARD_ROLE_BASED";
export const SET_DASHBOARD_LESSON_BASED = "SET_DASHBOARD_LESSON_BASED";
export const SET_DASHBOARD_USER_BASED = "SET_DASHBOARD_USER_BASED";

export const GET_DASHBOARD_USERS_LOADING = "GET_DASHBOARD_USERS_LOADING";
export const GET_DASHBOARD_USERS = "GET_DASHBOARD_USERS";

export const GET_DASHBOARD_LESSONS_LOADING = "GET_DASHBOARD_LESSONS_LOADING";
export const GET_DASHBOARD_LESSONS = "GET_DASHBOARD_LESSONS";

export const GET_DASHBOARD_ROLES_LOADING = "GET_DASHBOARD_ROLES_LOADING";
export const GET_DASHBOARD_ROLES = "GET_DASHBOARD_ROLES";

export const GET_DASHBOARD_LOCATIONS_LOADING =
  "GET_DASHBOARD_LOCATIONS_LOADING";
export const GET_DASHBOARD_LOCATIONS = "GET_DASHBOARD_LOCATIONS";
