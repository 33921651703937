import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = (theme) => ({
  tagsInputContainer: {
    overflow: "auto",
    // width: "270px", // Set your desired fixed width here
    height: "60px",
  },
});

class MyTags extends React.Component {
  render() {
    const { name, tags, onChange, onFocus, error, helperText, classes, width } =
      this.props;

    // Ensure that tags is always an array
    const tagsArray =
      typeof tags === "string"
        ? tags.split(",")
        : Array.isArray(tags)
        ? tags
        : [];

    return (
      <div className={classes.tagsInputContainer}>
        <TagsInput
          name={name}
          value={tagsArray}
          onChange={onChange}
          onFocus={onFocus}
          className="react-tagsinput py-0"
          style={{ width: width || "260px", height: "50px", overflow: "auto" }}
        />
        {error ? (
          <FormHelperText style={{ color: "red" }}>{helperText}</FormHelperText>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withStyles(styles)(MyTags);
