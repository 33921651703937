import React, { useEffect, useState } from "react";

// icons
// import { IoMdSearch } from "react-icons/io";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { mobileTheme } from "../../constant";
import { Toggel } from "../../utilities/common/Button";
import {
  Box,
  Checkbox,
  FormControlLabel,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableBody,
  TableRow,
  Stack,
  Pagination,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import { connect } from "react-redux";

// data
import { headMyGradingElements, headTeamGradingElements } from "./constant";

import "../style.css";
import {
  FailButton,
  GradientButton,
  PassButton,
  PendingButton,
} from "../../utilities/common/Button/buttonStyles";

import {
  // fetchData as fetchGrading,
  // fetchAssignLesson,
  // fetchlocationData,
  fetchRoleData,
  fetchTeamGradingData,
} from "../../../../pages/eLearning/Grading/modules/actions";

import {
  fetchData,
  getMyLessonNames,
} from "../../../../pages/eLearning/Progress/modules/actions";

// import Cookies from "universal-cookie";
import { history } from "../../../../history";
import { useGradingLessonDetail } from "../../context/gradingContext";
import { MobileLoader } from "../../utilities";

import "./GradingMobileList.scss";
import ReactSelect from "react-select";
import { NoPermission } from "../../../../components";
import { LoadMoreMobile } from "../../components/LoadMoreMobile";
import { progressServices } from "../../../../pages/eLearning/Progress/modules/services";

// const cookies = new Cookies();
// var ovUid = cookies.get("ovUid");

const generateFormControl = (onChange, value, items) => {
  return (
    <FormControl
      sx={{
        width: "80px",
        height: "26px",
        borderRadius: "5px!important",
        bgcolor: "#1B3F8F1A",
        // marginRight: "5px",
        "& .MuiSvgIcon-root": { color: "#1B3F8F" },
      }}
      size="small"
    >
      <Select
        IconComponent={() => <ExpandMoreIcon style={{ fontSize: "13px" }} />}
        SelectDisplayProps={{ style: { paddingRight: 0 } }}
        sx={{
          borderRadius: "5px!important",
          ".MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          border: "1px solid #1B3F8F1A",
          width: "80px",
          height: "26px",
          fontSize: "10px",
        }}
        onChange={onChange}
        value={value}
        displayEmpty
        // placeholder={placeholder}
      >
        {items.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            style={{ fontSize: "8px" }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const generateSearchSelectControl = (onChange, value, items) => {
  return (
    <FormControl
      sx={{
        width: "100px",
        height: "26px",
        borderRadius: "5px!important",
        // bgcolor: "#1B3F8F1A",
        // marginRight: "5px",
        "& .MuiSvgIcon-root": { color: "#1B3F8F" },
      }}
      size="small"
    >
      {/* <Select
        IconComponent={() => <ExpandMoreIcon style={{ fontSize: "13px" }} />}
        SelectDisplayProps={{ style: { paddingRight: 0 } }}
        sx={{
          borderRadius: "5px!important",
          ".MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          border: "1px solid #1B3F8F1A",
          width: "80px",
          height: "26px",
          fontSize: "10px",
        }}
        onChange={onChange}
        value={value}
        displayEmpty
        // placeholder={placeholder}
      >
        {items.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            style={{ fontSize: "8px" }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select> */}

      <ReactSelect
        options={items}
        // getOptionLabel={(option) => option.lesson_name}
        // getOptionValue={(option) => option._id}
        value={value}
        placeholder={
          items.find((item) => item.value === value)
            ? items.find((item) => item.value === value).label.slice(0, 8)
            : "Lesson"
        }
        onChange={onChange}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: "1px solid #1B3F8F1A",
            borderRadius: "5px!important",
            width: "100px",
            minHeight: "26px",
            height: "26px",
            fontSize: "10px",
            background: "#1B3F8F1A",
            overflowWrap: "break-word",
            wordBreak: "break-word",
          }),
          dropdownIndicator: (base) => ({
            ...base,
            color: "#1B3F8F",
            display: "flex",
            alignItems: "center",
            height: "26px",
            width: "30px",
          }),
          menuPortal: (base) => ({
            ...base,
            fontSize: "12px",
          }),
        }}
      />
    </FormControl>
  );
};

const MobileGrading = ({
  handleManagerQuiz,
  handleVideoQuiz,
  // userID,
  fetchTeamGradingData,
  teamGradingPayload,
  isTeamGradingLoading,
  fetchData,
  getMyLessonNames,
  pageNumber,
  loadMoreData,
  handleView,
  // Filters //
  showHideSettings,
  handleChangeCheckbox,
  // lesson
  lesson_s,
  handleChangeDropDownLesson,
  isLessonNamesDataLoading,
  lessonNamesData,
  // Role
  fetchRoleData,
  isRolesLoading,
  roleDetails,
  selectedRole,
  handleChangeDropDownRole,
  // lesson quiz
  handleChangeDropDownLessonQuizStatus,
  lesson_quiz_s,
  // my lessons
  myLessonNamesData,
  isMyLessonNamesDataLoading,
  // manager quiz
  handleChangeDropDownManagerStatus,
  manager_quiz_s,
  // video quiz
  handleChangeDropDownVideo1,
  video_quiz_s,
  permissionsData,
  // category filter
  handleChangeCategory,
  selectedCategory,
  categoryData,
  // location filter
  handleChangeLocation,
  selectedLocationFilter,
  locationsData,
  // user filter
  handleChangeUser,
  selectedUserFilter,
  usersData,
  // quiz count
  getQuizCountData,
}) => {
  const [selectedTab, setSelectedTab] = useState("my");
  const [accumulatedData, setAccumulatedData] = useState([]);
  // My Grading Filter States
  const initialFilterState = {
    lessonName: "",
    lessonQuizStatus: "",
    videoStatus: "",
    managerStatus: "",
  };
  const [filters, setFilters] = useState(initialFilterState);

  const [myGradingLoading, setMyGradingLoading] = useState(false);
  const [myGradingData, setMyGradingData] = useState([]);
  const [myGradingCurrentPage, setMyGradingCurrentPage] = useState(0);
  const [myGradingTotalPages, setMyGradingTotalPages] = useState(0);

  const { setUserName, setRole } = useGradingLessonDetail();

  const handleTabChange = (value) => {
    setAccumulatedData([]);
    setSelectedTab(value);
    localStorage.setItem("gradingToggelTabState", JSON.stringify(value));
  };

  useEffect(() => {
    if (
      !!permissionsData.elearning_grading_team_grading &&
      !permissionsData.elearning_grading_my_grading
    ) {
      setSelectedTab("team");
      localStorage.setItem("gradingToggelTabState", JSON.stringify("team"));
    } else if (
      !permissionsData.elearning_grading_team_grading &&
      !!permissionsData.elearning_grading_my_grading
    ) {
      setSelectedTab("my");
      localStorage.setItem("gradingToggelTabState", JSON.stringify("my"));
    } else if (
      !!permissionsData.elearning_grading_team_grading &&
      !!permissionsData.elearning_grading_my_grading
    ) {
      const storedState = localStorage.getItem("gradingToggelTabState");
      if (storedState) {
        const parsedState = JSON.parse(storedState);
        // Use parsedState to set the state
        setSelectedTab(parsedState);
      } else {
        setSelectedTab("my");
        localStorage.setItem("gradingToggelTabState", JSON.stringify("my"));
      }
    }
  }, [permissionsData]);

  // useEffect(() => {
  //   // Check if the user has permission for the "team" tab
  //   if (permissionsData.elearning_grading_team_grading) {
  //     // If the "team" tab is selected and the user still has permission, no change is needed
  //     if (selectedTab === "team") {
  //       return;
  //     }
  //     // If the "my" tab is selected but the user has permission for the "team" tab, switch to the "team" tab
  //     setSelectedTab("team");
  //   } else if (permissionsData.elearning_grading_my_grading) {
  //     // If the "my" tab is selected and the user still has permission, no change is needed
  //     if (selectedTab === "my") {
  //       return;
  //     }
  //     // If the "team" tab is selected but the user has permission for the "my" tab, switch to the "my" tab
  //     setSelectedTab("my");
  //   } else {
  //     // If the user has no permissions for either tab, you might want to handle this case differently,
  //     // such as showing an error message or redirecting the user.
  //     console.error("User has no permissions for either tab.");
  //   }
  // }, [permissionsData, selectedTab]);

  useEffect(() => {
    const storedState = localStorage.getItem("gradingToggelTabState");
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      // Use parsedState to set the state
      setSelectedTab(parsedState);
    }

    // fetchRoleData();
    // getMyLessonNames();
  }, [fetchRoleData]);

  const handleFetchMyGradingData = (page = 1) => {
    setMyGradingLoading(true);
    const truthyFilters = Object.entries(filters).reduce(
      (acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    let data = {
      page: page,
      limit: 50,
    };
    if (truthyFilters.lessonName) {
      data.lesson_id = truthyFilters.lessonName;
    }
    if (truthyFilters.videoStatus) {
      data.video_quiz_status = truthyFilters.videoStatus;
    }
    if (truthyFilters.lessonQuizStatus) {
      data.lesson_quiz_status = truthyFilters.lessonQuizStatus;
    }
    if (truthyFilters.managerStatus) {
      data.manager_quiz_status = truthyFilters.managerStatus;
    }
    progressServices
      .fetchData(data)
      .then((response) => {
        if (response.data.success === 1) {
          setMyGradingLoading(false);

          setMyGradingData((prevData) => [
            ...prevData,
            ...response.data.result,
          ]);
          setMyGradingCurrentPage(response.data.currentPage);
          setMyGradingTotalPages(response.data.totalPages);
        }
      })
      .catch((error) => {
        console.error("Error fetching team grading data:", error);
        setMyGradingLoading(false);
      });
  };

  const handleFetchTeamGradingData = (page = 1) => {
    let data = {
      page: page,
      limit: 50,
    };

    if (selectedLocationFilter) {
      data.lid = selectedLocationFilter;
    }
    if (selectedUserFilter) {
      data.uid = selectedUserFilter;
    }
    if (selectedCategory) {
      data.ecid = selectedCategory;
    }
    if (lesson_s) {
      data.lesson_id = lesson_s;
    }
    if (video_quiz_s) {
      data.video_quiz_status = video_quiz_s;
    }
    if (lesson_quiz_s) {
      data.lesson_quiz_status = lesson_quiz_s;
    }
    if (manager_quiz_s) {
      data.manager_quiz_status = manager_quiz_s;
    }
    if (selectedRole) {
      data.role_id = selectedRole;
    }

    fetchTeamGradingData(data);
  };

  useEffect(() => {
    setAccumulatedData([]);
    setMyGradingData([]);
    if (selectedTab === "team") {
      handleFetchTeamGradingData();
    } else if (selectedTab === "my") {
      handleFetchMyGradingData();
      getMyLessonNames();
    }
  }, [
    selectedTab,
    selectedLocationFilter,
    selectedUserFilter,
    selectedCategory,
    lesson_s,
    video_quiz_s,
    lesson_quiz_s,
    manager_quiz_s,
    selectedRole,
  ]);

  useEffect(() => {
    if (
      selectedTab === "team" &&
      !isTeamGradingLoading &&
      teamGradingPayload.result
    ) {
      setAccumulatedData((prevData) => [
        ...prevData,
        ...teamGradingPayload.result,
      ]);
      console.log("Setting up table Team Grading:", accumulatedData);
    }
  }, [teamGradingPayload, isTeamGradingLoading]);

  // const handleMyGradingPageChange = (e, value) => {
  //   setMyGradingPageNumber(value);
  //   const truthyFilters = Object.entries(filters).reduce(
  //     (acc, [key, value]) => {
  //       if (value) {
  //         acc[key] = value;
  //       }
  //       return acc;
  //     },
  //     {}
  //   );

  //   let data = {
  //     page: value,
  //     limit: 50,
  //   };
  //   if (truthyFilters.lessonName) {
  //     data.lesson_id = truthyFilters.lessonName;
  //   }
  //   if (truthyFilters.videoStatus) {
  //     data.video_quiz_status = truthyFilters.videoStatus;
  //   }
  //   if (truthyFilters.lessonQuizStatus) {
  //     data.lesson_quiz_status = truthyFilters.lessonQuizStatus;
  //   }
  //   if (truthyFilters.managerStatus) {
  //     data.manager_quiz_status = truthyFilters.managerStatus;
  //   }
  //   fetchData(data);
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  const loadMore = () => {
    if (selectedTab === "my") {
      const nextPage = myGradingCurrentPage + 1;
      if (nextPage <= myGradingTotalPages) {
        setMyGradingCurrentPage(nextPage);
        handleFetchMyGradingData(nextPage);
      }
    } else {
      const nextPage = teamGradingPayload.currentPage + 1;
      if (nextPage <= teamGradingPayload.totalPages) {
        handleFetchTeamGradingData(nextPage);
      }
    }
  };

  // My Grading Filter functions
  const handleChangeFilter = (filter, value) => {
    setMyGradingData([]);
    const newData = { ...filters, [filter]: value };

    let data = {
      page: 1,
      limit: 50,
    };

    if (newData.lessonName) {
      data.lesson_id = newData.lessonName;
    }
    if (newData.videoStatus) {
      data.video_quiz_status = newData.videoStatus;
    }
    if (newData.lessonQuizStatus) {
      data.lesson_quiz_status = newData.lessonQuizStatus;
    }
    if (newData.managerStatus) {
      data.manager_quiz_status = newData.managerStatus;
    }

    setFilters(newData);
    progressServices
      .fetchData(data)
      .then((response) => {
        if (response.data.success === 1) {
          setMyGradingLoading(false);
          setMyGradingData((prevData) => [
            ...prevData,
            ...response.data.result,
          ]);
          setMyGradingCurrentPage(response.data.currentPage);
          setMyGradingTotalPages(response.data.totalPages);
        }
      })
      .catch((error) => {
        console.error("Error fetching team grading data:", error);
        setMyGradingLoading(false);
      });
  };

  const handleSubmitVideo = (e, _id, ecid, lesson_result_id) => {
    history.push(`/elearning/grading/video/${_id}/${lesson_result_id}`);
  };

  const handleStoreUserDetails = (userName, role) => {
    setUserName(userName);
    setRole(role);
  };

  // const renderHeadRowsFilters = (data, showHideSettings) =>
  //   data.map((item, index) =>
  //     showHideSettings[item.heading.toLowerCase().replace(/\s/g, "_")] === 0 ? (
  //       ""
  //     ) : (
  //       <TableCell key={item.id} className={"headCell"}>
  //         <div>
  //           {selectedTab === "team" && item.heading === "Lesson Name"
  //             ? generateSearchSelectControl(
  //                 (event) => handleChangeDropDownLesson(event),
  //                 lesson_s,
  //                 [
  //                   { value: "", label: "All Lessons" },
  //                   ...lessonNamesData.map((item) => ({
  //                     value: item._id,
  //                     label: item?.lesson_name,
  //                   })),
  //                 ]
  //               )
  //             : selectedTab === "team" && item.heading === "Role"
  //             ? Array.isArray(roleDetails) &&
  //               generateFormControl(
  //                 (event) => handleChangeDropDownRole(event),
  //                 selectedRole,
  //                 [
  //                   { value: "", label: "All Role" },
  //                   ...roleDetails.map((item) => ({
  //                     value: item._id,
  //                     label: item?.role_name,
  //                   })),
  //                 ]
  //               )
  //             : selectedTab === "my" && item.heading === "Lesson Name"
  //             ? generateSearchSelectControl(
  //                 (event) => handleChangeFilter("lessonName", event.value),
  //                 filters["lessonName"],
  //                 [
  //                   { value: "", label: "All Lessons" },
  //                   ...myLessonNamesData.map((item) => ({
  //                     value: item._id,
  //                     label: item?.lesson_name,
  //                   })),
  //                 ]
  //               )
  //             : item.heading === "Lesson Quiz"
  //             ? generateFormControl(
  //                 (event) =>
  //                   selectedTab === "my"
  //                     ? handleChangeFilter(
  //                         "lessonQuizStatus",
  //                         event.target.value
  //                       )
  //                     : handleChangeDropDownLessonQuizStatus(event),
  //                 selectedTab === "my"
  //                   ? filters["lessonQuizStatus"]
  //                   : lesson_quiz_s,
  //                 [
  //                   { value: "", label: "Lesson Quiz" },
  //                   { value: "Passed", label: "Passed" },
  //                   { value: "Pending", label: "Pending" },
  //                 ]
  //               )
  //             : item.heading === "Manager Quiz"
  //             ? generateFormControl(
  //                 (event) =>
  //                   selectedTab === "my"
  //                     ? handleChangeFilter("managerStatus", event.target.value)
  //                     : handleChangeDropDownManagerStatus(event),
  //                 selectedTab === "my"
  //                   ? filters["managerStatus"]
  //                   : manager_quiz_s,
  //                 [
  //                   { value: "", label: "Manager Quiz" },
  //                   { value: "Reassigned", label: "Reassigned" },
  //                   { value: "Pass", label: "Pass" },
  //                   { value: "Pending", label: "Pending" },
  //                   { value: "Enabled", label: "Enabled" },
  //                   { value: "Disabled", label: "Disabled" },
  //                 ]
  //               )
  //             : item.heading === "Video Quiz" &&
  //               generateFormControl(
  //                 (event) =>
  //                   selectedTab === "my"
  //                     ? handleChangeFilter("videoStatus", event.target.value)
  //                     : handleChangeDropDownVideo1(event),
  //                 selectedTab === "my" ? filters["videoStatus"] : video_quiz_s,
  //                 [
  //                   { value: "", label: "Video Quiz" },
  //                   { value: "Pending", label: "Pending" },
  //                   { value: "Pass", label: "Pass" },
  //                   { value: "Reassigned", label: "Reassigned" },
  //                   { value: "Enabled", label: "Enabled" },
  //                   { value: "Disabled", label: "Disabled" },
  //                 ]
  //               )}
  //         </div>
  //       </TableCell>
  //     )
  //   );

  const renderHeadRows = (data, showHideSettings) =>
    data.map((item, index) =>
      showHideSettings[item.heading.toLowerCase().replace(/\s/g, "_")] === 0 ? (
        ""
      ) : showHideSettings[item.heading.toLowerCase().replace(/\s/g, "_")] ===
          undefined || null ? (
        <TableCell key={item.id} className={"headCell"}>
          {item.heading}
        </TableCell>
      ) : (
        <TableCell key={item.id} className={"headCell"}>
          {item.heading}
        </TableCell>
      )
    );

  const renderRows = (data) => {
    return data?.map((row, index) => (
      <TableRow key={row.id}>
        <TableCell className={"bodyCell"} component="th" scope="row">
          {row.lesson_name.length > 20
            ? `${row.lesson_name.slice(0, 20)}...`
            : row.lesson_name}
        </TableCell>
        {selectedTab === "team" ? (
          <TableCell className={"bodyCell"}>{row?.user?.user_name}</TableCell>
        ) : (
          ""
        )}

        {selectedTab === "team" ? (
          <TableCell className={"bodyCell"}>
            {row?.user_role?.role_name}
          </TableCell>
        ) : (
          ""
        )}

        {showHideSettings.lesson_quiz ? (
          <TableCell className={"bodyCell"}>{row.lesson_quiz_status}</TableCell>
        ) : (
          ""
        )}

        {showHideSettings.manager_quiz ? (
          <TableCell className={"bodyCell"}>
            {row.manager_quiz_status === "Pending" ||
            row.manager_quiz_status === "Reassigned" ? (
              row.lesson_result && selectedTab === "team" ? (
                <GradientButton
                  variant="contained"
                  value="Manager"
                  onClick={(e) => {
                    handleManagerQuiz(
                      e,
                      row._id,
                      row.ecid,
                      row.lesson_result._id,
                      row?.user?.user_name,
                      row?.user_role?.role_name
                    );
                    handleStoreUserDetails(
                      row?.user?.user_name,
                      row?.user_role?.role_name
                    );
                  }}
                >
                  Grade
                </GradientButton>
              ) : (
                row.manager_quiz_status
              )
            ) : (
              row.manager_quiz_status
            )}
          </TableCell>
        ) : (
          ""
        )}

        {showHideSettings.video_quiz ? (
          <TableCell className={"bodyCell"}>
            {row.video_quiz_status === "Pending" ||
            row.video_quiz_status === "Reassigned" ||
            row.video_quiz_status === "Enabled" ? (
              row.lesson_result &&
              row.lesson_result.video_url &&
              selectedTab === "team" ? (
                <GradientButton
                  variant="contained"
                  value="Video"
                  onClick={(e) => {
                    handleVideoQuiz(
                      e,
                      row.user._id,
                      row._id,
                      row.ecid,
                      row.lesson_result._id,
                      row.lesson_result.video_url
                    );
                    handleStoreUserDetails(
                      row?.user?.user_name,
                      row?.user_role?.role_name
                    );
                  }}
                >
                  Grade
                </GradientButton>
              ) : row.lesson_result &&
                !row.lesson_result.video_url &&
                selectedTab === "my" ? (
                <GradientButton
                  variant="contained"
                  value="Video"
                  onClick={(e) => {
                    handleSubmitVideo(
                      e,
                      row._id,
                      row.ecid,
                      row.lesson_result._id
                    );
                    handleStoreUserDetails(
                      row?.user?.user_name,
                      row?.user_role?.role_name
                    );
                  }}
                >
                  Record
                </GradientButton>
              ) : (
                row.video_quiz_status
              )
            ) : (
              row.video_quiz_status
            )}
          </TableCell>
        ) : (
          ""
        )}

        <TableCell className={"bodyCell"}>
          {row.overall_status === "Pending" ? (
            <PendingButton variant="contained" disableElevation>
              Pending
            </PendingButton>
          ) : row.overall_status === "Pass" ? (
            <PassButton variant="contained" disableElevation>
              Pass
            </PassButton>
          ) : (
            <FailButton variant="contained" disableElevation>
              Fail
            </FailButton>
          )}
        </TableCell>

        <TableCell className={"bodyCell"}>
          <GradientButton
            variant="contained"
            onClick={(e) => handleView(e, row.ecid, row._id)}
          >
            View
          </GradientButton>
        </TableCell>
      </TableRow>
    ));
  };

  return permissionsData.elearning_grading_team_grading ||
    permissionsData.elearning_grading_my_grading ? (
    <div className={"GradingListRoot"}>
      <div className={"toggelContainer"}>
        <Toggel
          options={[
            !!permissionsData.elearning_grading_my_grading && {
              id: 1,
              heading: "my",
              value: "my",
            },
            !!permissionsData.elearning_grading_team_grading && {
              id: 2,
              heading: "team",
              value: "team",
            },
          ].filter((tab) => tab)}
          tabSize={{ width: "65px", height: "30px" }}
          selected={selectedTab}
          selectedBgColor={mobileTheme}
          size={{
            width:
              !!permissionsData.elearning_grading_my_grading &&
              !!permissionsData.elearning_grading_team_grading
                ? "130px"
                : "65px",
            height: "34px",
          }}
          bgColor={"white"}
          border={"1px solid rgba(27, 63, 143,1)"}
          borderImageSource={
            "linear-gradient(180deg, #1B3F8F 0%, #635BFF 100%)"
          }
          handleTabChange={handleTabChange}
        />
      </div>
      {/* <Box
        display={"flex"}
        justifyContent={"space-evenly"}
        // mt={"3rem"}
        mx={"1rem"}
        mb={"1.5rem"}
      >
        <Box display={"flex"}>
          <Box
            style={{
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
            }}
            display={"flex"}
            justifyContent={"center"}
            border={"1px solid #1B3F8F1A"}
            padding={"2px"}
            backgroundColor={"white"}
          >
            <Box>
              <FormControlLabel
                alignItems={"center"}
                // mb={"0rem"}
                style={{
                  // marginLeft: "0.5px",
                  marginBottom: "0rem",
                }}
                control={
                  <Checkbox
                    name={"lesson_quiz"}
                    sx={{
                      // padding: "6px",
                      marginLeft: "2px",
                      "&.Mui-checked": {
                        color: "#1B3F8F",
                      },
                      "& .MuiSvgIcon-root": { fontSize: 15 },
                    }}
                    onClick={handleChangeCheckbox}
                    checked={showHideSettings.lesson_quiz === 1}
                  />
                }
                label={
                  <Typography className={"checkBoxText"}>
                    Lesson Quiz
                  </Typography>
                }
              />{" "}
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            border={"1px solid #1B3F8F1A"}
            padding={"2px"}
            borderLeft={"none"}
            backgroundColor={"white"}
          >
            <FormControlLabel
              alignItems={"center"}
              style={{
                // marginLeft: "0.5px",
                marginBottom: "0rem",
              }}
              control={
                <Checkbox
                  name={"video_quiz"}
                  sx={{
                    // padding: "6px",
                    marginLeft: "2px",
                    "&.Mui-checked": {
                      color: "#1B3F8F",
                    },
                    "& .MuiSvgIcon-root": { fontSize: 15 },
                  }}
                  onClick={handleChangeCheckbox}
                  checked={showHideSettings.video_quiz === 1}
                />
              }
              label={
                <Typography className={"checkBoxText"}>Video Quiz</Typography>
              }
            />{" "}
          </Box>
          <Box
            style={{
              borderTopRightRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
            display={"flex"}
            justifyContent={"center"}
            border={"1px solid #1B3F8F1A"}
            padding={"2px"}
            borderLeft={"none"}
            backgroundColor={"white"}
          >
            <FormControlLabel
              alignItems={"center"}
              style={{
                // marginLeft: "0.5px",
                marginBottom: "0rem",
              }}
              control={
                <Checkbox
                  name={"manager_quiz"}
                  sx={{
                    // padding: "6px",
                    marginLeft: "2px",
                    "&.Mui-checked": {
                      color: "#1B3F8F",
                    },
                    "& .MuiSvgIcon-root": { fontSize: 15 },
                  }}
                  onClick={handleChangeCheckbox}
                  checked={showHideSettings.manager_quiz === 1}
                />
              }
              label={
                <Typography className={"checkBoxText"}>Manager Quiz</Typography>
              }
            />{" "}
          </Box>
        </Box>
      </Box> */}
      <div className="dropdownsContainer">
        {selectedTab === "team" && (
          // !isRolesLoading &&
          <>
            {Array.isArray(roleDetails) &&
              generateSearchSelectControl(
                (event) => handleChangeDropDownRole(event),
                selectedRole,
                [
                  { value: "", label: "All Role" },
                  ...roleDetails.map((item) => ({
                    value: item._id,
                    label: item?.role_name,
                  })),
                ]
              )}
            {Array.isArray(categoryData) &&
              generateSearchSelectControl(
                (event) => handleChangeCategory(event),
                selectedCategory,
                [
                  { value: "", label: "All Categories" },
                  ...categoryData.map((item) => ({
                    value: item._id,
                    label: item?.category_name,
                  })),
                ]
              )}
            {Array.isArray(locationsData) &&
              generateSearchSelectControl(
                (event) => handleChangeLocation(event),
                selectedLocationFilter,
                [
                  { value: "", label: "All Locations" },
                  ...locationsData.map((item) => ({
                    value: item._id,
                    label: item?.name,
                  })),
                ]
              )}
            {Array.isArray(usersData) &&
              generateSearchSelectControl(
                (event) => handleChangeUser(event),
                selectedUserFilter,
                [
                  { value: "", label: "All Users" },
                  ...usersData.map((item) => ({
                    value: item._id,
                    label: item?.user_name,
                  })),
                ]
              )}
          </>
        )}

        {selectedTab === "my"
          ? generateSearchSelectControl(
              (event) => handleChangeFilter("lessonName", event.value),
              filters["lessonName"],
              [
                { value: "", label: "All Lessons" },
                ...myLessonNamesData.map((item) => ({
                  value: item._id,
                  label: item?.lesson_name,
                })),
              ]
            )
          : generateSearchSelectControl(
              (event) => handleChangeDropDownLesson(event),
              lesson_s,
              [
                { value: "", label: "All Lessons" },
                ...lessonNamesData.map((item) => ({
                  value: item._id,
                  label: item?.lesson_name,
                })),
              ]
            )}
        {generateSearchSelectControl(
          (event) =>
            selectedTab === "my"
              ? handleChangeFilter("lessonQuizStatus", event.value)
              : handleChangeDropDownLessonQuizStatus(event),
          selectedTab === "my" ? filters["lessonQuizStatus"] : lesson_quiz_s,
          [
            { value: "", label: "Lesson Quiz" },
            { value: "Passed", label: "Passed" },
            { value: "Pending", label: "Pending" },
          ]
        )}
        {generateSearchSelectControl(
          (event) =>
            selectedTab === "my"
              ? handleChangeFilter("managerStatus", event.value)
              : handleChangeDropDownManagerStatus(event),
          selectedTab === "my" ? filters["managerStatus"] : manager_quiz_s,
          [
            { value: "", label: "Manager Quiz" },
            { value: "Reassigned", label: "Reassigned" },
            { value: "Pass", label: "Pass" },
            { value: "Pending", label: "Pending" },
            { value: "Enabled", label: "Enabled" },
            { value: "Disabled", label: "Disabled" },
          ]
        )}
        {generateSearchSelectControl(
          (event) =>
            selectedTab === "my"
              ? handleChangeFilter("videoStatus", event.value)
              : handleChangeDropDownVideo1(event),
          selectedTab === "my" ? filters["videoStatus"] : video_quiz_s,
          [
            { value: "", label: "Video Quiz" },
            { value: "Pending", label: "Pending" },
            { value: "Pass", label: "Pass" },
            { value: "Reassigned", label: "Reassigned" },
            { value: "Enabled", label: "Enabled" },
            { value: "Disabled", label: "Disabled" },
          ]
        )}
      </div>

      <Box
        ml={"20px"}
        sx={{
          display: "flex",
          alignItems: "start",
          flexDirection: "column",
          justifyContent: "start",
        }}
      >
        <Typography fontSize={"12px"} sx={{ fontSize: "10px" }}>
          Pending Manager Quiz: {getQuizCountData?.pendingManagerCount || 0}
        </Typography>
        <Typography fontSize={"12px"} sx={{ fontSize: "10px" }}>
          Pending Video Quiz: {getQuizCountData?.pendingVideoCount || 0}
        </Typography>
      </Box>

      <TableContainer className={`scroll tableContainer`}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {selectedTab === "my"
                ? permissionsData?.elearning_grading_my_grading
                  ? renderHeadRows(headMyGradingElements, showHideSettings)
                  : ""
                : permissionsData?.elearning_grading_team_grading
                ? renderHeadRows(headTeamGradingElements, showHideSettings)
                : ""}
            </TableRow>
          </TableHead>
          {/* <TableHead>
            <TableRow>
              {selectedTab === "my"
                ? permissionsData?.elearning_grading_my_grading
                  ? renderHeadRowsFilters(
                      headMyGradingElements,
                      showHideSettings
                    )
                  : ""
                : permissionsData?.elearning_grading_team_grading
                ? renderHeadRowsFilters(
                    headTeamGradingElements,
                    showHideSettings
                  )
                : ""}
            </TableRow>
          </TableHead> */}
          <TableBody className={"tableBodyContainer"}>
            {selectedTab === "my" ? (
              permissionsData?.elearning_grading_my_grading ? (
                myGradingData && renderRows(myGradingData)
              ) : (
                <NoPermission />
              )
            ) : permissionsData?.elearning_grading_team_grading ? (
              accumulatedData && renderRows(accumulatedData)
            ) : (
              <NoPermission />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {(selectedTab === "my" && myGradingLoading) ||
      (selectedTab === "team" && isTeamGradingLoading) ? (
        <div className={"loadContainer"}>
          <MobileLoader />
        </div>
      ) : null}
      {((selectedTab === "my" &&
        !myGradingLoading &&
        (!myGradingData || myGradingData.length === 0)) ||
        (selectedTab === "team" &&
          !isTeamGradingLoading &&
          (!teamGradingPayload ||
            !teamGradingPayload.result ||
            teamGradingPayload.result.length === 0))) && (
        <Typography className="noRecordText">No record</Typography>
      )}
      <div className={"paginationContainer"}>
        {/* <Stack spacing={2}>
          <Pagination
            size="small"
            color="primary"
            count={
              selectedTab === "my"
                ? myGradingTotalPages
                : teamGradingPayload.totalPages
            }
            variant="outlined"
            page={
              selectedTab === "my" ? myGradingPageNumber : teamGradingPageNumber
            }
            onChange={
              selectedTab === "my"
                ? handleMyGradingPageChange
                : handleTeamPageChange
            }
          />
        </Stack> */}
      </div>
      {selectedTab === "my"
        ? myGradingCurrentPage < myGradingTotalPages && (
            <LoadMoreMobile handlePageChange={loadMore} />
          )
        : selectedTab === "team" &&
          teamGradingPayload?.currentPage < teamGradingPayload?.totalPages && (
            <LoadMoreMobile handlePageChange={loadMore} />
          )}
    </div>
  ) : (
    <NoPermission />
  );
};

const mapStateToProps = (state) => ({
  teamGradingPayload: state.eLearningGrading.teamGradingPayload,
  isTeamGradingLoading: state.eLearningGrading.isTeamGradingLoading,
  myLessonNamesData: state.elearningProgress.myLessonNamesData,
  isMyLessonNamesDataLoading:
    state.elearningProgress.isMyLessonNamesDataLoading,
  permissionsData: state.settingsRoles.permissionsData,
});

const ConnectWith = connect(mapStateToProps, {
  fetchRoleData,
  fetchData,
  fetchTeamGradingData,
  getMyLessonNames,
})(MobileGrading);
export default React.memo(ConnectWith);
