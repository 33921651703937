import { makeStyles } from "@material-ui/styles";
import { eLearningColor, ovHrColor } from "../../../config";

const useStyles = makeStyles((theme) => ({
  moreResultsDiv: {
    position: "relative",
  },
  moreResultsLine: {
    backgroundColor: "#dadce0",
    border: "0",
    height: "1px",
    left: "0",
    marginTop: "18px",
    position: "absolute",
    width: "100%",
  },
  moreResultsBtn: {
    marginTop: "20px",
    color: "white",
    border: "1px solid rgba(255, 255, 255, 0)",
    boxSizing: "borderBox",
    cursor: "pointer",
    fontSize: "14px",
    lineHeight: "20px",
    pointerEvents: "auto",
    position: "relative",
    width: "300px",
    padding: "7px 11px",
    margin: "0",
    flexDirection: "rowReverse",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    height: "36px",
    borderRadius: "18px",
  },
}));

export const LoadMore = ({ product, handlePageChange }) => {
  var classes = useStyles();

  return (
    <div className={classes.moreResultsDiv}>
      <hr className={classes.moreResultsLine} />
      <button
      className={classes.moreResultsBtn}
      style={{ 
        background: product == "hr" ? ovHrColor.main : eLearningColor.main
       }}
      onClick={handlePageChange}
      >
        More Results
      </button>
    </div>
  );
};
