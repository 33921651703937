import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { addOrEditRolesStyles } from "../Styles";
import {
  scheduler_Schedule_Data,
  scheduler_MyAvailability_Data,
  scheduler_TeamAvailability_Data,
  scheduler_TimeOffRequests_Data,
  scheduler_TradeShifts_Data,
  scheduler_CoverShifts_Data,
  scheduler_payroll_run_Data,
  scheduler_correction_request_Data,
  scheduler_Timesheet_Data,
  scheduler_TimecardReport_Data,
  scheduler_Payroll_Data,
  scheduler_Payroll_Summary_Data,
  scheduler_Roles_Data,
} from "../constants";

const SchedulerContent = ({ onPermissionsUpdate, checkboxesData }) => {
  const styles = addOrEditRolesStyles();

  const initializeAccessRights = () => ({
    scheduler_dashboard: checkboxesData.scheduler_dashboard === 1,
    scheduler_settings: checkboxesData.scheduler_settings === 1,
    receive_manager_alert: checkboxesData.receive_manager_alert === 1,
    scheduler_Schedule_Data: scheduler_Schedule_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    scheduler_MyAvailability_Data: scheduler_MyAvailability_Data.map(
      (item) => ({
        ...item,
        checked: checkboxesData[item.Name] === 1,
      })
    ),
    scheduler_TeamAvailability_Data: scheduler_TeamAvailability_Data.map(
      (item) => ({
        ...item,
        checked: checkboxesData[item.Name] === 1,
      })
    ),
    scheduler_TimeOffRequests_Data: scheduler_TimeOffRequests_Data.map(
      (item) => ({
        ...item,
        checked: checkboxesData[item.Name] === 1,
      })
    ),
    scheduler_TradeShifts_Data: scheduler_TradeShifts_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    scheduler_CoverShifts_Data: scheduler_CoverShifts_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    scheduler_payroll_run_Data: scheduler_payroll_run_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    scheduler_correction_request_Data: scheduler_correction_request_Data.map(
      (item) => ({
        ...item,
        checked: checkboxesData[item.Name] === 1,
      })
    ),
    scheduler_Timesheet_Data: scheduler_Timesheet_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    scheduler_TimecardReport_Data: scheduler_TimecardReport_Data.map(
      (item) => ({
        ...item,
        checked: checkboxesData[item.Name] === 1,
      })
    ),
    scheduler_Payroll_Data: scheduler_Payroll_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    scheduler_Payroll_Summary_Data: scheduler_Payroll_Summary_Data.map(
      (item) => ({
        ...item,
        checked: checkboxesData[item.Name] === 1,
      })
    ),
    scheduler_Roles_Data: scheduler_Roles_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
  });

  const [accessRights, setAccessRights] = useState(initializeAccessRights());

  useEffect(() => {
    setAccessRights(initializeAccessRights());
  }, [checkboxesData]);

  const handleCheckboxChange = (group, index = null) => {
    setAccessRights((prevState) => {
      if (Array.isArray(prevState[group])) {
        let updatedGroup;
        if (index !== null) {
          updatedGroup = [...prevState[group]];
          updatedGroup[index].checked = !updatedGroup[index].checked;
        } else {
          const allChecked = prevState[group].every((item) => item.checked);
          updatedGroup = prevState[group].map((item) => ({
            ...item,
            checked: !allChecked,
          }));
        }

        const newState = { ...prevState, [group]: updatedGroup };
        onPermissionsUpdate(getPermissionsValues(newState));
        return newState;
      } else {
        const newState = { ...prevState, [group]: !prevState[group] };
        onPermissionsUpdate(getPermissionsValues(newState));
        return newState;
      }
    });
  };

  const getPermissionsValues = (state) => {
    const permissions = {};
    Object.entries(state).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          permissions[item.Name] = item.checked ? 1 : 0;
        });
      } else {
        permissions[key] = value ? 1 : 0;
      }
    });
    return permissions;
  };

  const renderAccessRightPanel = (title, groupKey, data = []) => (
    <ExpansionPanel className={styles.hrExpansionPanel}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        className={styles.hrExpansionPanelSummaryThird}
      >
        <Checkbox
          color="primary"
          checked={data.every((item) => item.checked)}
          indeterminate={
            data.some((item) => item.checked) &&
            !data.every((item) => item.checked)
          }
          onChange={() => handleCheckboxChange(groupKey)}
          className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
        />

        <Typography className={styles.hrPageTitle} variant="h6" color="primary">
          {title}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={styles.hrExpansionPanelDetailsThird}>
        <Grid container spacing={2}>
          {data.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={item.Name}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={item.checked}
                    className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
                    onChange={() => handleCheckboxChange(groupKey, index)}
                  />
                }
                label={item.Label}
              />
            </Grid>
          ))}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );

  return (
    <Grid item md={12} xs={12}>
      <ExpansionPanel className={styles.hrExpansionPanelMain}>
        <ExpansionPanelSummary
          className={styles.hrExpansionPanelSummary}
          expandIcon={<ExpandMoreIcon className={styles.hrHeadingIcon} />}
          aria-controls="scheduler-content"
          id="scheduler-header"
        >
          <Typography className={styles.hrHeading}>Scheduler</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={styles.hrExpansionPanelDetailsSecond}>
          <Grid container>
            <Grid item md={12} xs={12}>
              <ExpansionPanel className={styles.hrExpansionPanel}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={styles.hrExpansionPanelSummaryThird}
                >
                  <Checkbox
                    color="primary"
                    checked={accessRights.scheduler_dashboard}
                    onChange={() => handleCheckboxChange("scheduler_dashboard")}
                    className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
                  />
                  <Typography
                    className={styles.hrPageTitle}
                    variant="h6"
                    color="primary"
                  >
                    Scheduler Dashboard
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                  className={styles.hrExpansionPanelDetailsThird}
                ></ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>

            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Schedule",
                "scheduler_Schedule_Data",
                accessRights.scheduler_Schedule_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "My Availability",
                "scheduler_MyAvailability_Data",
                accessRights.scheduler_MyAvailability_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Team Availability",
                "scheduler_TeamAvailability_Data",
                accessRights.scheduler_TeamAvailability_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Time-Off Requests",
                "scheduler_TimeOffRequests_Data",
                accessRights.scheduler_TimeOffRequests_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Trade Shifts",
                "scheduler_TradeShifts_Data",
                accessRights.scheduler_TradeShifts_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Cover Shifts",
                "scheduler_CoverShifts_Data",
                accessRights.scheduler_CoverShifts_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Payroll Run",
                "scheduler_payroll_run_Data",
                accessRights.scheduler_payroll_run_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Correction Request",
                "scheduler_correction_request_Data",
                accessRights.scheduler_correction_request_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Timesheet",
                "scheduler_Timesheet_Data",
                accessRights.scheduler_Timesheet_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Timecard Report",
                "scheduler_TimecardReport_Data",
                accessRights.scheduler_TimecardReport_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Payroll",
                "scheduler_Payroll_Data",
                accessRights.scheduler_Payroll_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Payroll Summary",
                "scheduler_Payroll_Summary_Data",
                accessRights.scheduler_Payroll_Summary_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Roles",
                "scheduler_Roles_Data",
                accessRights.scheduler_Roles_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              <ExpansionPanel className={styles.hrExpansionPanel}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={styles.hrExpansionPanelSummaryThird}
                >
                  <Checkbox
                    color="primary"
                    checked={accessRights.receive_manager_alert}
                    onChange={() =>
                      handleCheckboxChange("receive_manager_alert")
                    }
                    className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
                  />
                  <Typography
                    className={styles.hrPageTitle}
                    variant="h6"
                    color="primary"
                  >
                    Receive Manager Alerts
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                  className={styles.hrExpansionPanelDetailsThird}
                ></ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            <Grid item md={12} xs={12}>
              <ExpansionPanel className={styles.hrExpansionPanel}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={styles.hrExpansionPanelSummaryThird}
                >
                  <Checkbox
                    color="primary"
                    checked={accessRights.scheduler_settings}
                    onChange={() => handleCheckboxChange("scheduler_settings")}
                    className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
                  />
                  <Typography
                    className={styles.hrPageTitle}
                    variant="h6"
                    color="primary"
                  >
                    Scheduler Settings
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                  className={styles.hrExpansionPanelDetailsThird}
                ></ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  );
};

export default SchedulerContent;
