import {
  SET_USERS_LOADING,
  SET_USERS,
  ADD_USERS,
  EDIT_USERS,
  DELETE_USERS,
  LOGIN_USER,
  LOGOUT_USER,
  LOGIN_AS_USER,
  SET_COUNT,
  SET_USERS_NOTIFICATIONS,
  SET_USERS_NOTIFICATIONS_LOADING,
  TOGGLE_VERIFY_USERS,
  USER_RESEND_INVITE,
} from "./constants";
import { alert, invalidToken } from "../../../../utilities";
import { usersServices } from "./services";
import { COOKIE_MAX_AGE } from "../../../../config";
import Cookies from "universal-cookie";
// import { history } from "../../../../history";
const cookies = new Cookies();

export function fetchCountData(lid) {
  return function (dispatch) {
    if (lid) {
      usersServices.fetchCountData(lid).then((response) => {
        if (response.data.success === 2) {
          invalidToken(response.data.message);
        } else {
          dispatch({
            type: SET_COUNT,
            payload: response.data,
          });
        }
      });
    }
  };
}

export function getNotifications(isReload) {
  return function (dispatch) {
    dispatch({
      type: SET_USERS_NOTIFICATIONS_LOADING,
      payload: true,
    });
    usersServices.getNotifications().then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: SET_USERS_NOTIFICATIONS,
          payload: response.data,
        });
      }
    });
  };
}

export function toggleActiveDeactiveUser(data) {
  return function (dispatch) {
    usersServices.toggleActiveDeactiveUser(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        alert.success(response.data.message);
        dispatch({
          type: TOGGLE_VERIFY_USERS,
          payload: data,
        });
      }
    });
  };
}

export function fetchData(page) {
  return function (dispatch) {
    dispatch({
      type: SET_USERS_LOADING,
      payload: true,
    });

    usersServices.fetchData(page).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        dispatch({
          type: SET_USERS,
          payload: response.data,
        });
      }
    });
  };
}

export function deleteData(uid) {
  return function (dispatch) {
    usersServices.deleteData(uid).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        alert.success(response.data.message);
        dispatch({
          type: DELETE_USERS,
          payload: uid,
        });
      }
    });
  };
}

export function addData(data) {
  return function (dispatch) {
    dispatch({
      type: SET_USERS_LOADING,
      payload: true,
    });

    usersServices.addData(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else if (response.data.success === 0) {
        alert.error("Email address already exist");
      } else {
        alert.success(response.data.message);
        dispatch({
          type: ADD_USERS,
          payload: response.data.result,
        });
      }
    });
  };
}

export function userLogin(data) {
  return function (dispatch) {
    dispatch({
      type: SET_USERS_LOADING,
      payload: false,
    });
    usersServices.userLogin(data).then((response) => {
      if (response.data.success) {
        if (
          (response.data.user.profile_step === 5 &&
            response.data.subscriptionStatus === "ACTIVE") ||
          response.data.user.user_role === "624e84cc2049fe2553b878b0"
        ) {
          alert.success(response.data.message);
          cookies.set("ovCid", response.data.user.cid, {
            path: "/",
            maxAge: COOKIE_MAX_AGE,
          });
          cookies.set("ovUid", response.data.user._id, {
            path: "/",
            maxAge: COOKIE_MAX_AGE,
          });
          cookies.set("ovToken", response.data.user.token, {
            path: "/",
            maxAge: COOKIE_MAX_AGE,
          });
          cookies.set("ovUserName", response.data.user.user_name, {
            path: "/",
            maxAge: COOKIE_MAX_AGE,
          });
          cookies.set("ovUserImage", response.data.user.user_image, {
            path: "/",
            maxAge: COOKIE_MAX_AGE,
          });
          dispatch({
            type: LOGIN_USER,
            payload: response.data.user,
          });
          window.location.href = "/dashboard";
          return;
        }
        alert.error("Complete all billing steps to gain access");

        if (response.data.subscriptionId) {
          window.location.href = `${process.env.REACT_APP_PORTFOLIO_BASE_URL}/signup?subscription=${response.data.subscriptionId}`;
        }
      } else {
        alert.error(response.data.message);
        dispatch({
          type: SET_USERS_LOADING,
          payload: true,
        });
      }
    });
  };
}

export function userGoogleLogin(data) {
  return function (dispatch) {
    dispatch({
      type: SET_USERS_LOADING,
      payload: false,
    });
    usersServices.userGoogleLogin(data).then((response) => {
      if (response.data.success) {
        if (
          response.data.user.profile_step === 5 &&
          response.data.subscriptionStatus === "ACTIVE"
        ) {
          alert.success(response.data.message);
          cookies.set("ovCid", response.data.user.cid, {
            path: "/",
            maxAge: COOKIE_MAX_AGE,
          });
          cookies.set("ovUid", response.data.user._id, {
            path: "/",
            maxAge: COOKIE_MAX_AGE,
          });
          cookies.set("ovToken", response.data.token, {
            path: "/",
            maxAge: COOKIE_MAX_AGE,
          });
          cookies.set("ovUserName", response.data.user.user_name, {
            path: "/",
            maxAge: COOKIE_MAX_AGE,
          });
          cookies.set("ovUserImage", response.data.user.user_image, {
            path: "/",
            maxAge: COOKIE_MAX_AGE,
          });

          dispatch({
            type: LOGIN_USER,
            payload: response.data.user,
          });
          window.location.href = "/dashboard";
          return;
        }
        alert.error("Complete all billing steps to gain access");

        if (response.data.subscriptionId) {
          window.location.href = `${process.env.REACT_APP_PORTFOLIO_BASE_URL}/signup?subscription=${response.data.subscriptionId}`;
        }
      } else {
        alert.error(response.data.message);
        dispatch({
          type: SET_USERS_LOADING,
          payload: true,
        });
      }
    });
  };
}

export function resendInvitation(data) {
  return function (dispatch) {
    usersServices.resendInvitation(data).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        alert.success(response.data.message);
        dispatch({
          type: USER_RESEND_INVITE,
          payload: response.data.post,
        });
      }
    });
  };
}

export function loginAsUser(data) {
  return function (dispatch) {
    usersServices.loginAsUser(data).then((response) => {
      if (response.data.success) {
        alert.success(response.data.message);
        var ovUid = cookies.get("ovUid");
        var ovCid = cookies.get("ovCid");
        var ovToken = cookies.get("ovToken");
        var ovUserName = cookies.get("ovUserName");
        var ovUserImage = cookies.get("ovUserImage");
        cookies.remove("scheduler_lid");
        cookies.set("temp_ovCid", ovCid, { path: "/", maxAge: COOKIE_MAX_AGE });
        cookies.set("temp_ovUid", ovUid, { path: "/", maxAge: COOKIE_MAX_AGE });
        cookies.set("temp_ovToken", ovToken, {
          path: "/",
          maxAge: COOKIE_MAX_AGE,
        });
        cookies.set("temp_ovUserName", ovUserName, {
          path: "/",
          maxAge: COOKIE_MAX_AGE,
        });
        cookies.set("temp_ovUserImage", ovUserImage, {
          path: "/",
          maxAge: COOKIE_MAX_AGE,
        });
        cookies.set("ovCid", response.data.user.cid, {
          path: "/",
          maxAge: COOKIE_MAX_AGE,
        });
        cookies.set("ovUid", response.data.user.uid, {
          path: "/",
          maxAge: COOKIE_MAX_AGE,
        });
        cookies.set("ovToken", response.data.user.token, {
          path: "/",
          maxAge: COOKIE_MAX_AGE,
        });
        cookies.set("ovUserName", response.data.user.user_name, {
          path: "/",
          maxAge: COOKIE_MAX_AGE,
        });
        cookies.set("ovUserImage", response.data.user.user_image, {
          path: "/",
          maxAge: COOKIE_MAX_AGE,
        });
        dispatch({
          type: LOGIN_AS_USER,
          payload: response.data.user,
        });
        window.location.href = "/dashboard";
      } else {
        alert.error(response.data.message);
      }
    });
  };
}

export function userExitAccess() {
  return function (dispatch) {
    usersServices.userLogout().then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else if (response.data.success === 1) {
        alert.success(response.data.message);
        var ovCid = cookies.get("temp_ovCid");
        var ovUid = cookies.get("temp_ovUid");
        var ovToken = cookies.get("temp_ovToken");
        var ovUserName = cookies.get("temp_ovUserName");
        var ovUserImage = cookies.get("temp_ovUserImage");
        cookies.set("ovCid", ovCid, { path: "/", maxAge: COOKIE_MAX_AGE });
        cookies.set("ovUid", ovUid, { path: "/", maxAge: COOKIE_MAX_AGE });
        cookies.set("ovToken", ovToken, { path: "/", maxAge: COOKIE_MAX_AGE });
        cookies.set("ovUserName", ovUserName, {
          path: "/",
          maxAge: COOKIE_MAX_AGE,
        });
        cookies.set("ovUserImage", ovUserImage, {
          path: "/",
          maxAge: COOKIE_MAX_AGE,
        });
        cookies.remove("temp_ovCid");
        cookies.remove("temp_ovUid");
        cookies.remove("temp_ovToken");
        cookies.remove("temp_ovUserName");
        cookies.remove("temp_ovUserImage");
        cookies.remove("scheduler_lid");
        window.location.href = "/";
      } else {
        alert.error(response.data.message);
      }
    });
  };
}

export function userLogout() {
  return function (dispatch) {
    dispatch({
      type: SET_USERS_LOADING,
      payload: true,
    });

    usersServices.userLogout().then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else if (response.data.success === 1) {
        alert.success(response.data.message);
        dispatch({
          type: LOGOUT_USER,
          payload: true,
        });
        cookies.remove("ovCid");
        cookies.remove("ovUid");
        cookies.remove("ovToken");
        cookies.remove("ovUserName");
        cookies.remove("ovUserImage");
        cookies.remove("temp_ovCid");
        cookies.remove("temp_ovUid");
        cookies.remove("temp_ovToken");
        cookies.remove("temp_ovUserName");
        cookies.remove("temp_ovUserImage");
        cookies.remove("scheduler_lid");
        window.location.href = "/";
      } else {
        alert.error(response.data.message);
        dispatch({
          type: LOGOUT_USER,
          payload: true,
        });
      }
    });
  };
}

export function editData(data, _id) {
  return function (dispatch) {
    usersServices.editData(data, _id).then((response) => {
      if (response.data.success === 2) {
        invalidToken(response.data.message);
      } else {
        alert.success(response.data.message);
        dispatch({
          type: EDIT_USERS,
          payload: response.data.post,
        });
        window.location.href = "/hr/users";
      }
    });
  };
}
