// PayrollCard.js
import React from "react";
import { Typography } from "@mui/material";
import { BsFillPencilFill } from "react-icons/bs";
import "./PayrollCard.css"; // Import the CSS

const PayrollCard = ({ dateRange, cpp, federalTax, ei, incomeTax }) => {
  return (
    <div className="payroll-card-container">
      <div className="payroll-card-edit-container">
        <BsFillPencilFill className="payroll-card-edit-icon" />
      </div>

      <div className="payroll-card-title">{dateRange}</div>
      <div className="payroll-card-des-row">
        <div className="payroll-card-des-mini-row">
          <Typography className="payroll-card-des-bold">CPP:</Typography>
          <Typography className="payroll-card-des-normal">{cpp}</Typography>
        </div>
        <div className="payroll-card-des-mini-row">
          <Typography className="payroll-card-des-bold">Federal Tax:</Typography>
          <Typography className="payroll-card-des-normal">{federalTax}</Typography>
        </div>
      </div>
      <div className="payroll-card-des-row">
        <div className="payroll-card-des-mini-row">
          <Typography className="payroll-card-des-bold">EI:</Typography>
          <Typography className="payroll-card-des-normal">{ei}</Typography>
        </div>
        <div className="payroll-card-des-mini-row">
          <Typography className="payroll-card-des-bold">Income Tax:</Typography>
          <Typography className="payroll-card-des-normal">{incomeTax}</Typography>
        </div>
      </div>
    </div>
  );
};

export default PayrollCard;
