import { makeStyles } from "@material-ui/styles";
import { eLearningColor } from "../../../config";

const useStyles = makeStyles((theme) => ({
  moreResultsDiv: {
    position: "relative",
  },
  moreResultsLine: {
    backgroundColor: "#dadce0",
    border: "0",
    height: "1px",
    left: "0",
    marginTop: "18px",
    position: "absolute",
    width: "100%",
  },
  moreResultsBtn: {
    background: eLearningColor.main,
    color: "white",
    border: "1px solid rgba(255, 255, 255, 0)",
    boxSizing: "borderBox",
    cursor: "pointer",
    fontSize: "14px",
    lineHeight: "20px",
    pointerEvents: "auto",
    position: "relative",
    width: "150px",
    padding: "7px 11px",
    margin: "0",
    flexDirection: "rowReverse",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    height: "36px",
    borderRadius: "18px",
  },
}));

export const LoadMoreMobile = ({ handlePageChange }) => {
  var classes = useStyles();

  return (
    <div className={classes.moreResultsDiv}>
      <hr className={classes.moreResultsLine} />
      <button className={classes.moreResultsBtn} onClick={handlePageChange}>
        More Results
      </button>
    </div>
  );
};
