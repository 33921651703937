import { Link } from "react-router-dom";
import * as React from "react";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { mobileTheme } from "../../constant";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

import "../../../style.css";
import { GoDotFill } from "react-icons/go";
import "./UserCard.scss";
import { BiDotsVerticalRounded } from "react-icons/bi";
import BasicPopover from "../Popover/PopoverEditDelete";
import { history } from "../../../../history";
import DeleteModal from "../DeleteModal/DeleteModal";
import { IoIosArrowForward } from "react-icons/io";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const UserCard = ({
  Userid,
  name,
  email,
  message,
  designation,
  permissionsData,
  location,
  deleteData,
  search,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteModal, setDeleteModal] = React.useState(false);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickEdit = () => {
    history.push(`/hr/users/edituser/${Userid}`);
  };

  const handleClickDelete = () => {
    setDeleteModal(true);
  };

  const deleteModalClose = () => {
    setDeleteModal(false);
  };

  const handleUserCardClick = () => {
    window.location.assign(`/hr/users?uid=${Userid}`);
    cookies.set("selectedUserUid", Userid);
    console.log("USer Card User IDddddddddddddddd", Userid);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div
      className={search === true ? "cardSearchUser" : "HrUserCard"}
      onClick={handleUserCardClick}
    >
      <article>
        <div className="mvUserCardContentWrapper">
          <div className="infoContainer">
            <div className="nameIconContainer">
              <div
                style={{
                  border: `1px solid ${mobileTheme}`,
                  backgroundColor: mobileTheme,
                }}
                className="nameCharContainer"
              >
                {name
                  .split(" ")
                  .slice(0, 1)
                  .map((word) => word.charAt(0))
                  .join("")}
              </div>
              <div className="dotWrapper">
                <GoDotFill className="dotIcon" />
              </div>
            </div>
            <div className="userInfoMainContainer">
              <div className="userInfoContainer">
                <div className="name">{name}</div>
                <div className="email">{email}</div>
                <div className="email">{designation} to do</div>
                {/* <div
                      style={{ display: "flex", gap: "1px", justifyContent: "start" }}
                    >
                      <div
                        style={{
                          color: mobileTheme,
                        }}
                        className="message"
                      >
                        {message}
                      </div>
                    </div> */}
              </div>
            </div>
          </div>
          <div className="managerText">
            <div className="email">Manager</div>
            <div className="email">Location</div>
          </div>
          <div
            className="actionCardIcon"
            style={{ backgroundColor: mobileTheme }}
          >
            {search === true ? (
              ""
            ) : permissionsData?.common_users_edit === 1 ||
              permissionsData?.common_users_delete === 1 ? (
              <IoIosArrowForward
                aria-describedby={id}
                onClick={handleClick}
                className="actionIcon"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </article>
      {permissionsData?.common_users_edit === 1 ||
      permissionsData?.common_users_delete === 1 ? (
        <BasicPopover
          open={open}
          id={id}
          permissionsData={permissionsData}
          handleClickDelete={handleClickDelete}
          handleClickEdit={handleClickEdit}
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      ) : (
        ""
      )}

      <DeleteModal
        open={deleteModal}
        title={
          "Are you sure you want to remove user and associated data with it?"
        }
        onClose={deleteModalClose}
        handleDelete={() => {
          deleteData(Userid);
        }}
      />
    </div>
  );
};
