import React from "react";
import "./PayrollStyles.css"; // Import the CSS
import { FaRegEdit } from "react-icons/fa";
import { Typography } from "@mui/material";
import PayrollCard from "../../../components/PayrollCard/PayrollCard";

const EmployerDeduction = () => {
  const payrollData = [
    {
      dateRange: "1 April-23 April",
      cpp: "14000$",
      federalTax: "14000$",
      ei: "2300$",
      incomeTax: "14000$",
    },
    {
      dateRange: "24 April-15 May",
      cpp: "12000$",
      federalTax: "12000$",
      ei: "2100$",
      incomeTax: "12000$",
    },
    {
      dateRange: "16 May-5 June",
      cpp: "13000$",
      federalTax: "13000$",
      ei: "2200$",
      incomeTax: "13000$",
    },
    {
      dateRange: "16 May-5 June",
      cpp: "13000$",
      federalTax: "13000$",
      ei: "2200$",
      incomeTax: "13000$",
    },
    {
      dateRange: "16 May-5 June",
      cpp: "13000$",
      federalTax: "13000$",
      ei: "2200$",
      incomeTax: "13000$",
    },
    {
      dateRange: "16 May-5 June",
      cpp: "13000$",
      federalTax: "13000$",
      ei: "2200$",
      incomeTax: "13000$",
    },
    {
      dateRange: "16 May-5 June",
      cpp: "13000$",
      federalTax: "13000$",
      ei: "2200$",
      incomeTax: "13000$",
    },
  ];

  return (
    <div>
      {/* Top Section with Static Data */}
      <div className="payrollDescContainer">
        <div className="payrollDescFirstRow">
          <Typography className="payrollText">
            Total amount deducted:
          </Typography>
          <Typography className="payrollColoredText">24000$</Typography>
        </div>
        <div className="payrollDescSecondRow">
          <Typography className="payrollText">Gross Pay:</Typography>
          <Typography className="payrollColoredText">4300$</Typography>
        </div>
        <div className="payrollDescThirdRow">
          <div className="payrollDescThirdRowLeft">
            <Typography className="payrollText">Net Pay:</Typography>
            <Typography className="payrollColoredText">5400$</Typography>
          </div>
          <div className="payrollDescThirdRowRight">
            <FaRegEdit className="payroll-edit-icon" />
            <Typography className="payrollColoredText">
              Generate end slip
            </Typography>
          </div>
        </div>
      </div>

      {/* Payroll Cards Section */}
      <div className="payroll-cards-container">
        {payrollData.map((payroll, index) => (
          <PayrollCard
            key={index}
            dateRange={payroll.dateRange}
            cpp={payroll.cpp}
            federalTax={payroll.federalTax}
            ei={payroll.ei}
            incomeTax={payroll.incomeTax}
          />
        ))}
      </div>
    </div>
  );
};

export default EmployerDeduction;
