import { MoNGO_DB_API_URL, HEADER_TOKEN } from "../../../../config";
import axios from "axios";

export const commonpayrollServices = {
  fetchData,
  addData,
  editData,
  deleteData,
  fetchPayrollHistoryData,
  getDetailsPayrollCash,
  fetchPeData,
  addPeData,
  editPeData,
  deletePeData,
  fetchTimeCodeData,
  fetchUserListData,
  editCodeData,
  fetchCodeListData,
  editCustomCodeData,
  fetchExportHeader,
  editHeader,
  deleteCustom,
  addRowDeleted,
  AddCustomHeader,
  togglePayrollAssignUsers,
};

// function fetchData(cid) {
//   var data = {
//     cid: cid,
//   };
//   return axios
//     .post(
//       `${MoNGO_DB_API_URL}/scheduler/payroll_template/v3/list/company`,
//       data,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
// function fetchData(data) {
//   var dataNew = {
//     cid: data.cid,
//     pageNumber:data.pageNumber,
//     nPerPage:10
//   };
//   return axios
//     .post(
//       `${MoNGO_DB_API_URL}/scheduler/payroll_template/v3/list/company/paginated`,
//       dataNew,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function fetchData(data) {
  var dataNew = {
    cid: data.cid,
    pageNumber: data.pageNumber,
    nPerPage: 10,
  };
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/template/company/paginated`,
      dataNew,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function togglePayrollAssignUsers(data) {
//   return axios
//     .post(
//       `${MoNGO_DB_API_URL}/scheduler/payroll_template/company/users`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function togglePayrollAssignUsers(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/template/company/users`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function getDetailsPayrollCash() {
//   return axios
//     .get(`${MoNGO_DB_API_URL}/payroll_timecode/pi/connected`, HEADER_TOKEN)
//     .then((response) => response);
// }
function getDetailsPayrollCash() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/pi/connected`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function addData(data) {
//     return axios.post(`${MoNGO_DB_API_URL}/scheduler/payroll_template/v2/addcompay`, { data }, HEADER_TOKEN).then(response => response)
// };

// function addData(data) {
//   return axios
//     .post(
//       `${MoNGO_DB_API_URL}/scheduler/payroll_template/v4/addcompay`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function addData(data) {
  console.log(data, "addData data");
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/template/company`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function editData(data, id) {
//     return axios.put(`${MoNGO_DB_API_URL}/scheduler/payroll_template/v2/company/${id}`, { data }, HEADER_TOKEN).then(response => response)
// };
// function editData(data, id) {
//   return axios
//     .put(
//       `${MoNGO_DB_API_URL}/scheduler/payroll_template/v4/company/${id}`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function editData(data, id) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/template/company/${id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}
// function deleteData(_id) {
//   return axios
//     .delete(
//       `${MoNGO_DB_API_URL}/scheduler/payroll_template/company/${_id}`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function deleteData(_id) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/template/company/${_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function fetchPayrollHistoryData(id) {
//   return axios
//     .get(
//       `${MoNGO_DB_API_URL}/scheduler/payroll_template/history/${id}`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function fetchPayrollHistoryData(id) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/template/history/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

///////////////////////////////////PE///////////////////////////
// function fetchPeData() {
//   return axios
//     .get(`${MoNGO_DB_API_URL}/payroll_timecode/pi`, HEADER_TOKEN)
//     .then((response) => response);
// }
// function fetchPeData(page) {
//   return axios
//     .get(
//       `${MoNGO_DB_API_URL}/payroll_timecode/pi/paginated?pageNumber=${page}&nPerPage=10`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function fetchPeData(page = 1) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/pi/paginated?pageNumber=${page}&nPerPage=10`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function addPeData(data) {
//   return axios
//     .post(`${MoNGO_DB_API_URL}/payroll_timecode/pi`, { data }, HEADER_TOKEN)
//     .then((response) => response);
// }
function addPeData(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/pi`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function editPeData(data, id) {
//   return axios
//     .put(
//       `${MoNGO_DB_API_URL}/payroll_timecode/pi/${id}`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }

function editPeData(data, id) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/pi/${id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function deletePeData(_id) {
//   return axios
//     .delete(`${MoNGO_DB_API_URL}/payroll_timecode/pi/${_id}`, HEADER_TOKEN)
//     .then((response) => response);
// }
function deletePeData(_id) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/pi/${_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function fetchTimevruti() {
//     return axios.get(`${MoNGO_DB_API_URL}/payroll_timecode/v2`, HEADER_TOKEN).then(response => response)
// };
// function fetchTimeCodeData() {
//   return axios
//     .get(`${MoNGO_DB_API_URL}/payroll_timecode/v2`, HEADER_TOKEN)
//     .then((response) => response);
// }
function fetchTimeCodeData() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/timecode`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function fetchUserListData(data) {
//   return axios
//     .post(`${MoNGO_DB_API_URL}/payroll_timecode/user`, { data }, HEADER_TOKEN)
//     .then((response) => response);
// }
function fetchUserListData(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/timecode/user`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function editCodeData(data) {
//   return axios
//     .put(
//       `${MoNGO_DB_API_URL}/payroll_timecode/user/code/edit`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function editCodeData(data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/timecode/user/update`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function fetchCodeListData(pid) {
//   return axios
//     .get(`${MoNGO_DB_API_URL}/payroll_timecode/custom/${pid}`, HEADER_TOKEN)
//     .then((response) => response);
// }
function fetchCodeListData(pid) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/timecode/custom/${pid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function editCustomCodeData(data) {
//   return axios
//     .put(
//       `${MoNGO_DB_API_URL}/payroll_timecode/custom/edit`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function editCustomCodeData(data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/timecode/custom`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

///////////////////////////////Export Header ///////////////////////////////

// function fetchExportHeader(pid) {
//   return axios
//     .get(
//       `${MoNGO_DB_API_URL}/payroll_timecode/v2/pi/exportheader/${pid}`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function fetchExportHeader(pid) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/pi/export-header/${pid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function editHeader(data) {
//   return axios
//     .put(
//       `${MoNGO_DB_API_URL}/payroll_timecode/v2/pi/exportheader`,
//       { data },
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function editHeader(data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/payroll/pi/export-header`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function deleteCustom(_id) {
//   return axios
//     .delete(
//       `${MoNGO_DB_API_URL}/payroll_timecode/v2/pi/exportheader/${_id}`,
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }
function deleteCustom(_id) {
  return axios
    .delete(
      `${MoNGO_DB_API_URL}/payroll_timecode/v2/pi/exportheader/${_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function addRowDeleted(_id) {
//   return axios
//     .put(
//       `${MoNGO_DB_API_URL}/payroll_timecode/v2/pi/exportheader/add/${_id}`,
//       {},
//       HEADER_TOKEN
//     )
//     .then((response) => response);
// }

function AddCustomHeader (data) {
  return axios
    .post(
      `${MoNGO_DB_API_URL}/v5/payroll/pi/export-header/custom`,{data},
      
      HEADER_TOKEN
    )
  }
function addRowDeleted(_id) {
  return axios
    .put(
      `${MoNGO_DB_API_URL}/payroll_timecode/v2/pi/exportheader/add/${_id}`,
      {},
      HEADER_TOKEN
    )
    .then((response) => response);
}
