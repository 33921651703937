import {
  users,
  roles,
  payrollImage,
  feed_image_dashboard,
  feed_image_dashboard_active,
  sc_image_myavailability,
  sc_image_myavailability_active,
} from "../icons";

export const OvHrRoutes = [
  {
    label: "Hierarachy chart",
    path: "/hr/hierarachy_chart",
    image: feed_image_dashboard,
    active_image: feed_image_dashboard_active,
  },
  
  {
    label: "Payroll Management",
    path: "/hr/payroll",
    image: payrollImage,
    active_image: payrollImage,
  },
  {
    path: "/",
    label: "Directory",
    image: sc_image_myavailability,
    active_image: sc_image_myavailability_active,
    childData: [
      {
        label: "Locations",
        path: "/hr/locations",
        // image: users,
        // active_image: users,
      },
      {
        label: "Users",
        path: "/hr/users",
        // image: users,
        // active_image: users,
      },
      {
        label: "Roles & Permissions",
        path: "/hr/roles",
        // image: roles,
        // active_image: roles,
      },
      {
        label: "Group",
        path: "/hr/group",
        image: roles,
        active_image: roles,
      },
    ],
  },
  {
    path: "/",
    label: "Boarding List",
    image: sc_image_myavailability,
    active_image: sc_image_myavailability_active,
    childData: [
      {
        label: "On-boarding",
        path: "/hr/onboarding",
        // image: users,
        // active_image: users,
      },
      {
        label: "Off-boarding",
        path: "/hr/offboarding",
        // image: roles,
        // active_image: roles,
      },
      {
        label: "Layout",
        path: "/hr/layout",
        // image: roles,
        // active_image: roles,
      },
    ],
  },
  {
    path: "/",
    label: "Events",
    image: sc_image_myavailability,
    active_image: sc_image_myavailability_active,
    childData: [
     
    ],
  },
  {
    path: "/",
    label: "Hiring",
    image: sc_image_myavailability,
    active_image: sc_image_myavailability_active,
    childData: [
      {
        label: "Job posting",
        path: "/hr/jobposting",
        // image: users,
        // active_image: users,
      },
      {
        label: "Candidates",
        path: "/hr/hiring/candidates",
        // image: roles,
        // active_image: roles,
      },
      {
        label: "Interview",
        path: "/hr/hiring/interview",
        // image: roles,
        // active_image: roles,
      },
    ],
  },
  {
    path: "/",
    label: "Evaluation",
    image: sc_image_myavailability,
    active_image: sc_image_myavailability_active,
    childData: [
      {
        label: "Observation",
        path: "/hr/evaluation/observation",
      },
      {
        label: "Question",
        path: "/hr/evaluation/question",
      },   
      {
        label: "Form",
        path: "/hr/evaluation/allform",
      },
      {
        label: "Settings",
        path: "/hr/evaluation/settings",
      },
    ],
  },
  {
    path: "/",
    label: "Letter template",
    image: sc_image_myavailability,
    active_image: sc_image_myavailability_active,
    childData: [
      {
        label: "Template",
        path: "/hr/letter/template",
        // image: users,
        // active_image: users,
      },
      {
        label: "List",
        path: "/hr/letter/list",
        // image: roles,
        // active_image: roles,
      },
    ],
  },
];
