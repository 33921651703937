import {
  elearning_asad2,
  schedule_asad,
  feedback_asad,
  routine_asad,
  news_asad,
  chat_asad,
  hr_asad,
  star_asad,
  analyticsLogo,
  // scheduler_white,
  // todo_white,
  // ovChat,
  // ovHR,
} from "../icons";

export default [
  {
    title: "eLearning",
    href:
      window.innerWidth >= 768
        ? "/elearning/dashboard"
        : "/elearning/my-library",
    // backgroundColor: 'elearning.main',
    backgroundColor: "white",
    // iconColor: "rgb(211,211,211,.2)",
    imageUrl: elearning_asad2,
  },
  // {
  //   title: "Feedback",
  //   href: "/feedback/dashboard/new",
  //   backgroundColor: "white",
  //   // backgroundColor: feedbackColor.main,
  //   // iconColor: "rgb(211,211,211,.2)",
  //   imageUrl: feedback_asad,
  // },
  {
    title: "Scheduler",
    href: "/scheduler/dashboard",
    backgroundColor: "white",
    // backgroundColor: schedulerColor.main,
    // iconColor: "rgb(211,211,211,.2)",
    imageUrl: schedule_asad,
  },
  // {
  //   title: 'Todo',
  //   href: '/todo/dashboard/personal',
  //   backgroundColor: 'white',
  //   // backgroundColor: todoColor.main,
  //   // iconColor: "rgb(211,211,211,.2)",
  //   imageUrl: todo_white
  // },
  {
    title: "News",
    href: "/newsandupdate/dashboard",
    // backgroundColor: newsandupdate.main,
    backgroundColor: "white",
    // iconColor: "rgb(211,211,211,.2)",
    imageUrl: news_asad,
  },
  // {
  //   title: "Chat",
  //   href: "/chat/",
  //   // backgroundColor: ovChatColor.main,
  //   backgroundColor: "white",
  //   // iconColor: "rgb(211,211,211,.2)",
  //   imageUrl: chat_asad,
  // },
  // {
  //   title: "Forms",
  //   href: "/forms/dashboard",
  //   backgroundColor: "white",
  //   // backgroundColor: ovFormsColor.main,
  //   // iconColor: "rgb(211,211,211,.2)",
  //   imageUrl: routine_asad,
  // },
  {
    title: "Routine",
    href: "/routine/assignedToMe",
    backgroundColor: "white",
    // backgroundColor: ovFormsColor.main,
    // iconColor: "rgb(211,211,211,.2)",
    imageUrl: routine_asad,
  },
  {
    title: "Analytics",
    href: "/analytics/all/location",
    backgroundColor: "white",
    // backgroundColor: ovFormsColor.main,
    // iconColor: "rgb(211,211,211,.2)",
    imageUrl: analyticsLogo,
  },
  {
    title: "HR",
    href: "/hr/users",
    // backgroundColor: ovHrColor.main,
    backgroundColor: "white",
    // iconColor: "rgb(211,211,211,.2)",
    imageUrl: hr_asad,
  },
  // {
  //   title: "Team Feedback",
  //   href: "/ovRating/RateTeam",
  //   backgroundColor: "white",
  //   // backgroundColor: ratingColor.main,
  //   // iconColor: "rgb(211,211,211,.2)",
  //   imageUrl: star_asad,
  // },
];
// (ConnectWith);
