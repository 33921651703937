import { makeStyles } from "@mui/styles";

export const userDetailComponentStyles = makeStyles({
  mobOuterContainer: {
    paddingTop: "25px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
    height: "100%",
  },
  container: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    marginTop: "20px",
    marginBottom: "20px",
  },
  userDetailCustomAccordian: {
    width: "100%",
    borderRadius: "7px !important",
    border: "1px solid #E3E3E3",
    boxShadow: "none !important",
    height: "50px",
    padding: "1rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  userDetailLeftContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    alignItems: "center",
    width: "100%",
  },
  userDetailIconContainer: {
    width: "25px",
    height: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "##007E63",
  },
  userDetailIcon: {
    width: "14px",
    height: "14px",
  },
  userDetailHeading: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px !important",
    fontWeight: 400,
    lineHeight: "14.06px",
    textAlign: "left",
  },
  userDetailExpandIcon: {
    fontSize: "24px !important",
    color: "#007E63",
    transform: "rotate(-90deg)",
  },
  accordianContainer: {
    width: "100%",
    height: "100%",
  },
});

export const userDetailExpandStyles = makeStyles({
  mobOuterContainer: {
    paddingTop: "25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  container: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    marginTop: "20px",
    marginBottom: "20px",
  },
  accordianContainer: {
    width: "100%",
    height: "100%",
  },

  formRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    height: "100%",
  },

  formColumn: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem !important",
    height: "100%",
  },

  textField: {
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      color: "black",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      lineHeight: "15.75px",

      "& fieldset": {
        borderColor: "#E3E3E3",
        borderRadius: "6px",
        borderWidth: "1px",
      },
      "&:hover fieldset": {
        borderColor: "#007E63",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007E63",
      },
    },

    "& .MuiInputLabel-root": {
      fontSize: "12px",
      color: "#E3E3E3",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      lineHeight: "13.75px",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#007E63",
    },

    "& .MuiInputBase-input::placeholder": {
      fontSize: "14px",
      color: "#E3E3E3",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      lineHeight: "15.75px",
    },
  },

  selectFieldLabel: {
    fontSize: "12px !important",
    color: "#E3E3E3",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    lineHeight: "13.75px",
  },

  selectField: {
    "& .MuiSelect-select": {
      fontSize: "12px !important",
      fontWeight: 400,
      lineHeight: "15.75px",
      fontFamily: "Roboto, sans-serif",
      color: "black",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#007E63",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E3E3E3",
      borderRadius: "6px",
      borderWidth: "1px",
    },
    "& .MuiInputLabel-root": {
      fontSize: "12px",
      color: "#E3E3E3",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      lineHeight: "13.75px",
    },

    "& .MuiInputBase-input::placeholder": {
      fontSize: "14px",
      color: "#E3E3E3",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      lineHeight: "15.75px",
    },
  },

  contactHeading:{
    fontSize: "12px",
    color: "#007E63",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500,
    lineHeight: "14.06px",
  },
});
