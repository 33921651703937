import React from "react";
import { docStyles } from "./DocStyle";
import CustomFileUploader from "../../../components/CustomDownloadField/DownloadField";
import { ClockIcon } from "../../../../../assets/images/mobileView/icons/svgs";
import { MdFileUpload } from "react-icons/md";
import { Button } from "@mui/material";

const Document = () => {
  const classes = docStyles();

  const handleFileUpload = (file) => {
    console.log("Uploaded File:", file);
  };

  return (
    <div className={classes.docContainer}>
      <div>
        <CustomFileUploader
          label="Photo ID"
          icon={ClockIcon}
          placeholder="Photo.jpg"
          fileType="image/*" // Restrict to image files
          onFileChange={handleFileUpload}
          uploadIcon={MdFileUpload}
        />

        <CustomFileUploader
          label="National ID(SIN/SSN)"
          icon={ClockIcon}
          placeholder="Photo.jpg"
          fileType="image/*" // Restrict to image files
          onFileChange={handleFileUpload}
          uploadIcon={MdFileUpload}
        />

        <CustomFileUploader
          label="Offer Letter"
          placeholder="Photo.jpg"
          fileType="image/*" // Restrict to image files
          onFileChange={handleFileUpload}
          uploadIcon={MdFileUpload}
        />

        <CustomFileUploader
          label="Termination Letter"
          placeholder="Photo.jpg"
          fileType="image/*" // Restrict to image files
          onFileChange={handleFileUpload}
          uploadIcon={MdFileUpload}
        />
      </div>

      <div className={classes.buttonContainer}>
        <Button className={classes.saveButton}>Save</Button>
        <Button className={classes.cancelButton}>Cancel</Button>
      </div>
    </div>
  );
};

export default Document;
