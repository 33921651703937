
import {
  SET_USERS_NOTIFICATIONS_LOADING,
  SET_OV_GET_LOCATIONS,
  SET_CORRECTION_REQUEST_LOADING,
  SET_CORRECTION_REQUEST,
  ADD_CORRECTION_REQUEST,
  EDIT_CORRECTION_REQUEST,
  DELETE_CORRECTION_REQUEST,
  SET_CORRECTION_TEAM_REQUEST
} from "./constants";

const initialState = {
  isLoading: true,
  locationData: [],
  notificationLoading: true,
  countData: {},
  notificationsData: [],
  correctionData: [],
  correctionTeamData:[],
  locationDetails: [],
  currentUser: {},
  isLoggedIn: false
}

const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CORRECTION_REQUEST_LOADING:
      return {
        ...state,
        isLoading: payload
      };
      case SET_CORRECTION_REQUEST:
        return {
          ...state,
          isLoading: false,
          correctionData: payload.CorrectionRequest,
          totalPage:payload.total_page
        };

        case SET_CORRECTION_TEAM_REQUEST:
          return {
            ...state,
            isLoading: false,
            correctionTeamData: payload.result,
            totalPage:payload.totalPages
          };

    case ADD_CORRECTION_REQUEST:
      var myPayload = payload
      return {
        ...state,
        isLoading: false,
        correctionData: [...state.correctionData, myPayload]
      }
      case SET_OV_GET_LOCATIONS:
        return {
          ...state,
          isLoading: false,
          locationDetails: payload
        }
    case EDIT_CORRECTION_REQUEST:
      return {
        ...state,
        isLoading: false,
        correctionData: state.correctionData.map((item) => item._id === payload._id ? { ...payload } : item)
      }
    case DELETE_CORRECTION_REQUEST:
      return {
        ...state,
        isLoading: false,
        correctionData: state.correctionData.filter((item) => item._id !== payload)
      }
    default:
      return state;
  }
};

export default Reducer;