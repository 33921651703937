import { makeStyles } from "@mui/styles";

export const filterStyles = makeStyles({
    filterContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        margin: "auto",
        marginTop: "41px",
        padding: "1rem 1.5rem",
        position: "relative",
    },
    filterText: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "18.75px",
        textAlign: "left",
        color: "#000000",
    },
    filterButtonGroup: {
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        width: "100%",
    },
    filterButton: {
        height: "31px",
        padding: "0.6rem 1.2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        borderRadius: "6px !important",
        border: "1px solid #E3E3E3",
        fontFamily: "Roboto, sans-serif",
        fontSize: "11px",
        fontWeight: 400,
        lineHeight: "12.89px",
    },
    flterBottomBtnContainer:{
        position: "absolute",
        bottom: "10rem",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
    },
    filterApplyBtn: {
        height: "40px",
        borderRadius: "7px",
        backgroundColor: "#007E63",
        color: "#FFFFFF",
        fontFamily: "Roboto, sans-serif",
        fontSize: "16px !important",
        fontWeight: "500 !important",
        lineHeight: "18.75px !important",
        textAlign: "center",
        padding: "0.9rem 3rem",
    },
    flterCancelBtn: {
        height: "40px",
        borderRadius: "7px",
        backgroundColor: "#007E6340",
        color: "#007E63",
        fontFamily: "Roboto, sans-serif",
        fontSize: "16px !important",
        fontWeight: "500 !important",
        lineHeight: "18.75px !important",
        textAlign: "center",
        padding: "0.9rem 3rem",
    }
})