import React, {
  useEffect,
  // useRef,
  useState,
} from "react";
// import { Checkbox, ListItemText, TextField } from "@mui/material";

import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./DateRange.css";
import ReactSelect from "react-select";
import DateRangeSelector from "../../../../../components/ELearning/DateRange/DateRangeSelector";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme, size, bgColor, arrowColor, borderColor, buttonWidth }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    width: size.width.includes("%") ? buttonWidth : "auto",
    borderColor: borderColor,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: size || 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const Dropdown = ({
  size,
  bgColor,
  arrowColor,
  textColor,
  Icon,
  borderColor,
  rounded,
  buttonText,
  options,
  arrowFalseFlag,
  arrowSize,
  textSize,
  handleChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const buttonRef = React.useRef(null);

  React.useEffect(() => {
    if (buttonRef.current) {
      const buttonWidth = buttonRef.current.offsetWidth;
      setButtonWidth(buttonWidth);
    }
  }, [buttonText]);

  const [buttonWidth, setButtonWidth] = React.useState(null);

  return (
    <div>
      <Button
        ref={buttonRef}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        startIcon={Icon ? <Icon /> : null}
        endIcon={
          !arrowFalseFlag ? (
            <KeyboardArrowDownIcon
              style={{
                color: arrowColor,
                fontSize: arrowSize || "23px",
              }}
            />
          ) : null
        }
        onClick={handleClick}
        style={{
          backgroundColor: bgColor,
          color: textColor,
          border: borderColor ? `1px solid ${borderColor}` : "none",
          borderRadius: rounded ? rounded : "7px",
          width: `${size.width}`,
          height: `${size.height}`,
          display: "flex",
          justifyContent: arrowFalseFlag ? "center" : "space-between",
          padding: "8px 15px 8px 12px",
        }}
      >
        <span
          style={{
            textTransform: "capitalize",
            textAlign: "left",
            fontSize: textSize || "16px",
          }}
        >
          {buttonText}
        </span>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        size={size}
        buttonWidth={buttonWidth}
        borderColor={borderColor}
        bgColor={bgColor}
        arrowColor={arrowColor}
        style={{ padding: "0px" }}
      >
        {options &&
          options.map((option, index) => (
            <MenuItem
              onClick={() => {
                handleChange(option);
                handleClose();
              }}
              key={option.id}
              disableRipple
              style={{
                borderBottom:
                  index !== options.length - 1 && "1px solid rgba(0,0,0,0.1)",
                padding: "10px",
              }}
            >
              {/* <MoreHorizIcon /> */}
              {option.name}
            </MenuItem>
          ))}
      </StyledMenu>
    </div>
  );
};

// export const EarningMultiSelectDropdown = ({
//   size,
//   bgColor,
//   arrowColor,
//   textColor,
//   Icon,
//   borderColor,
//   rounded,
//   buttonText,
//   arrowFalseFlag,
//   arrowSize,
//   textSize,
//   options,
//   selectedOptions,
//   onOptionSelect,
// }) => {
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };
//   const buttonRef = React.useRef(null);

//   React.useEffect(() => {
//     if (buttonRef.current) {
//       const buttonWidth = buttonRef.current.offsetWidth;
//       setButtonWidth(buttonWidth);
//     }
//   }, [buttonText]);

//   const [buttonWidth, setButtonWidth] = React.useState(null);

//   const handleOptionSelect = (optionId) => {
//     onOptionSelect(optionId);
//   };

//   return (
//     <div>
//       <Button
//         ref={buttonRef}
//         id="demo-customized-button"
//         aria-controls={open ? "demo-customized-menu" : undefined}
//         aria-haspopup="true"
//         aria-expanded={open ? "true" : undefined}
//         variant="contained"
//         disableElevation
//         startIcon={Icon ? <Icon /> : null}
//         endIcon={
//           !arrowFalseFlag ? (
//             <KeyboardArrowDownIcon
//               style={{
//                 color: arrowColor,
//                 fontSize: arrowSize || "23px",
//               }}
//             />
//           ) : null
//         }
//         onClick={handleClick}
//         style={{
//           backgroundColor: bgColor,
//           color: textColor,
//           border: borderColor ? `1px solid ${borderColor}` : "none",
//           borderRadius: rounded ? rounded : "7px",
//           width: `${size.width}`,
//           height: `${size.height}`,
//           display: "flex",
//           justifyContent: arrowFalseFlag ? "center" : "space-between",
//           padding: "8px 15px 8px 12px",
//         }}
//       >
//         <span
//           style={{
//             textTransform: "capitalize",
//             textAlign: "left",
//             fontSize: textSize || "16px",
//           }}
//         >
//           {buttonText}
//         </span>
//       </Button>
//       <StyledMenu
//         id="demo-customized-menu"
//         MenuListProps={{
//           "aria-labelledby": "demo-customized-button",
//         }}
//         anchorEl={anchorEl}
//         open={open}
//         onClose={handleClose}
//         size={size}
//         buttonWidth={buttonWidth}
//         borderColor={borderColor}
//         bgColor={bgColor}
//         arrowColor={arrowColor}
//         style={{ padding: "0px" }}
//       >
//         {options?.map((option) => (
//           <div
//             key={option._id}
//             style={{ display: "flex", alignItems: "center" }}
//           >
//             <Checkbox
//               checked={selectedOptions.includes(option._id)}
//               onChange={() => handleOptionSelect(option._id)}
//             />
//             <ListItemText primary={option.label} />
//           </div>
//         ))}
//       </StyledMenu>
//     </div>
//   );
// };
export const EarningMultiSelectDropdown = ({
  size,
  bgColor,
  arrowColor,
  textColor,
  Icon,
  borderColor,
  rounded,
  buttonText,
  arrowFalseFlag,
  arrowSize,
  textSize,
  options,
  selectedOptions,
  onOptionSelect,
  earningEmployeesData,
  userIds,
  setUserIds,
  labelProperty,
  dropdownTextColor,
  dropdownTextAlign,
  dropdownTextSize,
  selectedOptionWidth,
  selectedOptionFontSize,
}) => {
  // Function to generate dynamic label
  const generateLabel = (id) => {
    const employee = earningEmployeesData.find((option) => option._id === id);
    if (employee) {
      return employee[labelProperty] || "";
    } else {
      return "";
    }
  };

  return (
    <ReactSelect
      options={options}
      value={selectedOptions.map((id) => ({
        value: id,
        label: generateLabel(id),
      }))}
      isMulti
      onChange={(selected) => {
        const selectedIds = selected
          ? selected.map((option) => option.value)
          : [];
        setUserIds(selectedIds);
      }}
      isSearchable
      menuPortalTarget={document.body}
      styles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: bgColor,
          borderColor: borderColor ? borderColor : "#ccc",
          borderRadius: rounded ? rounded : "7px",
          minHeight: size.height,
          maxHeight: "100px",
          width: selectedOptions.length > 0 ? selectedOptionWidth : size.width,
          maxWidth: selectedOptions.length > 0 ? "" : "150px",
          overflow: "auto",
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: bgColor,
          fontSize: dropdownTextSize || "14px",
          color: dropdownTextColor || "black",
          textAlign: dropdownTextAlign || "left",
        }),
        menuList: (provided) => ({
          ...provided,
        }),
        option: (provided, state) => ({
          ...provided,
          whiteSpace: "normal",
          wordBreak: "break-word",
          fontSize: "14px",
          textAlign: "left",
          backgroundColor: state.isSelected ? "#1B3F8F" : bgColor,
          color: state.isSelected ? "white" : dropdownTextColor || "black",
          ":hover": {
            backgroundColor: state.isSelected ? "#1B3F8F" : "#1B3F8F33",
          },
        }),
        multiValue: (provided) => ({
          ...provided,
          backgroundColor: "#1B3F8F",
          borderRadius: "5px",
          width: selectedOptionWidth ? selectedOptionWidth : "50px",
          padding: 0,
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          color: "white",
          fontSize: selectedOptionFontSize || "12px",
        }),
        multiValueRemove: (provided) => ({
          ...provided,
          color: "white",
          ":hover": {
            backgroundColor: "transparent",
            color: "white",
          },
        }),
      }}
    />
  );
};

export const SearchWithCounter = ({
  size,
  bgColor,
  textColor,
  borderColor,
  rounded,
  buttonText,
  value,
  textSize,
  onChange,
}) => {
  const [counterValue, setCounterValue] = useState(value || 0);

  useEffect(() => {
    setCounterValue(value || 0);
  }, [value]);

  const incrementCounter = () => {
    const newValue = counterValue + 5;
    setCounterValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const decrementCounter = () => {
    const newValue = counterValue - 5 >= 0 ? counterValue - 5 : 0;
    setCounterValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div
      style={{
        backgroundColor: bgColor,
        color: textColor,
        border: borderColor ? `1px solid ${borderColor}` : "none",
        borderRadius: rounded ? rounded : "7px",
        width: `${size.width}`,
        height: `${size.height}`,
        padding: "8px 15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxSizing: "border-box",
        outline: "none",
        fontSize: textSize || "14px",
      }}
    >
      <button
        style={{
          border: "none",
          backgroundColor: "transparent",
          fontSize: "inherit",
          cursor: "pointer",
        }}
        onClick={decrementCounter}
      >
        -
      </button>
      <span>{counterValue}</span>
      <button
        style={{
          border: "none",
          backgroundColor: "transparent",
          fontSize: "inherit",
          cursor: "pointer",
        }}
        onClick={incrementCounter}
      >
        +
      </button>
    </div>
  );
};

// Date Range Picker
const CustomDatePickerInput = ({
  value,
  onClick,
  size,
  bgColor,
  textColor,
  borderColor,
  rounded,
  arrowFalseFlag,
}) => (
  <input
    type="text"
    value={value}
    onClick={onClick}
    readOnly
    style={{
      cursor: "pointer",
      backgroundColor: bgColor,
      color: textColor,
      border: borderColor ? `1px solid ${borderColor}` : "none",
      borderRadius: rounded ? rounded : "7px",
      width: `${size.width}`,
      height: `${size.height}`,
      display: "flex",
      justifyContent: arrowFalseFlag ? "center" : "space-between",
      padding: "8px 15px 8px 12px",
    }}
    // className="datepickercontainer"
    placeholder="Date"
  />
);

export const EarningDateRangePicker = ({
  size,
  bgColor,
  textColor,
  borderColor,
  rounded,
  arrowFalseFlag,
  startDate,
  endDate,
  handleClose,
  handleDateChange,
}) => {
  return (
    // <DatePicker
    //   // className="datepickercontainer"
    //   style={{ height: `${size.height} !important` }}
    //   selected={startDate}
    //   onChange={handleDateChange}
    //   selectsRange
    //   startDate={startDate}
    //   endDate={endDate}
    //   dateFormat="MM/dd/yyyy"
    //   placeholderText={`Date`}
    //   isClearable
    //   showYearDropdown
    //   scrollableYearDropdown
    //   yearDropdownItemNumber={50}
    //   minDate={null}
    //   customInput={
    //     <CustomDatePickerInput
    //       size={size}
    //       bgColor={bgColor}
    //       textColor={textColor}
    //       borderColor={borderColor}
    //       rounded={rounded}
    //       arrowFalseFlag={arrowFalseFlag}
    //     />
    //   }
    // />
    <DateRangeSelector
      handleDateRange={handleDateChange}
      startDate={startDate}
      endDate={endDate}
      handleClose={handleClose}
      customButtonClassName={{
        cursor: "pointer",
        backgroundColor: bgColor,
        color: textColor,
        border: borderColor ? `1px solid ${borderColor}` : "none",
        borderRadius: rounded ? rounded : "7px",
        width: `${size.width}`,
        height: `${size.height}`,
        textTransform: "capitalize",
        fontSize: "12px",
        display: "flex",
        justifyContent: arrowFalseFlag ? "center" : "space-between",
        padding: "8px 15px 8px 12px",
      }}
    />
  );
};
