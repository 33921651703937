import {
  analyticsElearningIcon,
  analyticsNewsIcon
} from "../icons";

export const AnalyticsRoutes = [
  {
    label: "All",
    path: "/analytics/all/location",
    image: analyticsElearningIcon,
    active_image: analyticsElearningIcon,
  },
  {
    label: "eLearning",
    path: "/analytics/elearning/location",
    image: analyticsElearningIcon,
    active_image: analyticsElearningIcon,
  },
  {
    label: "News",
    path: "/analytics/news/location",
    image: analyticsNewsIcon,
    active_image: analyticsNewsIcon,
  },
  // {
  //   // asad hide
  //   path: "/",
  //   label: "Request",
  //   image: sc_image_req,
  //   active_image: sc_image_req_active,
  //   childData: [
  //     {
  //       label: "Trade shift",
  //       path: "/scheduler/tradeshift/my",
  //       image: sc_image_tradeshift,
  //       active_image: sc_image_tradeshift_active,
  //       count: "scheduler_trade_shifts",
  //     },
  //     {
  //       label: "Cover shift",
  //       path: "/scheduler/covershift/my",
  //       image: sc_image_covershift,
  //       active_image: sc_image_covershift_active,
  //     },
  //     {
  //       label: "Time off",
  //       path: "/scheduler/timeoffrequest/my",
  //       image: sc_image_timeoffrequest,
  //       active_image: sc_image_timeoffrequest_active,
  //       count: "scheduler_timeoff_requests",
  //     },
  //     {
  //       label: "Correction",
  //       path: "/scheduler/correction_request/my",
  //       image: sc_image_pay_summary,
  //       active_image: sc_image_pay_summary_active,
  //     },
  //     // {
  //     //   label: "My Request",
  //     //   path: "/scheduler/mycorrection_request",
  //     //   image: sc_image_pay_summary,
  //     //   active_image: sc_image_pay_summary_active,
  //     // },
  //   ],
  // },
  {
    type: "divider",
  }
];
