import React from "react";
import { filterStyles } from "./FiltersStyling";
import { Button, Typography } from "@material-ui/core";

const HrFilters = () => {
  const classes = filterStyles();

  return (
    <div className={classes.filterContainer}>
      <Typography className={classes.filterText}>Groups</Typography>
      <div className={classes.filterButtonGroup}>
        <div className={classes.filterButton}>Group 1</div>
        <div className={classes.filterButton}>Group 1</div>
        <div className={classes.filterButton}>Group 1</div>
        <div className={classes.filterButton}>Group 1</div>
      </div>
      <Typography className={classes.filterText}>Roles</Typography>
      <div className={classes.filterButtonGroup}>
        <div className={classes.filterButton}>Manager</div>
        <div className={classes.filterButton}>Manager</div>
        <div className={classes.filterButton}>Manager</div>
      </div>
      <Typography className={classes.filterText}>Locations</Typography>
      <div className={classes.filterButtonGroup}>
        <div className={classes.filterButton}>Location</div>
        <div className={classes.filterButton}>Location</div>
      </div>
      <Typography className={classes.filterText}>Status</Typography>
      <div className={classes.filterButtonGroup}>
        <div className={classes.filterButton}>Activated</div>
        <div className={classes.filterButton}>Deactivated</div>
      </div>
      <div className={classes.flterBottomBtnContainer}>
        <Button className={classes.filterApplyBtn}>Apply</Button>
        <Button className={classes.flterCancelBtn}>Cancel</Button>
      </div>
    </div>
  );
};

export default HrFilters;
