import { makeStyles } from "@mui/styles";
import { ovHrV5Color } from "../../../../config";

export const rolesStyles = makeStyles({
  hrRolesContainer: {
    display: "flex !important",
    flexDirection: "column !important",
    height: "100% !important",
    width: "100% !important",
    padding: "2rem 2rem !important",
    gap: "2rem !important",
  },

  hrRoleHeading: {
    fontFamily: "Roboto, sans-serif !important",
    fontSize: "48px !important",
    fontWeight: "700 !important",
    color: ovHrV5Color.main,
    lineHeight: "56.25px !important",
  },

  hrFieldRow:{
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "space-between !important",
  },

  hrTextFieldRole: {
    width: "30% !important",
    backgroundColor: "white !important",
    border: "none !important",
    "& .MuiOutlinedInput-root": {

      "&.Mui-focused fieldset": {
        borderColor: ovHrV5Color.main,
      },
    },
  },

  hrViewLogIcon: {
    color: ovHrV5Color.main,
    cursor: "pointer",
    width: "25px",
    height: "25px",
  },

  hrTableContainer: {
    height: "100% !important",
    width: "100% !important",
  },

  hrTableHead: {
    backgroundColor: ovHrV5Color.main,
  },

  tableRow: {
    marginLeft: "2rem !important",
  },

  hrTableHeadText: {
    color: "white !important",
    fontSize: "16px !important",
   },

   hrButtonRow: {
    width: "100% !important",
    display: "flex !important",
    justifyContent: "space-between !important",
    flexDirection: "row !important",
    height: "5rem !important",
    alignItems: "center !important",
    padding: "0 2rem 0 2rem !important",
   },

   hrTableHeadHeadingt: {
    fontSize: "20px !important",
    fontWeight: "500 !important",
    fontFamily: "Roboto, sans-serif !important",
    colro: "black !important",
   },
});
