import * as React from "react";
import Button from "@mui/material/Button";

export const OutlinedButton = ({
  text,
  icon,
  borderColor,
  textColor,
  disabled,
  onClick,
  size,
  textSize,
  bgColor,
  rounded,
}) => {
  const buttonStyle = {
    borderColor: disabled ? "#999999" : borderColor,
    color: disabled ? "#999999" : textColor,
    textTransform: "capitalize",
    borderRadius: rounded || "7px",
    fontWeight: "400",
    width: size?.width || "150px",
    height: size?.height || "40px",
    fontSize: textSize || "16px",
    background: bgColor || "transparent",
    cursor: disabled ? "not-allowed" : "pointer",
    lineHeight: "25px",
  };

  return (
    <Button
      disabled={disabled || false}
      variant="outlined"
      startIcon={icon ? icon : ""}
      style={buttonStyle}
      onClick={onClick ? onClick : ""}
    >
      {text}
    </Button>
  );
};
