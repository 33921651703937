import React from "react";
import { Box, Stack } from "@mui/material";
import { Grid, Button, Paper, Typography, makeStyles } from "@material-ui/core";
import { DisabledButtonMobile } from "../../../../pages/eLearning/Grading/GradingStyles";
import { GradingLoading } from "../../../../pages/eLearning/Grading/utils/GradingLoading";
import styled from "styled-components";
import FiberSmartRecordIcon from "@mui/icons-material/FiberSmartRecord";
import UploadIcon from "@mui/icons-material/Upload";
import VideoModal from "../../../../pages/eLearning/Grading/utils/VideoModal";
const useStyles = makeStyles((theme) => ({
  lessonSlideDetailRoot: {
    borderRadius: "5px",
    boxShadow: "0px 0px 4px 0px #00000040",
    maxWidth: "100%",
    padding: "10px",
    margin: "10px 20px",
    marginTop: window.location.href.includes("myhelpdesk/slider")
      ? "10px"
      : "150px",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    overflowY: "auto",
    background: "white",
  },
  lessonSlideDetailVideoContainer: {
    display: (props) => (props.description.videoUrl ? "flex" : "none"),
    width: "100%",
    justifyContent: "center",
    alignItems: "start",
  },
  lessonSlideDetailVideo: {
    width: "100%",
    maxHeight: "340px",
  },
  lessonSlideDetailIframe: {
    width: "100%",
    height: "300px",
  },
}));

const SubmitVideoContainer = styled.div`
  background-color: rgba(247, 248, 252, 1);
  height: 100%;
  padding: 20px 40px 40px 40px;
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const videoContainer = styled.div`
  padding: 10px 0px;
  width: 350px;
  minwidth: 300px;
  height: 250px;
`;

const SlideDetail = ({
  description,
  handleRecordVideo,
  handleUploadVideo,
  fileInputRef,
  isLoadingVideoURL,
  handleFileSelect,
  openModal,
  handleCloseModal,
  isloadingEnableVideo,
  handleModalLoading,
  handleSubmitVideo,
  sliderMobileRef,
  videoURL,
  videoURLdd,
}) => {
  const classes = useStyles({ description });

  const renderContent = () => {
    return { __html: description.content || "" };
  };

  const getFileExtension = (url) => {
    // Extract the part of the URL after the last period
    const extension = url?.split(".").pop();

    // Return the extension if it exists, otherwise return an empty string
    return extension?.includes("/") ? "" : extension;
  };

  var extension = getFileExtension(description.documenturl);

  return (
    <Box className={classes.lessonSlideDetailRoot} component="article">
      {description.lesson_type === 1 ? (
        <Typography component="div" dangerouslySetInnerHTML={renderContent()} />
      ) : description.lesson_type === 2 ? (
        <Box className={classes.lessonSlideDetailVideoContainer}>
          <video
            className={classes.lessonSlideDetailVideo}
            id="prevideo"
            controls={true}
          >
            <source
              className="preview_video_src"
              src={description.videoUrl}
              type="video/mp4"
            />
          </video>
        </Box>
      ) : description.lesson_type === 5 ? (
        description.video_quiz_url ? (
          <videoContainer className={classes.videoContainer}>
            <video
              src={description.video_quiz_url}
              controls
              // className={classes.video}
              style={{
                width: "95%",
                maxHeight: "300px",
                paddingTop: "20px",
              }}
              playsInline
            ></video>
          </videoContainer>
        ) : (
          <SubmitVideoContainer>
            <div
              className="align-items-center"
              style={{
                overflowY: "scroll",
                paddingTop: "1%",
                textAlign: "center",
              }}
              ref={sliderMobileRef}
            >
              <div
                className={"d-flex justifyContent center"}
                style={{ justifyContent: "center" }}
              >
                <p className={"mb-0 " + classes.lessonTitleS}>
                  Record and upload video
                </p>
              </div>
              <div
                className={"d-flex justifyContent center"}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "3%",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Paper
                  style={{
                    display: "flex",
                    width: "400px",
                    minWidth: "340px",
                    height: "auto",
                    minHeight: "340px",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px 0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "5px",
                      }}
                    >
                      <Button
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "136px",
                          height: "111px",
                          background: "rgba(27, 63, 143, 0.2)",
                          borderRadius: "7px",
                        }}
                        onClick={handleRecordVideo}
                      >
                        <FiberSmartRecordIcon
                          style={{
                            width: "30px",
                            height: "30px",
                            color: "#1B3F8F",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: "400",
                            color: "#1B3F8F",
                          }}
                        >
                          Record
                        </span>
                      </Button>
                      <div>
                        <Button
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "136px",
                            height: "111px",
                            background: "rgba(27, 63, 143, 0.2)",
                            borderRadius: "7px",
                          }}
                          onClick={handleUploadVideo}
                        >
                          <UploadIcon
                            style={{
                              width: "30px",
                              height: "30px",
                              color: "#1B3F8F",
                            }}
                          />
                          <span
                            style={{
                              fontWeight: "400",
                              color: "#1B3F8F",
                            }}
                          >
                            Upload
                          </span>
                        </Button>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          accept="video/*"
                          onChange={handleFileSelect}
                        />
                      </div>
                    </div>
                    {!isLoadingVideoURL && videoURLdd && (
                      <videoContainer className={classes.videoContainer}>
                        <video
                          src={videoURLdd}
                          controls
                          // className={classes.video}
                          style={{
                            width: "95%",
                            maxHeight: "300px",
                            paddingTop: "20px",
                          }}
                          playsInline
                        ></video>
                      </videoContainer>
                    )}
                  </div>
                </Paper>
                <VideoModal
                  open={openModal}
                  handleClose={handleCloseModal}
                  handleLoading={handleModalLoading}
                />
              </div>

              <Box m={"1rem"}>
                {isloadingEnableVideo && isLoadingVideoURL ? (
                  <Stack spacing={2} m={"1rem"}>
                    <GradingLoading />
                  </Stack>
                ) : isLoadingVideoURL ? (
                  <DisabledButtonMobile>Submit</DisabledButtonMobile>
                ) : (
                  <Button
                    style={{
                      cursor: "pointer",
                      textTransform: "capitalize",
                      fontSize: "16px",
                      height: "35px",
                      width: "115px",
                      backgroundColor: "#135098",
                      color: "white",
                      borderRadius: "29px",
                    }}
                    // onClick={() => this.passFunction("1")}
                    onClick={handleSubmitVideo}
                  >
                    Submit
                  </Button>
                )}
              </Box>
            </div>
          </SubmitVideoContainer>
        )
      ) : description.lesson_type === 4 ? (
        <Grid
          style={{
            display: description.imageUrl ? "flex" : "none",
            maxHeight: "250px",
            textAlign: "center",
            justifyContent: "center",
            position: "relative",
            border: "1px solid rgb(0,0,0,0.5)",
          }}
          item
          sm={12}
          xs={12}
        >
          <img
            alt=""
            style={{ width: "100%", height: "247px", objectFit: "contain" }}
            src={
              description.imageUrl &&
              (description.imageUrl?.startsWith("https")
                ? description.imageUrl
                : description.imageUrl?.startsWith("http:")
                ? description.imageUrl?.replaceAll("http:", "https:")
                : "https://" + description.imageUrl)
            }
          />
        </Grid>
      ) : extension === "pptx" || extension === "ppt" ? (
        <iframe
          className={classes.lessonSlideDetailIframe}
          id="myIframe"
          src={
            `https://view.officeapps.live.com/op/embed.aspx?src=` +
            description.documenturl
          }
          title="description"
        />
      ) : extension === "pdf" ? (
        <iframe
          src={description.documenturl}
          title="Document Viewer"
          className={classes.lessonSlideDetailIframe}
          // toolbar={0}
          id="myIframe"
          // navpanes="0"
          width="100%"
          height="500px"
          style={{ border: "none" }}
        />
      ) : (
        "File type is incorrect"
      )}
    </Box>
  );
};
export default SlideDetail;
