import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Divider from "@mui/material/Divider";

const ITEM_HEIGHT = 48;

export const MenuDropdown = ({ options, iconColor, handleViewLog }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon
          style={{
            color: iconColor,
            fontSize: "20px", // Adjust the size as needed
          }}
        />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
            border: "1px solid rgba(0, 0, 0, 0.05)",
            borderRadius: "5px",
          },
        }}
      >
        {options.map((option, index) => (
          <React.Fragment key={option}>
            <MenuItem
              selected={option === "Pyxis"}
              onClick={() => {
                if (option === "View Logs") {
                  handleViewLog();
                }
                handleClose();
              }}
            >
              {option}
            </MenuItem>
            {index < options.length - 1 && (
              <Divider style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }} />
            )}
          </React.Fragment>
        ))}
      </Menu>
    </div>
  );
};
