import { makeStyles } from "@mui/styles";
import { mobileTheme } from "../../../constant";

export const Styles = makeStyles({
  modal: {
    outline: "none",
  },
  content: {
    position: "absolute",
    bottom: "0",
    top: "0px",
    width: "100%",
    height: "100%",
    paddingBottom: "100px",
    background: "white",
    outline: "none",
    border: "none",
  },
  container: {
    width: "100%",
    display: "flex",
    position: "fixed",
    padding: "10px",
    height: "56px",
    marginBottom: "70px",
    alignItems: "center",
    background: "white",
    boxShadow: "0px 1px 4px 0px #0000001F",
    justifyContent: "center",
    zIndex: 99,
  },
  backButton: {
    position: "absolute",
    left: "20px",
    color: mobileTheme,
  },
  title: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#000000",
  },
  //   accordianContainer: {
  //     marginTop: "60px",
  //     padding: "10px 20px",
  //     overflowY: "auto",
  //     display: "flex",
  //     height: "100%",
  //     paddingBottom: "120px",
  //     flexDirection: "column",
  //     gap: "10px",
  //     border: "none",
  //   },
  //   checkbox: {
  //     color: mobileTheme,
  //     "&.Mui-checked": {
  //       color: mobileTheme,
  //     },
  //   },
  //   label: {
  //     fontSize: "12px",
  //     fontWeight: "400",
  //     color: "#00000080",
  //   },
  //   btnContainer: {
  //     position: "fixed",
  //     bottom: "0px",
  //     height: "115px",
  //     background: "white",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "start",
  //     gap: "10px",
  //     paddingTop: "10px",
  //     width: "100%",
  //   },
  //   saveBtn: {
  //     width: "150px",
  //     height: "40px",
  //     borderRadius: "7px",
  //     background: mobileTheme,
  //     color: "white",
  //     fontSize: "16px",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //   },
  //   cancelBtn: {
  //     width: "150px",
  //     height: "40px",
  //     borderRadius: "7px",
  //     background: `rgba(${hexToRgb(mobileTheme)}, 0.25)`,
  //     color: mobileTheme,
  //     fontSize: "16px",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //   },

  //   searchInput: {
  //     width: "100%",
  //   },

  //   inputField: {
  //     width: "100%",
  //     "& .MuiOutlinedInput-root": {
  //       "& fieldset": {
  //         borderRadius: "6px",
  //         borderColor: "#E3E3E3",
  //         borderWidth: "1px",
  //       },
  //       "&:hover fieldset": {
  //         borderColor: "#007E63",
  //       },
  //       "&.Mui-focused fieldset": {
  //         borderColor: "#007E63",
  //       },
  //     },
  //   },

  filterContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    margin: "auto",
    marginTop: "82px",
    padding: "1rem 1.5rem",
    position: "relative",
  },
  filterText: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "18.75px",
    textAlign: "left",
    color: "#000000",
  },
  filterButtonGroup: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    width: "100%",
  },
  filterButton: {
    height: "31px",
    padding: "0.6rem 1.2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "6px !important",
    border: "1px solid #E3E3E3",
    fontFamily: "Roboto, sans-serif",
    fontSize: "11px",
    fontWeight: 400,
    lineHeight: "12.89px",
  },
  flterBottomBtnContainer: {
    position: "absolute",
    bottom: "3rem",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
  },
  filterApplyBtn: {
    height: "40px !important",
    borderRadius: "7px !important",
    backgroundColor: "#007E63 !important",
    color: "#FFFFFF !important",
    fontFamily: "Roboto, sans-serif !important",
    fontSize: "16px !important",
    fontWeight: "500 !important",
    lineHeight: "18.75px !important",
    textAlign: "center",
    padding: "0.9rem 3rem !important",
  },
  flterCancelBtn: {
    height: "40px !important",
    borderRadius: "7px !important",
    backgroundColor: "#007E6340 !important",
    color: "#007E63 !important",
    fontFamily: "Roboto, sans-serif !important",
    fontSize: "16px !important",
    fontWeight: "500 !important",
    lineHeight: "18.75px !important",
    textAlign: "center",
    padding: "0.9rem 3rem !important",
  },
});
