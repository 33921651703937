import React, { useState } from "react";
import EmployeeDeduction from "./EmployeeDeduction";
import EmployerDeduction from "./EmployerDeduction";
import "./PayrollStyles.css";

const UserPayroll = () => {
  const [selected, setSelected] = useState("employee");

  const handleToggle = (option) => {
    setSelected(option);
  };

  return (
    <div>
      <div className="container">
        <div className="toggleButton">
          <button
            className={`toggleOption ${selected === "employee" ? "active" : ""}`}
            onClick={() => handleToggle("employee")}
          >
            Employee Deduction
          </button>
          <button
            className={`toggleOption ${selected === "employer" ? "active" : ""}`}
            onClick={() => handleToggle("employer")}
          >
            Employer Deduction
          </button>
        </div>

        <div className="content">
          {selected === "employee" && <EmployeeDeduction />}
          {selected === "employer" && <EmployerDeduction />}
        </div>
      </div>
    </div>
  );
};

export default UserPayroll;
