import { makeStyles } from "@mui/styles";
import { ovHrV5Color } from "../../../../config";

export const addOrEditRolesStyles = makeStyles({
  hrCheckboxRoot: {
    padding: "30px 20px",
    color: `${ovHrV5Color.main} !important`,
    "&.Mui-checked": {
      color: `${ovHrV5Color.main} !important`,
    },
    "&:hover": {
      color: `${ovHrV5Color.main} !important`,
    },
  },
  hrTextField: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: `${ovHrV5Color.main} !important`,
      },
    },
  },
  select: {
    "MuiSelect-outlined.MuiSelect-outlined": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
    '& .MuiInputBase-input': {
      '&:focus': {
      borderRadius: 6,
      borderColor: `${ovHrV5Color.main} !important`,
    },
    },
    "&:before": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
    "&:after": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
  },
  icon: {
    fill: ovHrV5Color.main,
  },
  headCell: {
    color: "#ffffff !important",
    fontWeight: "500 !important",
    textTransform: "capitalize",
  },
  rootToolbar: {
    minHeight: "58px !important",
    padding: "2px !important",
  },
  title: {
    flex: "1 1 100% !important",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  hrExpansionPanelMain: {
    "&:last-child": {
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
    "&:first-child": {
      borderTopLeftRadius: "0px !important",
      borderTopRightRadius: "0px !important",
    },
  },
  hrExpansionPanelSummary: {
    backgroundColor: `${ovHrV5Color.main} !important`,
  },
  hrHeading: {
    fontSize: "15px !important",
    fontWeight: "400 !important",
    color: "#ffffff !important",
  },
  headingReports: {
    fontSize: "15px !important",
    fontWeight: "400 !important",
    color: "black !important",
  },
  hrHeadingIcon: {
    color: "#ffffff !important",
  },
  hrExpansionPanelDetailsSecond: {
    borderLeft: `5px solid  ${ovHrV5Color.main} !important`,
    borderRight: `5px solid  ${ovHrV5Color.main} !important`,
    borderBottom: `5px solid ${ovHrV5Color.main} !important`,
    padding: "20px !important !important",
    display: "flex !important",
    flexDirection: "column !important",
  },
  hrExpansionPanel: {
    "&:last-child": {
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
    "&:first-child": {
      borderTopLeftRadius: "0px !important",
      borderTopRightRadius: "0px !important",
    },
  },
  hrExpansionPanelSummaryThird: {
    backgroundColor: "#f6f7f754 !important",
    padding: "0px 10px !important",
    height: "30px !important",
  },
  hrExpansionPanelDetailsThird: {
    padding: "10px 20px !important",
  },
  ExpansionPanelDetailsThirdCategory: {
    padding: "10px 20px !important",
    height: "20px !important",
  },
  ExpansionPanelDetailsThirdRole: {
    padding: "10px 20px !important",
    marginLeft: "32px !important",
    display: "flex !important",
    flexDirection: "column !important",
  },
  ExpansionPanelDetailsThirdBase: {
    padding: "10px 20px !important",
    marginLeft: "32px !important",
  },
  hrFormControlLabelSecond: {
    padding: "6px !important",
  },
  hrPageTitle: {
    lineHeight: "40px !important",
    color: "black !important",
  },
  pageHeading: {
    fontSize: "40px !important",
    color: ovHrV5Color.main,
    fontWeight: "700 !important",
  },
  viewLogsBtn: {
    border: `1px solid ${ovHrV5Color.main} !important`,
    borderRadius: "5px !important",
    color: `${ovHrV5Color.main} !important`,
    display: "inline-block !important",
    fontSize: "13px !important",
    height: "100% !important",
    width: "auto !important",
    padding: "8px 15px !important",
    "&:hover": {
      color: `${ovHrV5Color.main} !important`,
      textDecoration: "none !important",
    },
  },
});
