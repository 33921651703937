// constants.js
// Elearning Data

  export const elearning_Library_Data = [
    {
      Name: "elearning_library_category_add",
      Label: "Add Category",
    },
    {
      Name: "elearning_library_category_edit",
      Label: "Edit Category",
    },
    {
      Name: "elearning_library_category_delete",
      Label: "Delete Category",
    },
    {
      Name: "elearning_library_lessons_add",
      Label: "Add Lesson",
    },
    {
      Name: "elearning_library_lessons_edit",
      Label: "Edit Lesson",
    },
    {
      Name: "elearning_library_lessons_delete",
      Label: "Delete Lesson",
    },
    {
      Name: "elearning_library_copy_link",
      Label: "Copy Link",
    },
    {
      Name: "elearning_assign_library",
      Label: "Assign Library"
    }
  ];


  export const elearning_earning_Data = [
    {
      Name: "elearning_my_earnings",
      Label: "My Earnings",
    },
    {
      Name: "elearning_team_earnings",
      Label: "Team Earnings",
    },
  ];

   export const elearning_Feedback_Data = [
    {
      Name: "elearning_view_feedback",
      Label: "View Feedback",
    },
    {
      Name: "elearning_submit_feedback",
      Label: "Submit Feedback",
    },
    {
      Name: "elearning_feedback_update_status",
      Label: "Edit/Update Feedback",
    },
  ];

  export const elearning_Course_Data = [
    {
      Name: "elearning_course_add",
      Label: "Add Course",
    },
    {
      Name: "elearning_course_edit",
      Label: "Edit Course",
    },
    {
      Name: "elearning_course_delete",
      Label: "Delete Course",
    },
    {
      Name: "elearning_course_assign",
      Label: "Assign Course",
    },
  ];

   export const elearning_Helpdesk_Data = [
    {
      Name: "elearning_helpdesk_add",
      Label: "Add helpdesk",
    },
    {
      Name: "elearning_helpdesk_edit",
      Label: "Edit helpdesk",
    },
    {
      Name: "elearning_helpdesk_delete",
      Label: "Delete helpdesk",
    },
    {
      Name: "elearning_helpdesk_devices",
      Label: "Device Management",
    },
    {
      Name: "elearning_helpdesk_view_lessons",
      Label: "View helpdesk lessons",
    },
  ];

   export const elearning_Grading_Data = [
    {
      Name: "elearning_grading_my_grading",
      Label: "My Grading",
    },
    {
      Name: "elearning_grading_team_grading",
      Label: "Team Grading",
    },
    {
      Name: "elearning_grading_settings",
      Label: "Settings",
    },
  ];

  export const elearning_settings = [
    {
      Name: "elearning_dashboard_refresh_settings",
      Label: "Dashboard Settings",
    },
    {
      Name: "elearning_earning_settings",
      Label: "Earning Settings",
    },
  ];

  //Scheduler Data

  export const scheduler_Schedule_Data = [
    {
      Name: "scheduler_schedule_view_others_schedule",
      Label: "View Team Schedule",
    },
    {
      Name: "scheduler_schedule_add_shifts",
      Label: "Add Shifts",
    },
    {
      Name: "scheduler_schedule_edit_shifts",
      Label: "Edit Shifts",
    },
    {
      Name: "scheduler_schedule_delete_shifts",
      Label: "Delete Shifts",
    },
    {
      Name: "scheduler_schedule_drag",
      Label: "Drag Drop Shifts",
    },
    {
      Name: "scheduler_schedule_view_events",
      Label: "View Events",
    },
    {
      Name: "scheduler_schedule_add_events",
      Label: "Add Events",
    },
    {
      Name: "scheduler_schedule_edit_events",
      Label: "Edit Events",
    },
    {
      Name: "scheduler_schedule_delete_events",
      Label: "Delete Events",
    },
    {
      Name: "scheduler_schedule_print",
      Label: "Print Schedule",
    },
    {
      Name: "scheduler_schedule_copy",
      Label: "Copy Schedule",
    },
    {
      Name: "scheduler_schedule_publish",
      Label: "Publish Schedule",
    },
  ];

  export const scheduler_MyAvailability_Data = [
    {
      Name: "scheduler_myavailability_add",
      Label: "Add Availability",
    },
    {
      Name: "scheduler_myavailability_edit",
      Label: "Edit Availability",
    },
    {
      Name: "scheduler_myavailability_delete",
      Label: "Delete Availability",
    },
  ];


  export const scheduler_TeamAvailability_Data = [
    {
      Name: "scheduler_teamavailability_add",
      Label: "Add Availability",
    },
    {
      Name: "scheduler_teamavailability_edit",
      Label: "Edit Availability",
    },
    {
      Name: "scheduler_teamavailability_delete",
      Label: "Delete Availability",
    },
    {
      Name: "scheduler_teamavailability_approve",
      Label: "Approve & Decline",
    },
  ];


  export const scheduler_TimeOffRequests_Data =  [
    {
      Name: "scheduler_timeoffrequests_add",
      Label: "Add Time-Off",
    },
    {
      Name: "scheduler_timeoffrequests_edit",
      Label: "Edit Time-Off",
    },
    {
      Name: "scheduler_timeoffrequests_delete",
      Label: "Delete Time-Off",
    },
    {
      Name: "scheduler_timeoffrequests_approve",
      Label: "Approve Time-Off",
    },
  ];

  export const scheduler_TradeShifts_Data = [
    {
      Name: "scheduler_tradeshifts_add",
      Label: "Add Trade",
    },
    {
      Name: "scheduler_tradeshifts_edit",
      Label: "Edit Trade",
    },
    {
      Name: "scheduler_tradeshifts_delete",
      Label: "Delete Trade",
    },
    {
      Name: "scheduler_tradeshifts_approve",
      Label: "Approve & Decline",
    },
  ];

  export const scheduler_CoverShifts_Data = [
    {
      Name: "scheduler_covershifts_add",
      Label: "Add Cover",
    },
    {
      Name: "scheduler_covershifts_edit",
      Label: "Edit Cover",
    },
    {
      Name: "scheduler_covershifts_delete",
      Label: "Delete Cover",
    },
    {
      Name: "scheduler_covershifts_approve",
      Label: "Approve & Decline",
    },
  ];

  export const scheduler_payroll_run_Data = [
    {
      Name: "level_1_approval",
      Label: "Level 1 Approval",
    },
    {
      Name: "level_2_approval",
      Label: "Level 2 Approval",
    },
    {
      Name: "level_3_approval",
      Label: "Level 3 Approval",
    },
    {
      Name: "payrun_reset",
      Label: "Reset Button",
    },
  ];

  export const scheduler_correction_request_Data = [
    {
      Name: "scheduler_correction_request_my",
      Label: "My Correction Request",
    },
    {
      Name: "scheduler_correction_request_team",
      Label: "Team Correction Request",
    },
    {
      Name: "correction_Level_1_Approval",
      Label: "Level 1 Approval",
    },
    {
      Name: "correction_Level_2_Approval",
      Label: "Level 2 Approval",
    },
    {
      Name: "correction_Level_3_Approval",
      Label: "Level 3 Approval",
    },
    {
      Name: "delete_correction_request",
      Label: "Delete Correction Request",
    },
    {
      Name: "correction_reset",
      Label: "Reset Correction Request",
    },
  ];

  export const scheduler_Timesheet_Data = [
    {
      Name: "scheduler_timesheet_add",
      Label: "Add Timecard",
    },
    {
      Name: "scheduler_timesheet_edit",
      Label: "Edit Timecard",
    },
    {
      Name: "scheduler_timesheet_view",
      Label: "View Timecard",
    },
    {
      Name: "scheduler_timesheet_view_timecard_notes",
      Label: "Add/View Timecard notes",
    },
    {
      Name: "scheduler_timesheet_fix_issue",
      Label: "Fix Timecard issues",
    },
    {
      Name: "scheduler_timesheet_view_edit_history",
      Label: "View Edit History",
    },
    {
      Name: "scheduler_timesheet_lock",
      Label: "Lock Unlock Timecard",
    },
    {
      Name: "scheduler_timesheet_filter",
      Label: "Filter Timesheet",
    },
    {
      Name: "scheduler_timesheet_download",
      Label: "Download Timesheet",
    },
    {
      Name: "scheduler_timesheet_view_others_timesheet",
      Label: "View Team Timesheet",
    },
  ];

  export const scheduler_TimecardReport_Data = [
    {
      Name: "scheduler_timecardreport_view_detail",
      Label: "View Detail Timecard",
    },
  ];

  export const scheduler_Payroll_Data = [
    {
      Name: "scheduler_payroll_add",
      Label: "Add Payroll",
    },
    {
      Name: "scheduler_payroll_edit",
      Label: "Edit Payroll",
    },
    {
      Name: "scheduler_payroll_delete",
      Label: "Delete Payroll",
    },
    {
      Name: "scheduler_payroll_assign",
      Label: "Assign Payroll",
    },
    {
      Name: "scheduler_payroll_view_assign",
      Label: "View Assign Payroll",
    },
    {
      Name: "scheduler_payroll_code_add",
      Label: "Add Timecode",
    },
    {
      Name: "scheduler_payroll_code_edit",
      Label: "Edit Timecode",
    },
    {
      Name: "scheduler_payroll_code_delete",
      Label: "Delete Timecode",
    },
  ];

  export const scheduler_Payroll_Summary_Data = [
    {
      Name: "scheduler_payroll_reset_data_and_approvals",
      Label: "Reset Data & Approvals",
    },
    {
      Name: "scheduler_payroll_id",
      Label: "Payroll Id",
    },
  ];

  export const scheduler_Roles_Data = [
    {
      Name: "scheduler_roles_add",
      Label: "Add Role",
    },
    {
      Name: "scheduler_roles_edit",
      Label: "Edit Role",
    },
    {
      Name: "scheduler_roles_delete",
      Label: "Delete Role",
    },
  ];

  //HR Data

  export const roles_and_permissions_Data = [
    {
      Name: "hr_view_roles",
      Label: "View Roles",
    },
    {
      Name: "hr_add_role",
      Label: "Add Role",
    },
    {
      Name: "hr_edit_role",
      Label: "Edit Role",
    },
    {
      Name: "hr_delete_role",
      Label: "Delete Role",
    },
  ];

  export const users_HR_Data = [
    {
      Name: "hr_view_users",
      Label: "View Users",
    },
    {
      Name: "hr_add_user",
      Label: "Add User",
    },
    {
      Name: "hr_edit_user",
      Label: "Edit User",
    },
    {
      Name: "hr_delete_user",
      Label: "Delete User",
    },
  ];

  export const evaluation_HR_Data = [
    {
      Name: "hr_view_question_categories",
      Label: "Question Categories",
    },
    {
      Name: "hr_add_question_category",
      Label: "Add Question Category",
    },
    {
      Name: "hr_edit_question_category",
      Label: "Edit Question Category",
    },
    {
      Name: "hr_delete_question_category",
      Label: "Delete Question Category",
    },
    {
      Name: "hr_view_questions",
      Label: "Question List",
    },
    {
      Name: "hr_add_question",
      Label: "Add Question",
    },
    {
      Name: "hr_edit_question",
      Label: "Edit Question",
    },
    {
      Name: "hr_delete_question",
      Label: "Delete Question",
    },
    {
      Name: "hr_view_observation_categories",
      Label: "Observation Categories ",
    },
    {
      Name: "hr_add_observation_category",
      Label: "Add Observation Category",
    },
    {
      Name: "hr_edit_observation_category",
      Label: "Edit Observation Category",
    },
    {
      Name: "hr_delete_observation_category",
      Label: "Delete Observation Category",
    },
    {
      Name: "hr_observation_list",
      Label: "Observation List",
    },
    {
      Name: "hr_add_observation",
      Label: "Add Observation",
    },
    {
      Name: "hr_edit_observation",
      Label: "Edit Observation",
    },
    {
      Name: "hr_delete_observation",
      Label: "Delete Observation",
    },
    {
      Name: "hr_view_evaluation_forms_myself",
      Label: "Assigned To Me Evaluation Forms",
    },
    {
      Name: "hr_view_evaluation_forms_team",
      Label: "Assigned By Me Evaluation Forms",
    },
    {
      Name: "hr_add_evaluation_form",
      Label: "Add Evaluation Forms",
    },
    {
      Name: "hr_edit_evaluation_form",
      Label: "Edit Evaluation Forms",
    },
    {
      Name: "hr_delete_evaluation_form",
      Label: "Delete Evaluation Forms",
    },
    {
      Name: "hr_view_evaluation_settings",
      Label: "View Evaluation Settings",
    },
    {
      Name: "hr_publish_form_stages",
      Label: "Publish Evaluation Settings",
    },
    {
      Name: "hr_add_form_stage",
      Label: "Add Form Stage",
    },
  ];

  export const groups_HR_Data = [
    {
      Name: "hr_view_groups",
      Label: "View Groups",
    },
    {
      Name: "hr_view_group_members",
      Label: "View Group Members",
    },
    {
      Name: "hr_create_group",
      Label: "Create groups",
    },
    {
      Name: "hr_edit_group",
      Label: "Edit Group",
    },
    {
      Name: "hr_delete_group",
      Label: "Delete Group",
    },
  ];

  export const boarding_HR_Data = [
    {
      Name: "hr_view_onboarding_track",
      Label: "View On-boarding List",
    },
    {
      Name: "hr_add_update_onboarding_answer",
      Label: "Save user info on-boarding",
    },
    // {
    //   Name: "hr_on_boarding_list_creation",
    //   Label: "On-boarding List Creation",
    // },
    {
      Name: "hr_view_offboarding_track",
      Label: "View Off-boarding List",
    },
    {
      Name: "hr_add_update_offboarding_answer",
      Label: "Save user info off-boarding",
    },
    // {
    //   Name: "hr_off_boarding_list_creation",
    //   Label: "Off-boarding List Creation",
    // },
  ];

  export const hiring_HR_Data = [
    {
      Name: "hr_view_job_postings",
      Label: "View Job Postings",
    },
    {
      Name: "hr_add_job_posting",
      Label: "Add Jobs",
    },
    {
      Name: "hr_edit_job_posting",
      Label: "Edit Jobs",
    },
    {
      Name: "hr_delete_job_posting",
      Label: "Delete Jobs",
    },
    {
      Name: "hr_view_candidates",
      Label: "View Candidates",
    },
    {
      Name: "hr_add_candidate",
      Label: "Add Candidate",
    },
    {
      Name: "hr_edit_candidate",
      Label: "Edit Candidate",
    },
    {
      Name: "hr_delete_candidate",
      Label: "Delete Candidate",
    },
    {
      Name: "hr_view_interviews",
      Label: "Interviews List",
    },
    {
      Name: "hr_add_interview",
      Label: "Schedule Interview",
    },
    {
      Name: "hr_edit_interview",
      Label: "Edit Interview",
    },
    {
      Name: "hr_delete_interview",
      Label: "Delete Interview",
    },
  ];

  export const hierarchy_HR_Data = [
    {
      Name: "hr_view_hierarchy",
      Label: "View Hierarchy",
    },
  ];

  export const events_HR_Data = [
    {
      Name: "hr_graduation_event",
      Label: "Graduation Event",
    },
    {
      Name: "hr_view_promotion_rules",
      Label: "View Promotion Rule",
    },
    {
      Name: "hr_add_promotion_rule",
      Label: "Add Promotion Rule",
    },
    {
      Name: "hr_edit_promotion_rule",
      Label: "Edit Promotion Rule",
    },
    {
      Name: "hr_delete_promotion_rule",
      Label: "Delete Promotion Rule",
    },
    {
      Name: "hr_view_team_milestones",
      Label: "View Team Milestones",
    },
    {
      Name: "hr_view_birthdays",
      Label: "View birthday and Holidays",
    },
    {
      Name: "hr_view_team_life_events",
      Label: "View Team Life Events",
    },
    {
      Name: "hr_view_organizational_events",
      Label: "View Organizational Event",
    },
  ];

  export const template_HR_Data = [
    {
      Name: "hr_view_templates_list",
      Label: "View Templates List",
    },
    {
      Name: "hr_create_template_list",
      Label: "Create Template List",
    },
    {
      Name: "hr_view_templates",
      Label: "View Templates",
    },
    {
      Name: "hr_add_template",
      Label: "Add Templates",
    },
  ];

  //News Data

  export const news_my_news_Data = [
    {
      Name: "view_all_my_news",
      Label: "View All News",
    },
    {
      Name: "view_my_news_details",
      Label: "View News Details",
    },
  ];

 export const news_news_builder_Data = [
    {
      Name: "view_all_news_builder",
      Label: "View All News",
    },
    {
      Name: "news_builder_details",
      Label: "View News Details",
    },
    {
      Name: "create_news_builder",
      Label: "Create News",
    },
    {
      Name: "edit_news_builder",
      Label: "Edit News",
    },
    {
      Name: "delete_news_builder",
      Label: "Delete News",
    },
  ];


  // Common Data

  export const common_assigned_roles_dropdown = [
    {
      label: "All roles",
      value: 1,
    },
    {
      label: "Assigned Roles",
      value: 2,
    },
    {
      label: "Assigned roles with same levels and subordinates",
      value: 3,
    },
    {
      label: "SubOrdinates",
      value: 4,
    },
  ];

  export const common_Company_Data = [
    {
      Name: "common_company_edit",
      Label: "Edit Company",
    },
    {
      Name: "common_company_delete",
      Label: "Delete Company",
    },
  ];

  export const common_Location_Data = [
    {
      Name: "common_location_add",
      Label: "Add Location",
    },
    {
      Name: "common_location_edit",
      Label: "Edit Location",
    },
    {
      Name: "common_location_delete",
      Label: "Delete Location",
    },
  ];

  export const common_Users_Data = [
    {
      Name: "common_users_add",
      Label: "Add User",
    },
    {
      Name: "common_users_edit",
      Label: "Edit User",
    },
    {
      Name: "common_users_delete",
      Label: "Delete User",
    },
    {
      Name: "common_users_loginas",
      Label: "Login as User",
    },
    {
      Name: "common_users_active",
      Label: "Deactive User",
    },
    {
      Name: "common_users_is_view",
      Label: "View Assigned Users",
    },
  ];

  export const common_Roles_Data = [
    {
      Name: "common_roles_add",
      Label: "Add Roles",
    },
    {
      Name: "common_roles_edit",
      Label: "Edit Roles",
    },
    {
      Name: "common_roles_delete",
      Label: "Delete Roles",
    },
    {
      Name: "common_roles_view_assigned",
      Label: "View Assigned Roles",
    },
  ];

  export const common_Payroll_Data = [
    {
      Name: "common_payroll_add",
      Label: "Add Payroll",
    },
    {
      Name: "common_payroll_edit",
      Label: "Edit Payroll",
    },
    {
      Name: "common_payroll_delete",
      Label: "Delete Payroll",
    },
    {
      Name: "common_payroll_viewHistory",
      Label: "View History",
    },
  ];

  export const common_Ticket_Data = [
    {
      Name: "common_tickets_add",
      Label: "Add Ticket",
    },
    {
      Name: "common_tickets_edit",
      Label: "Edit Ticket",
    },
    {
      Name: "common_tickets_delete",
      Label: "Delete Ticket",
    },
  ]

  export const common_Ideas_Data = [
    {
      Name: "common_ideas_add",
      Label: "Add Ideas",
    },
    {
      Name: "common_ideas_edit",
      Label: "Edit Ideas",
    },
    {
      Name: "common_ideas_delete",
      Label: "Delete Ideas",
    },
  ];

  // Feedback Data

  export const feedback_Followups_Data = [
    {
      Name: "feedback_followups_approve",
      Label: "Approve Followups",
    },
    {
      Name: "feedback_followups_view_detail",
      Label: "View Detail Followups",
    },
    {
      Name: "feedback_followups_delete",
      Label: "Delete Followups",
    },
  ];

  export const feedback_Campaigns_Data = [
    {
      Name: "feedback_campaign_add",
      Label: "Add Campaign",
    },
    {
      Name: "feedback_campaign_edit",
      Label: "Edit Campaign",
    },
    {
      Name: "feedback_campaign_delete",
      Label: "Delete Campaign",
    },
    {
      Name: "feedback_campaign_assign_location",
      Label: "Assign Locations",
    },
  ];

  export const feedback_QR_Locations_Data = [
    {
      Name: "feedback_qr_locations_devices",
      Label: "Device Management",
    },
    {
      Name: "feedback_qr_locations_view_history",
      Label: "View History",
    },
    {
      Name: "feedback_qr_locations_edit",
      Label: "Edit Location",
    },
  ];

  export const feedback_Settings_Data = [
    {
      Name: "feedback_settings_sms",
      Label: "SMS",
    },
    {
      Name: "feedback_settings_percentage",
      Label: "Percentage",
    },
    {
      Name: "feedback_settings_timezone",
      Label: "Timezone",
    },
    {
      Name: "feedback_settings_notification_dashboard",
      Label: "Dashboard Notification",
    },
    {
      Name: "feedback_settings_notification_email",
      Label: "Email Notification",
    },
    {
      Name: "feedback_settings_notification_push",
      Label: "Push Notification",
    },
    {
      Name: "feedback_settings_notification_mobile_push",
      Label: "Mobile Push Notification",
    },
  ];

  //News Data

  export const rating_form_Data = [
    {
      Name: "rating_form_add",
      Label: "Add form",
    },
    {
      Name: "rating_form_delete",
      Label: "Delete form",
    },
  ];