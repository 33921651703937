import { useEffect, useState } from "react";
import { mobileTheme } from "../../constant";
import { Toggel } from "../../utilities/common/Button";
import "./TeamEarningDetailsMobile.scss";

import { PaymentLogsMobile } from "./PaymentLogsMobile";
import { OutlinedButton } from "../../../../utilities/v5/common";
import { EarningPaymentMobile } from "./EarningPaymentMobile";
import { useDispatch, useSelector } from "react-redux";
import { payUserEarning } from "../../../../pages/eLearning/Earning/modules/actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { history } from "../../../../history";
toast.configure();

const TeamEarningDetails = ({ userId }) => {
  const dispatch = useDispatch();
  const payUserEarningData = useSelector(
    (state) => state.eLearningEarning.payUserEarningData
  );
  const payUserEarningLoading = useSelector(
    (state) => state.eLearningEarning.payUserEarningLoading
  );

  const [selectedTab, setSelectedTab] = useState("earning");
  const [selectedRows, setSelectedRows] = useState([]);

  const handleTabChange = (value) => {
    setSelectedTab(value);
  };

  const handleSelectedRows = (rows) => {
    setSelectedRows(rows);
  };

  // const handleUserEarningPayment = () => {
  //   const data = {
  //     uid: userId,
  //     lessonDetails: selectedRows,
  //   };

  //   // Here, you can save the data to your backend or local storage
  //   console.log("User Earning Data: ", data);
  //   dispatch(payUserEarning(data));
  // };

  const handleOpenAddNote = () => {
    // setAddCommentPaymentDateAndTime(paymentDateAndTime);
    // setIsNoteModalFlag(true);
    const paymentDateAndTime = Date();

    history.push({
      pathname: `/elearning/earning/addnote/${userId}`,
      state: { paymentDateAndTime, selectedRows },
    });
  };

  useEffect(() => {
    console.log("user earning pay result:", payUserEarningData);

    if (payUserEarningData) {
      if (payUserEarningData.success === 1) {
        setSelectedRows([]);
        toast.success(payUserEarningData.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
        });
      } else {
        toast.error(payUserEarningData.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
        });
      }
    }
  }, [payUserEarningData]);

  return (
    <>
      <div className={"TeamEarningDetailsMobileRootContainer"}>
        <div className={"toggelContainer"}>
          <Toggel
            options={[
              {
                id: 1,
                heading: "earning",
                value: "earning",
              },
              {
                id: 2,
                heading: "payment logs",
                value: "payment logs",
              },
            ]}
            tabSize={{ width: "100px", height: "30px" }}
            selected={selectedTab}
            selectedBgColor={mobileTheme}
            selectedTextColor={"white"}
            textColor={"#1B3F8F"}
            size={{
              width: "203px",
              height: "34px",
            }}
            bgColor={"white"}
            border={"1px solid rgba(27, 63, 143,1)"}
            borderImageSource={
              "linear-gradient(180deg, #1B3F8F 0%, #635BFF 100%)"
            }
            handleTabChange={handleTabChange}
          />
        </div>

        {selectedTab === "earning" ? (
          <EarningPaymentMobile
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            userId={userId}
            onSelectedRows={handleSelectedRows}
            payUserEarningData={payUserEarningData}
          />
        ) : (
          <PaymentLogsMobile userId={userId} />
        )}

        {selectedTab === "earning" && (
          <div className="paynow-button-wrapper">
            <OutlinedButton
              text={"Pay Now"}
              icon={""}
              disabled={selectedRows.length < 1}
              bgColor={"white"}
              size={{ width: "130px", height: "43px" }}
              borderColor={"#1B3F8F33"}
              textColor={"#1B3F8F"}
              textSize={"17px"}
              rounded={"10px"}
              onClick={handleOpenAddNote}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TeamEarningDetails;
