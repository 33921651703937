import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ovHrV5Color } from '../config';

const styles = (theme) => ({
  formControl: {
    width: "100%",
    maxWidth: "100%"
  },
  input: {
    fontSize: '16px',
    fontWeight: 500,
    backgroundColor: '#f4f2f26b !important',
    borderRadius: 5,
    marginBottom: 0
  },
  textField: {
    width: "100%",
    maxWidth: "100%",
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: ovHrV5Color.main, // Change to your desired focus color
      },
    },
    '& .MuiFormLabel-root': {
      color: ovHrV5Color.main, // Change this to your desired label color
    },
  },
  label: {
    color: 'green', // Set label color to green
  },
  checkbox: {
    '&.Mui-checked': {
      color: ovHrV5Color.main, // Change the checked color to your desired color
    },
    '&:hover': {
      color: ovHrV5Color.main, // Change to your desired hover color
    }, // Set checkbox color to green
  },
  listItemText: {
    color: 'green', // Set list item text color to green
  },
  // Override styles for the outlined input to change the border color to green
  outlinedInput: {
    '&$focused $notchedOutline': {
      borderColor: 'green !important', // Change border color to green
    },
  },
  focused: {},
  notchedOutline: {},
});

const ITEM_HEIGHT = 48;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5,
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

class MyMultiSelect extends Component {
  render() {
    const { classes, mapId, mapName, options, selectedIds, label  } = this.props;
    return (
      <FormControl
        variant="outlined"
        className={this.props?.product === "HR" ? classes.textField : ""} 
        size="small"
      >
        <InputLabel
          id="mutiple-select-label"
        >
          {this.props.label}
        </InputLabel>
        <Select
          classes={{
            root: classes.input,
            outlined: classes.outlinedInput, // Apply outlinedInput style
          }}
          multiple
          disabled={this.props.disabled}
          value={selectedIds}
          onFocus={this.props.onFocus}
          onChange={this.props.handleChange}
          label={this.props.label}
          input={<OutlinedInput classes={{ focused: classes.focused, notchedOutline: classes.notchedOutline }} />}
          error={this.props.error}
          helperText={this.props.helperText}
          renderValue={(data) => {
            var newData = []
            options && options.map((items) => {
              if (selectedIds.indexOf(items[mapId]) > -1) {
                newData.push(items[mapName])
              }
            })
            return newData.join(",")
          }}
          MenuProps={MenuProps}
        >
          {options && options.map((items) => (
            <MenuItem
              key={items[mapId]}
              value={items[mapId]}
            >
              <Checkbox
                checked={selectedIds?.indexOf(items[mapId]) > -1}
                classes={{ root: this.props.product ===  "HR" ? classes.checkbox : "" }}
              />
              <ListItemText
                primary={items[mapName]}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
}

export default withStyles(styles)(MyMultiSelect);