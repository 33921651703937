// Earning constants
export const GET_MY_EARNING_LOADING = "GET_MY_EARNING_LOADING";
export const GET_MY_EARNING_DATA = "GET_MY_EARNING_DATA";

export const GET_TEAM_EARNING_LOADING = "GET_TEAM_EARNING_LOADING";
export const GET_TEAM_EARNING_DATA = "GET_TEAM_EARNING_DATA";

export const GET_USER_EARNING_LOADING = "GET_USER_EARNING_LOADING";
export const GET_USER_EARNING_DATA = "GET_USER_EARNING_DATA";

export const GET_USER_PAYMENT_LOGS_LOADING = "GET_USER_PAYMENT_LOGS_LOADING";
export const GET_USER_PAYMENT_LOGS_DATA = "GET_USER_PAYMENT_LOGS_DATA";

export const GET_EARNING_COMMENTS_LOADING = "GET_EARNING_COMMENTS_LOADING";
export const GET_EARNING_COMMENTS_DATA = "GET_EARNING_COMMENTS_DATA";

export const GET_ALL_EARNING_LESSONS_LOADING =
  "GET_ALL_EARNING_LESSONS_LOADING";
export const GET_ALL_EARNING_LESSONS_DATA = "GET_ALL_EARNING_LESSONS_DATA";

export const GET_MY_EARNING_LESSONS_DATA = "GET_MY_EARNING_LESSONS_DATA";
export const GET_MY_EARNING_LESSONS_LOADING = "GET_MY_EARNING_LESSONS_LOADING";

export const PAY_USER_EARNING_LOADING = "PAY_USER_EARNING_LOADING";
export const PAY_USER_EARNING = "PAY_USER_EARNING";

export const ADD_EARNING_COMMENT_LOADING = "ADD_EARNING_COMMENT_LOADING";
export const ADD_EARNING_COMMENT = "ADD_EARNING_COMMENT";

export const GET_EARNING_USER_DATA = "GET_EARNING_USER_DATA";
export const GET_EARNING_USER_LOADING = "GET_EARNING_USER_LOADING";
