import { HEADER_TOKEN } from "../../../../config";
import axios from "axios";

export const rolesServices = {
  cloneRoles,
  fetchData,
  fetchUsersByRole,
  addData,
  editData,
  deleteData,
  userPermissions,
  getroleDetails
};


function fetchData(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/role?page=${data.pageNumber}&limit=${process.env.REACT_APP_LIMIT}`
  if(data.name){
    url=`${url}&q=${data.name}`
  }
  return axios
    .get(url, HEADER_TOKEN)
    .then((response) => response);
}

function getroleDetails(_id) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/role/${_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
function fetchUsersByRole(_id) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/role/users/${_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}


function userPermissions() {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/role/permissions`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function addData(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/role`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function cloneRoles(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/role/clone`,
      data,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function editData(data) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/role/${data._id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

function deleteData(_id) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/role/${_id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}
