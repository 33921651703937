// UserLocationStyles.js
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";

const locationStyling = makeStyles((theme) => ({
  locationOuterContainer: {
    width: "100%",
    height: "100%",
    marginTop: "41px",
    marginBottom: "100px !important",
  },
  locContainer: {
    width: "100%",
    height: "100%",
    display: "flex !important",
    flexDirection: "column !important",
    gap: "10px !important",
    padding: "0px 1.5rem",
  },
  locCardContainer: {
    width: "100%",
    borderRadius: "10px",
    height: "230px",
    backgroundColor: "#eef2fb",
    padding: "16px",
  },
  locCardFirstRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  locCardSecRow: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  locCardLocationText: {
    fontSize: "12px !important",
    fontFamily: "Roboto, sans-serif !important",
    fontWeight: "500 !important",
    color: "black",
    lineHeight: "14.75px !important",
  },
  locCardPayrollLabel: {
    fontSize: "12px !important",
    fontFamily: "Roboto, sans-serif !important",
    fontWeight: "500 !important",
    color: "#007E63",
    lineHeight: "14.75px !important",
  },
  redSwitch: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#007E63",
    },
    "& .MuiSwitch-switchBase.Mui-checked:hover": {
      backgroundColor: alpha("#007E63"),
      color: "#007E63",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#007E63",
    },
  },
  rootCheckbox: {
    color: "#007E63",
    "&.Mui-checked": {
      color: "#007E63",
    },
    "&:hover": {
      color: "#007E63",
    },
  },
  selectField: {
    "& .MuiSelect-select": {
      fontSize: "12px !important",
      fontWeight: 400,
      lineHeight: "15.75px",
      fontFamily: "Roboto, sans-serif",
      color: "black",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E3E3E3", // Default border color
      borderRadius: "6px", // Border radius
      borderWidth: "1px", // Border width
    },
    // Hover effect - change border color to #007E63
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#007E63",
    },
    // Focus effect - change border color to #007E63
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#007E63",
    },
    "& .MuiInputLabel-root": {
      fontSize: "12px",
      color: "#E3E3E3",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      lineHeight: "13.75px",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#007E63",
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: "14px",
      color: "#E3E3E3",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      lineHeight: "15.75px",
    },
  },
  checkbox: {
    color: "#030303 !important",
    "&.Mui-checked": {
      color: "#007E63 !important",
    },
    "&:hover": {
      color: "#007E63",
    },
  },
  divider: {
    width: "100%",
    color: "#E3E3E3",
  },

  //Unchecked Cards
  locCardUnCheckedContainer: {
    width: "100%",
    height: "60px",
    padding: "10px 5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default locationStyling;
