// CustomSelect.js
import React from 'react';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  selectFieldLabel: {
    fontSize: "12px !important",
    color: "#E3E3E3",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    lineHeight: "13.75px",
  },

  selectField: {
    "& .MuiSelect-select": {
      fontSize: "12px !important",
      fontWeight: 400,
      lineHeight: "15.75px",
      fontFamily: "Roboto, sans-serif",
      color: "black",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E3E3E3", // Default border color
      borderRadius: "6px", // Border radius
      borderWidth: "1px", // Border width
    },
    // Hover effect - change border color to #007E63
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#007E63",
    },
    // Focus effect - change border color to #007E63
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#007E63",
    },
    "& .MuiInputLabel-root": {
      fontSize: "12px",
      color: "#00000050 !important",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      lineHeight: "13.75px",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#007E63",
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: "14px",
      color: "#00000050 !important",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      lineHeight: "15.75px",
    },
  },
});

const CustomSelect = ({ label, name, value, onChange, options, ...props }) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth variant="outlined" className={classes.selectField} size="small" {...props}>
      <InputLabel className={classes.selectFieldLabel}>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        className={classes.selectField}
        inputProps={{
          style: {
            color: "#00000050",
            fontSize: "14px",
            lineHeight: "16.41px",
            fontFamily: "Roboto",
            fontWeight: "400",
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
